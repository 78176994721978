import { BASE_URL } from 'api/url_helper';
import { useAxiosRequest } from "api/axiosRequest"
import { PoolPortalOwners, PoolPortalOwner } from '../helpers/types'
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const OwnerService = () => {
  const request = useAxiosRequest()
  const getOwners = async () => {
    try {
      const ownersUrl = `${POOLPORTAL_API_BASE_URL}/owners`
      const response = await request<any, PoolPortalOwners>(
        {
          url: ownersUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const createOwner = async (newOwner: PoolPortalOwner) => {
    try {
      const response = await request<any, PoolPortalOwner>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/owner`,
          method: "POST",
          data: newOwner
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateOwner = async (updatedOwner: PoolPortalOwner) => {
    try {
      const response = await request<any, PoolPortalOwner>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/owner`,
          method: "PUT",
          data: updatedOwner
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteOwner = async (id: string) => {
    try {
      const response = await request<any, PoolPortalOwner>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/owner?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getOwners,
    createOwner,
    updateOwner,
    deleteOwner
  }
}

export default OwnerService
