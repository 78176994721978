import React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { PowerBIEmbed } from "powerbi-client-react"

//Import Components
import AlertError from "./Alert/AlertError"

const PowerBi = (props) => {
  if (props.hasPerms) {
    return (
      <React.Fragment>
        {props.embedToken && (
          <Card bg="white">
            <PowerBIEmbed
              embedConfig={props.embedConfig}
              cssClassName={"powerbi-report-style"}
            />
          </Card>
        )}
      </React.Fragment>
    )
  }

  return (
    <AlertError
      title="Sorry! "
      text="You do not have the relevant permissions to access to this page."
    />
  )
}

PowerBi.propTypes = {
  embedToken: PropTypes.object,
  embedConfig: PropTypes.object,
  hasPerms: PropTypes.bool,
}

export default PowerBi
