//Import Packages
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { Auth0Provider } from "@auth0/auth0-react"
import { HelmetProvider } from "react-helmet-async"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

//Import Components
import "./locales/i18n"
import { App } from "./App"
import { store } from "./store"
import * as serviceWorker from "./serviceWorker"

//Import Helpers
import { getConfig } from "./helpers/Auth0/config_helper"
import { ProSidebarProvider } from "react-pro-sidebar"

const config = getConfig()

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  // cacheLocation: "localstorage",
  redirectUri: window.location.origin,
}

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("./mocks/browser")
//   worker.start({
//     onUnhandledRequest: "bypass",
//   })
// }

const queryClient = new QueryClient()

const app = (
  <Auth0Provider {...providerConfig}>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ProSidebarProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </ProSidebarProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </Auth0Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
