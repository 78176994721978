import Breadcrumbs from "components/Common/Breadcrumbs"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import MainAdminPoolPortal from "./MainAdminPoolPortal"

export function AdminContainer() {
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>Pool Portal | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div className="PoolPortal_BreadcrumbBar">
            <Breadcrumbs title="Admin" breadcrumbItem="Pool Portal" />
          </div>
          <MainAdminPoolPortal />
        </Container>
      </div>
    </>
  )
}
