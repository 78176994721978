import moment from "moment"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { VesselStay } from "../hooks/useFetchVesselStays"
import { useFetchVesselPositions } from "../hooks/useFetchVesselPositions"
import { VesselDetails } from "../hooks/usePrevetAPI"
import { VesselSegmentInputSelection } from "./PrevetMain"
import { PrevetModalInfo, TimeWindow } from "./PrevetModalInfo"
import { PrevetModalMap } from "./PrevetModalMap"

interface PrevetVesselTradingModalProps {
  vesselDetails: VesselDetails
  vesselStays: VesselStay[]
  chosenSegment: VesselSegmentInputSelection
  isTradingModalShown: boolean
  closeTradingModal: () => void
  dateForCenterMarker: string | undefined
}

const defaultTimeWindow = { label: "6 Month", value: 6 }

function calculateTimeWindow(date: string | undefined): TimeWindow {
  if (!date) {
    return defaultTimeWindow
  }
  const start = moment(date, "YYYY-MM-DD")
  const end = moment(Date.now())
  const diff = moment.duration(start.diff(end)).asMonths()
  const monthsBetween = Math.ceil(Math.abs(diff))

  if (monthsBetween <= 1) {
    return { label: "1 Month", value: 1 }
  } else if (monthsBetween < 3) {
    return { label: "3 Months", value: 3 }
  } else if (monthsBetween <= 6) {
    return { label: "6 Months", value: 6 }
  }

  return defaultTimeWindow
}

export function PrevetVesselTradingModal({
  chosenSegment,
  isTradingModalShown,
  closeTradingModal,
  dateForCenterMarker,
  vesselDetails,
  vesselStays,
}: PrevetVesselTradingModalProps) {
  const [timeWindow, setTimeWindow] = useState<TimeWindow>(defaultTimeWindow)
  const { vesselPositions } = useFetchVesselPositions({
    imo: vesselDetails.imo,
    segment: chosenSegment.value,
    months: timeWindow.value,
  })

  const [showTradingPatternMarkers, setShowTradingPatternMarkers] =
    useState(true)

  const [showPortStayMarkers, setShowPortStayMarkers] = useState(true)

  useEffect(() => {
    // This useEffect runs when the modal is opened from user selecting
    // a point on the SpeedGraph.
    const tw = calculateTimeWindow(dateForCenterMarker)
    setTimeWindow(tw)
  }, [dateForCenterMarker])

  return (
    <Modal
      className="PrevetVesselTradingModal"
      size="xl"
      show={isTradingModalShown}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="OverviewModalLabel">
          Vessel / Trading Information
        </h5>
        <button
          onClick={() => {
            closeTradingModal()
          }}
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="PrevetVesselTradingModal_Row">
        <div className="PrevetVesselTradingModal_Map">
          {vesselPositions && (
            <PrevetModalMap
              vesselStays={vesselStays}
              vesselPositions={vesselPositions}
              showPortStayMarkers={showPortStayMarkers}
              showTradingPatternMarkers={showTradingPatternMarkers}
              dateForCenterMarker={dateForCenterMarker}
            />
          )}
        </div>
        <div className="PrevetVesselTradingModal_Info">
          <PrevetModalInfo
            vesselDetails={vesselDetails}
            timeWindow={timeWindow}
            setTimeWindow={({ label, value }: TimeWindow) => {
              setTimeWindow({ label, value })
            }}
            showTradingPatternMarkers={showTradingPatternMarkers}
            setShowTradingPatternMarkers={setShowTradingPatternMarkers}
            showPortStayMarkers={showPortStayMarkers}
            setShowPortStayMarkers={setShowPortStayMarkers}
          />
        </div>
      </div>
    </Modal>
  )
}
