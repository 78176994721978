import moment from "moment"
import { VesselStay } from "../hooks/useFetchVesselStays"

export function PrevetPortStaysCautionMessage({
  cautionStay,
}: {
  cautionStay: VesselStay | null
}) {
  if (!cautionStay) {
    return <></>
  }

  const cautionDateRaw = cautionStay.date_from as string

  const cautionDate = moment(new Date(cautionDateRaw))
  const formattedDate = cautionDate?.format("DD MMM [']YY")
  // const monthsAgo = cautionDate?.fromNow()
  const port = cautionStay.port ? cautionStay.port : ""
  const duration = cautionStay.duration ? cautionStay.duration : ""
  // const message = `Prolonged Port Stay: ${formattedDate} (${monthsAgo}) ${port} ${duration} Days`
  const message1 = `Prolonged Port Stay: ${duration} Days`
  const message2 = `${port} (${formattedDate})`

  return (
    <div
      className={`PrevetDryDockBadge--${
        cautionStay.duration >= 20 ? "danger" : "warning"
      } d-flex align-items-center PrevetDryDockBadge mb-1`}
    >
      <div className="d-flex">
        <div>
          <i className="ti-alert me-2" />
        </div>
        <div className="d-flex flex-column">
          <span>{message1}</span>
          <span>{message2}</span>
        </div>
      </div>
    </div>
  )
}
