import { useQuery } from "@tanstack/react-query";
import { usePortPredictorAPI } from "./usePortPredictorAPI";

export function useFetchVesselDetails(imo: number | undefined) {
  const { getVesselDetails } = usePortPredictorAPI()

  const {
    isLoading: isFetchVesselDetailsLoading,
    isError: isFetchVesselDetailsError,
    isSuccess: isFetchVesselDetailsSuccess,
    data: vesselDetailsData
  } = useQuery({
    queryKey: ["port-predictor/getVesselDetails", imo],
    queryFn: () => getVesselDetails(imo as number).catch((err: unknown) => {
      return Promise.reject(new Error(`Unable to fecth vessel-stays for IMO ${imo}`))
    }),
    enabled: !!imo,
  })

  return {
    isFetchVesselDetailsError,
    isFetchVesselDetailsLoading,
    isFetchVesselDetailsSuccess,
    vesselDetailsData
  }
}