import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { AppRoutesFlattened } from "user/userSlice"

const defaultPaths = ["/prevetting", "/port-predictor"]

export function useRedirectWithPermissions(
  permissions: string[] | null | undefined
) {
  const navigate = useNavigate()
  const appRoutesFlattened = useSelector(
    (state: RootState) => state.user.appRoutesFlattened
  )

  useEffect(() => {
    if (!permissions || !appRoutesFlattened) {
      return
    }

    const piorityRedirectPerms = getPiorityRedirectPerms({
      permissions,
      appRoutesFlattened,
    })

    if (window.location.pathname !== "/") {
      const windowPath = window.location.pathname
      if (
        appRoutesFlattened[windowPath]?.path &&
        permissions.includes(appRoutesFlattened[windowPath].permission)
      ) {
        navigate(window.location.pathname)
        return
      }
    }

    const redirectToDefaultPath = redirectTo({
      appRoutesFlattened,
      permissions,
      piorityRedirectPerms,
    })

    if (redirectToDefaultPath) {
      navigate(redirectToDefaultPath)
      return
    }

    for (let i = 0; i < Object.keys(appRoutesFlattened).length; i++) {
      const path = Object.keys(appRoutesFlattened)[i]
      if (
        appRoutesFlattened[path].isPage === true &&
        permissions.includes(appRoutesFlattened[path].permission)
      ) {
        navigate(path)
        return
      }
      navigate("/")
      return
    }
  }, [permissions, appRoutesFlattened])
}

function getPiorityRedirectPerms({
  permissions,
  appRoutesFlattened,
}: {
  permissions: string[]
  appRoutesFlattened: AppRoutesFlattened
}) {
  /**
   * Returns arry of permissions the users has in order of piority to redirect user
   * e.g. [
   * 'read:prevetting',
   * 'read:port-predictor',
   * 'read:fop-isl-richardsbay-paradip',
   * 'read:fop-marubeni-santos-qingdao-s5',
   * 'read:freight-matrix-canpotex'
   * ]
   */

  const result = ["read:prevetting", "read:port-predictor"]
  for (let perm of permissions) {
    const routeName = perm.split("read:")[1]
    if (routeName && routeName.startsWith("fop")) {
      const routeStr = "/freight-optimizer/" + routeName.split("fop-")[1]

      const route = appRoutesFlattened[routeStr]
      if (route) {
        result.push(route.permission)
      }
    }
    if (routeName && routeName.startsWith("freight-matrix")) {
      const routeStr =
        "/freight-matrix/" + routeName.split("freight-matrix-")[1]
      const route = appRoutesFlattened[routeStr]
      if (route) {
        result.push(route.permission)
      }
    }
  }

  return result
}

function redirectTo({
  piorityRedirectPerms,
  permissions,
  appRoutesFlattened,
}: {
  piorityRedirectPerms: string[]
  permissions: string[]
  appRoutesFlattened: AppRoutesFlattened
}) {
  for (let piorityPerm of piorityRedirectPerms) {
    if (permissions.includes(piorityPerm)) {
      const found = Object.keys(appRoutesFlattened).find(
        (key) => appRoutesFlattened[key].permission === piorityPerm
      )

      return found
    }
  }
  return "/"
}
