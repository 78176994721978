/**
 * Returns true if value is null or undefined
 *
 * @param value
 */
export const isNull = (value) => {
    return value === null || value === undefined;
};

/**
 * Returns empty string if value is null or undefined
 *
 * @param str
 * @param placeholder
 */
export const formatNull = (str, placeholder = '') => {
    return isNull(str) ? placeholder : str;
};

/**
 * Makes tooltip string
 *
 * @param value
 * @param label
 * @param placeholder
 */
export const formatTooltip = (value, label = null, placeholder = '') => {
    const separator = label ? ': ' : '';

    return formatNull(label) + separator + formatNull(value, placeholder);
};

/**
 * Makes string with suffix
 *
 * @param value
 * @param suffix
 * @param placeholder
 */
export const formatString = (value, suffix = null, placeholder = '') => {
    return isNull(value) ? placeholder : value + (isNull(suffix) ? '' : ' ' + suffix);
};

/**
 *
 * @param str
 * @param length
 */
export const trimString = (str, length) => {
    return str.length > length ? str.substring(0, length) + '...' : str;
};
