import { useQuery, useQueryClient } from '@tanstack/react-query'
import { PoolPortalDocument } from '../helpers/types'
import DocumentService from './documentsService'


export const useUserDocuments = (docType: string, vessels: string, years: string) => {
  const queryClient = useQueryClient()
  const documentService = DocumentService()

  const { data: userDocuments, isLoading: isLoadingUserDocuments, refetch: refetchUserDocuments } = useQuery({
    queryKey: ['userdocuments', docType, vessels, years], // Include uuid in the query key
    queryFn: ({ queryKey }): any => {
      try {
        return documentService.getPoolUserDocuments(docType, vessels, years)
      } catch (err) {
        return Promise.resolve({} as PoolPortalDocument)
      }
    },
    staleTime: 0,
  })

  return {
    userDocuments,
    isLoadingUserDocuments,
    refetchUserDocuments,
  }
}
