import React, { useEffect, useRef } from 'react';
import { CircleMarker as LeafletCircleMarker, useMapEvent } from 'react-leaflet';

const getRadius = (minRadius, maxRadius, zoom, minZoom = 2, maxZoom = 18) => {
    const delta = (zoom - minZoom) / (maxZoom - minZoom);

    return minRadius + Math.floor(delta * (maxRadius - minRadius));
};

export const CircleMarker = ({
    lon,
    lat,
    minRadius,
    maxRadius,
    weight,
    color,
    opacity,
    setView,
    children,
    ...props
}) => {
    const markerRef = useRef(null);

    const map = useMapEvent('zoomend', () => {
        if (markerRef.current) {
            markerRef.current.setRadius(getRadius(minRadius, maxRadius, map.getZoom()));
        }
    });

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.setRadius(getRadius(minRadius, maxRadius, map.getZoom()));
        }
    }, []);

    if (lon && lat && setView) {
        map.setView([lat, lon], map.getZoom());
    }

    return (
        <>
            <LeafletCircleMarker
                ref={markerRef}
                center={[lat, lon]}
                radius={getRadius(minRadius, maxRadius, map.getZoom())}
                weight={weight}
                color={map.options.colors[color]}
                opacity={opacity}
                stroke
                fill
                {...props}
            >
                {children}
            </LeafletCircleMarker>
        </>
    );
};

CircleMarker.defaultProps = {
    minRadius: 3,
    maxRadius: 10,
    weight: 1,
    color: 'primary',
    opacity: 1,
    setView: false
};

export default CircleMarker;
