import { useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI, VesselPositionState } from "./usePortPredictorAPI"

export function useFetchVesselJourneyStart(imo: number | undefined) {
  const { getChosenVesselState } = usePortPredictorAPI()

  const {
    isLoading: isChosenVesselStateLoading,
    isError: isChosenVesselStateError,
    isSuccess: isChosenVesselStateSuccess,
    data: chosenVesselStateData,
  } = useQuery({
    queryKey: ["portPredictor/getChosenVesselState", imo],
    queryFn: ({ queryKey }) => getChosenVesselState(queryKey[1] as number).then((vesselState) => {
      const newVesselState = { ...vesselState }

      return newVesselState
    }),
    enabled: imo !== undefined,
    staleTime: 0, // 10 minutes
  })

  return {
    chosenVesselStateData,
    isChosenVesselStateLoading,
    isChosenVesselStateError,
    isChosenVesselStateSuccess,
  }
}
