import { formatString, trimString } from './format';

/**
 * Makes line formatting for chart component tooltip.
 *
 * @param label
 * @param value
 * @param color
 * @param placeholder
 * @param unit
 * @param maxLen
 */
export const tooltipLine = (
    label,
    value,
    color = null,
    placeholder = '',
    unit = null,
    maxLen = 15
) => {
    let formatted = formatString(value, unit, placeholder);

    if (typeof formatted === 'string') {
        formatted = trimString(formatted, maxLen);
    }

    let colorStyle = color ? `color: ${color};` : '';
    let line = '<div style="display: flex; justify-content: space-between;">';

    line += `<div style="text-align: left; ${colorStyle}">${label}:</div>`;
    line += `<div style="text-align: right;}"><b>${formatted}</b></div>`;
    line += '</div>';

    return line;
};

/**
 * Makes title formatting for chart component tooltip.
 *
 * @param title
 */
export const tooltipTitle = (title) => {
    return `<div style="text-align: left;"><b>${title}</b></div><br/>`;
};
