import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "react-bootstrap"

export function ToasterLogout(props: { info: string }) {
  const { logout } = useAuth0()

  return (
    <div className="d-flex flex-column mb-2 ms-2">
      <div>{props.info}</div>
      <div className="text-center mt-1 mb-3">
        You session has expired, please log out and log back in.
      </div>
      <Button
        onClick={() => {
          localStorage.clear()
          logout({ returnTo: window.location.origin })
        }}
      >
        Log Out
      </Button>
    </div>
  )
}
