import React, { useEffect, useState } from 'react';

import moment from 'moment';
import Select from 'react-select'

import { useFetchVessels } from './api/vessels';
import { useFetchPositions } from './api/positions';
import { EChartPanel } from './components/chart/EChartPanel';
import { MapPanel } from './components/map/MapPanel';
import { Line } from './components/map/Line';
import { Marker } from './components/map/Marker';
import { Tooltip } from './components/map/Tooltip';
import { useHighlight } from './hooks/useHighlight';
import { tooltipLine, tooltipTitle } from './utils/chart';
import { isValidArray } from "./utils/data";
import { formatTooltip } from "./utils/format";
import { getLastPosition } from "./utils/position";

const chartTooltipFormatter = (series) => {
    const {
        color,
        date,
        speed,
        draught,
        cargo,
        eventSubtype,
        navStaAlias,
        eta,
        destination
    } = series[0];

    let tooltip = '<div style="width: 170px;">';

    tooltip += tooltipTitle(date);
    tooltip += tooltipLine('event', eventSubtype, color);
    tooltip += tooltipLine('status', navStaAlias, color);
    tooltip += tooltipLine('cargo', cargo, color, 'undefined');
    tooltip += tooltipLine('speed', speed, color, '', 'kn');
    tooltip += tooltipLine('draught', draught, color, '', 'm');
    tooltip += tooltipLine('ETA', eta, color);
    tooltip += tooltipLine('master', destination, color, '');
    tooltip += '</div>';

    return tooltip;
};

const Playground = () => {
    // data
    const [vessels, setVessels] = useState([]);

    // filters
    const [resolution, setResolution] = useState(null);

    // selected vessel
    const [vessel, setVessel] = useState(null);
    // selected vessel positions
    const [positions, setPositions] = useState([]);

    // chart settings
    const [chartDatasets, setChartDatasets] = useState([]);
    const [highlight, setHighlight] = useHighlight(null);
    const { fetchVessels } = useFetchVessels()
    const { fetchPositions } = useFetchPositions()

    useEffect(() => {
        fetchVessels('panamax').then((data) => setVessels(data));

        return () => {
            setVessels([]);
        };
    }, []);

    useEffect(() => {
        setChartDatasets([]);
        setHighlight(null);
        if (vessel) {
            fetchPositions(vessel.imo).then(
                (data) => setPositions(data)
            );
        }

        // if (vessel && resolution) {
        //     const dateTo = moment().format('YYYY-MM-DD');
        //     const dateFrom = moment().subtract(30, 'days').format('YYYY-MM-DD');

        //     fetchPositions(vessel.imo, dateFrom, dateTo, resolution.value).then(
        //         (data) => setPositions(data)
        //     );
        // } else {
        //     setPositions([]);
        // }

        return () => {
            setPositions([]);
        };
    }, [vessel, resolution]);

    useEffect(() => {
        if (isValidArray(positions)) {

            setHighlight(getLastPosition(positions));

            setChartDatasets([
                {
                    data: positions,
                    label: 'date',
                    value: 'speed',
                    color: 'red'
                }
            ]);
        } else {
            setHighlight(null);
        }
    }, [positions]);

    const resolutions = [
        { value: 'daily', label: 'Daily' },
        { value: 'hourly', label: 'Hourly' },
    ];

    return (
        <div className="grid p-fluid">
            <div className="col-12">
                <MapPanel height={50} overlays={["positron", "image"]}>
                    {isValidArray(positions) && (
                        <Line
                            points={positions}
                            showMarkers={false}
                            showLine
                            markerProps={{
                                minRadius: 1,
                                maxRadius: 15
                            }}
                            usePointColor={false}
                        />
                    )}

                    {highlight && (
                        <Marker
                            lon={highlight.lon}
                            lat={highlight.lat}
                            heading={highlight.heading}
                            minSize={20}
                            maxSize={80}
                            setView
                        >
                            <Tooltip
                                title={highlight.date}
                                rows={[
                                    formatTooltip(highlight.navStaAlias, 'nav sta'),
                                    formatTooltip(highlight.eventSubtype, 'ops'),
                                    formatTooltip(highlight.speed, 'speed (kn)')
                                ]}
                                opacity={0.75}
                                direction="top"
                                offset={[0, -10]}
                                permanent
                            />
                        </Marker>
                    )}
                </MapPanel>

                {vessel && isValidArray(chartDatasets) && (
                    <EChartPanel
                        datasets={chartDatasets}
                        tooltipFormatter={chartTooltipFormatter}
                        xAxisFormatter={(value) => moment(value).format('DD MMM')}
                        highlight={(series) => setHighlight(series[0])}
                        responsive
                        yMin={0}
                        yMax={17}
                        height="280px"
                    />
                )}
            </div>

            <div className="col-12">
                <div className="card mb-3">
                    <div className="col-4">
                        <Select
                            options={vessels}
                            value={vessel}
                            placeholder="Search by Vessel Name / IMO Number"
                            onChange={setVessel}
                        />
                    </div>

                    <div className="col-4">
                        <Select
                            options={resolutions}
                            value={resolution}
                            onChange={setResolution}
                            placeholder="Select resolution"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const routes = [
    {
        path: '/playground',
        permissionForPage: 'read:playground',
        component: Playground,
    },
];

const settings = {
    routes,
};

export default settings;
