import { ConstructPoolPortalOwnerstoragesettingHeaders } from "modules/PoolPortal/helpers/headers"
import {
  AdminSettingsEnum,
  PoolPortalOwnerstoragesetting,
  PoolPortalOwnerstoragesettings,
} from "modules/PoolPortal/helpers/types"
import { useOwnerstoragesettings } from "modules/PoolPortal/hooks/useOwnerstoragesettingsAPI"
import { FormatEnumToString, GetEntryPageState } from "modules/PoolPortal/utils"
import { useEffect, useState } from "react"
import { Button, Modal, Offcanvas } from "react-bootstrap"
import { SettingsEntryHOC } from "../SettingsHOC"
import { SettingsTable } from "../SettingsTable"

export const OwnerstoragesettingsAdminMain = () => {
  // UseHooks for the Ownerstoragesettings
  const { ownerstoragesettings, isLoading, deleteOwnerstoragesetting } =
    useOwnerstoragesettings()

  const [ownerstoragesettingsData, setOwnerstoragesettingsData] = useState(
    ownerstoragesettings ?? ([] as PoolPortalOwnerstoragesettings)
  )
  const [showEntryBlade, setShowEntryBlade] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [currentOwnerstoragesetting, setCurrentOwnerstoragesetting] = useState(
    {} as PoolPortalOwnerstoragesetting
  )

  useEffect(() => {
    if (ownerstoragesettings) {
      setOwnerstoragesettingsData([...ownerstoragesettings])
    }
  }, [isLoading, ownerstoragesettings])

  const handleEditBladeClose = () => {
    setShowEntryBlade(false)
  }
  const handleEditBladeShow = () => {
    setShowEntryBlade(true)
  }

  const newOwnerstoragesettingsHandler = () => {
    setShowEntryBlade(true)
    setCurrentOwnerstoragesetting({} as PoolPortalOwnerstoragesetting)
  }

  const editOwnerstoragesettingHandler = (id: string) => {
    const data = ownerstoragesettingsData as PoolPortalOwnerstoragesettings
    if (data) {
      var foundIndex = data?.findIndex(
        (x: PoolPortalOwnerstoragesetting) => x.id === id
      )
      setCurrentOwnerstoragesetting(data[foundIndex])
      handleEditBladeShow()
    }
  }

  const deleteOwnerstoragesettingHandler = (id: string) => {
    // show the pop here and set the currentOwnerstoragesetting for deletion
    const data = ownerstoragesettingsData as PoolPortalOwnerstoragesettings
    if (data) {
      var foundIndex = data?.findIndex(
        (x: PoolPortalOwnerstoragesetting) => x.id === id
      )
      setCurrentOwnerstoragesetting(data[foundIndex])
      setShowDelete(true)
    }
  }

  const modalDeleteHandler = async () => {
    //  actual deletion
    await deleteOwnerstoragesetting(currentOwnerstoragesetting.id)
    setShowDelete(false)
  }

  return (
    <>
      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        style={{ zIndex: 5060 }}
        backdropClassName="SettingsDeleteConfirmationBackDrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Ownerstoragesetting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the "
          {currentOwnerstoragesetting.ownerName}" storage setting?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={modalDeleteHandler}>
            Delete
          </Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        className="PoolPortal_SettingsEditOffcanvas"
        show={showEntryBlade}
        onHide={handleEditBladeClose}
        placement="end"
        backdrop="static"
        scroll={false}
        style={{ zIndex: 4060 }}
        backdropClassName="SettingsEditOffCanvassBackDrop"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Settings - {GetEntryPageState(currentOwnerstoragesetting)}{" "}
            {FormatEnumToString(AdminSettingsEnum.OWNERSTORAGESETTING)}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SettingsEntryHOC
            type={AdminSettingsEnum.OWNERSTORAGESETTING}
            data={currentOwnerstoragesetting}
            setShowEntryBlade={setShowEntryBlade}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <SettingsTable
        body={ownerstoragesettingsData!}
        headers={ConstructPoolPortalOwnerstoragesettingHeaders(
          editOwnerstoragesettingHandler,
          deleteOwnerstoragesettingHandler
        )}
        newHandler={newOwnerstoragesettingsHandler}
      />
    </>
  )
}
