import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'
import { PoolPortalPartofmonth } from '../helpers/types'
import PartofmonthService from './partofmonthsService'


export const usePartofmonths = () => {
  const queryClient = useQueryClient()
  const partofmonthService = PartofmonthService()

  // Fetch partofmonths
  const { data: partofmonths, isLoading } = useQuery(['partofmonths'], partofmonthService.getPartofmonths)


  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newPartofmonth: PoolPortalPartofmonth }) =>
      partofmonthService.createPartofmonth(variables.newPartofmonth),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['partofmonths'])
      },
      onError: (error: Error) => {
        console.error("Error during createPartofmonth:", error.message)
      },
    })

  const handleCreate = async (newPartofmonth: PoolPortalPartofmonth): Promise<PoolPortalPartofmonth> => {
    try {
      const createdPartofmonth = await createMutation.mutateAsync({
        newPartofmonth,
      })
      return createdPartofmonth as PoolPortalPartofmonth // Explicitly cast to PoolPortalPartofmonth
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create partofmonth - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { updatedPartofmonth: PoolPortalPartofmonth }) => partofmonthService.updatePartofmonth(variables.updatedPartofmonth), {
    onSuccess: () => {
      queryClient.invalidateQueries(['partofmonths'])
    },
  })

  const handleUpdate = async (updatedPartofmonth: PoolPortalPartofmonth): Promise<PoolPortalPartofmonth> => {
    try {
      const result = await updateMutation.mutateAsync({ updatedPartofmonth })
      return result as PoolPortalPartofmonth
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to update partofmonth - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(partofmonthService.deletePartofmonth, {
    onSuccess: () => {
      queryClient.invalidateQueries(['partofmonths'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete partofmonth - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    partofmonths,
    isLoading,
    createPartofmonth: handleCreate,
    updatePartofmonth: handleUpdate,
    deletePartofmonth: handleDelete
  }
}
