import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"

interface UseDeleteReportArgs {
  handleDeleteReport?: (uuid: string) => void
}

export function useDeleteReport({ handleDeleteReport }: UseDeleteReportArgs) {
  const request = useAxiosRequest()
  const [currentlyDeletingUuid, setCurrentlyDeletingUuid] = useState<
    string | undefined
  >()

  async function deleteReport(uuid: string) {
    if (!handleDeleteReport) {
      return
    }
    try {
      setCurrentlyDeletingUuid(uuid)
      await request<any, unknown>({
        url: "api/v1/reports/single",
        method: "DELETE",
        params: {
          uuid,
        },
      })
      handleDeleteReport(uuid)
    } catch (err: unknown) {
      let message = "Error deleting report"
      if (axios.isAxiosError(err)) {
        const axiosErroMessage = err?.response?.data?.error?.message
        message = axiosErroMessage
          ? message + ": " + axiosErroMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      toast.warn(message, {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setCurrentlyDeletingUuid(undefined)
    }
  }

  return { deleteReport, currentlyDeletingUuid }
}
