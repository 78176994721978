import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Breadcrumb } from "react-bootstrap"

const Breadcrumbs = (props) => {
  return (
    <Row className="align-items-center">
      <Col sm={12}>
        <div className="page-title-box">
          <h4 className="font-size-18">{props.breadcrumbItem}</h4>
          <ol className="breadcrumb mb-0">
            {props.maintitle ? (
              <>
                <Breadcrumb.Item
                  href={props.maintitlelink ? props.maintitlelink : "/#"}
                >
                  {props.maintitle}
                </Breadcrumb.Item>
              </>
            ) : (
              ""
            )}
            <Breadcrumb.Item href={props.titlelink ? props.titlelink : "/#"}>
              {props.title}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{props.breadcrumbItem}</Breadcrumb.Item>
          </ol>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumbs
