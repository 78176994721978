import { Spinner } from "react-bootstrap"
import { MenuItem, SubMenu } from "react-pro-sidebar"
import { Link } from "react-router-dom"
import { AppRouteSingle, useGetUserPermissionsQuery } from "user/userService"

export function MenuIcon({
  icon,
  permissionForPath,
}: {
  icon: string | undefined
  permissionForPath: string
}) {
  const {
    data: permissions,
    error: getPermissionsError,
    isLoading: getPermissionsLoading,
  } = useGetUserPermissionsQuery()

  if (!icon) {
    return <></>
  }

  if (getPermissionsLoading) {
    return <Spinner as="span" animation="border" size="sm" variant="light" />
  }

  if (
    !permissions ||
    getPermissionsError ||
    !permissions?.includes(permissionForPath)
  ) {
    return <i className="ti-lock"></i>
  }

  return <i className={`ti-${icon}`}></i>
}

function isMenuItemDisabled(menuItem: AppRouteSingle) {
  // Disable for those pages that do not have sales pages
  const paths = ["/freight-matrix", "/dashboard", "/pool-portal"]

  return paths.includes(menuItem.path)
}

export function renderProductsMenu({
  currentPath,
  permissions,
  menuItems,
  level,
}: {
  currentPath: string
  permissions: string[]
  menuItems: AppRouteSingle[]
  level: number
}) {
  const subMenu: JSX.Element[] = []

  for (let i = 0; i < menuItems.length; i++) {
    if (!menuItems[i].subMenus) {
      subMenu.push(
        <MenuItem
          key={menuItems[i].path}
          active={currentPath === menuItems[i].path}
          icon={
            menuItems[i].icon ? (
              <MenuIcon
                icon={menuItems[i].icon ? menuItems[i].icon : undefined}
                permissionForPath={menuItems[i].permission}
              />
            ) : null
          }
          disabled={isMenuItemDisabled(menuItems[i])}
          component={
            menuItems[i].externalLink ? (
              <a
                href={menuItems[i].externalLink}
                target="_blank"
                rel="noopener noreferrer"
              />
            ) : (
              <Link to={menuItems[i].path} />
            )
          }
        >
          <div
            className={`${level === 2 && "Sidebar_LevelTwoMenuItemInner"} ${
              level === 3 && "Sidebar_LevelThree"
            }`}
          >
            {menuItems[i].name}
          </div>
        </MenuItem>
      )
    } else if (menuItems[i].subMenus !== undefined) {
      const subMenus = menuItems[i].subMenus as AppRouteSingle[]
      const el = (
        <SubMenu
          key={menuItems[i].path}
          className={`${level === 2 && "Sidebar_LevelTwoSubMenu"}`}
          label={menuItems[i].name}
          defaultOpen={currentPath.startsWith(menuItems[i].path)}
          disabled={!permissions.includes(menuItems[i].permission)}
          icon={
            menuItems[i].icon ? (
              <MenuIcon
                icon={menuItems[i].icon}
                permissionForPath={menuItems[i].permission}
              />
            ) : null
          }
        >
          {renderProductsMenu({
            currentPath,
            permissions,
            menuItems: subMenus,
            level: level + 1,
          })}
        </SubMenu>
      )
      subMenu.push(el)
    }
  }

  return <>{subMenu}</>
}
