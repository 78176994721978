import React from "react"
import { toggleFullscreen } from "helpers/Layout/helper"

const FullScreen = () => {
  return (
    <div className="dropdown d-none d-lg-inline-block">
      <button
        type="button"
        onClick={() => toggleFullscreen()}
        className="btn header-item noti-icon waves-effect"
        data-toggle="fullscreen"
      >
        <i className="mdi mdi-fullscreen"></i>
      </button>
    </div>
  )
}

export default FullScreen
