import { BASE_URL } from 'api/url_helper';
import { useAxiosRequest } from "api/axiosRequest"
import { PoolPortalOwnerstoragesettings, PoolPortalOwnerstoragesetting } from '../helpers/types'
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const OwnerstoragesettingService = () => {
  const request = useAxiosRequest()
  const getOwnerstoragesettings = async () => {
    try {
      const ownerstoragesettingsUrl = `${POOLPORTAL_API_BASE_URL}/ownerstoragesettings`
      const response = await request<any, PoolPortalOwnerstoragesettings>(
        {
          url: ownerstoragesettingsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const createOwnerstoragesetting = async (newOwnerstoragesetting: PoolPortalOwnerstoragesetting) => {
    try {
      const response = await request<any, PoolPortalOwnerstoragesetting>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/ownerstoragesetting`,
          method: "POST",
          data: newOwnerstoragesetting
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateOwnerstoragesetting = async (id: string, updatedOwnerstoragesetting: PoolPortalOwnerstoragesetting) => {
    try {
      const response = await request<any, PoolPortalOwnerstoragesetting>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/ownerstoragesetting`,
          method: "PUT",
          data: updatedOwnerstoragesetting
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteOwnerstoragesetting = async (id: string) => {
    try {
      const response = await request<any, PoolPortalOwnerstoragesetting>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/ownerstoragesetting?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getOwnerstoragesettings,
    createOwnerstoragesetting,
    updateOwnerstoragesetting,
    deleteOwnerstoragesetting
  }
}

export default OwnerstoragesettingService
