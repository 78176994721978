import { fixAntimeridian } from "modules/MapComponent/components/map/positions"
import { MarkerType, PortPredictorJourneyState } from "../portPredictorReducer"
import { MarkerPoint } from "../components/PortPredictorMain"
export interface FuturePortLoc {
  lon: number
  lat: number
  port?: string
  key?: string
  id?: number
  portNumber: number
}
export function getMarkersForFuturePorts(journey: PortPredictorJourneyState) {
  const markerPorts = journey
    .filter((marker) => {
      return marker.markerType === "arrival" && marker.port !== undefined
    })
    .map((marker, i) => {
      if (marker.port) {
        return {
          lon: marker.port.lon,
          lat: marker.port.lat,
          port: marker.port.port,
          key: marker.markerType + marker.id,
          id: marker.id,
          portNumber: i,
        }
      }
      return {}
    })

  const startMarker = journey[0]
  if (
    startMarker &&
    startMarker.markerType === MarkerType.Departure &&
    startMarker.posWkt
  ) {
    /** Add first marker if its an open sea marker
     * This is a fix for vessels that are on the other side of the meridian line
     * e.g. vessel has just left Los Angeles and the next stop is back in Asia, eg Singapore, we
     * need the current location of the vessel to fix the marker for the first stop (in this case, Singapore)
     */
    const startPos = getLatLonFromPosWkt(startMarker.posWkt)
    return (
      fixAntimeridian([startPos, ...markerPorts]) as FuturePortLoc[]
    ).slice(1)
  }

  return fixAntimeridian([...markerPorts]) as FuturePortLoc[]
}

export function getLatLonFromPosWkt(pointString: string) {
  // Remove the "POINT(" prefix and ")" suffix
  const cleanString = pointString.replace("POINT(", "").replace(")", "")

  // Split the string by space to separate longitude and latitude
  const [lon, lat] = cleanString.split(" ").map(Number)

  // Return the object with lat and lon properties
  return { lat, lon }
}

export function getMarkerPointsFromJourneyMarkerState(
  journeyMarkerState: PortPredictorJourneyState
) {
  const points = [] as MarkerPoint[]
  for (let marker of journeyMarkerState) {
    if (marker.markerType === MarkerType.Arrival && marker.port) {
      points.push({ lat: marker.port.lat, lon: marker.port.lon })
    }
    if (
      marker.markerType === MarkerType.Departure &&
      marker.id === 0 &&
      marker.posWkt
    ) {
      const { lat, lon } = getLatLonFromPosWkt(marker.posWkt)
      points.push({ lat, lon })
    }
  }
  return points
}
