import BigNumber from "bignumber.js"
import { PortPredictorVesselSpeed } from "modules/PortPredictor/hooks/usePortPredictorAPI"
import {
  PortPredictorJourneyMarker,
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
  VesselStateLadenOrBallast,
} from "modules/PortPredictor/portPredictorReducer"
import { ChangeEvent, useState } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import Select, { SingleValue } from "react-select"
import { defaultTimeRangeForHistoricalVesselSpeed } from "../PortPredictorTimeline/PortPredictorTimeline"
import { SpeedDataAvgKey, getVesselSpeedDataWithAverages } from "./helpers"
import { getIsChosenVesselSpeedAverageSpeed } from "./PortPredictorDisplayDeparture"
import DatePicker from "react-datepicker"
import moment from "moment"
interface SelectOption {
  label: string
  value: string
}

interface PortPredictorDepartureInputsProps {
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
  currMarker: PortPredictorMarkerDeparture
  prevMarker: PortPredictorMarkerArrival | undefined
  onUpdateMarker: (updatedMarker: PortPredictorJourneyMarker) => void
  showSpeedGraphModal: () => void
  isStartOfJourney: boolean
}

const vesselStates = [
  {
    label: VesselStateLadenOrBallast.Laden,
    value: VesselStateLadenOrBallast.Laden,
  },
  {
    label: VesselStateLadenOrBallast.Ballast,
    value: VesselStateLadenOrBallast.Ballast,
  },
]

export function PortPredictorDepartureInputs({
  currMarker,
  chosenVesselSpeedData,
  onUpdateMarker,
  showSpeedGraphModal,
}: PortPredictorDepartureInputsProps) {
  const speedDataWithAvg = getVesselSpeedDataWithAverages(chosenVesselSpeedData)
  const isChosenVesselSpeedAverageSpeed = getIsChosenVesselSpeedAverageSpeed({
    chosenVesselSpeedData,
    currMarker,
  })

  const defaultMonthsAgoFromToday =
    currMarker.vesselState === VesselStateLadenOrBallast.Laden
      ? speedDataWithAvg?.laden.defaultMonths
      : speedDataWithAvg?.ballast.defaultMonths

  const defaultSpeedKey =
    currMarker.vesselState === VesselStateLadenOrBallast.Laden
      ? speedDataWithAvg?.laden.defaultMonthsKey
      : speedDataWithAvg?.ballast.defaultMonthsKey

  const defaultSpeed =
    currMarker.vesselState === VesselStateLadenOrBallast.Laden
      ? speedDataWithAvg?.laden[defaultSpeedKey as SpeedDataAvgKey]?.avg
      : speedDataWithAvg?.ballast[defaultSpeedKey as SpeedDataAvgKey]?.avg

  const [chosenVesselState, setChosenVesselState] = useState<
    VesselStateLadenOrBallast | undefined
  >(currMarker.vesselState)

  const [chosenVesselSpeed, setChosenVesselSpeed] = useState<string>(
    String(defaultSpeed)
  )

  const [monthsAgoFromToday, setMonthsAgoFromToday] = useState<
    number | undefined
  >(defaultMonthsAgoFromToday)

  const [weatherMargin, setWeatherMargin] = useState<string>("")

  const handleChangeMonthsAgoFromToday = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.id) {
      return
    }

    setMonthsAgoFromToday(Number(event.target.id))
    setWeatherMargin("")

    const monthsKey =
      Number(event.target.id) === 3
        ? SpeedDataAvgKey.threeMonths
        : SpeedDataAvgKey.SixMonths

    const newAvgSpeed =
      chosenVesselState === VesselStateLadenOrBallast.Laden
        ? speedDataWithAvg?.laden[monthsKey].avg
        : speedDataWithAvg?.ballast[monthsKey].avg

    if (!newAvgSpeed) {
      setChosenVesselSpeed("")
      return
    }

    onUpdateMarker({
      ...currMarker,
      vesselSpeedSelected: newAvgSpeed,
      preserveFollowingPortType: true,
    })

    setChosenVesselSpeed(String(newAvgSpeed))
    return
  }

  return (
    <div className="mb-4 PortPredictor_InputsContainer">
      {currMarker.id === 1 && (
        <div className="PortPredictor_InputWrapper mb-2">
          <Form.Label className="PortPredictor_FormLabel fw-bold">
            <>Departure Date</>
          </Form.Label>
          <div className="PortPredictor_InputGroup">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Enter Departure Date"
              selected={currMarker.date ? new Date(currMarker.date) : null}
              onChange={(date) => {
                onUpdateMarker({
                  ...currMarker,
                  date: moment(date).format("YYYY-MM-DD"),
                })
              }}
            />
          </div>
        </div>
      )}
      <div className="PortPredictor_InputWrapper">
        <Form.Label className="PortPredictor_FormLabel fw-bold">
          <div>Vessel State</div>
        </Form.Label>
        <Select
          className="PortPredictor_InputGroup PortPredictor_Select"
          options={vesselStates}
          value={
            chosenVesselState
              ? { label: chosenVesselState, value: chosenVesselState }
              : undefined
          }
          onChange={(vesselState: SingleValue<SelectOption>) => {
            if (!vesselState) {
              return
            }
            const chosenState =
              vesselState.value === VesselStateLadenOrBallast.Laden
                ? VesselStateLadenOrBallast.Laden
                : VesselStateLadenOrBallast.Ballast

            const keyForAvg =
              monthsAgoFromToday === 3
                ? SpeedDataAvgKey.threeMonths
                : SpeedDataAvgKey.SixMonths

            const speed =
              vesselState.value === VesselStateLadenOrBallast.Laden
                ? speedDataWithAvg?.laden[keyForAvg].avg
                : speedDataWithAvg?.ballast[keyForAvg].avg

            if (!speed) {
              setChosenVesselState(chosenState)
              setChosenVesselSpeed("")
              return
            }

            onUpdateMarker({
              ...currMarker,
              vesselState: chosenState,
              vesselSpeedSelected: speed,
              preserveFollowingPortType: false,
            })
            setChosenVesselState(chosenState)
            setChosenVesselSpeed(String(speed))
            setWeatherMargin("")
          }}
        />
      </div>
      <div className="PortPredictor_InputWrapper mt-2">
        <Form.Label className="PortPredictor_FormLabel fw-bold">
          <>Vessel Speed</>
        </Form.Label>
        <InputGroup className="PortPredictor_InputGroup">
          <Form.Control
            className="ms-0"
            placeholder="Average Speed"
            aria-label="Average Speed"
            aria-describedby="avg-speed"
            value={getRoundedValue(chosenVesselSpeed)}
            type="number"
            onChange={(event) => {
              if (event.target.value) {
                setChosenVesselSpeed(event.target.value)
                setWeatherMargin("")
                onUpdateMarker({
                  ...currMarker,
                  vesselSpeedSelected: Number(event.target.value),
                  preserveFollowingPortType: true,
                })
                return
              }
              setChosenVesselSpeed("")
            }}
          />
          <InputGroup.Text id="avg-speed">Kts.</InputGroup.Text>
        </InputGroup>
      </div>
      {(chosenVesselSpeed === undefined || chosenVesselSpeed === "") && (
        <div
          className="d-flex justify-content-end"
          style={{ fontSize: "0.6rem" }}
        >
          {`No data with ${chosenVesselState} status in the last ${monthsAgoFromToday} months`}
        </div>
      )}
      <div className="PortPredictor_InputWrapper mt-2">
        {[defaultTimeRangeForHistoricalVesselSpeed, 6].map((dateRange) => {
          return (
            <div
              key={dateRange}
              className="PortPredictor_CheckBoxSelector_ItemWrapper"
            >
              <Form.Check
                className="p-0  PortPredictor_CheckBoxSelector_Item"
                type="checkbox"
                id={String(dateRange)}
              >
                <div className="PortPredictor_CheckBoxSelector_CheckInput">
                  <Form.Check.Input
                    className="p-0 ms-1 mt-0"
                    style={{ float: "revert" }}
                    type="checkbox"
                    checked={
                      dateRange === monthsAgoFromToday &&
                      isChosenVesselSpeedAverageSpeed
                    }
                    onChange={handleChangeMonthsAgoFromToday}
                  />
                  <Form.Check.Label
                    style={{ width: "unset" }}
                    className="m-0 ms-1 mt-0"
                  >
                    {dateRange} mths avg. speed
                  </Form.Check.Label>
                </div>
              </Form.Check>
            </div>
          )
        })}
      </div>
      <div className="PortPredictor_InputWrapper mt-2 mb-2">
        <Form.Label className="PortPredictor_FormLabel fw-bold">
          <>Sea Margin</>
        </Form.Label>
        <InputGroup className="PortPredictor_InputGroup">
          <Form.Control
            className="ms-0"
            placeholder="Est. margin in %"
            aria-label="Weather Margin"
            aria-describedby="weather-margin"
            disabled={!chosenVesselSpeed}
            value={weatherMargin}
            type="number"
            onChange={(event) => {
              if (!chosenVesselSpeed) {
                return
              }

              // TODO: Extract this and do tests
              const currentVesselSpeed = Number(chosenVesselSpeed)
              const prevWeatherMargin = Number(weatherMargin)
              setWeatherMargin(event.target.value)

              if (event.target.value === "") {
                onUpdateMarker({
                  ...currMarker,
                  vesselSpeedSelected: defaultSpeed,
                  preserveFollowingPortType: true,
                })
                setChosenVesselSpeed(String(defaultSpeed))
              }

              if (event.target.value) {
                const newWeatherMargin = Number(event.target.value)

                if (prevWeatherMargin === 0) {
                  const newBase1 = calcDiscount({
                    base: currentVesselSpeed,
                    percentToDeduct: newWeatherMargin,
                  })

                  onUpdateMarker({
                    ...currMarker,
                    vesselSpeedSelected: newBase1,
                  })

                  setChosenVesselSpeed(String(newBase1))
                  return
                }

                const origAmount = calcOriginalAmountFromDiscountedValue({
                  discountedValue: currentVesselSpeed,
                  percentageDeducted: prevWeatherMargin,
                })
                const newBase2 = calcDiscount({
                  base: origAmount,
                  percentToDeduct: newWeatherMargin,
                })

                onUpdateMarker({
                  ...currMarker,
                  vesselSpeedSelected: newBase2,
                })
                setChosenVesselSpeed(String(newBase2))
                return
              }

              if (!event.target.value) {
                const origAmount = calcOriginalAmountFromDiscountedValue({
                  discountedValue: currentVesselSpeed,
                  percentageDeducted: prevWeatherMargin,
                })

                onUpdateMarker({
                  ...currMarker,
                  vesselSpeedSelected: origAmount,
                })
                setChosenVesselSpeed(String(origAmount))
                return
              }

              setWeatherMargin("")
            }}
          />
          <InputGroup.Text id="avg-speed">%</InputGroup.Text>
        </InputGroup>
      </div>
      <div className="PortPredictor_InputWrapper d-flex mt-1 fs-sm">
        <span>Open Speed Graph</span>
        <Button
          className="d-flex justify-content-center align-items-center p-1 ms-1"
          onClick={() => showSpeedGraphModal()}
        >
          <i className="ti-fullscreen m-0" style={{ fontSize: "10px" }} />
        </Button>
      </div>
    </div>
  )
}

function calcDiscount({
  base,
  percentToDeduct,
}: {
  base: number
  percentToDeduct: number
}) {
  const percentInDecimals = percentToDeduct / 100
  const multiplyBy = 1 - percentInDecimals
  return base * multiplyBy
}

function calcOriginalAmountFromDiscountedValue({
  discountedValue,
  percentageDeducted,
}: {
  discountedValue: number
  percentageDeducted: number
}) {
  const percentInDecimals = percentageDeducted / 100
  const decimalMultiplier = 1 - percentInDecimals
  return discountedValue / decimalMultiplier
}

function getRoundedValue(weatherMargin: string) {
  if (!weatherMargin) {
    return ""
  }
  return BigNumber(weatherMargin)
    .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
    .toString()
}
