import React, { useEffect, useRef } from "react"
import { MapContainer, TileLayer } from "react-leaflet"
import L from "leaflet"
import "leaflet.fullscreen"
import { useMap } from "react-leaflet";

export const Map = ({ width, height, children, overlay, ...props }) => {
  const map = useRef(null)

  useEffect(() => {
    return () => {
      if (map.current) {
        map.current.off()
        map.current.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (map.current) {
      map.current.invalidateSize()
    }
  }, [width, height])

  useEffect(() => {
    if (map.current) {
      if (props.scrollWheelZoom) {
        map.current.scrollWheelZoom.enable()
      } else {
        map.current.scrollWheelZoom.disable()
      }
    }
  }, [props.scrollWheelZoom])

  return (
    <div className="mb-3" style={{ height: height, width: width }}>
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        whenCreated={(mapInstance) => {
          map.current = mapInstance
        }}
        {...props}
      >
        {children}
        <FullscreenControl />
      </MapContainer>
    </div>
  )
}

Map.defaultProps = {
  width: "100%",
  height: "100%",
  center: [51.9, 4.5],
  zoom: 4,
  scrollWheelZoom: false,
  wheelPxPerZoomLevel: 150,
  zoomControl: true,
  minZoom: 2,
  maxZoom: 18,
  worldCopyJump: false,
  maxBounds: [
    [-90, -720],
    [90, 720],
  ],
}

export const PositronMap = ({ width, height, children, ...props }) => {
  return (
    <Map width={width} height={height} {...props}>
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution="&copy; OpenStreetMap contributors"
      />
      {children}
    </Map>
  )
}

PositronMap.defaultProps = {
  colors: {
    primary: "#ff6459", //red-400
    secondary: "#1c80cf", //blue-600
    red: "#ff6459", //red-400h
    lightblue: "#4baaf5", //blue-400
    blue: "#1c80cf", //blue-600
    orange: "#f79530", //orange-400
    yellow: "#fbc02d", //yellow-500
    lightgreen: "#6ebe71", //green-400
    green: "#419544", //green-600
    purple: "#9c27b0", //purple-500
    pink: "#ed4981", //pink-400
    gray: "#607d8b", //bluegray-500
  },
  overlay: "positron",
}

export const ImageMap = ({ width, height, children, ...props }) => {
  return (
    <Map width={width} height={height} {...props}>
      <TileLayer
        // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        url="https://mts1.google.com/vt/lyrs=s,h&hl=en&src=app&x={x}&y={y}&z={z}&s=G"
        // url="https://mts1.google.com/vt/lyrs=s&hl=en&src=app&x={x}&y={y}&z={z}&s=G"
        attribution="&copy; Esri"
      />
      {children}
    </Map>
  )
}

ImageMap.defaultProps = {
  colors: {
    primary: "#00ffff",
    secondary: "#ffff00",
    red: "#ff0000",
    lightblue: "#00ffff",
    blue: "#0000ff",
    orange: "#ff8000",
    yellow: "#ffff00",
    lightgreen: "#80ff00",
    green: "#00ff00",
    purple: "#ff00ff",
    pink: "#ff00ff",
    gray: "#607d8b", //bluegray-500
  },
  overlay: "image",
}

export function FullscreenControl(props) {
  const map = useMap();
  const ctrl = useRef(L.control.fullscreen(props));

  useEffect(() => {
      ctrl.current.addTo(map);

      return () => {
          ctrl.current.remove();
          ctrl.current.link.remove();
      };
  });

  return null;
}