import React, { useEffect, useRef } from "react"
import { Marker as LeafletMarker, useMapEvent } from "react-leaflet"

import L from "leaflet"
import "leaflet-rotatedmarker"

const getIconSize = (
  minSize: number,
  maxSize: number,
  zoom: number,
  minZoom = 2,
  maxZoom = 18
) => {
  const delta = (zoom - minZoom) / (maxZoom - minZoom)

  return minSize + Math.floor(delta * (maxSize - minSize))
}

const createMarkerIcon = (size = 30) => {
  return new L.Icon({
    iconUrl: `/images/marker-icon-default.svg`,
    iconRetinaUrl: `/images/marker-icon-default.svg`,
    iconSize: [size, size],
    className: "leaflet-div-icon",
  })
}

export function Marker({
  lon,
  lat,
  heading = 0,
  minSize = 20,
  maxSize = 80,
  setView,
  children,
  openPopup = false,
  isDefaultIcon = true,
  ...props
}: {
  lat: number
  lon: number
  heading?: number
  minSize?: number
  maxSize?: number
  setView?: any
  children?: JSX.Element
  openPopup?: boolean
  isDefaultIcon?: boolean
}) {
  const markerRef = useRef<L.Marker>(null)

  const map = useMapEvent("zoomend", () => {
    if (markerRef.current) {
      if (!isDefaultIcon) {
        markerRef.current.setIcon(createIcon())
      }
    }
  })

  useEffect(() => {
    if (markerRef.current) {
      if (!isDefaultIcon) {
        markerRef.current.setIcon(createIcon())
      }
      markerRef.current.openPopup()
    }
  }, [])

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setRotationAngle(heading)
      markerRef.current.setRotationOrigin("center center")
    }
  }, [heading])

  if (lon && lat && setView) {
    map.setView([lat, lon], map.getZoom())
  }

  const createIcon = () => {
    const size = getIconSize(minSize, maxSize, map.getZoom())

    return createMarkerIcon(size)
  }

  return (
    <>
      <LeafletMarker
        ref={markerRef}
        // icon={createIcon()}
        position={[lat, lon]}
        opacity={1}
        {...props}
      >
        {children}
      </LeafletMarker>
    </>
  )
}

export default Marker
