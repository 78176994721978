import { Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap"
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
  TableRow,
} from "react-bs-datatable"
import { DDCategories, VesselStay } from "../hooks/useFetchVesselStays"
import { TABLE_HEADERS } from "./PrevetVesselHistoryTableHeaders"

interface PrevetVesselHistoryProps {
  vesselStays: VesselStay[]
}

const renderTooltip = () => {
  return (
    <Popover id="popover-basic">
      <Popover.Body>Prolonged Port Stay</Popover.Body>
    </Popover>
  )
}

export function PrevetVesselHistory({ vesselStays }: PrevetVesselHistoryProps) {
  const TABLE_BODY = vesselStays

  return (
    <DatatableWrapper
      body={TABLE_BODY}
      headers={TABLE_HEADERS}
      sortProps={{
        initialState: {
          prop: "date",
          order: "desc" as const,
        },
      }}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 50, 100],
        },
      }}
    >
      <Row className="PrevetVesselHistory_Header mb-4 p-2">
        <Col xs={12} className="d-flex justify-content-between">
          <h4 className="flex-1 card-title mt-2 mb-1">Historical Voyages</h4>
          <Filter
            classes={{
              inputGroup: "PrevetVesselHistory_FilterInput",
              clearButton: "PrevetVesselHistory_ClearButton",
            }}
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
        ></Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        ></Col>
      </Row>
      <Table>
        <TableHeader />
        <TableBody
          rowProps={(row: VesselStay) => {
            if (row.yard_activity && DDCategories.includes(row.yard_activity)) {
              return { className: "PrevetVesselHistory_Row--success" }
            }
            if (row.duration >= 20) {
              return {
                className: "PrevetVesselHistory_Row--danger",
              }
            }
            if (row.duration >= 10) {
              return {
                className: "PrevetVesselHistory_Row--warning",
              }
            }
            // if (row.yard_duration) {
            //   return { className: "PrevetVesselHistory_Row--success" }
            // }
            return {}
          }}
          children={(rows) => {
            return rows.map((data, idx) => {
              const rowColorClass = getRowColor(data)
              if (data.duration >= 10) {
                return (
                  <OverlayTrigger
                    key={`${data.date_from}_${data.date_to}`}
                    overlay={renderTooltip()}
                    placement="top"
                  >
                    {({ ref, ...triggerHandler }) => (
                      <TableRow
                        key={`${data.date_from}_${data.date_to}`}
                        classes={{ tr: `${rowColorClass}` }}
                        rowData={data}
                        rowIdx={idx}
                        rowProps={{ ref: ref, ...triggerHandler }}
                      />
                    )}
                  </OverlayTrigger>
                )
              }
              return (
                <TableRow
                  key={`${data.date_from}_${data.date_to}`}
                  classes={{ tr: `${rowColorClass}` }}
                  rowData={data}
                  rowIdx={idx}
                />
              )
            })
          }}
        />
      </Table>
      <Row className="PrevetVesselHistory_Pagination d-flex flex-row justify-content-between">
        <PaginationOptions labels={{ beforeSelect: "Show results" }} />
        <Pagination />
      </Row>
    </DatatableWrapper>
  )
}

function getRowColor(row: VesselStay) {
  if (row.yard_activity && DDCategories.includes(row.yard_activity)) {
    return "PrevetVesselHistory_Row--success"
  }
  if (row.duration >= 20) {
    return "PrevetVesselHistory_Row--danger"
  }
  if (row.duration >= 10) {
    return "PrevetVesselHistory_Row--warning"
  }
}
