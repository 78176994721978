import { Tooltip as LeafletTooltip } from 'react-leaflet';

const formatTitle = (title) => {
    if (typeof title === 'string') {
        return <b>{title}</b>;
    }

    return title;
};

export const Tooltip = ({ title, rows, key, ...props }) => {
    const addRow = (row, index) => {
        return <div key={index}>{row}</div>;
    };

    return (
        <LeafletTooltip key={key} {...props}>
            <div>
                <div>{formatTitle(title)}</div>
                {rows && (
                    <>
                        <br />
                        {rows.map((row, index) => addRow(row, index))}
                    </>
                )}
            </div>
        </LeafletTooltip>
    );
};

export default Tooltip;
