import React from "react"
import { Card } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { useLocation } from 'react-router-dom'

//Import Components
import Footer from "./Footer"
import Header from "./Header"

//Import Assets
import errorImg from "../../../assets/images/404.png"

const ErrorAuth0 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const error = queryParams.get('error')
  const error_description = queryParams.get('error_description')
  return (
    <React.Fragment>
      <Helmet>
        <title>Oops, Something Went Wrong | Market Manager Platform</title>
      </Helmet>
      <div className="loading-bg d-flex align-items-center pb-0 content-landing">
        <div className="content-center w-100 mb-2">
          <div className="container">
            <Header />
            <div className="row justify-content-center">
              <div className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8 mx-auto">
                <Card>
                  <Card.Body>
                    <div className="row align-items-center">
                      <div className="col-lg-4 col-sm-12 ms-auto">
                        <div className="ex-page-content">
                          <h1 className="mt-4">Oops!</h1>
                          <h4 className="mb-4">Something went wrong.</h4>
                          <p className="mb-5">
                            Sorry, there has been an error. Please contact
                            support for further details.
                          </p>
                          <p className="mb-5">
                            Error : {error} <br />
                            Description : {error_description}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-sm-12 mx-auto">
                        <img
                          src={errorImg}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ErrorAuth0
