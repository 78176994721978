import BigNumber from "bignumber.js"
import ReactEcharts, { EChartsInstance } from "echarts-for-react"
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  memo,
  useEffect,
} from "react"
import { Row } from "react-bootstrap"
import {
  average as findAverage,
  max as findMax,
  min as findMin,
} from "simple-statistics"
import { getSpeedChartOptions } from "../helpers/getSpeedChartOptionsNew"

import { useFetchVesselLegs } from "../hooks/useFetchVesselLegs"
import { PrevetSpeedGraphZoneType } from "../getSpeedChartOptionsHelpers"

export interface SpeedChartClickEvent {
  name: string // date e.g. "2022-08-24"
  data: {
    average: string // e.g. "12.92"
    pos_date: string // e.g "2022-08-24"
    sog: number // 8.4
  }
  seriesName: "BALLAST" | "LADEN"
}

interface PrevetVesselSpeedChartArgs {
  vesselImo: number
  vesselName: string
  isLaden: boolean
  setIsTradingModalShown: React.Dispatch<React.SetStateAction<boolean>>
  setDateForCenterMarker: React.Dispatch<
    React.SetStateAction<undefined | string>
  >
  setAvgSpeed: Dispatch<SetStateAction<undefined | number>>
  setMaxSpeed: Dispatch<SetStateAction<undefined | number>>
  chartInstance: MutableRefObject<any>
  useForMinTreshold: PrevetSpeedGraphZoneType
}

// Do not pass in properties to this chart that will cause it to unnecessarily re-draw
// Edits to the chart should be made imperatively via a ref e.g. chart.current.setOption()
function PrevetVesselSpeedChart({
  vesselImo,
  vesselName,
  isLaden,
  setAvgSpeed,
  setMaxSpeed,
  setIsTradingModalShown,
  setDateForCenterMarker,
  chartInstance,
  useForMinTreshold,
}: PrevetVesselSpeedChartArgs) {
  const { vesselLegsData } = useFetchVesselLegs({
    imo: vesselImo,
  })

  useEffect(() => {
    if (!vesselLegsData || vesselLegsData.length < 1) {
      return
    }
    const filtered = vesselLegsData.filter((data) => data.laden === isLaden)

    if (filtered.length < 1) {
      return
    }
    const avg = findAverage(filtered.map((item) => item.sog))
    const max = findMax(filtered.map((item) => item.sog))

    setAvgSpeed(avg)
    setMaxSpeed(max)
  }, [isLaden, setAvgSpeed, setMaxSpeed, vesselLegsData])

  if (!vesselLegsData) {
    return <div>Loading {isLaden ? "Laden" : "Ballast"} Speed Data...</div>
  }

  const speedData = vesselLegsData.filter((data) => data.laden === isLaden)

  const options6M = getSpeedChartOptions({
    speedDataRaw: speedData,
    vesselName,
    ladenStatus: isLaden ? "laden" : "ballast",
    months: 6,
    showAllZones: false,
    userVesselSpeed: undefined,
    useForMinTreshold,
  })

  return (
    <Row>
      <ReactEcharts
        // Height of container, and by extension the distance between the chart and
        // datazoom component is controlled by height and grid.height
        style={{
          height: "358px",
        }}
        className="PrevetVesselSpeedChart"
        onChartReady={(chart: EChartsInstance) => {
          chartInstance.current = chart
        }}
        option={options6M}
        onEvents={{
          click: (event: SpeedChartClickEvent) => {
            setIsTradingModalShown(true)
            setDateForCenterMarker(event.data.pos_date)
          },
          dataZoom: (event: any, echart: EChartsInstance) => {
            const opts = echart.getOption()
            const startIndex = opts.dataZoom[0].startValue
            const endIndex = opts.dataZoom[0].endValue

            const min = findMin(
              speedData.slice(startIndex, endIndex + 1).map((item) => item.sog)
            )
            const max = findMax(
              speedData.slice(startIndex, endIndex + 1).map((item) => item.sog)
            )
            const avgCalc = findAverage(
              speedData.slice(startIndex, endIndex + 1).map((item) => item.sog)
            )

            const avg = BigNumber(avgCalc)
              .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
              .toNumber()

            setAvgSpeed(avg)
            chartInstance.current.setOption({
              ...opts,
              title: {
                ...opts.title,
                text: `6 Months LADEN (Kt)  Min: ${min}  Max: ${max}  Avg: ${avg}`,
              },
            })
          },
        }}
      />
    </Row>
  )
}

/**
 * Memo will prevent this component from re-rendering when the parent re-renders
 * This prevents the chart from re-drawing itself which looks janky
 */
export const MemoPrevetVesselSpeedChart = memo(PrevetVesselSpeedChart)
