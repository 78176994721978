import { useState } from "react"
import Select, { SingleValue } from "react-select"
import { useDispatch } from "react-redux"
import { setDefaultSegment } from "../../user/userSlice"
import { VesselSegment } from "user/userService"

export type ImoNumber = string

export interface VesselListSingle {
  label: string
  value: ImoNumber
}

export interface VesselSegmentInputSelection {
  label: string
  value: VesselSegment
}

export interface ChosenVesselForSelect {
  imo: number
  segment?: string
}

export interface SelectOption {
  label: string
  value: string
}
interface VesselSearchProps {
  vesselsData: VesselListSingle[] | undefined
  defaultSegment: VesselSegmentInputSelection
  getVesselsLoading: boolean
  getChosenVesselLoading: boolean
  chosenSegment: VesselSegmentInputSelection
  getChosenVessel: ({ name, imo }: { imo: number; name: string }) => void
  setChosenSegment: (segment: VesselSegmentInputSelection) => void
  getVessels: ({ segment }: { segment: string }) => void
  vesselSegments: VesselSegmentInputSelection[]
}

export function VesselSearch({
  vesselsData,
  getVesselsLoading,
  chosenSegment,
  getChosenVessel,
  defaultSegment,
  setChosenSegment,
  getVessels,
  vesselSegments,
}: VesselSearchProps) {
  const dispatch = useDispatch()
  const [chosenVessel, setChosenVessel] = useState<SelectOption | undefined>()

  return (
    <>
      <div className="VesselSearch_Label">
        <h5 className="mb-0">Search Vessel</h5>
      </div>
      <Select
        isDisabled={getVesselsLoading}
        className="VesselSearch_Name"
        options={vesselsData}
        value={chosenVessel ? chosenVessel : null}
        placeholder={
          getVesselsLoading
            ? `Loading ${chosenSegment.label} vessels...`
            : "Search by Vessel Name / IMO Number"
        }
        onChange={(vessel: SingleValue<SelectOption>) => {
          if (!vessel) {
            return
          }
          setChosenVessel(vessel)
          getChosenVessel({ name: vessel.label, imo: Number(vessel.value) })
        }}
      />

      <div className="VesselSearch_Segment">
        <Select
          defaultValue={defaultSegment}
          options={vesselSegments}
          onChange={(segment: SingleValue<VesselSegmentInputSelection>) => {
            if (!segment) {
              return
            }
            setChosenVessel(undefined)
            setChosenSegment(segment)
            dispatch(setDefaultSegment(segment.value as VesselSegment))
            getVessels({ segment: segment.value })
          }}
        />
      </div>
    </>
  )
}
