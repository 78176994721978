import { axiosRequest } from "api/axiosRequest"
import axios from "axios"
import { Button, Card, Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { RootState } from "store"
import { UserData } from "../store/user/interfaces"

interface ResetPasswordRequest {
  client_id: string
  email: string
  connection: "Username-Password-Authentication"
}

export function UserProfileFormNew() {
  const userState = useSelector((state: RootState) => state.user)

  return (
    <Form data-testid="user-profile-form">
      <Card.Body>
        <div>
          <img
            src={userState.user?.picture}
            alt=""
            className="avatar-xl rounded-circle img-thumbnail mx-3"
          />
        </div>
        <div>
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={userState.user?.name}
            disabled
          />
        </div>
        <div>
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            type="text"
            value={userState.user?.email}
            disabled
          />
        </div>
        {userState.user &&
          userState.user.email &&
          process.env.REACT_APP_AUTH0_CLIENTID && (
            <Button
              color="primary"
              onClick={() => {
                async function resetPassword() {
                  try {
                    const responseBody = await axiosRequest<
                      ResetPasswordRequest,
                      any
                    >({
                      baseURL: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
                      url: `/dbconnections/change_password`,
                      method: "POST",
                      data: {
                        email: (userState.user as UserData).email,
                        connection: "Username-Password-Authentication",
                        client_id: process.env
                          .REACT_APP_AUTH0_CLIENTID as string,
                      },
                    })
                    toast.success(responseBody.data, {
                      position: "top-right",
                      autoClose: 8000,
                    })
                  } catch (err) {
                    let message =
                      "Please contact support to reset your password. Error: "
                    if (axios.isAxiosError(err)) {
                      message = message + err?.response?.data?.error
                    } else if (err instanceof Error) {
                      message = message + err.message
                    }
                    toast.warn(message, {
                      position: "bottom-right",
                      autoClose: 8000,
                    })
                  }
                }
                resetPassword()
              }}
            >
              Reset Password
            </Button>
          )}
      </Card.Body>
    </Form>
  )
}
