import { useAxiosRequest } from "api/axiosRequest";
import { BASE_URL } from 'api/url_helper';
import { PoolPortalDocument, PoolPortalDocuments, PoolPortalUsers, PoolPortalVessels } from '../helpers/types';
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const DocumentsService = () => {
  const request = useAxiosRequest()

  const getDocuments = async () => {
    try {
      const documentsUrl = `${POOLPORTAL_API_BASE_URL}/documents`
      const response = await request<any, PoolPortalDocuments>(
        {
          url: documentsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPoolUsers = async () => {
    try {
      const usersUrl = `${POOLPORTAL_API_BASE_URL}/users`
      const response = await request<any, PoolPortalUsers>(
        {
          url: usersUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPoolUserVessels = async () => {
    try {
      const userVesselsUrl = `${POOLPORTAL_API_BASE_URL}/user/vessels`
      const response = await request<any, PoolPortalVessels>(
        {
          url: userVesselsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPoolUserDocuments = async (docType: string, vessels: string, years: string) => {
    try {
      if (docType) {
        const userDocumentsUrl = `${POOLPORTAL_API_BASE_URL}/user/documents?doctype=${docType}&vessels=${vessels}&years=${years}`
        const response = await request<any, PoolPortalDocuments>(
          {
            url: userDocumentsUrl,
            method: "GET"
          }
        );

        return response && response.data;
      }
      return [] as PoolPortalDocuments
    } catch (error) {
      throw error;
    }
  };

  const createDocument = async (newDocument: PoolPortalDocument) => {
    try {
      const response = await request<any, PoolPortalDocument>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/document`,
          method: "POST",
          data: newDocument
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateDocument = async (updatedDocument: PoolPortalDocument) => {
    try {
      const response = await request<any, PoolPortalDocument>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/document`,
          method: "PUT",
          data: updatedDocument
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteDocument = async (id: string) => {
    try {
      const response = await request<any, PoolPortalDocument>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/document?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getDocuments,
    getPoolUsers,
    getPoolUserVessels,
    getPoolUserDocuments,
    createDocument,
    updateDocument,
    deleteDocument
  }
}

export default DocumentsService
