import { useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"

interface UseFetchVesselPositionsArgs {
  imo: string | number | undefined
  months: number
}

export function useFetchVesselPositions({ imo, months }: UseFetchVesselPositionsArgs) {
  const { getVesselPositions } = usePortPredictorAPI()

  const {
    isLoading: isFetchVesselPositionsLoading,
    isError: isFetchVesselPositionsError,
    isSuccess: isFetchVesselPositionsSuccess,
    data: vesselPositionsData,
  } = useQuery({
    queryKey: ["port-predictor/getVesselPositions", imo],
    queryFn: () => getVesselPositions({ imo: imo as number | string, months }).catch((err: unknown) => {
      return Promise.reject(new Error(`Unable to fecth vessel positions for IMO ${imo}`))
    }),
    enabled: !!imo,
    staleTime: 1000 * 60
  })

  return {
    isFetchVesselPositionsError,
    isFetchVesselPositionsLoading,
    isFetchVesselPositionsSuccess,
    vesselPositions: vesselPositionsData
  }
}