import moment from "moment"

import BigNumber from "bignumber.js"
import { LadenStatus, PrevetSpeedGraphZoneType, ballastColor, ballastLegLineColor, ballastMarkAreaColor, calcMarkLineOffset, createAllZones, createLegPieces, createZonesOfConcern, drawLegLines, findIndexOfSinglePointZones, getChartMaxSpeed, getChartMinSpeed, getTitleText, ladenColor, ladenLegLineColor, ladenMarkAreaColor } from "../getSpeedChartOptionsHelpers"
import { VesselLeg } from "../hooks/usePrevetAPI"
import { VesselStateLadenOrBallast } from "modules/PortPredictor/portPredictorReducer"
import {
  average as findAverage,
} from "simple-statistics"


export function getSpeedChartOptions({
  vesselName,
  speedDataRaw,
  ladenStatus,
  months,
  showAllZones,
  userVesselSpeed,
  useForMinTreshold,
}: {
  vesselName: string
  speedDataRaw: VesselLeg[]
  ladenStatus: LadenStatus
  months: number
  showAllZones: boolean
  userVesselSpeed: string | undefined
  useForMinTreshold?: PrevetSpeedGraphZoneType
}) {
  const speedData = speedDataRaw.map((data) => {
    // Chart does not like it when first property is "laden"
    return {
      pos_date: data.pos_date,
      sog: data.sog,
      laden: data.laden,
      zone_code: data.zone_code,
      zone_name: data.zone_name,
      zone_alias: data.zone_alias,
      zone_group: data.zone_group,
      prev_port: data.prev_port,
      next_port: data.next_port
    }
  })

  const averageSpeed = speedData.length > 0 && findAverage(speedData.map((item) => item.sog))

  const titleText = getTitleText({
    speedData: speedDataRaw,
    months,
    ladenStatus,
  })

  const zonesOfConcern = createZonesOfConcern({
    speedData,
    isLaden: ladenStatus === "laden",
    useForMinTreshold: useForMinTreshold as PrevetSpeedGraphZoneType,
  })
  // Hacky code to draw markArea in shadow xAxis around a single data point
  const singlePointZones = zonesOfConcern.filter((zone) => {
    return zone[0].xAxis === zone[1].xAxis
  })

  const indexOfSinglePointZones = findIndexOfSinglePointZones({
    speedData,
    singlePointZones,
  })

  /**
  * Variables to help with custom formatting of x-axis lables
  * prevMonth keeps track of the month at the current index - 1
  */
  let prevMonth = ""

  const options = {
    color: [ladenStatus === "laden" ? ladenColor : ballastColor],
    dataset: [
      {
        source: speedData
      }
    ],
    title: {
      show: true,
      left: "center",
      top: speedData.length < 1 ? "40%" : "auto",
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: "Source Sans Pro",
        width: speedData.length < 1 ? 220 : 310,
        overflow: "break",
      },
    },
    grid: {
      height: 220,
      right: 80,
      left: 40,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          name: vesselName.toLowerCase().replace(" ", "-") + "-6m-speed",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const dp = params[0].data as VesselLeg
        return `
          <div>${moment(dp.pos_date).format("DD-MM-YYYY")}<div>
          <div>Zone: ${dp.zone_name}</div>
          <div>${dp.prev_port} - ${dp.next_port ? dp.next_port : "TBD"}</div>
          <div style="display:flex; justify-content:space-between;">
            <div style="display:flex; align-items:center;">
              <div style="top:-1px; position: relative; width:10px; height:10px; background-color:${ladenStatus === "laden" ? ladenColor : ballastColor}; border-radius:100%; margin-right:4px;"></div>
              <div>${dp.laden ? "LADEN" : "BALLAST"}</div>
            </div>
            <div>${dp.sog}</div>
          </div>
        `
      }
    },
    visualMap: {
      show: false,
      dimension: 0,
      pieces: createLegPieces({ speedData, isLaden: ladenStatus === "laden" })
    },
    xAxis: [
      {
        type: "category",
        axisPointer: {
          type: "shadow",
          label: {
            show: true,
            formatter: function (params: any) {
              return moment(new Date(params.value)).format("DD-MM-YYYY")
            },
          },
        },
        axisLabel: {
          formatter: (params: any, index: number) => {
            const currMonth = moment(params).format("MMM")
            if (index === speedData.length - 1) {
              // Check if we are on the last iteration of the dataset, If so, we always want to
              // return the current month to end the x-axis. However if there is not enough space,
              // chart will not render the last month to cap the x-axis.
              return currMonth
            }
            if (currMonth === prevMonth) {
              return ""
            }
            prevMonth = currMonth
            return currMonth
          },
          margin: 6,
          hideOverlap: true,
        },
      },
      !showAllZones && {
        // Creates a "shadow" xAxis with a 2x grid granularity. This grid helps to draw the markArea
        // around a single data point. Simplified example: https://codepen.io/isachenx/pen/mdojBmQ
        type: "category",
        data: Array.from({ length: speedData.length * 2 }),
        axisPointer: {
          show: false,
        },
        show: false,
      },
    ],
    yAxis: [
      {
        type: "value",
        min: getChartMinSpeed(speedData),
        max:
          userVesselSpeed &&
            Number(userVesselSpeed) >= getChartMaxSpeed(speedData)
            ? Number(userVesselSpeed) + 1
            : getChartMaxSpeed(speedData),
        minInterval: 1,
        axisLabel: {
          formatter: "{value} Kt",
        },
        splitLine: {
          show: false
        }
      }
    ],
    dataZoom: [
      {
        backgroundColor: ladenStatus === "laden" ? "#b2b6c3" : "#ffc9bb",
        fillerColor: ladenStatus === "laden" ? ladenColor : ballastColor,
        bottom: 28,
        textStyle: {
          width: 55,
          color: "#333333",
          fontSize: 10,
        },
        handleSize: "200%",
        moveHandleStyle: {
          opacity: 0,
        },
        type: "slider",
        show: true,
        xAxisIndex: [0, 1],
        height: 10,
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(1,1,1,0)",
          },
          areaStyle: {
            color: "rgba(1,1,1,0)",
          },
        },
        dataBackground: {
          lineStyle: {
            color: "rgba(1,1,1,0)",
          },
          areaStyle: {
            color: "rgba(1,1,1,0)",
          },
        },
      },
    ],
    series: [
      /** Items in series array need to maintain index order for Performance Analysis component updates to the chart instance */
      {
        name: ladenStatus,
        type: "line",
        encode: {
          x: "pos_date",
          y: "sog"
        },
        areaStyle: {
          opacity: 0.35
        },
        symbolSize: 6,
        markLine: {
          symbol: ["none", "none"],
          label: { show: false },
          lineStyle: {
            color: ladenStatus === "laden" ? ballastLegLineColor : ladenLegLineColor,
            type: "dotted",
            width: 2
          },
          data: drawLegLines(speedData)
        },
        markArea: {
          label: {
            fontSize: 8,
          },
          data: showAllZones ? createAllZones({ speedData }) : zonesOfConcern,
        },
      },
      {
        name: `${ladenStatus} Avg`,
        type: "line",
        showSymbol: false,
        color: "transparent",
        tooltip: {
          show: false,
        },
        markLine: {
          precision: 3,
          symbolSize: 7,
          symbol: ["none", "arrow"],
          lineStyle: {
            color: ladenStatus === "laden" ? ladenColor : ballastColor,
            type: "solid",
            opacity: 0.75
          },
          data: [
            {
              type: "average",
              name: "Avg",
              label: {
                offset: [0, 0],
                fontSize: 10,
                formatter: (params: any) => {
                  const value = params.value
                  const rounded = BigNumber(value)
                    .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                    .toString()
                  return `${rounded} Avg`
                },
              },
            },
          ],
        },
      },
      /* Create markArea in the shadow xAxis for single point zones.
      /* Only necessary for the SpeedGraph, but not in modal which shows all zones */
      !showAllZones && {
        type: "line",
        xAxisIndex: 1,
        markArea: {
          itemStyle: {
            opacity: 0.2,
            color:
              ladenStatus === "laden"
                ? ladenMarkAreaColor
                : ballastMarkAreaColor,
          },
          data: indexOfSinglePointZones.map((idx) => {
            return [{ xAxis: idx * 2 }, { xAxis: idx * 2 + 1 }]
          }),
        },
      },
      {
        name: `USR VESSEL`, // Put this on its own markLine because of symbol: none
        type: "line",
        markLine: {
          symbolSize: 7,
          symbol: ["none", "arrow"],
          lineStyle: {
            color: ladenStatus === "laden" ? ladenColor : ballastColor,
            type: "solid",
            opacity: 0.75
          },
          data: [
            {
              name: "User Vsl",
              yAxis: Number(userVesselSpeed),
              label: {
                fontSize: 10,
                offset: averageSpeed ? calcMarkLineOffset({ label: Number(userVesselSpeed), compared: averageSpeed }) : [0, 0],
                formatter: (params: any) => {
                  return `${BigNumber(params.data.yAxis).toFormat(1)} User Vsl`
                },
              },
            },
          ],
        },
      }
    ],
  }
  return options
}

