import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"
import { setDefaultSegment } from "./userSlice"
import type { RootState } from "../store"

export const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: isAnyOf(setDefaultSegment),
  effect: (action, listenerApi) => {
    localStorage.setItem(
      "defaultSegment",
      JSON.stringify(
        (listenerApi.getState() as RootState).user.defaultSegment
      )
    )
  },
})
