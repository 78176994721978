import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authentication } from "../authentication"
import { Organization } from "./userSlice"

export enum VesselSegment {
  Handysize = "handysize",
  Supramax = "supramax",
  Panamax = "panamax",
  Capesize = "capesize"
}

export type AppRouteSingle = {
  path: string
  name: string
  icon?: string
  isPage: boolean
  permission: string
  reportId?: string
  groupId?: string
  pageName?: string
  subMenus?: AppRouteSingle[]
  externalLink?: string
}


export interface UserVesselSegments {
  segments: VesselSegment[]
  default_segment: VesselSegment
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVERAPIURL}/api/v1`,
    prepareHeaders: async (headers) => {
      const access_token = await authentication.getAccessTokenSilently()()
      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getUserPermissions: builder.query<string[], void>({
      query: (endpoint) => ({
        url: "/permissions",
      }),
      extraOptions: { maxRetries: 2 },
    }),
    getUserOrganizations: builder.query<Organization[], void>({
      query: () => ({
        url: "/user-organizations",
      }),
      extraOptions: { maxRetries: 2 },
    }),
    getAppRoutes: builder.query<AppRouteSingle[], void>({
      query: () => ({
        url: "/sidebar",
      }),
      extraOptions: { maxRetries: 2 },
    }),
  }),
})

export const { useGetUserPermissionsQuery, useGetUserOrganizationsQuery, useGetAppRoutesQuery } =
  userApi
