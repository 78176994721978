import { Button, Form, InputGroup } from "react-bootstrap"

import BigNumber from "bignumber.js"
import { LineSeriesOption } from "echarts"
import { MutableRefObject, useEffect, useState } from "react"
import { SegmentSensitivityData } from "../hooks/usePrevetGetSensitivityDataForSegment"
import { calcExtraCostPerDay } from "../helpers/calculateSensitivity"
import { VesselStay } from "../hooks/useFetchVesselStays"
import { getPortStaysData } from "../helpers/getPortStaysData"
import { average as findAverage } from "simple-statistics"
import { calcMarkLineOffset } from "../getSpeedChartOptionsHelpers"

interface PrevetSensitivityCalculatorProps {
  segmentSensitivityData: SegmentSensitivityData | undefined
  ladenChartInstance: MutableRefObject<any>
  ballastChartInstance: MutableRefObject<any>
  speedGraphLadenAvgSpeed: number | undefined
  speedGraphBallastAvgSpeed: number | undefined
  portStaysLadenAvgSpeed: number | undefined
  portStaysBallastAvgSpeed: number | undefined
  realLadenMaxSpeed: number | undefined
  realBallastMaxSpeed: number | undefined
  vesselName: string
  defaultFuelCon: number
  setUserLadenVesselSpeed: (speed: string | undefined) => void
  setUserBallastVesselSpeed: (speed: string | undefined) => void
}

export function PrevetSensitivityCalculator({
  ladenChartInstance,
  ballastChartInstance,
  speedGraphLadenAvgSpeed,
  speedGraphBallastAvgSpeed,
  portStaysLadenAvgSpeed,
  portStaysBallastAvgSpeed,
  realLadenMaxSpeed,
  realBallastMaxSpeed,
  segmentSensitivityData,
  vesselName,
  defaultFuelCon,
  setUserLadenVesselSpeed,
  setUserBallastVesselSpeed,
}: PrevetSensitivityCalculatorProps) {
  const defaultSeaDays = 25
  const standardVesselDefaultSpeed = 12

  const spotIndexName =
    segmentSensitivityData?.benchmark.spot_index_name || "P5TC"
  const defaultHirePrice = segmentSensitivityData?.benchmark.spot_price || ""
  const defaultFuelPrice = segmentSensitivityData?.benchmark.fuel_price || ""

  const [describedLadenSpeed, setDescribedLadenSpeed] = useState(
    String(standardVesselDefaultSpeed)
  )
  const [describedLadenSpeedTouched, setDescribedLadenSpeedTouched] =
    useState(false)

  const [describedBallastSpeed, setDescribedBallastSpeed] = useState(
    String(standardVesselDefaultSpeed)
  )
  const [describedBallastSpeedTouched, setDescribedBallastSpeedTouched] =
    useState(false)

  // Keep track of minor customization to grey out the Std Vsl box
  const [isStandardVesselBoxGray, setIsStandardVesselBoxGray] = useState(false)

  const [ladenSeaDays, setLadenSeaDays] = useState(String(defaultSeaDays))
  const [ballastSeaDays, setBallastSeaDays] = useState(String(defaultSeaDays))

  const [ladenFuelCon, setLadenFuelCon] = useState(String(defaultFuelCon))
  const [ballastFuelCon, setBallastFuelCon] = useState(String(defaultFuelCon))

  const [hirePrice, setHirePrice] = useState(String(defaultHirePrice))
  const [fuelPrice, setFuelPrice] = useState(String(defaultFuelPrice))

  const [performanceExposure, setPerformanceExposure] = useState<
    number | undefined
  >()

  const [vesselComparisonMessage, setVesselComparisonMessage] = useState(
    `Std vsl / ${vesselName}`
  )

  useEffect(() => {
    setHirePrice(String(segmentSensitivityData?.benchmark.spot_price))
  }, [segmentSensitivityData?.benchmark.spot_price])

  useEffect(() => {
    setFuelPrice(String(segmentSensitivityData?.benchmark.fuel_price))
  }, [segmentSensitivityData?.benchmark.fuel_price])

  useEffect(() => {
    const ladenAvgSpeedForCalculation = speedGraphLadenAvgSpeed
      ? speedGraphLadenAvgSpeed
      : portStaysLadenAvgSpeed
    const ballastAvgSpeedForCalculation = speedGraphBallastAvgSpeed
      ? speedGraphBallastAvgSpeed
      : portStaysBallastAvgSpeed

    if (!ladenAvgSpeedForCalculation && !ballastAvgSpeedForCalculation) {
      return
    }

    const performance = calcPerformance({
      describedLadenSpeed,
      describedBallastSpeed,
      speedGraphLadenAvgSpeed: ladenAvgSpeedForCalculation,
      speedGraphBallastAvgSpeed: ballastAvgSpeedForCalculation,
      ladenFuelCon,
      ballastFuelCon,
      hirePrice,
      fuelPrice,
      ladenSeaDays,
      ballastSeaDays,
    })
    setPerformanceExposure(performance)
  }, [
    describedLadenSpeed,
    describedBallastSpeed,
    portStaysLadenAvgSpeed,
    portStaysBallastAvgSpeed,
    speedGraphLadenAvgSpeed,
    speedGraphBallastAvgSpeed,
    ladenFuelCon,
    ballastFuelCon,
    hirePrice,
    fuelPrice,
    ladenSeaDays,
    ballastSeaDays,
  ])

  function updateChart({
    isLaden,
    speed,
    isReset,
  }: {
    isLaden: boolean
    speed: string
    isReset: boolean
  }) {
    if (
      (isLaden && !speedGraphLadenAvgSpeed) ||
      (!isLaden && !speedGraphBallastAvgSpeed)
    ) {
      return
    }
    const avgSpeed = isLaden
      ? speedGraphLadenAvgSpeed
      : speedGraphBallastAvgSpeed

    const chart = isLaden ? ladenChartInstance : ballastChartInstance
    const opts = chart.current.getOption()

    const maxYAxis = isLaden ? realLadenMaxSpeed : realBallastMaxSpeed

    const yAxis = !maxYAxis // Grow or shrink yAxis depending on user input
      ? [{ ...opts.yAxis[0] }]
      : [
          {
            ...opts.yAxis[0],
            max: [
              Number(speed) > maxYAxis // If user input TC desc > speed graph max
                ? Number(speed) + 1
                : BigNumber(maxYAxis + 1)
                    .decimalPlaces(0, BigNumber.ROUND_HALF_CEIL)
                    .toString(),
            ],
          },
        ]

    // When focusing any input, draw the usr vessel line on speed graph
    chart.current.setOption({
      ...opts,
      series: [
        {
          ...(opts.series as LineSeriesOption[])[0],
        },
        {
          ...(opts.series as LineSeriesOption[])[1],
        },
        {
          ...(opts.series as LineSeriesOption[])[2],
        },
        !isReset
          ? {
              name: `User Vsl`,
              markLine: {
                symbolSize: 7,
                lineStyle: {
                  width: 1,
                  opacity: 0.75,
                },
                data: [
                  {
                    name: "user vessel",
                    yAxis: Number(speed),
                    label: {
                      offset: calcMarkLineOffset({
                        label: Number(speed),
                        compared: avgSpeed as number,
                      }),
                      fontSize: 10,
                      formatter: (params: any) => {
                        return `${BigNumber(params.data.yAxis).toFormat(
                          1
                        )} User Vsl`
                      },
                    },
                  },
                ],
              },
            }
          : {
              name: "User Vsl", // Put this on its own markLine because of symbol: none
              type: "line",
              markLine: {
                lineStyle: {
                  width: 0,
                  opacity: 0,
                },
              },
            },
      ],
      yAxis,
    })
  }

  const updateVesselComparisonMessage = () => {
    setVesselComparisonMessage(`User vsl / ${vesselName}`)
  }

  return (
    <div className="PrevetSensitivity">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="card-title mb-0">Performance Estimation</h4>
        <Button
          className="PrevetSensitivity_ResetButton"
          onClick={() => {
            setDescribedLadenSpeed(String(standardVesselDefaultSpeed))
            setDescribedBallastSpeed(String(standardVesselDefaultSpeed))
            setUserLadenVesselSpeed(undefined)
            setUserBallastVesselSpeed(undefined)
            setLadenSeaDays(String(defaultSeaDays))
            setBallastSeaDays(String(defaultSeaDays))
            setLadenFuelCon(String(defaultFuelCon))
            setBallastFuelCon(String(defaultFuelCon))
            setHirePrice(String(defaultHirePrice))
            setFuelPrice(String(defaultFuelPrice))
            setVesselComparisonMessage(`Std vsl / ${vesselName}`)
            setDescribedLadenSpeedTouched(false)
            setDescribedBallastSpeedTouched(false)
            updateChart({
              isLaden: true,
              speed: String(standardVesselDefaultSpeed),
              isReset: true,
            })
            updateChart({
              isLaden: false,
              speed: String(standardVesselDefaultSpeed),
              isReset: true,
            })
            setIsStandardVesselBoxGray(false)
          }}
        >
          Reset{" "}
        </Button>
      </div>
      <div
        className={`PrevetSensitivity_StandardVesselBox PrevetSensitivity_StandardVesselBox--${
          isStandardVesselBoxGray ? "light" : "dark"
        }`}
      >
        <div className="PrevetSensitivity_StandardVesselTitle PrevetSensitivity_Label">
          Std Vsl
        </div>
        <div className="PrevetSensitivity_Label">
          {spotIndexName}: ${defaultHirePrice}
        </div>
        <div className="PrevetSensitivity_Label">
          VLSFO: ${defaultFuelPrice}
        </div>
        <div className="PrevetSensitivity_Label d-flex">
          <div className="me-1">Std Vsl Description:</div>
          <div>
            {standardVesselDefaultSpeed} Kt/ {defaultFuelCon} Mt{" "}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between">
        <InputGroup className="mb-4 d-flex align-items-center">
          <InputGroup.Text className="me-2 PrevetSensitivity_Label PrevetSensitivity_Label--heading">
            Hire
          </InputGroup.Text>
          <div className="d-flex align-items-center">
            <Form.Control
              className="PrevetSensitivity_Input PrevetSensitivity_Label PrevetSensitivity_Input--large"
              aria-label="Amount (to the nearest dollar)"
              value={
                hirePrice === "" || isNaN(Number(hirePrice))
                  ? ""
                  : Number(hirePrice).toLocaleString("en-US")
              }
              onFocus={(e) => {
                updateChart({
                  isLaden: true,
                  speed: describedLadenSpeed,
                  isReset: false,
                })
                updateChart({
                  isLaden: false,
                  speed: describedBallastSpeed,
                  isReset: false,
                })
                updateVesselComparisonMessage()
                setIsStandardVesselBoxGray(true)
                setUserLadenVesselSpeed(describedLadenSpeed)
                setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
              }}
              onChange={(e) => {
                if (e.target.value === "") {
                  setHirePrice("")
                }
                const regex = new RegExp(/^(?:\d{1,3}(?:[,]\d{1,4})*|\d+)$/)
                const match = regex.test(e.target.value)
                if (!match) {
                  return
                }
                const num = convertToLocaleNumStringToNumber(e.target.value) // Convert 15,333 to 15333
                setHirePrice(String(num))
              }}
            />
            <div className="PrevetSensitivity_Unit">$</div>
            <div className="PrevetSensitivity_Input--dummy"></div>{" "}
          </div>
        </InputGroup>
        <InputGroup className="d-flex align-items-center">
          <InputGroup.Text className="me-2 PrevetSensitivity_Label PrevetSensitivity_Label--heading">
            Fuel
          </InputGroup.Text>
          <div className="d-flex align-items-center">
            <Form.Control
              className="PrevetSensitivity_Input PrevetSensitivity_Input--large PrevetSensitivity_Label"
              aria-label="Amount (to the nearest dollar)"
              type="number"
              value={fuelPrice}
              onFocus={(e) => {
                updateChart({
                  isLaden: true,
                  speed: describedLadenSpeed,
                  isReset: false,
                })
                updateChart({
                  isLaden: false,
                  speed: describedBallastSpeed,
                  isReset: false,
                })
                setUserLadenVesselSpeed(describedLadenSpeed)
                setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                updateVesselComparisonMessage()
                setIsStandardVesselBoxGray(true)
              }}
              onChange={(e) => {
                setFuelPrice(e.target.value)
              }}
            />
            <div className="PrevetSensitivity_Unit">$</div>
            <div className="PrevetSensitivity_Input--dummy"></div>{" "}
          </div>
        </InputGroup>
      </div>
      <div className="PrevetSensitivity_TCDescGroup">
        <InputGroup className="d-flex">
          <InputGroup.Text className="me-2 flex-0 PrevetSensitivity_Label">
            Laden
          </InputGroup.Text>
          <div className="d-flex flex-1">
            <div className="position-relative" style={{ flex: 1.1 }}>
              <div className="PrevetSensitivity_FloatTitle PrevetSensitivity_Label">
                TC Desc.
              </div>
              <Form.Control
                className="PrevetSensitivity_Input PrevetSensitivity_Label"
                aria-label="Knots"
                disabled={
                  Boolean(speedGraphLadenAvgSpeed) === false &&
                  Boolean(portStaysLadenAvgSpeed) === false
                }
                placeholder="Laden Speed"
                value={
                  describedLadenSpeedTouched
                    ? describedLadenSpeed
                    : BigNumber(describedLadenSpeed).toFormat(1)
                }
                onFocus={(e) => {
                  updateChart({
                    isLaden: true,
                    speed: e.target.value,
                    isReset: false,
                  })
                  updateChart({
                    isLaden: false,
                    speed: describedBallastSpeed,
                    isReset: false,
                  })
                  setUserLadenVesselSpeed(e.target.value)
                  setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                  updateVesselComparisonMessage()
                  setIsStandardVesselBoxGray(true)
                }}
                onChange={(e) => {
                  setDescribedLadenSpeedTouched(true)
                  setDescribedLadenSpeed(e.target.value)
                  setUserLadenVesselSpeed(e.target.value)
                  updateChart({
                    isLaden: true,
                    speed: e.target.value,
                    isReset: false,
                  })
                }}
              />
              <div className="PrevetSensitivity_UnitAbs PrevetSensitivity_Label">
                Kt
              </div>
              {speedGraphLadenAvgSpeed && (
                <div className="PrevetSensitivity_FloatTitle--bottom PrevetSensitivity_Label">
                  {BigNumber(speedGraphLadenAvgSpeed)
                    .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                    .toString()}{" "}
                  Kt Pre-Vetting Avg. SOG
                </div>
              )}
              {!speedGraphLadenAvgSpeed && portStaysLadenAvgSpeed && (
                <div className="PrevetSensitivity_FloatTitle--bottom PrevetSensitivity_Label">
                  {BigNumber(portStaysLadenAvgSpeed)
                    .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                    .toString()}{" "}
                  Best Est. Avg SOG
                </div>
              )}
              {!speedGraphLadenAvgSpeed && !portStaysLadenAvgSpeed && (
                <div className="PrevetSensitivity_FloatTitle--bottom PrevetSensitivity_Label">
                  No avg. Pre-Vetting SOG recorded in last 6 months
                </div>
              )}
            </div>
            <div className="position-relative" style={{ flex: 1.1 }}>
              <Form.Control
                className="PrevetSensitivity_Input PrevetSensitivity_Label"
                aria-label="Metric Tons"
                type="number"
                value={ladenFuelCon}
                disabled={
                  Boolean(speedGraphLadenAvgSpeed) === false &&
                  Boolean(portStaysLadenAvgSpeed) === false
                }
                onFocus={(e) => {
                  updateChart({
                    isLaden: true,
                    speed: describedLadenSpeed,
                    isReset: false,
                  })
                  updateChart({
                    isLaden: false,
                    speed: describedBallastSpeed,
                    isReset: false,
                  })
                  setUserLadenVesselSpeed(describedLadenSpeed)
                  setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                  updateVesselComparisonMessage()
                  setIsStandardVesselBoxGray(true)
                }}
                onChange={(e) => {
                  setLadenFuelCon(e.target.value)
                }}
              />
              <div className="PrevetSensitivity_UnitAbs PrevetSensitivity_Label">
                Mt
              </div>
            </div>
            <div className="ms-2" style={{ flex: 1 }}>
              <div className="PrevetSensitivity_FloatTitle PrevetSensitivity_Label">
                Sea Days
              </div>
              <Form.Control
                className="PrevetSensitivity_Input PrevetSensitivity_Label"
                aria-label="Days"
                disabled={
                  Boolean(speedGraphLadenAvgSpeed) === false &&
                  Boolean(portStaysLadenAvgSpeed) === false
                }
                type="number"
                value={ladenSeaDays}
                onFocus={(e) => {
                  updateChart({
                    isLaden: true,
                    speed: describedLadenSpeed,
                    isReset: false,
                  })
                  updateChart({
                    isLaden: false,
                    speed: describedBallastSpeed,
                    isReset: false,
                  })
                  setUserLadenVesselSpeed(describedLadenSpeed)
                  setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                  updateVesselComparisonMessage()
                  setIsStandardVesselBoxGray(true)
                }}
                onChange={(e) => {
                  setLadenSeaDays(e.target.value)
                }}
              />
            </div>
          </div>
        </InputGroup>
      </div>
      <div className="PrevetSensitivity_TCDescGroup--bottom">
        <InputGroup className="d-flex">
          <InputGroup.Text className="me-2 flex-0 PrevetSensitivity_Label">
            Ballast
          </InputGroup.Text>
          <div className="d-flex flex-1">
            <div className="position-relative" style={{ flex: 1.1 }}>
              <div className="PrevetSensitivity_FloatTitle PrevetSensitivity_Label">
                TC Desc.
              </div>
              <Form.Control
                className="PrevetSensitivity_Input PrevetSensitivity_Label"
                placeholder="Ballast Speed"
                aria-label="Described speed in Knots"
                disabled={
                  Boolean(speedGraphBallastAvgSpeed) === false &&
                  Boolean(portStaysBallastAvgSpeed) === false
                }
                value={
                  describedBallastSpeedTouched
                    ? describedBallastSpeed
                    : BigNumber(describedBallastSpeed).toFormat(1)
                }
                type="number"
                onFocus={(e) => {
                  updateChart({
                    isLaden: false,
                    speed: e.target.value,
                    isReset: false,
                  })
                  updateChart({
                    isLaden: true,
                    speed: describedLadenSpeed,
                    isReset: false,
                  })
                  setUserBallastVesselSpeed(e.target.value)
                  setUserLadenVesselSpeed(describedLadenSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                  updateVesselComparisonMessage()
                  setIsStandardVesselBoxGray(true)
                }}
                onChange={(e) => {
                  setDescribedBallastSpeedTouched(true)
                  setDescribedBallastSpeed(e.target.value)
                  setUserBallastVesselSpeed(e.target.value)
                  updateChart({
                    isLaden: false,
                    speed: e.target.value,
                    isReset: false,
                  })
                }}
              />
              <div className="PrevetSensitivity_UnitAbs PrevetSensitivity_Label">
                Kt
              </div>
              {speedGraphBallastAvgSpeed && (
                <div className="PrevetSensitivity_FloatTitle--bottom PrevetSensitivity_Label">
                  {BigNumber(speedGraphBallastAvgSpeed)
                    .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                    .toString()}{" "}
                  Kt Pre-Vetting Avg. SOG
                </div>
              )}
              {!speedGraphBallastAvgSpeed && portStaysBallastAvgSpeed && (
                <div className="PrevetSensitivity_FloatTitle--bottom PrevetSensitivity_Label">
                  {BigNumber(portStaysBallastAvgSpeed)
                    .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                    .toString()}{" "}
                  Best Est. Avg SOG
                </div>
              )}
              {!speedGraphBallastAvgSpeed && !portStaysBallastAvgSpeed && (
                <div className="PrevetSensitivity_FloatTitle--bottom PrevetSensitivity_Label">
                  No avg. Pre-Vetting SOG recorded in last 6 months
                </div>
              )}
            </div>
            <div className="position-relative" style={{ flex: 1.1 }}>
              <Form.Control
                className="PrevetSensitivity_Input PrevetSensitivity_Label"
                aria-label="Metric Tons"
                type="number"
                value={ballastFuelCon}
                disabled={
                  Boolean(speedGraphBallastAvgSpeed) === false &&
                  Boolean(portStaysBallastAvgSpeed) === false
                }
                onFocus={(e) => {
                  updateChart({
                    isLaden: true,
                    speed: describedLadenSpeed,
                    isReset: false,
                  })
                  updateChart({
                    isLaden: false,
                    speed: describedBallastSpeed,
                    isReset: false,
                  })
                  setUserLadenVesselSpeed(describedLadenSpeed)
                  setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                  updateVesselComparisonMessage()
                  setIsStandardVesselBoxGray(true)
                }}
                onChange={(e) => {
                  setBallastFuelCon(e.target.value)
                }}
              />
              <div className="PrevetSensitivity_UnitAbs PrevetSensitivity_Label">
                Mt
              </div>
            </div>
            <div className="ms-2" style={{ flex: 1 }}>
              <div className="PrevetSensitivity_FloatTitle PrevetSensitivity_Label">
                Sea Days
              </div>
              <Form.Control
                className="PrevetSensitivity_Input PrevetSensitivity_Label"
                type="number"
                aria-label="Days"
                value={ballastSeaDays}
                disabled={
                  Boolean(speedGraphBallastAvgSpeed) === false &&
                  Boolean(portStaysBallastAvgSpeed) === false
                }
                onFocus={(e) => {
                  updateChart({
                    isLaden: true,
                    speed: describedLadenSpeed,
                    isReset: false,
                  })
                  updateChart({
                    isLaden: false,
                    speed: describedBallastSpeed,
                    isReset: false,
                  })
                  setUserLadenVesselSpeed(describedLadenSpeed)
                  setUserBallastVesselSpeed(describedBallastSpeed) // Set the User Vsl line in Speed Graph Modal / All Ocean Zones Modal
                  updateVesselComparisonMessage()
                  setIsStandardVesselBoxGray(true)
                }}
                onChange={(e) => {
                  setBallastSeaDays(e.target.value)
                }}
              />
            </div>
          </div>
        </InputGroup>
      </div>
      <div>
        <div className="mt-5  PrevetSensitivity_PerformanceExposure">
          <div className="me-2 PrevetSensitivity_PerformanceExposure_Title">
            Performance Exposure
          </div>
          <div className="d-flex mt-1">
            {performanceExposure && (
              <>
                <DownIcon
                  direction={`${performanceExposure > 0 ? "down" : "up"}`}
                />
                <div
                  style={{
                    color: `${performanceExposure > 0 ? "#e61f2f" : "#00763b"}`,
                  }}
                >
                  {performanceExposure > 0
                    ? performanceExposure.toLocaleString("en-US")
                    : Math.abs(performanceExposure).toLocaleString(
                        "en-US"
                      )}{" "}
                  est.
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex mt-1 justify-content-center align-items-center">
          <span style={{ fontSize: "1rem" }}>
            {vesselComparisonMessage.split("/")[0]}
          </span>
          <span className="ms-1 me-1" style={{ fontSize: "0.75rem" }}>
            "vs"
          </span>
          <span style={{ fontSize: "1rem" }}>
            {vesselComparisonMessage.split("/")[1]}
          </span>
        </div>
      </div>
    </div>
  )
}

function roundToNearestThousand(cost: number) {
  return Math.round(cost / 1000) * 1000
}

function DownIcon({ direction }: { direction: "up" | "down" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 32 32"
      fill={`${direction === "down" ? "#e61f2f" : "#00763b"}`}
      className={`PrevetSensitivity_Icon--${direction}`}
    >
      <title>play3</title>
      <path d="M6 4l20 12-20 12z"></path>
    </svg>
  )
}

function convertToLocaleNumStringToNumber(numStr: string) {
  return Number(numStr.split(",").join(""))
}

function calcPerformance({
  describedLadenSpeed,
  describedBallastSpeed,
  speedGraphLadenAvgSpeed,
  speedGraphBallastAvgSpeed,
  ladenFuelCon,
  ballastFuelCon,
  hirePrice,
  fuelPrice,
  ladenSeaDays,
  ballastSeaDays,
}: {
  describedLadenSpeed: string
  describedBallastSpeed: string
  speedGraphLadenAvgSpeed?: number
  speedGraphBallastAvgSpeed?: number
  ladenFuelCon: string
  ballastFuelCon: string
  hirePrice: string
  fuelPrice: string
  ladenSeaDays: string
  ballastSeaDays: string
}) {
  let ladenCost
  let ballastCost

  if (speedGraphLadenAvgSpeed) {
    ladenCost =
      calcExtraCostPerDay({
        fastShipKtsPerHour: Number(describedLadenSpeed),
        slowShipKtsPerHour: speedGraphLadenAvgSpeed,
        fuelConPerDay: Number(ladenFuelCon),
        hirePrice: Number(hirePrice),
        fuelPrice: Number(fuelPrice),
      }) * Number(ladenSeaDays)
  }

  if (speedGraphBallastAvgSpeed) {
    ballastCost =
      calcExtraCostPerDay({
        fastShipKtsPerHour: Number(describedBallastSpeed),
        slowShipKtsPerHour: speedGraphBallastAvgSpeed,
        fuelConPerDay: Number(ballastFuelCon),
        hirePrice: Number(hirePrice),
        fuelPrice: Number(fuelPrice),
      }) * Number(ballastSeaDays)
  }

  if (ladenCost && ballastCost) {
    return roundToNearestThousand(ladenCost + ballastCost)
  }

  if (ladenCost) {
    return roundToNearestThousand(ladenCost)
  }
  if (ballastCost) {
    return roundToNearestThousand(ballastCost)
  }
}

export function getAvgSpeedFromPortStays({
  vesselStaysData,
  isLaden,
}: {
  vesselStaysData: VesselStay[] | undefined
  isLaden: boolean
}) {
  if (!vesselStaysData || vesselStaysData.length < 1) {
    return undefined
  }
  const portStaysData = getPortStaysData(vesselStaysData)
  const stays = isLaden ? portStaysData.laden : portStaysData.ballast

  if (!stays || stays.length < 1) {
    return undefined
  }

  return findAverage(stays.map((stay) => stay.avg_speed_mov))
}
