import Authentication from "./Authentication"
import { SalesPage } from "./Marketing/SalesPage"
import PortPredictor from "./PortPredictor"
import Prevetting from "./Prevetting"
import Reports from "./Reports"
import Version from "./Version"
import Playground from "./MapComponent"
import PoolPortal from "./PoolPortal"
import MaintenanceNotification from "./MaintenanceNotification"

const routes = [
  // ...Dashboard.routes,
  ...Authentication.routes,
  ...Reports.routes,
  ...Prevetting.routes,
  ...PortPredictor.routes,
  ...Version.routes,
  ...Playground.routes,
  ...PoolPortal.routes,
  ...MaintenanceNotification.routes,
  {
    path: "/freight-optimizer",
    permissionForPage: "read:freight-optimizer",
    component: () => <SalesPage />,
  },
  {
    path: "/chartering-insights",
    permissionForPage: "read:chartering-insights",
    component: () => <SalesPage />,
  },
  // ...ChartDesigns.routes,
  // ...PageDesigns.routes,
  // ...UIElements.routes,
  // ...FormDesigns.routes,
]

export { routes }
