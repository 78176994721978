import { PortPredictorCargoGroupTypes } from "../hooks/usePortPredictorAPI"

export function toCaps(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatCargoLabel(label: string) {
  if (label.split("_").length > 1) {
    const formatted = label
      .split("_")
      .map((word) => {
        if (word === "or") {
          return "or"
        }
        return toCaps(word)
      })
      .join(" ")
    return formatted
  }
  if (label.split("/").length > 1) {
    const formatted = label
      .split("/")
      .map((item) => {
        return item
          .split(" ")
          .map((w) => {
            if (w === "of") {
              return "of"
            }
            return toCaps(w)
          })
          .join(" ")
      })
      .join("/")

    return formatted
  }
  if (label.split(" ").length > 1) {
    const formatted = label
      .split(" ")
      .map((w) => toCaps(w))
      .join(" ")
    return formatted
  }
  return toCaps(label)
}

export function formatCargoLabelsForDropDown(
  cargoes: {
    label: string
    value: string
    cargoGroup: PortPredictorCargoGroupTypes
  }[]
) {
  return cargoes.map((c) => {
    const label = c.label
    const formattedLabel = formatCargoLabel(label)
    return { ...c, label: formattedLabel }
  })
}
