import { Organisation } from "modules/Authentication/store/user/interfaces"
import Modal from "react-bootstrap/Modal"
import { ReportsListSingle } from "../hooks/useGetReportsList"

import {
  AdminReportsUploadForm,
  ReportFormikValuesWithUuid,
} from "./AdminReportsUploadForm"

interface AdminReportsUploadEditModalProps {
  isModalShown: boolean
  handleClose: () => void
  chosenOrganisation: Organisation | null
  handleCreateNewReport: (payload: ReportsListSingle) => void
  handleEditReport: (payload: ReportsListSingle) => void
  editReportInitialValues: ReportFormikValuesWithUuid | undefined
  isModalInEditMode: boolean
}

export function AdminReportsUploadEditModal({
  handleClose,
  isModalShown,
  chosenOrganisation,
  handleCreateNewReport,
  handleEditReport,
  editReportInitialValues,
  isModalInEditMode,
}: AdminReportsUploadEditModalProps) {
  return (
    <Modal
      className="AdminReportsUploadEditModal"
      show={isModalShown}
      onHide={() => {
        handleClose()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Upload Report for{" "}
          <span style={{ color: "#ffad00" }}>{chosenOrganisation?.label}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!chosenOrganisation ? (
          <div>Please close the modal and choose a client organisation</div>
        ) : (
          <AdminReportsUploadForm
            isModalInEditMode={isModalInEditMode}
            editReportInitialValues={editReportInitialValues}
            handleCreateNewReport={handleCreateNewReport}
            handleEditReport={handleEditReport}
            chosenOrganisation={chosenOrganisation}
            handleClose={() => handleClose()}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}
