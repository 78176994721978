//Import Assets
import logoFooterImg from "../../../assets/images/tk-logo-blue.png"

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <img src={logoFooterImg} alt="Torvald Klaveness" height="36" />
        <div className="footer_credit">
          © {new Date().getFullYear()} Torvald Klaveness - All rights reserved
        </div>
      </footer>
    </div>
  )
}

export default Footer
