import { BASE_URL } from 'api/url_helper';
import { useAxiosRequest } from "api/axiosRequest"
import { PoolPortalContenttype, PoolPortalContenttypes } from '../helpers/types'
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const ContenttypeService = () => {
    const request = useAxiosRequest()
    const getContenttypes = async () => {
        try {
            const contenttypesUrl = `${POOLPORTAL_API_BASE_URL}/contenttypes`
            const response = await request<any, PoolPortalContenttypes>(
                {
                    url: contenttypesUrl,
                    method: "GET"
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    const createContenttype = async (newContenttype: PoolPortalContenttype) => {
        try {
            const response = await request<any, PoolPortalContenttype>(
                {
                    url: `${POOLPORTAL_API_BASE_URL}/contenttype`,
                    method: "POST",
                    data: newContenttype
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    const updateContenttype = async (updatedContenttype: PoolPortalContenttype) => {
        try {
            const response = await request<any, PoolPortalContenttype>(
                {
                    url: `${POOLPORTAL_API_BASE_URL}/contenttype`,
                    method: "PUT",
                    data: updatedContenttype
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    const deleteContenttype = async (id: string) => {
        try {
            const response = await request<any, PoolPortalContenttype>(
                {
                    url: `${POOLPORTAL_API_BASE_URL}/contenttype?id=${id}`,
                    method: "DELETE",
                }
            );

            return response && response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getContenttypes,
        createContenttype,
        updateContenttype,
        deleteContenttype
    }
}

export default ContenttypeService
