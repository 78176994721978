import React from "react"

//Import Assets
import mmImg from "../../../assets/images/logo-light.png"

const Header = () => {
  return (
    <React.Fragment>
      <div className="row justify-content-center mt-5 mb-5">
        <div className="col-xl-3 col-sm-3 col-md-6 col-lg-3 mx-auto">
          <div className="row align-items-center">
            <img
              src={mmImg}
              alt="market manager logo"
              className="img-fluid mx-auto d-block"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
