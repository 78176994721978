import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"

const DefaultSweetAlert = (props) => {
  const AlertBox = props.success ? (
    <SweetAlert success title={props.title} onConfirm={props.onConfirm}>
      {props.message}
    </SweetAlert>
  ) : (
    <SweetAlert warning title={props.title} onConfirm={props.onConfirm}>
      {props.message}
    </SweetAlert>
  )

  return AlertBox
}

export default DefaultSweetAlert
