import { useQuery, useMutation, useQueryClient, QueryFunctionContext } from '@tanstack/react-query'
import PoolService from './poolsService'
import { PoolPortalPool } from '../helpers/types'
import axios from 'axios'
import { toast } from 'react-toastify'


export const usePools = () => {
  const queryClient = useQueryClient()
  const poolService = PoolService()

  // Fetch pools
  const { data: pools, isLoading } = useQuery(['pools'], poolService.getPools)


  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newPool: PoolPortalPool }) =>
      poolService.createPool(variables.newPool),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pools'])
      },
      onError: (error: Error) => {
        console.error("Error during createPool:", error.message)
      },
    })

  const handleCreate = async (newPool: PoolPortalPool): Promise<PoolPortalPool> => {
    try {
      const createdPool = await createMutation.mutateAsync({
        newPool,
      })
      return createdPool as PoolPortalPool // Explicitly cast to PoolPortalPool
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create pool - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { updatedPool: PoolPortalPool }) => poolService.updatePool(variables.updatedPool), {
    onSuccess: () => {
      queryClient.invalidateQueries(['pools'])
    },
  })

  const handleUpdate = async (updatedPool: PoolPortalPool): Promise<PoolPortalPool> => {
    try {
      const result = await updateMutation.mutateAsync({ updatedPool })
      return result as PoolPortalPool
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to udpate pool - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(poolService.deletePool, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pools'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete pool - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    pools,
    isLoading,
    createPool: handleCreate,
    updatePool: handleUpdate,
    deletePool: handleDelete
  }
}
