import { BASE_URL } from "api/url_helper"
import { ContentLoading } from "components/Layout/Content/ContentLoading"
import { PdfModal } from "modules/Shared/components/PdfModal"
import { useCallback, useState } from "react"
import { ReportsListTable } from "../Shared/ReportsListTable"
import { Organisation } from "../hooks/useGetOrganisations"
import {
  ReportsListSingle,
  useGetReportsList,
} from "../hooks/useGetReportsList"

interface ReportsOverviewMainProps {
  userOrganisation: Organisation
}
export function ReportsOverviewMain({
  userOrganisation,
}: ReportsOverviewMainProps) {
  const [isPdfModalShown, setIsPdfModalShown] = useState(false)
  const [viewPdfUuid, setViewPdfUuid] = useState<string | undefined>()
  const [viewPdfOrgId, setViewPdfOrgId] = useState<string | undefined>()
  const [reportsList, setReportsList] = useState<
    ReportsListSingle[] | undefined
  >()
  const [getReportsListLoading, setGetReportsListLoading] = useState(false)
  const [getReportsListError, setGetReportsListError] = useState<
    string | undefined
  >()

  useGetReportsList({
    chosenOrganisation: userOrganisation,
    handleGetReportsListSuccess: useCallback((data) => {
      setReportsList(data)
    }, []),
    handleGetReportsListLoading: useCallback((isLoading) => {
      setGetReportsListLoading(isLoading)
    }, []),
    handleGetReportsListError: useCallback((err) => {
      setGetReportsListError(err)
    }, []),
  })

  return (
    <div>
      <PdfModal
        idToCheck={viewPdfUuid}
        isModalShown={isPdfModalShown}
        handleClose={() => {
          setIsPdfModalShown(false)
        }}
        requestParameters={{
          url: `${BASE_URL}/reports/single`,
          params: {
            uuid: viewPdfUuid,
          },
        }}
      />
      <div>
        {getReportsListLoading && <ContentLoading />}
        {reportsList && !getReportsListError ? (
          <ReportsListTable
            openPdfModal={({ orgId, uuid }) => {
              setViewPdfUuid(uuid)
              setViewPdfOrgId(orgId)
              setIsPdfModalShown(true)
            }}
            reportsList={reportsList}
          />
        ) : !getReportsListLoading ? (
          <div>Unable to load reports.</div>
        ) : null}
      </div>
    </div>
  )
}
