import { Polyline, useMap } from "react-leaflet"

import { Map } from "leaflet"
import { MarkerPoint } from "modules/PortPredictor/components/PortPredictorMain"
import { CircleMarkerGroup } from "./CircleMarkerGroup"

interface LinePoint extends MarkerPoint {
  [key: string]: any
}

export function Line({
  points,
  showMarkers = false,
  showLine = true,
  setView = false,
  fitBounds = false,
  markerProps,
  usePointColor = false,
  tooltip: Tooltip,
  color = "primary",
  weight = 2,
  pathOptions = { color: "cyan" },
  ...props
}: {
  points: any //LinePoint[]
  tooltip: any
  markerProps: any
  showMarkers?: boolean
  showLine?: boolean
  setView?: any
  fitBounds?: boolean
  weight?: number
  color?: string
  usePointColor?: boolean
  pathOptions?: { color?: string; dashArray?: string }
  key: string
}) {
  const map = useMap() as Map

  if (!markerProps?.minRadius) {
    showMarkers = false
  }

  const positions = points.filter((p: LinePoint) => p.lat && p.lon)

  // if (positions.length > 0) {
  //   if (fitBounds) {
  //     fitMapBounds(map, positions)
  //   }

  //   if (setView) {
  //     map.setView(
  //       [
  //         (positions.at(-1) as LinePoint).lat,
  //         (positions.at(-1) as LinePoint).lon,
  //       ],
  //       map.getZoom()
  //     )
  //   }
  // }

  return (
    <>
      {showLine && (
        <Polyline
          positions={positions.map((p: LinePoint) => [p.lat, p.lon])}
          weight={weight}
          color={color}
          pathOptions={pathOptions}
          key={props.key}
        />
      )}

      {showMarkers && (
        <CircleMarkerGroup
          points={positions}
          validate={false}
          tooltip={Tooltip}
          usePointColor={usePointColor}
          {...markerProps}
        />
      )}
    </>
  )
}

export default Line
