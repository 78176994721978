

import BigNumber from "bignumber.js";
import { PortPredictorTurnAroundTimeData } from "modules/PortPredictor/hooks/usePortPredictorAPI"
import moment from "moment"

const colors = [
  "#5470C6", // Moderate Blue
  "#0035d4",  // Saturated Blue
  "#009fd4",  // Saturated Turquoise
  "#4700cc",  // Saturated Purple Ultramarine
  "#423a99",  // Dark Ultramarine
  "#487896",  // Gray Turquoise
  "#113b80",  // Dark Cobalt
  "#80ccff",  // Sky Blue
  "#0f4c75",  // Teal Blue
  "#1b262c"   // Slate Blue
];

function generateCargoColorMap(sortedData: PortPredictorTurnAroundTimeData[]) {
  const map: Record<string, string> = {}
  for (const [i, vessel] of sortedData.entries()) {
    if (!map[vessel.cargo]) {
      map[vessel.cargo] = colors[i]
    }
  }
  return map
}

export function getPortTurnAroundTimeOptions(portTurnAroundTimeData: PortPredictorTurnAroundTimeData[]) {
  const sortedData = portTurnAroundTimeData.sort((a, b) => {
    if (!a || !b) {
      // Handle undefined items
      return 0;
    }

    const momentA = moment(a.datetime_to);
    const momentB = moment(b.datetime_to);

    if (!momentA.isValid() || !momentB.isValid()) {
      // Handle undefined or invalid dates
      return 0; // or handle differently depending on how you want to treat invalid dates
    }

    return momentA.diff(momentB);
  });

  const colorMap = generateCargoColorMap(sortedData)

  return {
    title: {
      // text: 'Vessel Days in Port vs. Departure Time'
    },
    grid: {
      y: 55,
      x: 50,
      bottom: 40,
      top: 30,
      left: 60,
      right: 120
    },
    dataset: {
      source: sortedData,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params: any) {
        var vessel = params[0].data;
        const daysInPort = BigNumber(vessel.days_in_port).decimalPlaces(1, BigNumber.ROUND_CEIL)
        return `
          <div>
            <div>IMO: ${vessel.imo}</div>
            <div>Vessel: ${vessel.vessel_name}</div>
            <div>From: ${moment(vessel.datetime_from).format('D/MM/YY')}</div>
            <div>To: ${moment(vessel.datetime_to).format('D/MM/YY')}</div>
            <div>${vessel.cargo ? `Most Likely Cargo: ${vessel.cargo}` : ""}</div>
            <div>Days in Port: ${daysInPort}</div>
          </div>
        `
      }
    },
    xAxis: {
      type: 'value',
      name: 'Days in Port',
      nameTextStyle: {
        align: 'right',
        verticalAlign: 'top',
        padding: [28, 0, 0, 0]
      },
      axisLabel: {
        formatter: '{value} d'
      }
    },
    yAxis: {
      type: 'category',
      name: 'Departure',
      nameTextStyle: {
        padding: [-5, 0, 0, 0]
      },
      axisLabel: {
        formatter: function (value: string) {
          return moment(value).format('D/MM/YY');
        }
      }
    },
    series: [
      {
        type: 'bar',
        encode: {
          x: 'days_in_port',
          y: 'datetime_to'
        },
        label: {
          show: true,
          position: 'right',
          formatter: function (params: any) {
            return params.data.vessel_name;
          }
        },
        itemStyle: {
          color: function (params: any) {
            const cargo = params.data.cargo;
            return colorMap[cargo]
          }
        }
      }
    ]
  };
}
