import { VesselStay } from "../hooks/useFetchVesselStays"
import { VesselPortStayActivity } from "../hooks/usePrevetAPI"

export function getPortStaysActivity(vesselStay: VesselStay) {
  if (vesselStay.yard_activity === "DD-Late") {
    if (vesselStay.yard_duration > 25) {
      return "Dry Dock (Long & Late)"
    }
    if (vesselStay.yard_duration > 14) {
      return "Dry Dock (Medium & Late)"
    }
    return "Dry Dock (Short & Late)"
  }
  if (vesselStay.yard_activity === "DD-S") {
    return "Dry Dock (Short)"
  }
  if (vesselStay.yard_activity === "DD-M") {
    return "Dry Dock (Medium)"
  }
  if (vesselStay.yard_activity === "DD-L") {
    return "Dry Dock (Long)"
  }
  if (vesselStay.yard_activity === "DD-R") {
    return "DD/Repair/IWS"
  }
  if (vesselStay.yard_activity === "R") {
    return "Yard/Repair/IWS"
  }
  if (!vesselStay.activity || vesselStay.activity === "X" || vesselStay.activity === "O") {
    return "Other"
  }
  if (vesselStay.activity === VesselPortStayActivity.Load) {
    return "Load"
  }
  if (vesselStay.activity === VesselPortStayActivity.Discharge) {
    return "Discharge"
  }
  if (vesselStay.activity === VesselPortStayActivity.Fuel) {
    return "Bunkering"
  }
  if (vesselStay.activity === VesselPortStayActivity.CanalTransit) {
    return "Canal transit"
  }
  if (vesselStay.activity === VesselPortStayActivity.Yard) {
    return "Yard"
  }
}
