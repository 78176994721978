import MDEditor, {
  bold,
  italic,
  quote,
  strikethrough,
  link,
  unorderedListCommand,
  orderedListCommand,
} from "@uiw/react-md-editor"
import { Field, Form, FormikErrors, FormikTouched } from "formik"
import { Organisation } from "modules/Authentication/store/user/interfaces"
import { ChangeEvent } from "react"
import "react-toastify/dist/ReactToastify.css"
import { AdminReportsDropZone } from "./AdminReportsDropZone"
import { ReportFormikValues, UploadEditFields } from "./AdminReportsUploadForm"

interface AdminReportsUploadFormFormikProps {
  chosenOrganisation: Organisation
  errors: FormikErrors<ReportFormikValues>
  touched: FormikTouched<ReportFormikValues>
  values: ReportFormikValues
  setFieldTouched: (
    field: UploadEditFields,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void
  setFieldValue: (
    field: UploadEditFields,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  fileName: string | undefined
  setFileName: (fileName: string) => void
  isValid: boolean
  handleChange: {
    (e: ChangeEvent<any>): void
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void
  }
}

export function AdminReportsUploadFormFormik({
  errors,
  touched,
  values,
  setFieldTouched,
  setFieldValue,
  fileName,
  setFileName,
  isValid,
  chosenOrganisation,
}: AdminReportsUploadFormFormikProps) {
  return (
    <Form>
      <div className="d-flex flex-column">
        <div className="mb-3">
          <h5 className="mb-2">Name</h5>
          <Field
            className={`AdminReportsUploadFormFormik_Name form-control ${
              errors.reportName && touched.reportName ? "is-invalid" : ""
            }`}
            name="reportName"
          />
          {errors.reportName && touched.reportName ? (
            <div className="AdminReportsUploadFormFormik_Error">
              {errors.reportName}
            </div>
          ) : null}
        </div>
        <div data-color-mode="light" className="mb-4">
          <h5 className="mb-2">Summary</h5>
          <MDEditor
            className={`AdminReportsUploadFormFormik_MDEditor ${
              errors.reportSummary && touched.reportSummary ? "is-invalid" : ""
            }`}
            height={200}
            value={values.reportSummary}
            commands={[
              bold,
              italic,
              quote,
              strikethrough,
              link,
              orderedListCommand,
              unorderedListCommand,
            ]}
            onBlur={() => {
              setFieldTouched("reportSummary", true)
            }}
            onChange={(summary: string | undefined) => {
              const summaryFieldValue = summary === undefined ? "" : summary
              setFieldValue("reportSummary", summaryFieldValue)
            }}
          />
          {errors.reportSummary && touched.reportSummary ? (
            <div className="AdminReportsUploadFormFormik_Error">
              {errors.reportSummary}
            </div>
          ) : null}
        </div>
        <h5 className="mb-2">File Upload</h5>
        <div
          className={`AdminReportsUploadFormFormik_DropZoneContainer ${
            errors.filePath && touched.filePath ? "is-invalid" : ""
          }`}
        >
          <AdminReportsDropZone
            chosenOrganisation={chosenOrganisation}
            uploadedFile={values.filePath}
            fileName={fileName}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setFileName={(fileName: string) => {
              setFileName(fileName)
            }}
          />
        </div>
        {errors.filePath && touched.filePath ? (
          <div className="AdminReportsUploadFormFormik_Error">
            {errors.filePath}
          </div>
        ) : null}
      </div>
    </Form>
  )
}
