import { useQuery, useMutation, useQueryClient, QueryFunctionContext } from '@tanstack/react-query'
import ContenttypeService from './contenttypesService'
import { PoolPortalContenttype } from '../helpers/types'
import axios from 'axios'
import { toast } from 'react-toastify'


export const useContenttypes = () => {
  const queryClient = useQueryClient()
  const contenttypeService = ContenttypeService()

  // Fetch contenttypes
  const { data: contenttypes, isLoading } = useQuery(['contenttypes'], contenttypeService.getContenttypes)


  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newContenttype: PoolPortalContenttype }) =>
      contenttypeService.createContenttype(variables.newContenttype),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contenttypes'])
      },
      onError: (error: Error) => {
        console.error("Error during createContenttype:", error.message)
      },
    })

  const handleCreate = async (newContenttype: PoolPortalContenttype): Promise<PoolPortalContenttype> => {
    try {
      const createdContenttype = await createMutation.mutateAsync({
        newContenttype,
      })
      return createdContenttype as PoolPortalContenttype // Explicitly cast to PoolPortalContenttype
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create contenttype - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { updatedContenttype: PoolPortalContenttype }) => contenttypeService.updateContenttype(variables.updatedContenttype), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contenttypes'])
    },
  })

  const handleUpdate = async (id: string, updatedContenttype: PoolPortalContenttype): Promise<PoolPortalContenttype> => {
    try {
      const result = await updateMutation.mutateAsync({ updatedContenttype })
      return result as PoolPortalContenttype
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to update contenttype - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(contenttypeService.deleteContenttype, {
    onSuccess: () => {
      queryClient.invalidateQueries(['contenttypes'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete contenttype - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    contenttypes,
    isLoading,
    createContenttype: handleCreate,
    updateContenttype: handleUpdate,
    deleteContenttype: handleDelete
  }
}
