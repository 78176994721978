import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"

interface SegmentSensitivityBenchmark {
  fuel_price: number
  spot_price: number
  consumption: number
  spot_index_name: string
}
interface SegmentSensitivitySpeedPrice {
  speed: number
  price: number
}

export interface SegmentSensitivityData {
  benchmark: SegmentSensitivityBenchmark
  data: SegmentSensitivitySpeedPrice[]
}
export function usePrevetGetSensitivityDataForSegment() {
  const request = useAxiosRequest()
  const [segmentSensitivityData, setSegmentSensitivity] = useState<
    SegmentSensitivityData | undefined
  >()
  const [
    getSegmentSensitivityDataLoading,
    setGetSegmentSensitivityDataLoading,
  ] = useState(false)
  const [getSegmentSensitivityDataError, setGetSegmentSensitivityDataError] =
    useState<string | undefined>()

  async function getSegmentSensitivityData(segment: string) {
    try {
      const responseBody = await request<any, SegmentSensitivityData>({
        url: `${BASE_URL}/prevetting/vessel-sensitivity`,
        params: { segment },
      })

      if (responseBody) {
        setSegmentSensitivity(responseBody.data)

      }
    } catch (err: unknown) {
      const message = `Unable to load sensitivity analysis for ${segment}`
      setGetSegmentSensitivityDataError(message)
      if (axios.isAxiosError(err)) {
        toast.warn(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      setGetSegmentSensitivityDataLoading(false)
    }
  }

  return {
    getSegmentSensitivityData,
    segmentSensitivityData,
    getSegmentSensitivityDataLoading,
    getSegmentSensitivityDataError,
  }
}
