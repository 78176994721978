import { ConstructPoolPortalContenttypesHeaders } from "modules/PoolPortal/helpers/headers"
import {
  AdminSettingsEnum,
  PoolPortalContenttype,
  PoolPortalContenttypes,
} from "modules/PoolPortal/helpers/types"
import { useContenttypes } from "modules/PoolPortal/hooks/useContenttypesAPI"
import { FormatEnumToString, GetEntryPageState } from "modules/PoolPortal/utils"
import { useEffect, useState } from "react"
import { Button, Modal, Offcanvas } from "react-bootstrap"
import { SettingsEntryHOC } from "../SettingsHOC"
import { SettingsTable } from "../SettingsTable"

export const ContenttypesAdminMain = () => {
  const { contenttypes, isLoading, deleteContenttype } = useContenttypes()

  const [contenttypesData, setContenttypesData] = useState(
    contenttypes ?? ([] as PoolPortalContenttypes)
  )
  const [showEntryBlade, setShowEntryBlade] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [currentContenttype, setCurrentContenttype] = useState(
    {} as PoolPortalContenttype
  )

  useEffect(() => {
    if (contenttypes) {
      setContenttypesData([...contenttypes])
    }
  }, [isLoading, contenttypes])

  const handleEditBladeClose = () => {
    setShowEntryBlade(false)
  }
  const handleEditBladeShow = () => {
    setShowEntryBlade(true)
  }

  const newContenttypesHandler = () => {
    setShowEntryBlade(true)
    setCurrentContenttype({} as PoolPortalContenttype)
  }

  const editContenttypeHandler = (id: string) => {
    const data = contenttypesData as PoolPortalContenttypes
    if (data) {
      var foundIndex = data?.findIndex(
        (x: PoolPortalContenttype) => x.id === id
      )
      setCurrentContenttype(data[foundIndex])
      handleEditBladeShow()
    }
  }

  const deleteContenttypeHandler = (id: string) => {
    // show the pop here and set the currentContenttype for deletion
    const data = contenttypesData as PoolPortalContenttypes
    if (data) {
      var foundIndex = data?.findIndex(
        (x: PoolPortalContenttype) => x.id === id
      )
      setCurrentContenttype(data[foundIndex])
      setShowDelete(true)
    }
  }

  const modalDeleteHandler = async () => {
    //  actual deletion
    await deleteContenttype(currentContenttype.id)
    setShowDelete(false)
  }

  return (
    <>
      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        style={{ zIndex: 5060 }}
        backdropClassName="SettingsDeleteConfirmationBackDrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Contenttype</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the "{currentContenttype.name}"
          contenttype?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={modalDeleteHandler}>
            Delete
          </Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        className="PoolPortal_SettingsEditOffcanvas"
        show={showEntryBlade}
        onHide={handleEditBladeClose}
        placement="end"
        backdrop="static"
        scroll={false}
        style={{ zIndex: 4060 }}
        backdropClassName="SettingsEditOffCanvassBackDrop"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Settings - {GetEntryPageState(currentContenttype)}{" "}
            {FormatEnumToString(AdminSettingsEnum.CONTENTTYPE)}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SettingsEntryHOC
            type={AdminSettingsEnum.CONTENTTYPE}
            data={currentContenttype}
            setShowEntryBlade={setShowEntryBlade}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <SettingsTable
        body={contenttypesData!}
        headers={ConstructPoolPortalContenttypesHeaders(
          editContenttypeHandler,
          deleteContenttypeHandler
        )}
        newHandler={newContenttypesHandler}
      />
    </>
  )
}
