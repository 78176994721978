import { PrevetWrapper } from "./components/PrevetWrapper"

const routes = [
  {
    path: "prevetting",
    permissionForPage: "read:prevetting",
    component: PrevetWrapper,
  },
]

const settings = {
  routes,
}

export default settings
