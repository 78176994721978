import { useQuery, useMutation, useQueryClient, QueryFunctionContext } from '@tanstack/react-query'
import OwnerService from './ownersService'
import { PoolPortalOwner } from '../helpers/types'
import axios from 'axios'
import { toast } from 'react-toastify'

export const useOwners = () => {
  const queryClient = useQueryClient()
  const ownerService = OwnerService()

  // Fetch owners
  const { data: owners, isLoading } = useQuery(['owners'], ownerService.getOwners)


  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newOwner: PoolPortalOwner }) =>
      ownerService.createOwner(variables.newOwner),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['owners'])
      },
      onError: (error: Error) => {
        console.error("Error during createOwner:", error.message)
      },
    })

  const handleCreate = async (newOwner: PoolPortalOwner): Promise<PoolPortalOwner> => {
    try {
      const createdOwner = await createMutation.mutateAsync({
        newOwner,
      })
      return createdOwner as PoolPortalOwner // Explicitly cast to PoolPortalOwner
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create owner - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { updatedOwner: PoolPortalOwner }) => ownerService.updateOwner(variables.updatedOwner), {
    onSuccess: () => {
      queryClient.invalidateQueries(['owners'])
    },
  })

  const handleUpdate = async (updatedOwner: PoolPortalOwner): Promise<PoolPortalOwner> => {
    try {
      const result = await updateMutation.mutateAsync({ updatedOwner })
      return result as PoolPortalOwner
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to udpate owner - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(ownerService.deleteOwner, {
    onSuccess: () => {
      queryClient.invalidateQueries(['owners'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete owner - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    owners,
    isLoading,
    createOwner: handleCreate,
    updateOwner: handleUpdate,
    deleteOwner: handleDelete
  }
}
