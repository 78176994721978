import React from "react"

const AlertError = (props) => {
  return (
    <div
      role="alert"
      className="fade bg-danger text-white mb-0 alert alert-danger show"
    >
      <strong>{props.title}</strong>
      {props.text}
    </div>
  )
}

export default AlertError
