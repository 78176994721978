import React from 'react';

import { EChart } from './EChart';

export const EChartPanel = ({
    datasets,
    tooltipFormatter,
    xAxisFormatter,
    highlight,
    legend,
    responsive,
    yMin,
    yMax,
    width,
    height,
    children
}) => {
    return (
        <div className="card mb-3">
            <EChart
                datasets={datasets}
                tooltipFormatter={tooltipFormatter}
                xAxisFormatter={xAxisFormatter}
                highlight={highlight}
                legend={legend}
                responsive={responsive}
                yMin={yMin}
                yMax={yMax}
                width={width}
                height={height}
            />

            {children && (
                <>
                    <br />
                    <div className="flex align-items-start justify-content-start">
                        {children}
                    </div>
                </>
            )}
        </div>
    );
};

EChartPanel.defaultProps = {
    datasets: [],
    tooltipFormatter: null,
    xAxisFormatter: null,
    highlight: null,
    legend: false,
    responsive: false,
    yMin: null,
    yMax: null,
    width: '100%',
    height: '100%'
};

export default EChartPanel;
