import { TableColumnType } from "react-bs-datatable"
import { getPortStaysActivity } from "../helpers/getPortStaysActivity"
import { DDAlertCategories, VesselStay } from "../hooks/useFetchVesselStays"

export const TABLE_HEADERS: TableColumnType<VesselStay>[] = [
  {
    title: "Date From",
    prop: "date_from" as keyof VesselStay,
    isFilterable: true,
    isSortable: true,
    cell: (row) => (
      <div className="PrevetVesselHistory_DateCell">{row.date_from}</div>
    ),
  },
  {
    title: "Date To",
    prop: "date_to" as keyof VesselStay,
    isFilterable: true,
    isSortable: true,
    cell: (row) => (
      <div className="PrevetVesselHistory_DateCell">{row.date_to}</div>
    ),
  },
  {
    title: "Port",
    prop: "port" as keyof VesselStay,
    isFilterable: true,
  },
  {
    title: "Duration",
    prop: "duration" as keyof VesselStay,
    isFilterable: true,
    isSortable: true,
    cell: (row) => {
      return <span>{row.duration}</span>
    },
  },
  {
    title: "Activity",
    prop: "activity" as keyof VesselStay,
    isFilterable: true,
    cell: (row) => {
      const shouldShowAlert = row.yard_activity
        ? DDAlertCategories.includes(row.yard_activity)
        : false
      return (
        <span>
          {shouldShowAlert && <i className="ti-alert ms-1" />}{" "}
          {getPortStaysActivity(row)}
        </span>
      )
    },
  },
  {
    title: "Prior Leg",
    prop: "leg" as keyof VesselStay,
    isFilterable: true,
    cell: (row) => {
      return !row.leg
        ? ""
        : row.leg?.charAt(0).toUpperCase() + row.leg?.slice(1).toLowerCase()
    },
  },
  {
    title: "Yard Dur.",
    prop: "yard_duration" as keyof VesselStay,
    isFilterable: true,
    cell: (row) => {
      return row.yard_duration
    },
  },
  // {
  //   title: "Yard Act.",
  //   prop: "yard_activity" as keyof VesselStay,
  //   isFilterable: true,
  //   cell: (row) => {
  //     return row.yard_activity
  //   },
  // },
  {
    title: "Cargo",
    prop: "cargo" as keyof VesselStay,
    isFilterable: true,
    cell: (row) => {
      if (!row.cargo) {
        return ""
      } else {
        const words = row.cargo.split("_")
        return words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      }
    },
  },
  {
    title: "Avg. Speed",
    prop: "avg_speed_mov" as keyof VesselStay,
    isFilterable: true,
    isSortable: true,
  },
  {
    title: "Max. Speed",
    prop: "max_speed" as keyof VesselStay,
    isFilterable: true,
    isSortable: true,
  },
  // {
  //   title: "Min. Speed",
  //   prop: "min_speed" as keyof VesselStay,
  //   isFilterable: true,
  //   isSortable: true,
  // },
  // {
  //   title: "Avg. Speed",
  //   prop: "avg_speed" as keyof VesselStay,
  //   isFilterable: true,
  //   isSortable: true,
  // },
  // {
  //   title: "No. Obs",
  //   prop: "nobs" as keyof VesselStay,
  //   isFilterable: true,
  //   isSortable: true,
  // },
]
