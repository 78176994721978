import axios from 'axios';
import { useAxiosRequest } from 'api/axiosRequest';

const baseUrl = 'http://localhost:1337/api/v1/prevetting/vessels';

export const useFetchVessels = () => {
    const request = useAxiosRequest()

    async function fetchVessels(segment) {
        try {
            const response = await request({ url: baseUrl, params: { segment } });

            const vessels = response.data.map((vessel) => ({
                imo: vessel.value,
                name: vessel.label,
                label: vessel.label
            }));

            vessels.sort((a, b) => a.name.localeCompare(b.name));

            return vessels;
        } catch (error) {
            throw new Error(error);
        }
    }

    return {
        fetchVessels
    }
};
