import moment from "moment"
import { VesselStay } from "../hooks/useFetchVesselStays"

export interface PortStaysData {
  laden: VesselStay[]
  ballast: VesselStay[]
  days: VesselStay[]
}

const sixMonthsAgo = moment(new Date())
  .subtract("6", "months")
  .format("YYYY-MM-DD")

// TODO: Write test for this
export function getPortStaysData(vesselStays: VesselStay[]) {
  const sixMonthsVesselStays = vesselStays.filter((stay) => {
    const isStayOlderThan6Months = moment(stay.date_from).isBefore(
      sixMonthsAgo
    )
    return !isStayOlderThan6Months
  })

  const laden = sixMonthsVesselStays.filter((stay) => stay.leg === "laden")
  const ballast = sixMonthsVesselStays.filter(
    (stay) => stay.leg === "ballast"
  )
  return {
    laden,
    ballast,
    days: sixMonthsVesselStays
  }
}
