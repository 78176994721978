import { TypeOfTurnAroundTimeData } from "./components/PortPredictorArrival/PortOrTerminalTurnAroundTimeCheckboxes"
import {
  PortPredictorBerthsData,
  PortPredictorCargoGroupTypes,
  PortPredictorPortsData,
  VesselPositionState
} from "./hooks/usePortPredictorAPI"

export enum CheckedMinMedianMax {
  Min = "min",
  Median = "median",
  Average = "average",
  Max = "max",
}

export enum MarkerType {
  Arrival = "arrival",
  Departure = "departure",
}

export enum PortType {
  Discharge = "discharge-port",
  DischargeWithArrivalWindow = "discharge-port-w-arrival",
  Load = "load-port",
  LoadWithLaycan = "load-port-w-laycan",
  Other = "other",
}

export enum VesselStateLadenOrBallast {
  Laden = "Laden",
  Ballast = "Ballast",
}

export interface PortPredictorMarkerBase {
  id: number
  lastNavStaDesc?: string
}

export interface PortPredictorMarkerArrivalBase {
  lastPosTime?: string
}

export interface PortPredictorMarkerArrivalUserChangeProps {
  arrivalDate?: string
  markerType: MarkerType.Arrival
  portType: PortType
  cargo?: string
  cargoGroup?: PortPredictorCargoGroupTypes
  vesselState: VesselStateLadenOrBallast
  port?: PortPredictorPortsData,
  berth?: PortPredictorBerthsData,
  typeOfTurnAroundTimeData?: TypeOfTurnAroundTimeData,
  turnAroundTime?: number
  daysFromDeparture?: number
  distanceFromDeparture?: number
  laycanStart?: string
  laycanEnd?: string
  // Used to guess the portType when displaying the arrival form
  // These props are only needed if the first stop is an arrival stop
  vesselPosition?: VesselPositionState
}

export type PortPredictorMarkerArrival =
  PortPredictorMarkerBase & PortPredictorMarkerArrivalBase & PortPredictorMarkerArrivalUserChangeProps


export interface PortPredictorMarkerDepartureUserChangeProps {
  vesselSpeedSelected: number | undefined
  vesselState: VesselStateLadenOrBallast
}

// Instead of ? undefined properties, use null
export type PortPredictorMarkerDepartureBase = {
  date?: string
  markerType: MarkerType.Departure
  cargo?: string
  cargoGroup?: PortPredictorCargoGroupTypes
  distanceToArrival?: number
  daysToArrival?: number
  posWkt?: string
  vesselPosition?: VesselPositionState.OpenSea
  port?: PortPredictorPortsData
  preserveFollowingPortType?: boolean
} & PortPredictorMarkerDepartureUserChangeProps

export type PortPredictorMarkerDeparture =
  PortPredictorMarkerBase &
  PortPredictorMarkerDepartureBase &
  PortPredictorMarkerDepartureUserChangeProps

export type PortPredictorJourneyMarker =
  | PortPredictorMarkerDeparture
  | PortPredictorMarkerArrival

export type PortPredictorJourneyState = PortPredictorJourneyMarker[]

const ADD_JOURNEY_MARKER = "ADD_JOURNEY_MARKER"
export const EXTEND_JOURNEY_MARKERS = "EXTEND_JOURNEY_MARKERS"
export const ADD_JOURNEY_START_MARKERS = "ADD_JOURNEY_START_MARKERS"
const CLEAR_JOURNEY = "CLEAR_JOURNEY"
export const EDIT_JOURNEY = "EDIT_JOURNEY"

export type PortPredictorJourneyActions =
  | {
    type: "ADD_JOURNEY_MARKER"
    payload: PortPredictorJourneyMarker
  }
  | {
    type: "ADD_JOURNEY_START_MARKERS"
    payload: PortPredictorJourneyMarker[]
  }
  | {
    type: "CLEAR_JOURNEY"
  }
  | {
    type: "EDIT_JOURNEY"
    payload: PortPredictorJourneyMarker[]
  }
  | {
    type: "EXTEND_JOURNEY_MARKERS"
    payload: PortPredictorJourneyMarker[]
  }

export function portPredictorJourneyReducer(
  state: PortPredictorJourneyState,
  action: PortPredictorJourneyActions
) {
  switch (action.type) {
    case ADD_JOURNEY_MARKER:
      const final = [...state, action.payload]
      return final
    case ADD_JOURNEY_START_MARKERS:
      const r1 = [...action.payload]
      console.log('ADD JOURNEY START', r1)
      return r1
    case EXTEND_JOURNEY_MARKERS:
      return [...state, ...action.payload]
    case EDIT_JOURNEY:
      const sliceUpToButNotIncluding = action.payload[0].id
      const result = [...state.slice(0, sliceUpToButNotIncluding), ...action.payload]
      console.log('EDIT JOURNEY', result)
      return result
    case CLEAR_JOURNEY:
      return []
    default:
      return state
  }
}
