import { PortType } from "modules/PortPredictor/portPredictorReducer"
import { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { PortPredictorPortTypeSelectOption } from "./PortPredictorArrivalInputs"

interface PortPredictorDatePickerProps {
  currMarkerLaycanStart: string | undefined
  currMarkerLaycanEnd: string | undefined
  chosenPortType: PortPredictorPortTypeSelectOption
  updateLaycan: ({ date, isStart }: { date: Date; isStart: boolean }) => void
}

export function PortPredictorDatePicker({
  currMarkerLaycanStart,
  currMarkerLaycanEnd,
  updateLaycan,
  chosenPortType,
}: PortPredictorDatePickerProps) {
  const [startDate, setStartDate] = useState<Date | null>(
    currMarkerLaycanStart ? new Date(currMarkerLaycanStart) : null
  )
  const [endDate, setEndDate] = useState<Date | null>(
    currMarkerLaycanEnd ? new Date(currMarkerLaycanEnd) : null
  )
  const label =
    chosenPortType.value === PortType.LoadWithLaycan
      ? "Laycan"
      : "Arrival Window"

  useEffect(() => {
    if (!startDate) {
      return
    }
    updateLaycan({ date: startDate, isStart: true })
  }, [startDate])

  useEffect(() => {
    if (!endDate) {
      return
    }
    updateLaycan({ date: endDate, isStart: false })
  }, [endDate])

  return (
    <>
      <div className="PortPredictor_FormLabel fw-bold">{label}</div>
      <div className="PortPredictor_ArrivalDatePicker_Wrapper">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selectsStart
          placeholderText="Start"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          startDate={startDate}
          endDate={endDate}
          // minDate={new Date()}
        />

        <DatePicker
          className="PortPredictor_DatePicker_Input"
          dateFormat="dd/MM/yyyy"
          selectsEnd
          placeholderText="End"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
    </>
  )
}
