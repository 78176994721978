import {
  AdminSettingsEnum,
  PoolPortalContenttype,
  PoolPortalOwner,
  PoolPortalOwnerstoragesetting,
  PoolPortalPartofmonth,
  PoolPortalPool,
  PoolPortalSingleTypes,
  PoolPortalVessel,
} from "modules/PoolPortal/helpers/types"
import { ContenttypesAdminEntryPage } from "./SettingsPages/ContenttypeAdminEntryPage"
import { ContenttypesAdminMain } from "./SettingsPages/ContenttypeAdminMain"
import { OwnerstoragesettingsAdminMain } from "./SettingsPages/OwnerStorageSettingAdminMain"
import { OwnerstoragesettingsAdminEntryPage } from "./SettingsPages/OwnerStorageSettingEntryPage"
import { OwnersAdminEntryPage } from "./SettingsPages/OwnersAdminEntryPage"
import { OwnersAdminMain } from "./SettingsPages/OwnersAdminMain"
import { PartofmonthsAdminEntryPage } from "./SettingsPages/PartofmonthAdminEntryPage"
import { PartofmonthsAdminMain } from "./SettingsPages/PartofmonthAdminMain"
import { PoolsAdminEntryPage } from "./SettingsPages/PoolsAdminEntryPage"
import { PoolsAdminMain } from "./SettingsPages/PoolsAdminMain"
import { VesselsAdminEntryPage } from "./SettingsPages/VesselsAdminEntryPage"
import { VesselsAdminMain } from "./SettingsPages/VesselsAdminMain"

// Define a higher-order component
export const SettingsEntryHOC = ({
  type,
  data,
  setShowEntryBlade,
}: {
  type: AdminSettingsEnum
  data: PoolPortalSingleTypes
  setShowEntryBlade: (show: boolean) => void
}) => {
  switch (type) {
    case AdminSettingsEnum.VESSELS:
      return (
        <VesselsAdminEntryPage
          data={data as PoolPortalVessel}
          setShowEntryBlade={setShowEntryBlade}
        />
      )
    case AdminSettingsEnum.OWNERS:
      return (
        <OwnersAdminEntryPage
          data={data as PoolPortalOwner}
          setShowEntryBlade={setShowEntryBlade}
        />
      )
    case AdminSettingsEnum.POOLS:
      return (
        <PoolsAdminEntryPage
          data={data as PoolPortalPool}
          setShowEntryBlade={setShowEntryBlade}
        />
      )
    case AdminSettingsEnum.CONTENTTYPE:
      return (
        <ContenttypesAdminEntryPage
          data={data as PoolPortalContenttype}
          setShowEntryBlade={setShowEntryBlade}
        />
      )
    case AdminSettingsEnum.PARTOFMONTH:
      return (
        <PartofmonthsAdminEntryPage
          data={data as PoolPortalPartofmonth}
          setShowEntryBlade={setShowEntryBlade}
        />
      )
    case AdminSettingsEnum.OWNERSTORAGESETTING:
      return (
        <OwnerstoragesettingsAdminEntryPage
          data={data as PoolPortalOwnerstoragesetting}
          setShowEntryBlade={setShowEntryBlade}
        />
      )
    default:
      return <></>
  }
}

export const SettingsAdminHOC = ({ type }: { type: AdminSettingsEnum }) => {
  switch (type) {
    case AdminSettingsEnum.VESSELS:
      return <VesselsAdminMain />
    case AdminSettingsEnum.OWNERS:
      return <OwnersAdminMain />
    case AdminSettingsEnum.POOLS:
      return <PoolsAdminMain />
    case AdminSettingsEnum.CONTENTTYPE:
      return <ContenttypesAdminMain />
    case AdminSettingsEnum.PARTOFMONTH:
      return <PartofmonthsAdminMain />
    case AdminSettingsEnum.OWNERSTORAGESETTING:
      return <OwnerstoragesettingsAdminMain />
    default:
      return <></>
  }
}
