import { BASE_URL } from 'api/url_helper';
import { useAxiosRequest } from "api/axiosRequest"
import { PoolPortalPartofmonth, PoolPortalPartofmonths } from '../helpers/types';

const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const PartofmonthService = () => {
  const request = useAxiosRequest()
  const getPartofmonths = async () => {
    try {
      const partofmonthsUrl = `${POOLPORTAL_API_BASE_URL}/partofmonths`
      const response = await request<any, PoolPortalPartofmonths>(
        {
          url: partofmonthsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const createPartofmonth = async (newPartofmonth: PoolPortalPartofmonth) => {
    try {
      const response = await request<any, PoolPortalPartofmonth>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/partofmonth`,
          method: "POST",
          data: newPartofmonth
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updatePartofmonth = async (updatedPartofmonth: PoolPortalPartofmonth) => {
    try {
      const response = await request<any, PoolPortalPartofmonth>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/partofmonth`,
          method: "PUT",
          data: updatedPartofmonth
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deletePartofmonth = async (id: string) => {
    try {
      const response = await request<any, PoolPortalPartofmonth>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/partofmonth?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getPartofmonths,
    createPartofmonth,
    updatePartofmonth,
    deletePartofmonth
  }
}

export default PartofmonthService
