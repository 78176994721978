import { Form, InputGroup, Spinner } from "react-bootstrap"

interface PortPredictorDistanceToPortInputProps {
  distanceFromDepartureDisplay: number | undefined
  isGetDistanceReallyLoading: boolean
}

export function PortPredictorDistanceToPortInput({
  distanceFromDepartureDisplay,
  isGetDistanceReallyLoading,
}: PortPredictorDistanceToPortInputProps) {
  return (
    <>
      <Form.Label className="PortPredictor_FormLabel fw-bold">
        Distance
      </Form.Label>
      {isGetDistanceReallyLoading ? (
        <div
          className="PortPredictor_InputGroup d-flex align-items-center"
          style={{ height: "35px" }}
        >
          <Spinner as="span" animation="border" size="sm" variant="light" />
          <span className="ms-1">Calculating distance...</span>
        </div>
      ) : (
        <InputGroup className="PortPredictor_InputGroup">
          <Form.Control
            disabled={true}
            className="ms-0"
            placeholder="Please select a port"
            aria-label="Distance to Port"
            aria-describedby="Distance to Port"
            value={distanceFromDepartureDisplay}
            type="number"
          />
          <InputGroup.Text id="turnAroundTime">N.M.</InputGroup.Text>
        </InputGroup>
      )}
    </>
  )
}
