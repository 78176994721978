import BigNumber from "bignumber.js"
import { PortPredictorTurnAroundTimeData } from "../hooks/usePortPredictorAPI"

import {
  average as findAverage,
  max as findMax,
  median as findMedian,
  min as findMin,
} from "simple-statistics"
import { CheckedMinMedianMax } from "../portPredictorReducer"
import { TurnAroundTimeMinMedianMax } from "../hooks/useFetchPortTurnAroundTime"

function roundUpPortTurnAroundTime(turnAroundTime: number) {
  return BigNumber(turnAroundTime).decimalPlaces(1, BigNumber.ROUND_CEIL)
}

export function getTurnAroundTimeMinMedianMax(turnAroundTimeData: PortPredictorTurnAroundTimeData[] | null | undefined): TurnAroundTimeMinMedianMax | undefined {
  if (!turnAroundTimeData) {
    return
  }

  const portTurnAroundTimeDays = turnAroundTimeData.map(
    (vessel) => vessel.days_in_port
  )

  const min = roundUpPortTurnAroundTime(findMin(portTurnAroundTimeDays)).toNumber()
  const median = roundUpPortTurnAroundTime(findMedian(portTurnAroundTimeDays)).toNumber()
  const average = roundUpPortTurnAroundTime(findAverage(portTurnAroundTimeDays)).toNumber()
  const max = roundUpPortTurnAroundTime(findMax(portTurnAroundTimeDays)).toNumber()

  return {
    min: { label: CheckedMinMedianMax.Min, value: min },
    median: { label: CheckedMinMedianMax.Median, value: median },
    average: { label: CheckedMinMedianMax.Average, value: average },
    max: { label: CheckedMinMedianMax.Max, value: max },
  }
}

export function getIsTurnAroundTimeMinMedianMax({
  turnAroundTime,
  turnAroundTimeMinMedianMax,
}: {
  turnAroundTime: number | undefined
  turnAroundTimeMinMedianMax: TurnAroundTimeMinMedianMax | undefined
}) {
  if (!turnAroundTime || !turnAroundTimeMinMedianMax) {
    return false
  }

  const found = Object.keys(turnAroundTimeMinMedianMax).filter((k) => {
    const key = k as CheckedMinMedianMax
    const item = turnAroundTimeMinMedianMax[key]
    const days = item.value
    return turnAroundTime === days
  })

  return found.length > 0 ? true : false
}