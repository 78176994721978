import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { PoolPortalVessel } from '../helpers/types'
import axios from 'axios'
import { toast } from 'react-toastify'
import VesselService from './vesselsService'

export const useVessels = () => {
  const queryClient = useQueryClient()
  const vesselService = VesselService()

  // Fetch vessels
  const { data: vessels, isLoading } = useQuery(['vessels'], vesselService.getVessels)


  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newVessel: PoolPortalVessel }) =>
      vesselService.createVessel(variables.newVessel),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['vessels'])
      },
      onError: (error: Error) => {
        console.error("Error during createVessel:", error.message)
      },
    })

  const handleCreate = async (newVessel: PoolPortalVessel): Promise<PoolPortalVessel> => {
    try {
      const createdVessel = await createMutation.mutateAsync({
        newVessel,
      })
      return createdVessel as PoolPortalVessel // Explicitly cast to PoolPortalVessel
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create vessel - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { id: string, updatedVessel: PoolPortalVessel }) => vesselService.updateVessel(variables.id, variables.updatedVessel), {
    onSuccess: () => {
      queryClient.invalidateQueries(['vessels'])
    },
  })

  const handleUpdate = async (id: string, updatedVessel: PoolPortalVessel): Promise<PoolPortalVessel> => {
    try {
      const result = await updateMutation.mutateAsync({ id, updatedVessel })
      return result as PoolPortalVessel
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to update vessel - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(vesselService.deleteVessel, {
    onSuccess: () => {
      queryClient.invalidateQueries(['vessels'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete vessel - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    vessels,
    isLoading,
    createVessel: handleCreate,
    updateVessel: handleUpdate,
    deleteVessel: handleDelete
  }
}
