import BigNumber from "bignumber.js"
import { VesselLeg } from "./hooks/usePrevetAPI"
import {
  average as findAverage,
  max as findMax,
  min as findMin,
  mode as findMode,
  median as findMedian,
  interquartileRange as findIQR,
} from "simple-statistics"

export type LadenStatus = "laden" | "ballast"

export enum PrevetSpeedGraphZoneType {
  Dips = "Dips",
  Outliers = "Outliers",
  None = "None",
}

export const ladenMarkAreaColor = "rgba(159, 166, 191, 1)"
export const ballastMarkAreaColor = "rgba(255, 200, 184, 1)"
export const ladenLegLineColor = "rgba(159, 166, 191, 1)"
export const ballastLegLineColor = "rgba(255, 92, 41, 0.6)"
export const ladenColor = "#001446"
export const ballastColor = "#FF5B29"

type VesselLegWithDelta = VesselLeg & { delta: number }

function findLowerBound(speedData: VesselLeg[]) {
  const sortedSog = speedData.map((dp) => dp.sog).sort((a, b) => a - b) // A sorted array of sog, e.g. [7.2, 9.1, 10.1, 11, 12.3 ...]
  const idxQ2 = sortedSog.indexOf(findMedian(sortedSog)) // the index of the median, e.g. 2, which is the index of 10.1
  const firstHalf = sortedSog.slice(0, idxQ2) // first half of sorted sog array e.g. [7.2, 9.1]

  if (firstHalf.length < 1) {
    // Sometimes not enough datapoints, so this:
    return findMedian(sortedSog) / 2
  }
  const Q1 = findMedian(firstHalf)
  const IQR = findIQR(sortedSog)
  const lowerBound = Q1 - IQR
  return lowerBound
}

function addDeltaToLegs(legs: VesselLeg[]): VesselLegWithDelta[] {
  return legs.map((leg, idx) => {
    if (idx > 0) {
      return {
        ...leg,
        delta: new BigNumber(leg.sog).minus(legs[idx - 1].sog).toNumber(),
      }
    }
    return {
      ...leg,
      delta: 0,
    }
  })
}

export function createZonesOfConcern({
  speedData,
  isLaden,
  useForMinTreshold,
}: {
  speedData: VesselLeg[]
  isLaden: boolean
  useForMinTreshold: PrevetSpeedGraphZoneType
}) {

  if (
    !speedData ||
    speedData.length < 1 ||
    useForMinTreshold === PrevetSpeedGraphZoneType.None
  ) {
    return []
  }
  const color = isLaden ? ladenMarkAreaColor : ballastMarkAreaColor
  const dLegs = addDeltaToLegs(speedData)

  const dLegsNegDelta = dLegs.filter((dp) => dp.delta < 0)
  if (dLegsNegDelta.length < 1) {
    return []
  }

  const deltaMode = findMode(
    dLegsNegDelta.map((dp) => Math.abs(dp.delta as number))
  )
  let minSpeedTreshold = findLowerBound(dLegs)

  if (useForMinTreshold === PrevetSpeedGraphZoneType.Outliers) {
    minSpeedTreshold = findLowerBound(dLegs)
  }

  if (useForMinTreshold === PrevetSpeedGraphZoneType.Dips) {
    const speedAvg = findAverage(dLegs.map((dp) => dp.sog))
    minSpeedTreshold = BigNumber(speedAvg - deltaMode)
      .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
      .toNumber()
  }

  const result = []
  let currZoneStart = dLegs[0].pos_date
  let flagged = 0

  for (let i = 0; i < dLegs.length; i++) {
    if (i > 0) {
      // Only do zone change comparison if i is not the item at index 0
      if (
        dLegs[i].zone_group !== dLegs[i - 1].zone_group ||
        i === dLegs.length - 1
      ) {
        // If zone change or end of array
        const lastLegInPrevZone =
          i === dLegs.length - 1 ? dLegs[i] : dLegs[i - 1]
        if (flagged > 0) {
          result.push([
            {
              name: lastLegInPrevZone.zone_group,
              xAxis: `${currZoneStart}`,
              itemStyle: {
                color,
                opacity: 0.3,
              },
            },
            { xAxis: lastLegInPrevZone.pos_date },
          ])
        }
        currZoneStart = dLegs[i].pos_date
        flagged = 0
      }
    }
    if (dLegs[i].sog < minSpeedTreshold && (dLegs[i].delta as number) < 0 && dLegs[i].zone_group) {
      flagged++
    }
    if (
      i + 1 === dLegs.length - 1 &&
      dLegs[i + 1].sog < minSpeedTreshold &&
      (dLegs[i + 1].delta as number) <= 0
    ) {
      // Before we hit the last leg, we need to look ahead and see if the last point is an outlier and flag it
      flagged++
    }
  }

  return result
}

export function createAllZones({ speedData }: { speedData: VesselLeg[] }) {
  if (!speedData || speedData.length === 0) {
    return
  }
  const result = []
  let currZone = speedData[0].zone_group
  let markAreaDisplayName = speedData[0].zone_group
  let currZoneStart = speedData[0].pos_date
  let currZoneEnd = undefined

  for (let i = 0; i < speedData.length; i++) {
    if (speedData[i].zone_group !== currZone) {
      currZoneEnd = speedData[i].pos_date
      result.push([
        {
          name: `${markAreaDisplayName}`,
          xAxis: currZoneStart,
          itemStyle: {
            borderType: "solid",
            borderWidth: 1,
            opacity: 0.1,
          },
        },
        { xAxis: currZoneEnd },
      ])
      currZone = speedData[i].zone_group
      markAreaDisplayName = speedData[i].zone_group
      currZoneStart = speedData[i].pos_date
      currZoneEnd = undefined
    }
    if (i === speedData.length - 1) {
      currZoneEnd = speedData[i].pos_date
      result.push([
        {
          name: `${markAreaDisplayName}`,
          xAxis: currZoneStart,
          itemStyle: {
            borderType: "solid",
            borderWidth: 1,
            opacity: 0.1,
          },
        },
        { xAxis: currZoneEnd },
      ])
    }
  }
  return result
}

export function getTitleText({
  speedData,
  months,
  ladenStatus,
}: {
  speedData: VesselLeg[]
  months: number
  ladenStatus: LadenStatus
}) {
  if (speedData.length < 1) {
    return `${ladenStatus.toUpperCase()} legs in the last ${String(
      months
    )} months too short to be considered`
  }

  const min = findMin(speedData.map((item) => item.sog))
  const max = findMax(speedData.map((item) => item.sog))
  const average = findAverage(speedData.map((item) => item.sog))

  return (
    months +
    " Months " +
    ladenStatus.toUpperCase() +
    " (Kt)  Min: " +
    min +
    "  Max: " +
    max +
    "  Avg: " +
    BigNumber(average).decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
  )
}

export function getChartMinSpeed(speedData: VesselLeg[]) {
  if (speedData.length < 1) {
    return 0
  }
  const min = findMin(speedData.map((item) => item.sog))

  // Make sure the min point does not sit directly on the bottom of the chart
  if (min - 1 > 0) {
    return Math.floor(min - 1)
  }
  return 0
}

export function getChartMaxSpeed(speedData: VesselLeg[]) {
  const standardVesselSpeed = 12

  if (speedData.length < 1) {
    // Make sure there is upper room on y-axis to show the standard vessel line
    return standardVesselSpeed + 1
  }

  const max = findMax(speedData.map((item) => item.sog))

  if (max > standardVesselSpeed) {
    return BigNumber(max + 1)
      .decimalPlaces(0, BigNumber.ROUND_HALF_CEIL)
      .toNumber()
  }

  return standardVesselSpeed + 1
}

export function findIndexOfSinglePointZones({
  speedData,
  singlePointZones,
}: {
  speedData: VesselLeg[]
  singlePointZones: { name?: string; xAxis: string }[][]
}) {
  const arrOfPointsIndex: number[] = []
  singlePointZones.forEach((zone) => {
    const idx = speedData.findIndex(
      (leg) => leg.zone_group === zone[0].name && leg.pos_date === zone[0].xAxis
    )
    arrOfPointsIndex.push(idx)
  })

  return arrOfPointsIndex
}

export function getZonesOfConcernWithSinglePointZoneIndex({
  speedData,
  isLaden,
  useForMinTreshold,
}: {
  speedData: VesselLeg[]
  isLaden: boolean
  useForMinTreshold: PrevetSpeedGraphZoneType
}) {
  const zonesOfConcern = createZonesOfConcern({
    speedData,
    isLaden,
    useForMinTreshold: useForMinTreshold as PrevetSpeedGraphZoneType,
  })
  // Hacky code to draw markArea in shadow xAxis around a single data point
  const singlePointZones = zonesOfConcern.filter((zone) => {
    return zone[0].xAxis === zone[1].xAxis
  })


  const indexOfSinglePointZones = findIndexOfSinglePointZones({
    speedData,
    singlePointZones,
  })

  return { zonesOfConcern, indexOfSinglePointZones }
}

export function createLegPieces({
  speedData,
  isLaden,
}: {
  speedData: VesselLeg[]
  isLaden: boolean
}) {
  if (speedData.length < 1) {
    return []
  }
  const color = isLaden ? "rgba(0,20,70,1)" : "rgba(255,92,41,1)"
  let prevPort = speedData[0].prev_port;
  let nextPort = speedData[0].next_port;
  let minIndex = 0
  const result = []

  for (let i = 1; i < speedData.length; i++) {
    let currPrevPort = speedData[i].prev_port;
    let currNextPort = speedData[i].next_port;
    if ((currPrevPort !== prevPort || currNextPort !== nextPort)) {
      result.push({
        min: minIndex,
        max: i - 1,
        color: color
      })
      minIndex = i
      prevPort = currPrevPort
      nextPort = currNextPort
    }
    if (i === speedData.length - 1) {
      result.push({
        min: minIndex,
        max: i,
        color: color
      })
    }
  }
  return result
}

export function calcMarkLineOffset({ label, compared }: { label: number, compared: number }) {
  // Sometimes the lines are too close together, eg Avg is 11.8 and User Vsl is 12
  // So we offset a bit to put the bigger number higher and smaller number lower
  const labelBN = new BigNumber(label)
  const diff = labelBN.minus(compared).toNumber()
  if (Math.abs(diff) > 0.4) {

    return [0, 0]
  }
  if (label <= compared) {
    return [0, 4]
  }
  return [0, -4]
}

export function drawLegLines(speedData: VesselLeg[]) {
  if (speedData.length < 1) {
    return []
  }
  const result = [];
  for (let i = 1; i < speedData.length; i++) {
    const right = speedData[i];
    const left = speedData[i - 1];
    if (
      right.prev_port !== left.prev_port ||
      right.next_port !== left.next_port
    ) {
      result.push(i - 1);
      result.push(i);
    }
  }
  const uniq = [...new Set(result)];
  return uniq.map(dp => {
    return { xAxis: dp }
  });
}