import React, { useCallback, useEffect, useState } from "react"
import { Container, Button } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import Breadcrumbs from "components/Common/Breadcrumbs"
import MaintenanceOffcanvas from "./AdminMaintenance/MaintenanceOffcanvas"
import MaintenancePreview from "./AdminMaintenance/MaintenancePreview"
import MaintenanceListWrapper from "./MaintenanceList/MaintenanceListWrapper"

function AdminMaintenanceWrapper() {
  const [show, setShow] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handlePreviewClose = () => setShowPreview(false)
  const handlePreviewShow = () => setShowPreview(true)

  const handleEditorChange = ({
    html,
    text,
  }: {
    html: string
    text: string
  }) => {
    setMessage(text)
  }

  const refreshMaintenanceList = useCallback(() => {
    setRefresh(prev => !prev)
  },[])

  useEffect(() => {
    const now = new Date()
    const utc8Now = new Date(now.getTime() + 8 * 60 * 60 * 1000)
    const defaultDate = utc8Now.toISOString().slice(0, 16)
    setStartDate(defaultDate)
    setEndDate(defaultDate)
  }, [])


  return (
    <React.Fragment>
      <Helmet>
        <title>
          Maintenance Notification Management | Market Manager Platform
        </title>
      </Helmet>
      <Container fluid className="my-3 px-4">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <Breadcrumbs
            title="Maintenance Notification"
            breadcrumbItem="Maintenance Management"
          />
          <Button
            className="d-flex flex-row gap-2 align-items-center text-md mt-2 mt-md-0"
            onClick={handleShow}
          >
            <i className="ti-plus"></i>Add Maintenance
          </Button>
        </div>

        {/* Table for maintenance list */}
        <MaintenanceListWrapper refresh = {refresh} />
      </Container>

      {/* Offcanvas for Add Maintenance */}
      <MaintenanceOffcanvas
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        startDate={startDate}
        endDate={endDate}
        message={message}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setMessage={setMessage}
        handleEditorChange={handleEditorChange}
        handlePreviewShow={handlePreviewShow}
        refreshMaintenanceList = {refreshMaintenanceList}
        mode = "create"
      />
      {/* Modal to show preview */}
      <MaintenancePreview
        showPreview={showPreview}
        handlePreviewClose={handlePreviewClose}
        message={message}
      />

     
    </React.Fragment>
  )
}

export default AdminMaintenanceWrapper
