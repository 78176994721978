import ReactEcharts from "echarts-for-react"
import React from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap"

import { getPortStaysChartOptions } from "../helpers/getPortStaysChartOptions"
import { getPortStaysData } from "../helpers/getPortStaysData"
import { VesselStay } from "../hooks/useFetchVesselStays"
import { VesselDetails } from "../hooks/usePrevetAPI"

interface PrevetPortStaysProps {
  vesselDetailsData: VesselDetails
  vesselStaysData: VesselStay[] | undefined
  isVesselStaysLoading: boolean
  isVesselStaysError: boolean
}

export function PrevetPortStays({
  vesselDetailsData,
  vesselStaysData,
  isVesselStaysError,
  isVesselStaysLoading,
}: PrevetPortStaysProps): JSX.Element {
  const portStaysData = vesselStaysData && getPortStaysData(vesselStaysData)
  return (
    <React.Fragment>
      <Card className="mb-3 flex-grow-1">
        <Card.Body>
          {isVesselStaysLoading && (
            <div className="d-flex flex-column align-items-center">
              <Spinner className="mb-3" animation="border" role="status" />
              <div>Loading vessel stays...</div>
            </div>
          )}
          {isVesselStaysError && (
            <div className="d-flex justify-content-center">
              Error loading vessel stays
            </div>
          )}
          {portStaysData && (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  <h4 style={{ marginBottom: "1.2rem" }} className="card-title">
                    Port Stays
                  </h4>
                </div>
              </div>
              <Row>
                <Col>
                  <ReactEcharts
                    // Control the dimensions of the chart with height
                    // and grid.height of chartOptions
                    style={{
                      height: "420px",
                    }}
                    option={getPortStaysChartOptions({
                      portStaysData,
                      vesselInfo: vesselDetailsData,
                    })}
                  />
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}
