import { Organisation } from "modules/Authentication/store/user/interfaces"
import { Button, Card } from "react-bootstrap"
import Select, { SingleValue } from "react-select"

interface AdminReportsUploadBarProps {
  organisations: Organisation[]
  chosenOrganisation: Organisation | null
  setChosenOrganisation: (org: Organisation) => void
  setIsModalShown: (isShown: boolean) => void
}

export function AdminReportsUploadBar({
  organisations,
  chosenOrganisation,
  setChosenOrganisation,
  setIsModalShown,
}: AdminReportsUploadBarProps): JSX.Element {
  return (
    <>
      <Card className="d-flex align-items-center flex-row mb-3 p-3">
        <h5 className="AdminReportsUploadBar_Title mb-0">
          Client Organisation
        </h5>
        <div className="AdminReportsUploadBar_Select_Wrapper">
          <Select
            className="AdminReportsUploadBar_Select"
            onChange={(event: SingleValue<Organisation>) => {
              setChosenOrganisation(event as Organisation)
            }}
            defaultValue={organisations[0]}
            options={organisations}
          />
        </div>
        <Button
          disabled={!chosenOrganisation}
          style={{ marginRight: "1rem" }}
          onClick={() => {
            setIsModalShown(true)
          }}
        >
          Upload
        </Button>
      </Card>
    </>
  )
}
