import { ReactNode, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { AppSidebar } from "../Sidebar/AppSidebar"
import Footer from "./Footer"
import Header from "./Header"

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(1).toUpperCase() + str.slice(2)
}

export interface AuthLayoutProps {
  children: ReactNode
}

export function AuthLayout({ children }: AuthLayoutProps) {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    let currentPage = capitalizeFirstLetter(location.pathname)
    document.title = currentPage + " | Market Manager Platform"
  }, [location.pathname])

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <div className="app-wrapper">
          <AppSidebar />
          <div className="main-content">
            {/* <div className="main-content_inner">{children}</div> */}
            <>{children}</>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}
