import { ErrorBoundary } from "ErrorBoundary"
import { Map as LeafletMap } from "leaflet"
import { fixAntimeridian } from "modules/MapComponent/components/map/positions"

import { useGetVessels } from "modules/VesselSearch/useGetVessels"
import { useCallback, useReducer, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useSelector } from "react-redux"
import { RootState } from "store"
import {
  VesselSearch,
  VesselSegmentInputSelection,
} from "../../VesselSearch/VesselSearchMain"
import { formatVesselNamesForDropdown } from "../helpers/formatVesselNamesForDropdown"
import { getMarkerPointsFromJourneyMarkerState } from "../helpers/mapHelpers"
import { useFetchFuturePath } from "../hooks/useFetchFuturePath"
import { useFetchVesselJourneySpeed } from "../hooks/useFetchVesselJourneySpeed"
import { useFetchVesselJourneyStart } from "../hooks/useFetchVesselJourneyStart"
import { useJourneyStart } from "../hooks/useJourneyStart"
import { PortPredictorVesselStateDataWithMajorCargo } from "../hooks/usePortPredictorAPI"
import {
  ADD_JOURNEY_START_MARKERS,
  EDIT_JOURNEY,
  EXTEND_JOURNEY_MARKERS,
  MarkerType,
  PortPredictorJourneyMarker,
  portPredictorJourneyReducer,
  PortPredictorJourneyState,
} from "../portPredictorReducer"
import { ErrorFallback } from "./PortPredictorErrorFallback"
import { PortPredictorMap } from "./PortPredictorMap"
import { PortPredictorSpeedGraphModal } from "./PortPredictorSpeedGraphModal"
import { PortPredictorTimeline } from "./PortPredictorTimeline/PortPredictorTimeline"
import { useFetchVesselPositions } from "../hooks/useFetchVesselPositions"
import { useFetchVesselDetails } from "../hooks/useFetchVesselDetails"
import { useFetchVesselStays } from "../hooks/useFetchVesselStays"

export interface VesselListSingle {
  imo: number
  segment: string
  vessel_name: string
}

export interface MarkerPoint {
  journeyMarkerStateIndex?: number
  journeyMarkerStateLocation?: string
  port?: string
  lat: number
  lon: number
}

export interface MarkerLatLon {
  lat: number
  lon: number
  markerId?: number
}

export function PortPredictor() {
  /**
   * Segment
   */
  const user = useSelector((state: RootState) => state.user)

  const vesselSegments = user.chosenOrganization?.segments

  const defaultSegment = user.defaultSegment

  const [chosenSegment, setChosenSegment] = useState<
    VesselSegmentInputSelection | undefined
  >(
    defaultSegment
      ? { value: defaultSegment, label: defaultSegment }
      : undefined
  )

  /**
   * Chosen Vessel
   */
  const [chosenVesselImo, setChosenVesselImo] = useState<number | undefined>()
  const [chosenVesselName, setChosenVesselName] = useState<string | undefined>()
  const { vesselsData, getVessels, getVesselsLoading } = useGetVessels({
    defaultSegment: chosenSegment?.value,
  })

  const {
    isChosenVesselStateLoading,
    chosenVesselStateData,
    isChosenVesselStateSuccess,
  } = useFetchVesselJourneyStart(chosenVesselImo)

  const { isChosenVesselSpeedSuccess, chosenVesselSpeedData } =
    useFetchVesselJourneySpeed(chosenVesselImo)

  /**
   * Journey
   */
  const [journeyMarkerState, journeyMarkerDispatch] = useReducer(
    portPredictorJourneyReducer,
    []
  )

  const addJourneyStartMarkersArr = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: ADD_JOURNEY_START_MARKERS,
        payload: markers,
      })
    },
    [journeyMarkerDispatch]
  )

  const { journeyStartMarkersArr } = useJourneyStart({
    chosenVesselStateData,
    chosenVesselImo,
    journeyMarkerState,
    chosenVesselSpeedData,
    isChosenVesselSpeedSuccess,
    isChosenVesselStateSuccess,
    addJourneyStartMarkersArr,
    isStartOfJourney: journeyMarkerState.length === 0,
  })

  const dispatchEditJourney = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: EDIT_JOURNEY,
        payload: markers,
      })
    },
    []
  )
  const dispatchAddJourneyStartMarkers = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: ADD_JOURNEY_START_MARKERS,
        payload: markers,
      })
    },
    []
  )
  const dispatchExtendJourneyMarkers = useCallback(
    (markers: PortPredictorJourneyMarker[]): void => {
      journeyMarkerDispatch({
        type: EXTEND_JOURNEY_MARKERS,
        payload: markers,
      })
    },
    []
  )

  /**
   * SpeedGraph
   */
  const [isSpeedGraphModalShown, setIsSpeedGraphModalShown] = useState(false)

  /**
   * Map
   */
  const [map, setMap] = useState<LeafletMap | null>(null)
  const { vesselDetailsData } = useFetchVesselDetails(chosenVesselImo)
  const { vesselStaysData } = useFetchVesselStays({
    imo: chosenVesselImo,
    built: vesselDetailsData?.built,
  })

  const { futurePathData } = useFetchFuturePath(
    getMarkerPointsFromJourneyMarkerState(journeyMarkerState)
  )
  const { vesselPositions } = useFetchVesselPositions({
    imo: chosenVesselImo,
    months: 6,
  })

  return (
    <>
      {chosenVesselSpeedData && chosenVesselName && isSpeedGraphModalShown && (
        <PortPredictorSpeedGraphModal
          isSpeedGraphModalShown={isSpeedGraphModalShown}
          chosenVesselSpeedData={chosenVesselSpeedData}
          chosenVesselName={chosenVesselName}
          closeSpeedGraphModal={() => {
            setIsSpeedGraphModalShown(false)
          }}
        />
      )}
      <Card>
        <Card.Body>
          <div className="VesselSearch_Row">
            {defaultSegment && chosenSegment && vesselSegments && (
              <VesselSearch
                vesselsData={formatVesselNamesForDropdown(vesselsData)}
                defaultSegment={{
                  label: defaultSegment,
                  value: defaultSegment,
                }}
                getVesselsLoading={getVesselsLoading}
                getChosenVesselLoading={isChosenVesselStateLoading}
                chosenSegment={chosenSegment}
                getChosenVessel={({
                  name,
                  imo,
                }: {
                  imo: number
                  name: string
                }) => {
                  journeyMarkerDispatch({ type: "CLEAR_JOURNEY" })
                  setChosenVesselName(name)
                  setChosenVesselImo(imo) // Set on main component
                }}
                setChosenSegment={(segment: VesselSegmentInputSelection) => {
                  journeyMarkerDispatch({ type: "CLEAR_JOURNEY" })
                  setChosenSegment(segment)
                }}
                getVessels={getVessels}
                vesselSegments={vesselSegments.map((segment) => {
                  return { label: segment, value: segment }
                })}
              />
            )}
            <div className="VesselSearch_ButtonContainer">
              <Button
                onClick={() => {
                  journeyMarkerDispatch({ type: "CLEAR_JOURNEY" })
                }}
                className="me-1 PortPredictor_VesselSearch_Button w-100"
                color="primary"
              >
                Reset
              </Button>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <>
                {chosenVesselImo &&
                  chosenSegment &&
                  chosenVesselStateData &&
                  vesselStaysData &&
                  vesselPositions && (
                    <PortPredictorMap
                      chosenVesselStateData={chosenVesselStateData}
                      futurePath={futurePathData}
                      journeyMarkerState={journeyMarkerState}
                      vesselStaysData={vesselStaysData}
                      map={map}
                      setMap={setMap}
                      vesselPositions={vesselPositions}
                    />
                  )}
              </>
            </ErrorBoundary>
            {chosenSegment && chosenVesselStateData && (
              <PortPredictorTimeline
                chosenVesselStateData={chosenVesselStateData}
                showSpeedGraphModal={() => {
                  setIsSpeedGraphModalShown(true)
                }}
                chosenVesselSpeedData={chosenVesselSpeedData}
                journeyMarkerState={journeyMarkerState}
                dispatchEditJourney={dispatchEditJourney}
                dispatchExtendJourneyMarkers={dispatchExtendJourneyMarkers}
                dispatchAddJourneyStartMarkers={dispatchAddJourneyStartMarkers}
                segment={chosenSegment.value}
                journeyStartMarkersArr={journeyStartMarkersArr}
                map={map}
                markers={getMarkersForMapBound({
                  journeyMarkerState,
                  vesselState: chosenVesselStateData,
                })}
              />
            )}
          </ErrorBoundary>
        </Card.Body>
      </Card>
    </>
  )
}

function getMarkersForMapBound({
  vesselState,
  journeyMarkerState,
}: {
  journeyMarkerState: PortPredictorJourneyState
  vesselState: PortPredictorVesselStateDataWithMajorCargo
}) {
  const initialPosition = {
    lat: vesselState.last_pos_lat,
    lon: vesselState.last_pos_lon,
  }
  const result = [initialPosition]

  const arrivalMarkers = journeyMarkerState.filter(
    (marker) => marker.markerType === MarkerType.Arrival
  )

  for (let arrivalMarker of arrivalMarkers) {
    if (arrivalMarker.port) {
      const portLocation = {
        lat: arrivalMarker.port.lat,
        lon: arrivalMarker.port.lon,
        markerId: arrivalMarker.id,
      }
      result.push(portLocation)
    }
  }

  return fixAntimeridian(result)
}
