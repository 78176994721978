import ReactEcharts from "echarts-for-react"
import {
  getIsTurnAroundTimeMinMedianMax,
  getTurnAroundTimeMinMedianMax,
} from "modules/PortPredictor/helpers/utils"
import { useFetchTerminalTurnAroundTime } from "modules/PortPredictor/hooks/useFetchTerminalTurnAroundTime"
import {
  PortPredictorTurnAroundTimeData,
  PortPredictorVesselStateDataWithMajorCargo,
  VesselActivity,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import {
  PortPredictorMarkerArrival,
  PortType,
} from "modules/PortPredictor/portPredictorReducer"
import moment from "moment"
import { Dispatch, SetStateAction } from "react"
import { Accordion, Button, OverlayTrigger, Popover } from "react-bootstrap"
import { VesselSegment } from "user/userService"
import { TypeOfTurnAroundTimeData } from "./PortOrTerminalTurnAroundTimeCheckboxes"
import {
  PortPredictorPortTypeSelectOption,
  portTypes,
} from "./PortPredictorArrivalInputs"
import { getPortTurnAroundTimeOptions } from "./getPortTurnAroundTimeOptions"

interface PortPredictorArrivalDisplayProps {
  currMarker: PortPredictorMarkerArrival
  revertPortToOpenSea: () => void
  setIsPortTurnAroundTimeModalOpen: Dispatch<SetStateAction<boolean>>
  turnAroundTimeData?: PortPredictorTurnAroundTimeData[] | null | undefined
  segment: VesselSegment
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
}

export function PortPredictorArrivalDisplay({
  currMarker,
  revertPortToOpenSea,
  turnAroundTimeData,
  setIsPortTurnAroundTimeModalOpen,
  segment,
  chosenVesselStateData,
}: PortPredictorArrivalDisplayProps) {
  const isStartOfJourney = currMarker.id === 0
  const vesselActivity = getActivityFromPortType(currMarker.portType)

  const { isTerminalTurnAroundTimeSuccess } = useFetchTerminalTurnAroundTime({
    segment,
    cargo: currMarker.cargo,
    cargoGroup: currMarker.cargoGroup,
    locode: currMarker?.port?.locode,
    terminalId: currMarker.berth?.terminal_id,
    activity: vesselActivity.activity,
  })

  const showTerminal =
    currMarker.typeOfTurnAroundTimeData ===
      TypeOfTurnAroundTimeData.UseTerminalData &&
    isTerminalTurnAroundTimeSuccess

  const turnAroundTimeMinMedianMax =
    getTurnAroundTimeMinMedianMax(turnAroundTimeData)
  const isTurnAroundTimeMinMedianMax = getIsTurnAroundTimeMinMedianMax({
    turnAroundTime: currMarker.turnAroundTime,
    turnAroundTimeMinMedianMax,
  })

  function getDateUpTill(lastPosTime: string | undefined) {
    if (!lastPosTime) {
      return moment(new Date())
    }
    return moment(currMarker.lastPosTime)
  }

  const arrivalDate = currMarker.arrivalDate && moment(currMarker.arrivalDate)

  const dateUpTill = getDateUpTill(currMarker.lastPosTime)
  const daysSinceArrival =
    arrivalDate && dateUpTill && dateUpTill.diff(arrivalDate, "days")

  return (
    <div className="PortPredictor_Display mb-5">
      <div className="d-flex">
        <span className="me-1">Arrival:</span>
        <span className="me-1 fw-bold">
          {currMarker.arrivalDate &&
            moment(currMarker.arrivalDate).format("D MMM YYYY")}
        </span>
        {currMarker.portType &&
          currMarker.laycanEnd &&
          currMarker.laycanStart &&
          currMarker.arrivalDate &&
          getLaycanWarningBadge({
            portType: currMarker.portType,
            laycanStart: currMarker.laycanStart,
            laycanEnd: currMarker.laycanEnd,
            arrivalDate: currMarker.arrivalDate,
          })}
      </div>
      {currMarker.id === 0 && (
        <div className="d-flex">
          <div>
            {currMarker.id === 0 && (
              <Accordion>
                <Accordion.Item
                  eventKey={`${currMarker.id}_${currMarker.arrivalDate}_${currMarker.vesselPosition}`}
                >
                  <Accordion.Header>
                    {getPortType(currMarker.portType)}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="flex-fill" style={{ maxWidth: "250px" }}>
                      <div
                        onClick={() => {
                          revertPortToOpenSea()
                        }}
                      >
                        Change to Open Sea
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            <div className="mb-1">{currMarker.lastNavStaDesc}</div>
          </div>
        </div>
      )}
      <div className="d-flex">
        <div className="d-inline">
          <span className="me-1">Est. Turn Around Time:</span>
          <span>{currMarker.turnAroundTime}</span>
        </div>
        {!isTurnAroundTimeMinMedianMax &&
          currMarker.port &&
          currMarker.portType !== PortType.Other &&
          currMarker.cargo &&
          currMarker.turnAroundTime !== undefined && (
            <div
              style={{ borderRadius: "4px" }}
              className="bg-yellow ms-2 PortPredictor_LaycanBadge"
            >
              <i className="ti-alert me-1" />
              <span>Manual input by user</span>
            </div>
          )}
      </div>

      {isStartOfJourney && (
        <div className="d-flex mt-2">
          <span className="me-1">Days since arrival:</span>
          <span>{daysSinceArrival} days</span>
          {currMarker.portType !== PortType.Other &&
            createWarnings({
              turnAroundTime: currMarker.turnAroundTime,
              daysSinceArrival,
              maxTurnAroundTime: turnAroundTimeMinMedianMax?.max.value,
            })}
        </div>
      )}
      {turnAroundTimeData &&
        currMarker.portType &&
        currMarker.portType !== PortType.Other &&
        currMarker.cargo &&
        currMarker.port &&
        currMarker.port.port && (
          <div className="PortPredictor_TurnAroundTimeChart PortPredictor_InputWrapper mt-1">
            <div className="d-flex align-items-center flex-column">
              <div className="mt-1 d-flex justify-content-center">
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderTooltip({
                    segment,
                    portType: getActivityFromPortType(currMarker.portType),
                    cargo: currMarker.cargo,
                    portName: currMarker.port?.port,
                    terminalName: currMarker.berth?.terminal_name,
                    typeOfTurnAroundTimeData:
                      currMarker.typeOfTurnAroundTimeData,
                  })}
                >
                  <div className="d-flex justify-content-center align-items-center me-2">
                    <i className="PortPredictor_MinMaxMedianInfoIcon ti-info-alt" />
                  </div>
                </OverlayTrigger>
                <span className="me-1">Last 10 vessels </span>
                <Button
                  className="d-flex justify-content-center align-items-center p-1"
                  onClick={() => {
                    setIsPortTurnAroundTimeModalOpen(true)
                  }}
                >
                  <i
                    className="ti-fullscreen m-0"
                    style={{ fontSize: "10px" }}
                  />
                </Button>
              </div>
              <div>
                {!showTerminal ? (
                  <span className="me-1">({currMarker.port.port})</span>
                ) : (
                  <span>({currMarker.berth?.terminal_name})</span>
                )}
              </div>
            </div>
            <ReactEcharts
              style={{
                height: "300px",
                width: "550px",
              }}
              option={getPortTurnAroundTimeOptions(turnAroundTimeData)}
            />
          </div>
        )}
    </div>
  )
}

const renderTooltip = ({
  segment,
  cargo,
  portType,
  portName,
  terminalName,
  typeOfTurnAroundTimeData,
}: {
  segment: VesselSegment
  cargo: string
  portType: PortPredictorPortTypeSelectOption
  portName: string
  terminalName?: string
  typeOfTurnAroundTimeData?: TypeOfTurnAroundTimeData
}) => {
  const activity =
    portType.activity === VesselActivity.Discharge ? "discharging" : "loading"
  const portOrTerminalName =
    typeOfTurnAroundTimeData === TypeOfTurnAroundTimeData.UsePortData
      ? portName
      : terminalName
  return (
    <Popover id="popover-basic">
      <Popover.Body>{`Last 10 ${segment} ships ${activity} ${cargo} at ${portOrTerminalName} `}</Popover.Body>
    </Popover>
  )
}

function getLaycanWarningBadge({
  portType,
  laycanStart,
  laycanEnd,
  arrivalDate,
}: {
  portType: PortType
  laycanEnd: string
  laycanStart: string
  arrivalDate: string
}) {
  const label =
    portType === PortType.DischargeWithArrivalWindow ? "window" : "laycan"
  const msIn24Hours = 60 * 60 * 24 * 1000
  const end = moment(laycanEnd)
  const start = moment(laycanStart)
  const arrival = moment(arrivalDate)

  // If start date - arrival date is positive, start date is bigger
  // than arrival date, therefore vessel has arrived early.

  if (start.diff(arrival) > 0) {
    return (
      <div className="PortPredictor_LaycanBadge bg-yellow">
        <i className="ti-alert me-1" />
        <span>Caution! ETA before start of {label}</span>
      </div>
    )
  }

  if (end.diff(arrival) < 0) {
    return (
      <div className="PortPredictor_LaycanBadge bg-danger">
        <i className="ti-alert me-1" />
        <span>Attention! ETA outside {label}</span>
      </div>
    )
  }

  if (end.diff(arrival) < msIn24Hours * 2) {
    return (
      <div className="PortPredictor_LaycanBadge bg-yellow">
        <i className="ti-alert me-1" />
        <span>Caution! ETA close to end of {label}</span>
      </div>
    )
  }

  return (
    <div className="PortPredictor_LaycanBadge bg-success">
      <i className="ti-check me-1" style={{ bottom: "0px" }} />
      <span>Within {label}</span>
    </div>
  )
}

function getPortType(portType: PortType | undefined) {
  if (
    portType === PortType.Discharge ||
    portType === PortType.DischargeWithArrivalWindow
  ) {
    return "Discharge Port"
  }
  if (portType === PortType.Load || portType === PortType.LoadWithLaycan) {
    return "Load Port"
  }
  if (portType === PortType.Other) {
    return "Other/Bunkering"
  }
  return "Unknown Port Type"
}

function getActivityFromPortType(portType: PortType) {
  return portTypes.find((opt) => {
    return opt.value === portType
  }) as PortPredictorPortTypeSelectOption
}

function createWarnings({
  turnAroundTime,
  daysSinceArrival,
  maxTurnAroundTime,
}: {
  daysSinceArrival: number | undefined | ""
  turnAroundTime: number | undefined
  maxTurnAroundTime: number | undefined
}) {
  const result = []
  if (!daysSinceArrival || !turnAroundTime || !maxTurnAroundTime) {
    return <></>
  }
  if (daysSinceArrival > turnAroundTime) {
    result.push(
      <div className="PortPredictor_LaycanBadge bg-yellow ms-1">
        <i className="ti-alert me-1" />
        <span>Turnaround time exceeded</span>
      </div>
    )
  }
  if (daysSinceArrival >= 25) {
    result.push(
      <div
        className="PortPredictor_LaycanBadge bg-danger ms-1"
        style={{ minWidth: "auto" }}
      >
        <i className="ti-alert me-1" />
        <span>Fouling Risk</span>
      </div>
    )
  }
  if (daysSinceArrival > maxTurnAroundTime) {
    result.push(
      <div className="PortPredictor_LaycanBadge bg-yellow ms-1">
        <i className="ti-alert me-1" />
        <span>Insufficient information to estimate departure time. </span>
      </div>
    )
  }
  return <div style={{ maxWidth: "200px" }}>{result}</div>
}
