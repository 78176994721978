import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { RootState } from "store"
import "./assets/scss/theme.scss"
import {
  useGetAppRoutesQuery,
  useGetUserOrganizationsQuery,
  useGetUserPermissionsQuery,
} from "./user/userService"
import { authentication } from "./authentication"
import { useEffect, useState } from "react"
import { authenticateUserSuccess } from "user/userSlice"
import { Helmet } from "react-helmet-async"

import { AuthLayout } from "./components/Layout/Content/AuthLayout"
import ErrorAuth0 from "components/Layout/NonAuth/ErrorAuth0"
import { Route, Routes } from "react-router-dom"
import { HomePage } from "modules/HomePage/HomePage"
import { routes } from "modules/routes"
import { NonAuthLayout } from "components/Layout/NonAuth/NonAuthLayout"
import NotFound from "components/Layout/NonAuth/NotFound"
import { AuthMiddleware } from "routes/middleware/AuthMiddleware"
import { toast } from "react-toastify"
import { useRedirectWithPermissions } from "hooks/useRedirectWithPermissions"

import { useAxiosRequest } from "api/axiosRequest"
import { useGetPowerBiRoutes } from "modules/PowerBi/useGetPowerBiRoutes"

export interface AppRoute {
  path: string
  component: () => JSX.Element
  permissionForPage?: string
}

export function App(): JSX.Element {
  const request = useAxiosRequest()
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  useGetAppRoutesQuery()
  const {
    data: permissions,
    error: getPermissionsError,
    isLoading: getPermissionsLoading,
  } = useGetUserPermissionsQuery()

  // const {
  //   data: organizations,
  //   error: getOrganizationsError,
  //   isLoading: getOrganizationsLoading,
  // } = useGetUserOrganizationsQuery()

  const powerBiRoutes = useGetPowerBiRoutes()

  const [hasInternetConnection, setHasInternetConnection] = useState(true)

  useRedirectWithPermissions(permissions)

  const {
    user: auth0User,
    getAccessTokenSilently,
    error: getAccessTokenSilentlyError,
  } = useAuth0()
  authentication.setAccessTokenSilently(getAccessTokenSilently)

  useEffect(() => {
    if (!auth0User) {
      return
    }
    dispatch(authenticateUserSuccess(auth0User))
  }, [auth0User, dispatch])

  useEffect(() => {
    window.addEventListener("online", () => {
      setHasInternetConnection(true)
    })
    window.addEventListener("offline", () => {
      setHasInternetConnection(false)
    })
  }, [])

  useEffect(() => {
    if (!hasInternetConnection) {
      toast.warn("No internet connection", {
        position: "top-right",
        autoClose: 8000,
      })
    }
  }, [hasInternetConnection])

  return (
    <div className="App">
      <Helmet>
        <title>Market Manager Platform</title>
        <meta name="theme-color" content="#001446" />
        <meta
          name="description"
          content="Market Manager is a commercial 
              analytics platform by Torvald Klaveness"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <div>
        {getAccessTokenSilentlyError ? (
          <ErrorAuth0 />
        ) : (
          <Routes>
            {[
              ...routes,
              ...powerBiRoutes,
              {
                path: "/",
                component: HomePage,
              },
            ].map((route: AppRoute) => (
              <Route
                path={route.path}
                key={route.path}
                element={
                  <AuthMiddleware
                    path={route.path}
                    layout={AuthLayout}
                    component={route.component}
                    isAuthProtected={true}
                    permissionForPage={route.permissionForPage as string}
                  />
                }
              />
            ))}
            <Route
              path="*"
              element={
                <AuthMiddleware
                  path="*"
                  layout={NonAuthLayout}
                  component={NotFound}
                  isAuthProtected={false}
                />
              }
            />
          </Routes>
        )}
        <ToastContainer />
      </div>
    </div>
  )
}
