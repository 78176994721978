import { Card } from "react-bootstrap"
import { RightShipVesselInfo } from "../hooks/usePrevetGetRightShipData"
import rightShipLogo from "../../../assets/images/right-ship.svg"
import { PrevetRightShipBadgeIcon } from "./PrevetRightShipBadgeIcon"

function getDocSafetyScore(
  rightShipVesselInfo: RightShipVesselInfo
): number | undefined {
  const doctSafetyScoreObject =
    rightShipVesselInfo?.safetyScore?.additionalData.find(
      (data) => data.key === "docSafetyScore"
    )

  return doctSafetyScoreObject?.value
}

function getGhgVerified(rightShipVesselInfo: RightShipVesselInfo): boolean {
  const isVerifiedObject = rightShipVesselInfo?.ghg?.additionalData.find(
    (data) => data.key === "verified"
  )

  return isVerifiedObject?.value as boolean
}

function getGhgEvdi(rightShipVesselInfo: RightShipVesselInfo): number {
  const isVerifiedObject = rightShipVesselInfo?.ghg?.additionalData.find(
    (data) => data.key === "evdi"
  )

  const value = isVerifiedObject?.value as number

  return Number(value.toFixed(2))
}

function getGhgPlus(rightShipVesselInfo: RightShipVesselInfo): boolean {
  const plusObject = rightShipVesselInfo?.ghg?.additionalData.find(
    (data) => data.key === "plus"
  )

  return plusObject?.value as boolean
}

function getGhgIconColors(rating: string | number) {
  switch (rating) {
    case "A":
      return { fill: "#00763B", font: "#FFFFFF" }
    case "B":
      return { fill: "#62BA46", font: "#001728" }
    case "C":
      return { fill: "#AED36B", font: "#001728" }
    case "D":
      return { fill: "#FFDD15", font: "#001728" }
    case "E":
      return { fill: "#FFAA1C", font: "#001728" }
    case "F":
      return { fill: "#FC6B37", font: "#001728" }
    case "G":
      return { fill: "#D71920", font: "#001728" }
    default:
      return { fill: "#d9d9d9", font: "#000000" }
  }
}

export function getScoreFontColor(score: number, tag?: string) {
  switch (score) {
    case 5:
      return "#00763B"
    case 4:
      return "#62BA46"
    case 3:
      return "#AED36B"
    case 2:
      return "#FFDD15"
    case 1:
      return "#D71920"
    case 0:
      return "#F7661F"
    default:
      return "#FFFFFFF"
  }
}

function GhgIcon({
  rating,
  plus,
}: {
  rating: string
  plus: boolean | undefined
}) {
  return (
    <div className="PrevetRightShip_GhgIcon">
      <div
        className="PrevetRightShip_GhgIcon-Letter"
        style={{ color: getGhgIconColors(rating).font }}
      >
        {rating === "U" ? (
          <span
            style={{ fontSize: "1rem", position: "relative", left: "-1px" }}
          >
            N/A
          </span>
        ) : (
          <span>{rating}</span>
        )}
        {plus && <span>+</span>}
      </div>
      <svg
        className="PrevetRightShip_GhgIcon-Svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56.7 40"
      >
        <polygon
          fill={getGhgIconColors(rating).fill}
          points="0,0 0,40 42.5,40 56.7,20 42.5,0"
        />
      </svg>
    </div>
  )
}

export function PrevetRightShip({
  rightShipVesselInfo,
}: {
  rightShipVesselInfo: RightShipVesselInfo
}) {
  const docSafetyScore = getDocSafetyScore(rightShipVesselInfo)

  const isValidSafetyScore =
    rightShipVesselInfo.safetyScore &&
    Number(rightShipVesselInfo.safetyScore.safetyScore) > 0

  return (
    <Card className="PrevetRightShip mt-3 mb-0">
      <div className="d-flex">
        <img
          className="PrevetRightShip_Logo"
          src={rightShipLogo}
          alt="right-ship-logo"
        />
        <PrevetRightShipBadgeIcon
          rightShipInspection={rightShipVesselInfo.rightshipInspection}
          platformLink={rightShipVesselInfo.platformLink}
        />
      </div>
      <div className="d-flex PrevetRightShip_Row">
        <div className="PrevetRightShip_Col">
          <div className="PrevetRightShip_Col_Inner">
            <h3 className="PrevetRightShip_Heading">Safety Score</h3>
            {isValidSafetyScore ? (
              <div>
                <span
                  className="PrevetRightShip_Score"
                  style={{
                    color: `${getScoreFontColor(
                      rightShipVesselInfo.safetyScore?.safetyScore
                    )}`,
                  }}
                >
                  {rightShipVesselInfo.safetyScore?.safetyScore}
                </span>
                <span className="PrevetRightShip_Score--sm">/5</span>
              </div>
            ) : (
              <div className="PrevetRightShip_Score--sm">N/A</div>
            )}
          </div>
          <div className="PrevetRightShip_Footnote">Source: RightShip</div>
        </div>
        <div className="ps-2 PrevetRightShip_Col PrevetRightShip_Col--border">
          <div className="PrevetRightShip_Col_Inner">
            <h3 className="PrevetRightShip_Heading">DOC Subscore</h3>
            {docSafetyScore ? (
              <div>
                <span
                  className="PrevetRightShip_Score"
                  style={{
                    color: `${getScoreFontColor(
                      Number(docSafetyScore),
                      "docSafetyScore"
                    )}`,
                  }}
                >
                  {docSafetyScore}
                </span>
                <span className="PrevetRightShip_Score--sm">/5</span>
              </div>
            ) : (
              <div className="PrevetRightShip_Score--sm">N/A</div>
            )}
          </div>
          <div className="PrevetRightShip_Footnote">Source: RightShip</div>
        </div>
        <div className="ps-2 PrevetRightShip_Col">
          <div className="PrevetRightShip_Col_Inner">
            <h3 className="PrevetRightShip_Heading">GHG Rating</h3>
            <div className="d-flex align-items-center">
              <GhgIcon
                rating={rightShipVesselInfo.ghg.rating}
                plus={getGhgPlus(rightShipVesselInfo)}
              />
              <div className="PrevetRightShip_Evdi">
                {getGhgVerified(rightShipVesselInfo) ? (
                  <div style={{ color: "#00763B", fontWeight: "bold" }}>
                    {getGhgVerified(rightShipVesselInfo) && "VERIFIED"}
                  </div>
                ) : (
                  <div style={{ fontWeight: "bold" }}>
                    {!getGhgVerified(rightShipVesselInfo) && "UNVERIFIED"}
                  </div>
                )}
                <div className="PrevetRightShip_Subscript PrevetRightShip_Subscript--bold">
                  EEDI/EVDI
                </div>
                <div className="fs-4">{getGhgEvdi(rightShipVesselInfo)}</div>
                <div className="PrevetRightShip_Subscript">
                  g CO₂ per nautical mile
                </div>
              </div>
            </div>
          </div>
          <div className="PrevetRightShip_Footnote">Source: RightShip</div>
        </div>
      </div>
    </Card>
  )
}
