import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import BigNumber from "bignumber.js"
import {
  average as findAverage,
  max as findMax,
  median as findMedian,
  min as findMin,
} from "simple-statistics"
import { CheckedMinMedianMax } from "../portPredictorReducer"
import {
  PortPredictorCargoGroupTypes,
  PortPredictorTurnAroundTimeData,
  VesselActivity,
  usePortPredictorAPI,
} from "./usePortPredictorAPI"
import { TurnAroundTimeMinMedianMax } from "./useFetchPortTurnAroundTime"
import { getTurnAroundTimeMinMedianMax } from "../helpers/utils"

export interface FetchTerminalTurnAroundTimeParams {
  segment: string
  cargo: string | undefined
  locode: string | undefined
  activity: VesselActivity | undefined
  terminalId: string | undefined
  cargoGroup: PortPredictorCargoGroupTypes | undefined
}

export function useFetchTerminalTurnAroundTime({
  segment,
  cargo,
  locode,
  activity,
  terminalId,
  cargoGroup,
}: FetchTerminalTurnAroundTimeParams) {
  const { getTerminalTurnAroundTime } = usePortPredictorAPI()

  const {
    isLoading: isTerminalTurnAroundTimeLoading,
    isError: isTerminalTurnAroundTimeError,
    isSuccess: isTerminalTurnAroundTimeSuccess,
    data: terminalTurnAroundTimeData,
  } = useQuery({
    queryKey: [
      "portPredictor/getTerminalTurnAroundTime",
      {
        segment,
        cargo,
        locode,
        activity,
        terminalId,
        cargoGroup,
      },
    ],
    queryFn: ({
      queryKey,
    }: QueryFunctionContext<(string | FetchTerminalTurnAroundTimeParams)[]>) => {
      const res = getTerminalTurnAroundTime(
        queryKey[1] as FetchTerminalTurnAroundTimeParams
      )
      return res
    }
    ,
    enabled: !!(segment && locode && cargo && activity && cargoGroup && terminalId), // Ensure all props are there
    staleTime: 600000, // 10 minutes,
    retry: (failureCount, error) => {
      if ((error as any).response?.status === 404) {
        return false; // Don't retry if the status is 404
      } else if (failureCount >= 3) {
        return false; // Stop retrying after 3 tries
      } else {
        return true; // Retry for other errors
      }
    },
  })

  const terminalTurnAroundTimeMinMedianMax =
    getTurnAroundTimeMinMedianMax(terminalTurnAroundTimeData)
  return {
    terminalTurnAroundTimeMinMedianMax,
    terminalTurnAroundTimeData,
    isTerminalTurnAroundTimeLoading,
    isTerminalTurnAroundTimeError,
    isTerminalTurnAroundTimeSuccess,
  }
}