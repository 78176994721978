import { useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"

export function useFetchPorts() {
  const { getPorts } = usePortPredictorAPI()

  const {
    isLoading: isPortsLoading,
    isError: isPortsError,
    isSuccess: isPortsSuccess,
    data: portsData,
  } = useQuery({
    queryKey: ["portPredictor/getPorts"],
    queryFn: ({ queryKey }) => getPorts(),
    staleTime: 600000, // 10 minutes
  })

  return {
    portsData,
    isPortsLoading,
    isPortsError,
    isPortsSuccess,
  }
}
