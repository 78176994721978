import moment from "moment"

import { PortStaysData } from "./getPortStaysData"
import { getPortStaysActivity } from "./getPortStaysActivity"
import { DDYardStayCategories, VesselStay } from "../hooks/useFetchVesselStays"
import { VesselDetails } from "../hooks/usePrevetAPI"

import {
  min as findMin,
} from "simple-statistics"
interface GetPortStaysChartOptionsArgs {
  vesselInfo: VesselDetails
  portStaysData: PortStaysData
}

type EChartParams = unknown & { value: VesselStay; axisValueLabel: string }

const okColor = "#00763b"
const dangerColor = "#ec4561"
const warningColor = "#ffdd15"

export function getPortStaysChartOptions({
  vesselInfo,
  portStaysData,
}: GetPortStaysChartOptionsArgs) {
  const dryDockDays = portStaysData.days.filter(
    (stay) =>
      stay.yard_activity &&
      ["DD-L", "DD-M", "DD-S", "DD-Late"].includes(stay.yard_activity)
  )
  const repairDays = portStaysData.days.filter(
    (stay) => stay.yard_activity && stay.yard_activity === "R"
  )
  const repairDryDockDays = portStaysData.days.filter(
    (stay) => stay.yard_activity && stay.yard_activity === "DD-R"
  )
  const nonDryDockOrRepairDays = portStaysData.days.filter(
    (stay) =>
      !stay.yard_activity || !DDYardStayCategories.includes(stay.yard_activity)
  )

  // TODO: Refactor getPortStaysData function so this is not necessary to re-merge the speedData
  const allSpeeds = [...portStaysData.laden, ...portStaysData.ballast]
  const minSpeed = allSpeeds.length > 0 ? findMin(allSpeeds.map((data) => data.avg_speed_mov)) : 0

  /**
  * Variables to help with custom formatting of x-axis lables
  * prevMonth keeps track of the month at the current index - 1
  */
  let prevMonth = ""

  const options = {
    title: {
      show: portStaysData.days.length < 1,
      left: "center",
      top: "40%",
      text: "Not enough data in last 6 months",
      textStyle: {
        fontSize: 14,
        fontColor: "#5b626b",
        fontWeight: "normal",
        fontFamily: "Source Sans Pro",
        overflow: "break"
      },
    },
    dataset: [
      {
        source: dryDockDays, // datasetIndex: 0
      },
      {
        source: repairDryDockDays,
      },
      {
        source: repairDays,
      },
      {
        source: nonDryDockOrRepairDays,
      },
      {
        source: portStaysData.laden,
      },
      {
        source: portStaysData.ballast,
      },
    ],
    series: [
      {
        name: "Days",
        type: "bar",
        datasetIndex: 0,
        barWidth: 7,
        encode: {
          x: "date_from",
          y: "duration",
        },
        markPoint: {
          label: {
            formatter: (params: EChartParams) => {
              return "DD"
            },
            fontSize: 10,
          },
          data: [
            // We assume 99.99% of vessels will not have more than 2 DD port stays in last 6 months
            // If there are, the roundedRect will not label those in between
            { type: "max", name: "Max" },
            { type: "min", name: "Min" },
          ],
          symbol: "roundRect",
          symbolOffset: [0, -12],
          symbolSize: [20, 20],
          itemStyle: { color: "#fff" },
        },
        itemStyle: {
          color: (params: EChartParams) => {
            return okColor
          },
        },
      },
      {
        name: "Days",
        type: "bar",
        datasetIndex: 1,
        barWidth: 7,
        encode: {
          x: "date_from",
          y: "duration",
        },
        markPoint: {
          label: {
            formatter: (params: EChartParams) => {
              return "DD/Repair/\nIWS"
            },
            fontSize: 10,
          },
          data: [
            // We assume 99.99% of vessels will not have more than 2 DD-R port stays in last 6 months
            // If there are, the roundedRect will not label those in between
            { type: "max", name: "Max" },
            { type: "min", name: "Min" },
          ],
          symbol: "roundRect",
          symbolOffset: [0, -18],
          symbolSize: [30, 30],
          itemStyle: { color: "#fff" },
        },
        itemStyle: {
          color: (params: EChartParams) => {
            return okColor
          },
        },
      },
      {
        name: "Days",
        type: "bar",
        datasetIndex: 2,
        barWidth: 7,
        encode: {
          x: "date_from",
          y: "duration",
        },
        itemStyle: {
          color: (params: EChartParams) => {
            const data = params.value
            if (data.duration >= 20) {
              return dangerColor
            }
            if (data.duration >= 10 && data.duration <= 19) {
              return warningColor
            }
            if (data.duration <= 9) {
              return okColor
            }
          },
        },
        markPoint: {
          itemStyle: { color: "#fff" },
          symbol: "roundRect",
          symbolOffset: [0, -27],
          symbolSize: [40, 40],
          label: {
            formatter: (params: EChartParams) => {
              return "Yard/Repair/\nIWS"
            },
            fontSize: 10,
          },

          data: [
            // If there are more than 2 repair port stays, we will not see the roundedRect markPoint
            // hovering over the repair days that are not the min or max duration. We assume
            // 99.99% of vessels will have no more than 2 repair port stays in the last 6 months.
            { type: "max", name: "Max" },
            { type: "min", name: "Min" },
          ],
        },
      },
      {
        name: "Days",
        type: "bar",
        datasetIndex: 3,
        barWidth: 7,
        encode: {
          x: "date_from",
          y: "duration",
        },
        itemStyle: {
          color: (params: EChartParams) => {
            const data = params.value
            if (data.duration >= 20) {
              return dangerColor
            }
            if (data.duration >= 10 && data.duration <= 19) {
              return warningColor
            }
            if (data.duration <= 9) {
              return okColor
            }
          },
        },
      },
      {
        name: "Laden Avg.",
        type: "line",
        yAxisIndex: 1,
        datasetIndex: 4,
        encode: {
          x: "date",
          y: "avg_speed_mov",
          tooltip: ["avg_speed_mov"],
        },
        symbolSize: 8,
        connectNulls: true,
      },
      {
        name: "Ballast Avg.",
        type: "line",
        yAxisIndex: 1,
        datasetIndex: 5,
        encode: {
          x: "date",
          y: "avg_speed_mov",
          tooltip: ["avg_speed_mov"],
        },
        symbolSize: 8,
        connectNulls: true,
      },
    ],
    grid: {
      zlevel: 0,
      x: 50,
      y: 100,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
      height: 260,
      bottom: 0,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#849CDB",
        },
      },
      formatter: (params: EChartParams[]) => {
        const activity = getPortStaysActivity(params[0].value)

        let tooltip =
          params[0].axisValueLabel +
          "<br/>" +
          "Port: " +
          params[0].value.port +
          " (" +
          activity +
          ")<br/>"

        params.forEach(({ seriesName, marker, value }: any) => {
          tooltip += marker + " " + seriesName + "   "

          if (seriesName === "Days") {
            tooltip += `${value.duration}`
            if (value.yard_duration > 0) {
              tooltip += ` (Yard Dur. ${value.yard_duration} d)`
            } else {
              if (value.duration < 10) {
                tooltip += ` (Short Port Stay)`
              }
              if (value.duration >= 10) {
                tooltip += ` (Prolonged Port Stay)`
              }
            }
            tooltip += `<br/>`
          }

          if (seriesName === "Ballast Avg." || seriesName === "Laden Avg.") {
            tooltip += value.avg_speed_mov + " (Kt)<br/>"
          }
        })
        return tooltip
      },
    },
    toolbox: {
      top: "0%",
      feature: {
        saveAsImage: {
          name:
            (vesselInfo.vessel_name as string).toLowerCase().replace(" ", "-") +
            "-stays",
        },
      },
    },
    color: ["#FF5B29", "#001446"],
    legend: {
      data: ["Laden Avg.", "Ballast Avg."],
      show: true,
    },
    xAxis: [
      {
        type: "time",
        name: "Past 6 Months",
        nameLocation: "middle",
        nameGap: 32,
        nameTextStyle: {
          fontSize: 12,
        },
        axisLabel: {
          formatter: (params: any, index: number) => {
            const currMonth = moment(params).format("MMM")
            if (index === portStaysData.days.length - 1) {
              // Check if we are on the last iteration of the dataset
              // If so, we always want to return the current month to end the x-axis
              // However the chart will not always choose to render this depending on the space available
              // As such, this is more of a nice to have.
              return currMonth
            }
            if (currMonth === prevMonth) {
              return ""
            }
            prevMonth = currMonth
            return currMonth
          },
          margin: 6,
          hideOverlap: true,
        },
        axisPointer: {
          type: "shadow",
          label: {
            formatter: function (params: any) {
              return moment(new Date(params.value)).format("YYYY-MM-DD")
            },
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        nameLocation: "middle",
        min: 0,
        axisLabel: {
          formatter: "{value} D",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
        splitLine: {
          show: false,
        },
      },
      {
        type: "value",
        min: (minSpeed - 1) > 0 ? Math.floor(minSpeed - 1) : 0,
        nameRotate: 270,
        minInterval: 1,
        nameLocation: "middle",
        nameTextStyle: {
          fontWeight: "normal",
          fontSize: 12,
        },
        axisLabel: {
          formatter: "{value} Kt",
          margin: 4,
          showMinLabel: true,
        },
        splitLine: {
          show: false,
        },
      },
    ],
  }

  return options
}
