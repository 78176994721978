import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BASE_URL } from "api/url_helper"
import {
  ContentType,
  PoolPortalDocument,
  PoolPortalDocuments,
  PoolPortalVessel,
  SelectOption,
} from "modules/PoolPortal/helpers/types"
import { useDocuments } from "modules/PoolPortal/hooks/useDocumentsAPI"
import { useUserDocuments } from "modules/PoolPortal/hooks/useUserDocumentsAPI"
import { GenerateYearArray } from "modules/PoolPortal/utils"
import { PdfModal } from "modules/Shared/components/PdfModal"
import { useGetPdfBase64 } from "modules/Shared/hooks/useGetPdfBase64"
import { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Row, Spinner } from "react-bootstrap"
import Select from "react-select"

export function Agreements() {
  const { poolUserVessels } = useDocuments()
  const { getPdfBase64 } = useGetPdfBase64()
  const years = GenerateYearArray()

  const [vesselfilter, setVesselfilter] = useState([] as SelectOption[])

  const { userDocuments, refetchUserDocuments, isLoadingUserDocuments } =
    useUserDocuments(
      ContentType.AGREEMENTS,
      vesselfilter ? vesselfilter.map((item) => item.value).join(",") : "",
      ""
    )

  const [currentShowMoreVessel, setCurrentShowMoreVessel] = useState("-1,-1")

  const [groupedVesselUserDataDocuments, setGroupedVesselUserDataDocuments] =
    useState({} as { [key: string]: PoolPortalDocuments })

  const [currentDocument, setCurrentDocument] = useState(
    {} as PoolPortalDocument
  )

  const [showDocument, setShowDocument] = useState(false)

  const [sortedKeys, setSortedKeys] = useState([] as string[])

  useEffect(() => {
    if (userDocuments) {
      const groupedByVesselsName: { [key: string]: PoolPortalDocuments } =
        userDocuments.reduce(
          (
            accumulator: { [key: string]: PoolPortalDocuments },
            current: PoolPortalDocument
          ) => {
            const key = current.vesselName || ""
            accumulator[key] = accumulator[key] || []
            if (accumulator[key] && current) {
              accumulator[key]!.push(current)
            }

            return accumulator
          },
          {} as { [key: string]: PoolPortalDocuments }
        )
      setGroupedVesselUserDataDocuments(groupedByVesselsName)
      setSortedKeys(
        groupedByVesselsName
          ? Object.keys(groupedByVesselsName).sort((a, b) => a.localeCompare(b))
          : ([] as string[])
      )
    }
  }, [userDocuments])

  const [curMainIndex, curDocIndex] = currentShowMoreVessel
    .split(",")
    .map(Number)

  return (
    <>
      <PdfModal
        idToCheck={currentDocument.id}
        nameFieldName="name"
        isModalShown={showDocument}
        handleClose={() => {
          setShowDocument(false)
        }}
        requestParameters={{
          url: `${BASE_URL}/poolportal/document`,
          params: {
            id: currentDocument.id,
            includefile: true,
          },
        }}
      />
      <div className="PoolPortal_UserContainer">
        <div className="PoolPortal_UserFilter">
          <div className="PoolPortal_UserFilterRow">
            <div className="PoolPortal_UserFilterLabel">
              <h5>Vessel</h5>
            </div>
            <div style={{ width: "100%" }}>
              <Select
                aria-labelledby="aria-label"
                isMulti
                inputId="aria-example-input"
                name="aria-live-color"
                options={
                  poolUserVessels
                    ? poolUserVessels.map((vessel: PoolPortalVessel) => ({
                      label: vessel.name,
                      value: vessel.id,
                    }))
                    : []
                }
                onChange={(selectedOptions: any) => {
                  setVesselfilter(selectedOptions)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      {isLoadingUserDocuments ? (
        <div className="PoolPortal_LoadingContainer">
          <Spinner as="span" animation="border" size="sm" variant="primary" />
          <span >
            Loading...
          </span>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {groupedVesselUserDataDocuments &&
            sortedKeys &&
            sortedKeys.map((key: string, mainIndex: number) => {
              return (
                <div
                  key={key}
                  className="PoolPortal_SortedGroupContainer" >
                  <h5>{key}</h5>
                  <div style={{ paddingLeft: "30px" }}>
                    {groupedVesselUserDataDocuments &&
                      groupedVesselUserDataDocuments[key] &&
                      (curMainIndex !== mainIndex
                        ? groupedVesselUserDataDocuments[key]!.slice(0, 1)
                        : groupedVesselUserDataDocuments[key]!
                      ).map((doc: PoolPortalDocument, docIndex: number) => (
                        <Row key={doc.id}>
                          <Col className="PoolPortal_DocGroupCol" >
                            <div className="PoolPortal_DocGroupLabel" >
                              <ButtonGroup>
                                <Button
                                  size="sm"
                                  className="PoolPortal_ActionButtons"
                                  onClick={() => {
                                    setCurrentDocument({ ...doc })
                                    setShowDocument(true)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="fa-thin"
                                  />
                                </Button>{" "}
                                <Button
                                  size="sm"
                                  className="PoolPortal_ActionButtons"
                                  onClick={() => {
                                    getPdfBase64({
                                      id: doc.id,
                                      reportName: doc.name,
                                      requestParameters: {
                                        url: `${BASE_URL}/poolportal/document`,
                                        params: {
                                          id: doc.id,
                                          includefile: true,
                                        },
                                      },
                                    })
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className="fa-thin"
                                  />
                                </Button>{" "}
                              </ButtonGroup>
                              <h5>{doc.name}</h5>
                            </div>
                            <div>
                              {docIndex === 0 &&
                                (groupedVesselUserDataDocuments[key] || [])
                                  .length > 1 && (
                                  <Button
                                    style={{ width: "80px" }}
                                    variant={
                                      curMainIndex !== mainIndex ||
                                        curDocIndex !== docIndex
                                        ? "light"
                                        : "warning"
                                    }
                                    size="sm"
                                    className="PoolPortal_ActionButtons"
                                    onClick={() => {
                                      if (
                                        curMainIndex === mainIndex &&
                                        curDocIndex === docIndex
                                      ) {
                                        setCurrentShowMoreVessel(`${-1},${-1}`)
                                      } else {
                                        setCurrentShowMoreVessel(
                                          `${mainIndex},${docIndex}`
                                        )
                                      }
                                    }}
                                  >
                                    {curMainIndex !== mainIndex ||
                                      curDocIndex !== docIndex
                                      ? " More"
                                      : " Less"}{" "}
                                    &nbsp;
                                    <FontAwesomeIcon
                                      icon={
                                        curMainIndex !== mainIndex ||
                                          curDocIndex !== docIndex
                                          ? faAngleDoubleDown
                                          : faAngleDoubleUp
                                      }
                                      className="fa-thin"
                                    />
                                  </Button>
                                )}
                            </div>
                          </Col>
                        </Row>
                      ))}
                  </div>
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}
