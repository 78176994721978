import { isNull } from './format';

/**
 * Returns true if the array is not empty.
 *
 * @param array
 */
export const isValidArray = (array) => {
    return array && Array.isArray(array) && array.length > 0;
};

/*
 * Returns last item with not null keys from an array of objects.
 * @param array - array of objects
 * @param keys - array of keys to check
 */
export const getLastValid = (array, keys) => {
    for (let i = array.length - 1; i >= 0; i--) {
        if (Array.isArray(keys)) {
            if (keys.every((key) => !isNull(array[i][key]))) {
                return array[i];
            }
        } else {
            if (!isNull(array[i][keys])) {
                return array[i];
            }
        }
    }

    return null;
};
