import React from "react"
import { Col } from "react-bootstrap"

//Import Images
import tkLogoImg from "../../../assets/images/tk-logo-white.png"

const Footer = () => {
  return (
    <React.Fragment>
      <div className="row justify-content-center mt-2 mb-5">
        <div className="col-xl-3 col-sm-3 col-md-6 col-lg-3 mx-auto">
          <div className="row align-items-center">
            <img
              src={tkLogoImg}
              alt="torvald klaveness logo"
              className="img-fluid mx-auto d-block"
            />
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="footer-landing">
          <Col md={12}>
            <p className="text-center mx-auto d-block">
              © {new Date().getFullYear()} Torvald Klaveness - All rights
              reserved | Careers | Privacy Policy
            </p>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
