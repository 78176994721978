import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import BigNumber from "bignumber.js"
import {
  average as findAverage,
  max as findMax,
  median as findMedian,
  min as findMin,
} from "simple-statistics"
import { CheckedMinMedianMax } from "../portPredictorReducer"
import { PortPredictorCargoGroupTypes, PortPredictorTurnAroundTimeData, VesselActivity, usePortPredictorAPI } from "./usePortPredictorAPI"
import { getTurnAroundTimeMinMedianMax } from "../helpers/utils"
export interface FetchPortTurnAroundTimeParams {
  segment: string
  cargo: string | undefined
  locode: string | undefined
  activity: VesselActivity | undefined
  cargoGroup: PortPredictorCargoGroupTypes | undefined
}

export interface TurnAroundTimeMinMedianMax {
  [CheckedMinMedianMax.Min]: { label: CheckedMinMedianMax.Min, value: number },
  [CheckedMinMedianMax.Median]: { label: CheckedMinMedianMax.Median, value: number },
  [CheckedMinMedianMax.Average]: { label: CheckedMinMedianMax.Average, value: number },
  [CheckedMinMedianMax.Max]: { label: CheckedMinMedianMax.Max, value: number },
}


export function useFetchPortTurnAroundTime({
  segment,
  cargo,
  locode,
  activity,
  cargoGroup
}: FetchPortTurnAroundTimeParams) {

  const { getPortTurnAroundTime } = usePortPredictorAPI()

  const {
    isLoading: isPortTurnAroundTimeLoading,
    isError: isPortTurnAroundTimeError,
    isSuccess: isPortTurnAroundTimeSuccess,
    data: portTurnAroundTimeData,
  } = useQuery({
    queryKey: [
      "portPredictor/getPortTurnAroundTime",
      {
        segment,
        cargo,
        locode,
        activity,
        cargoGroup
      },
    ],
    queryFn: ({
      queryKey,
    }: QueryFunctionContext<(string | FetchPortTurnAroundTimeParams)[]>) => {
      return getPortTurnAroundTime(queryKey[1] as FetchPortTurnAroundTimeParams)
    },
    enabled: !!(segment && locode && cargo && activity && cargoGroup), // Ensure all props are there
    staleTime: 600000, // 10 minutes
  })

  const portTurnAroundTimeMinMedianMax = getTurnAroundTimeMinMedianMax(portTurnAroundTimeData)
  return {
    portTurnAroundTimeMinMedianMax,
    portTurnAroundTimeData,
    isPortTurnAroundTimeLoading,
    isPortTurnAroundTimeError,
    isPortTurnAroundTimeSuccess,
  }
}

