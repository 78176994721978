import moment from "moment"
import { VesselStay } from "../hooks/useFetchVesselStays"

const DDInterval = 5 // Vessels expected to DD every 5 years

export function getVesselAge({ built, date }: { built: number; date: string }) {
  const currYear = moment(date, "YYYY")
  const builtDate = moment(built, "YYYY")
  return Math.round(moment.duration(currYear.diff(builtDate)).asYears())
}

export function addYardActivity({
  initialStays,
  built,
}: {
  initialStays: VesselStay[]
  built: number
}) {
  let stays = [...initialStays]
  let start = 0

  for (let end = 0; end < stays.length; end++) {
    const currDate = stays[end].date_from
    const vesselAge = getVesselAge({ built, date: currDate })

    if (vesselAge % DDInterval === 0) {
      // DD year
      if (stays[end].yard_duration === 0) {
        if (
          // stays[start].yard_activity !== "DD-S" &&
          // If stays[start] is candidate for a DD-Late, don't increment
          stays[start].yard_activity !== "R"
        ) {
          start += 1
        }
      } else if (stays[end].yard_duration > 25) {
        stays[end].yard_activity = "DD-L"
        start = end + 1
      } else if (stays[end].yard_duration > 14) {
        stays[end].yard_activity = "DD-M"
        start = end + 1
      } else if (stays[end].yard_duration > 0) {
        stays[end].yard_activity = "DD-S"
        start = end + 1
      }
    }
    if (vesselAge % DDInterval === 1) {
      // Year after a DD year
      if (stays[end].yard_duration === 0) {
        if (start === end) {
          start += 1
        }
      } else if (
        stays[end].yard_duration > 0 &&
        stays[start].yard_activity === "R"
      ) {
        // There is candidate for DD-Late before this stay
        stays[end].yard_activity = "R"
        start += 1
      } else if (stays[end].yard_duration > 0) {
        stays[end].yard_activity = "R"
      }
    }
    if (vesselAge % DDInterval === 2 || vesselAge % 5 === 3) {
      if (stays[end].yard_duration > 0) {
        stays[end].yard_activity = "R"
      }

      // By the time we get to the year before the year before a DD year
      // We no longer care about checking for late (DD-L)
      start = end + 1
    }
    if (vesselAge % DDInterval === 4) {
      // Year before a DD year
      if (stays[end].yard_duration > 0) {
        stays[end].yard_activity = "R"
        start = end + 1
      } else if (stays[start].yard_activity === "R") {
        // 1B: Vessel with DD in year after its DD year, and did not do a DD in DD year
        stays[start].yard_activity = "DD-Late"
        start = end + 1
      } else if (
        // stays[start].yard_activity !== "DD-S" &&
        stays[start].yard_activity !== "R"
      ) {
        start += 1
      }
    }
  }

  return stays.map((stay) => {
    if (stay.yard_activity === "R" && stay.yard_duration > 25) {
      stay.yard_activity = "DD-R"
    }
    return stay
  })
}
