import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { PoolPortalDocument } from '../helpers/types'
import DocumentService from './documentsService'


export const useDocuments = () => {
  const queryClient = useQueryClient()
  const documentService = DocumentService()
  const [userdocumentParams, setUserdocumentParams] = useState("")

  // Fetch documents
  const { data: documents, isLoading } = useQuery(['documents'], documentService.getDocuments)
  const { data: poolUsers, isLoading: isLoadingPoolUsers } = useQuery(['poolUsers'], documentService.getPoolUsers)
  const { data: poolUserVessels, isLoading: isLoadingPoolUserVessels } = useQuery(['poolUserVessels'], documentService.getPoolUserVessels)

  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newDocument: PoolPortalDocument }) =>
      documentService.createDocument(variables.newDocument),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documents'])
      },
      onError: (error: Error) => {
        console.error("Error during createDocument:", error.message)
      },
    })

  const handleCreate = async (newDocument: PoolPortalDocument): Promise<PoolPortalDocument> => {
    try {
      const createdDocument = await createMutation.mutateAsync({
        newDocument,
      })
      return createdDocument as PoolPortalDocument // Explicitly cast to PoolPortalDocument
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create document - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { updatedDocument: PoolPortalDocument }) => documentService.updateDocument(variables.updatedDocument), {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents'])
    },
  })

  const handleUpdate = async (updatedDocument: PoolPortalDocument): Promise<PoolPortalDocument> => {
    try {
      const result = await updateMutation.mutateAsync({ updatedDocument })
      return result as PoolPortalDocument
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to update document - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(documentService.deleteDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete document - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    documents,
    isLoading,
    poolUsers,
    isLoadingPoolUsers,
    poolUserVessels,
    isLoadingPoolUserVessels,
    createDocument: handleCreate,
    updateDocument: handleUpdate,
    deleteDocument: handleDelete
  }
}
