import { useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"

export function useFetchCargoesFromPort({ segment, locode, activity }: { segment: string, locode: string | undefined, activity: string | undefined }) {
  const { getCargoesFromPort } = usePortPredictorAPI()


  const {
    isLoading: isCargoesLoading,
    isError: isCargoesError,
    isSuccess: isCargoesSuccess,
    data: cargoesData,
  } = useQuery({
    queryKey: ["portPredictor/getCargoesFromPort", segment, locode, activity],
    queryFn: ({ queryKey }) => getCargoesFromPort({ segment, locode, activity }),
    staleTime: 0,
  })

  return {
    cargoesData,
    isCargoesLoading,
    isCargoesError,
    isCargoesSuccess,
  }
}
