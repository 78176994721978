import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"
import { MarkerPoint } from "../components/PortPredictorMain"

interface FetchDistanceParams { source: string | undefined, target: string | undefined }

export function useFetchFuturePath(points: MarkerPoint[]) {
    const { getFuturePath } = usePortPredictorAPI()

    const {
        isLoading: isGetFuturePathLoading,
        isError: isGetFuturePathError,
        isSuccess: isGetFuturePathSuccess,
        data: futurePathData,
    } = useQuery({
        queryKey: [
            "portPredictor/getDistance",
            JSON.stringify(points)
        ],
        queryFn: ({ queryKey }: QueryFunctionContext<(string | FetchDistanceParams)[]>) => {
            const result = getFuturePath(points)
            return result
        },
        select: (routes) => {
            let futurePath = [] as MarkerPoint[]
            for (let route of routes) {
                const points = getLatLngPointsFromPath(route?.path)
                if (points) {
                    futurePath = [...futurePath, ...points]
                }
            }
            return futurePath
        },
        enabled: points.length > 0, // Ensure all props are there
        staleTime: 600000, // 10 minutes
    })

    return {
        futurePathData,
        isGetFuturePathLoading,
        isGetFuturePathError,
        isGetFuturePathSuccess,
    }
}

export function getLatLngPointsFromPath(path: string | undefined) {
    if (!path) {
        return
    }
    const values = path.split("(")[1].split(")")[0]
    return values.split(",").map((pair) => {
        const latLng = pair.trim().split(" ")
        return {
            lat: Number(latLng[1]),
            lon: Number(latLng[0])
        }
    })
}