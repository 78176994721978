import { useAuth0 } from "@auth0/auth0-react"
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios"
import { toast } from "react-toastify"
import { ToasterLogout } from "./ToasterLogout"

export const baseURL = (process.env.REACT_APP_SERVERAPIURL as string)?.trim()

export type AxiosRequestConfigWithData<Data> = Omit<
  AxiosRequestConfig,
  "data"
> & {
  data?: Data
}

function createAxiosInstance() {
  const opts = {
    baseURL: baseURL,
    timeout: 1000 * 1000,
    // withCredentials: true,
  }

  return axios.create({
    ...opts,
  })
}

const instance = createAxiosInstance()

export function axiosRequest<Request, Response>(
  options: AxiosRequestConfigWithData<Request>
): AxiosPromise<Response> {
  return instance({
    ...options,
  }) as AxiosPromise<Response>
}

export function useAxiosRequest() {
  const { getAccessTokenSilently, user } = useAuth0()
  return async function <Request, Response>(
    options: AxiosRequestConfigWithData<Request>
  ) {
    try {
      if (!navigator.onLine) {
        return
      }
      const token = await getAccessTokenSilently()

      const data = await instance({
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Request-ID": user?.email || user?.name || "",
          "Content-Type": "application/pdf",
        },
        withCredentials: true,
        ...options,
      })

      return data as AxiosResponse<Response>
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          toast.warn(<ToasterLogout info={`logout due to ${options.url}`} />, {
            toastId: "logout123",
            position: "top-right",
            autoClose: false,
          })
        }
      }
      throw err
    }
  }
}
