import React, { useState } from "react"

// Import menuDropdown
import BurgerToggle from "../Topbar/BurgerToggle"
import FullScreen from "../Topbar/FullScreen"
import { OrganizationMenu } from "../Topbar/OrganizationMenu"
import ProfileMenu from "../Topbar/ProfileMenu"

//Import Assets
import logolightImg from "../../../assets/images/logo-light.png"
import logosmImg from "../../../assets/images/logo-sm.png"

const Header = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="navbar-header-inner">
            <div
              className={`navbar-brand-box ${
                isSidebarCollapsed ? "navbar-brand-box--isCollapsed" : ""
              }`}
            >
              {isSidebarCollapsed ? (
                // <Link to="/" className="logo logo-light">
                <div className="logo logo-light">
                  <img src={logosmImg} alt="" height="22" />
                </div>
              ) : (
                // <Link to="/" className="logo logo-light">
                <div className="logo logo-light">
                  <img src={logolightImg} alt="" height="36" />
                </div>
              )}
            </div>
            <BurgerToggle
              setIsSidebarCollapsed={() => {
                setIsSidebarCollapsed(!isSidebarCollapsed)
              }}
            />
          </div>

          <div className="d-flex">
            <OrganizationMenu />
            <FullScreen />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
