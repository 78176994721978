import AdminMaintenanceWrapper from "./components/AdminMaintenanceWrapper"
const settings = {
    routes : [
        {
            path: "/admin/maintenance-notification",
            permissionForPage: "read:maintenance-admin",
            component: AdminMaintenanceWrapper,
        },     
    ]
}

export default settings