import { BASE_URL } from 'api/url_helper';
import { useAxiosRequest } from "api/axiosRequest"
import { PoolPortalVessels, PoolPortalVessel } from '../helpers/types'
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const VesselService = () => {
  const request = useAxiosRequest()
  const getVessels = async () => {
    try {
      const vesselsUrl = `${POOLPORTAL_API_BASE_URL}/vessels`
      const response = await request<any, PoolPortalVessels>(
        {
          url: vesselsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const createVessel = async (newVessel: PoolPortalVessel) => {
    try {
      const response = await request<any, PoolPortalVessel>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/vessel`,
          method: "POST",
          data: newVessel
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateVessel = async (id: string, updatedVessel: PoolPortalVessel) => {
    try {
      const response = await request<any, PoolPortalVessel>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/vessel`,
          method: "PUT",
          data: updatedVessel
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteVessel = async (id: string) => {
    try {
      const response = await request<any, PoolPortalVessel>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/vessel?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getVessels,
    createVessel,
    updateVessel,
    deleteVessel
  }
}

export default VesselService
