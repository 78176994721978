import Breadcrumbs from "components/Common/Breadcrumbs"
import React from "react"
import { Button, Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { PrevetMain } from "./PrevetMain"

export function PrevetWrapper() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Pre-Vetting | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div className="main-content_inner">
            <div className="PrevetWrapper_BreadcrumbBar">
              <Breadcrumbs title="Pre-Vetting" breadcrumbItem="Pre-Vetting" />
              <Button
                onClick={() => {
                  window.print()
                }}
              >
                <i className="me-2 ti-download" />
                Save | Print
              </Button>
            </div>
            <PrevetMain />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
