import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, FormikProvider, useFormik } from "formik"
import {
  MonthObject,
  PoolPortalContenttype,
  PoolPortalDocument,
  PoolPortalOwner,
  PoolPortalPartofmonth,
  PoolPortalPool,
  PoolPortalVessel,
  SelectOption,
  YearObject,
} from "modules/PoolPortal/helpers/types"
import { useContenttypes } from "modules/PoolPortal/hooks/useContenttypesAPI"
import { useDocuments } from "modules/PoolPortal/hooks/useDocumentsAPI"
import { useOwners } from "modules/PoolPortal/hooks/useOwnersAPI"
import { usePartofmonths } from "modules/PoolPortal/hooks/usePartofmonthsAPI"
import { usePools } from "modules/PoolPortal/hooks/usePoolsAPI"
import { useVessels } from "modules/PoolPortal/hooks/useVesselsAPI"
import {
  GenerateMonthArray,
  GenerateYearArray,
  GetMonthLabel,
} from "modules/PoolPortal/utils"
import { ChangeEvent, useEffect, useState } from "react"
import { Form as BootstrapForm, Button, Modal, Spinner } from "react-bootstrap"
import Select from "react-select"
import * as Yup from "yup"
import { FileDropZone } from "./FileDropZone"

interface MainAdminEntryPageProps {
  data: PoolPortalDocument
  showUploadDocs: boolean
  setShowUploadDocs: (show: boolean) => void
  setShowSettings: (show: boolean) => void
}


export const MainAdminEntryPage = ({
  data,
  showUploadDocs,
  setShowUploadDocs,
  setShowSettings,
}: MainAdminEntryPageProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [documentData, setDocumentData] = useState(data as PoolPortalDocument)

  const { contenttypes } = useContenttypes()
  const { owners } = useOwners()
  const { partofmonths } = usePartofmonths()
  const { pools } = usePools()
  const { vessels } = useVessels()
  const { createDocument, updateDocument } = useDocuments()

  const years = GenerateYearArray()
  const months = GenerateMonthArray()

  const handleCancel = () => {
    setShowUploadDocs(false)
  }

  const handleSubmit = async (values: PoolPortalDocument) => {
    setShowConfirmation(true)
    setDocumentData({ ...values })
  }

  // Function to simulate owner-vessel validation
  const validateVesselOwnership = (ownerId: string, vesselId: string): boolean => {
    const selectedVesselOwnerId = vessels?.find(vessel => vessel.id === formik.values.vesselId)?.ownerId

    if (!ownerId || !vesselId) return true; // No need to validate if either field is empty

    return selectedVesselOwnerId === formik.values.ownerId
  };

  const validationSchema: any = Yup.object().shape({
    contenttypeId: Yup.string().required("Content type is required"),
    contenttypeCode: Yup.string(),
    name: Yup.string().required("Name is required"),
    poolId: Yup.string().required("Pool is required."),
    ownerId: Yup.string()
      .nullable()
      .when("contenttypeCode", {
        is: (contenttypeCode: string) =>
          Yup.string()
            .nullable()
            .oneOf(["HIRESTATEMENTS", "AGREEMENTS"])
            .isValidSync(contenttypeCode),
        then: Yup.string()
          .nullable()
          .required(
            'Owner is required when "Content type" is HIRESTATEMENTS or AGREEMENTS.'
          ),
      })
      // .required('Owner ID is required')
      .test('ownerId-vesselId-match', 'Vessel does not belong to this Owner', function (value) {
        
        const { contenttypeCode, vesselId } = this.parent
        if (contenttypeCode === "NEWSLETTERS") {
          return true
        }

        return validateVesselOwnership(value!, vesselId)
      }),
    vesselId: Yup.string()
      .nullable()
      .when("contenttypeCode", {
        is: (contenttypeCode: string) =>
          Yup.string()
            .nullable()
            .oneOf(["HIRESTATEMENTS", "AGREEMENTS"])
            .isValidSync(contenttypeCode),
        then: Yup.string()
          .nullable()
          .required(
            'Vessel is required when "Content type" is HIRESTATEMENTS or AGREEMENTS.'
          ),
      }),
    hasPoolOwnerVesselIds: Yup.boolean(),
    year: Yup.string().nullable().when("contenttypeCode", {
      is: (contenttypeCode: string) =>
        Yup.string().nullable()
          .oneOf(["HIRESTATEMENTS", "NEWSLETTERS"])
          .isValidSync(contenttypeCode),
      then: Yup.string().nullable().required(
        'Year is required when "Content type" is HIRESTATEMENTS or NEWSLETTERS.'
      ),
    }),
    monthLabel: Yup.string().nullable(),
  })

  const formik = useFormik({
    initialValues: { ...documentData },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    formik.resetForm()
    setDocumentData({ ...data })
    const setFormikValues = async (item: PoolPortalDocument) => {
      if (Object.keys(item).length > 0) {
        await formik.setValues({ ...item })
      }
    }
    setFormikValues(data)
  }, [data, showUploadDocs])

  const handleSaving = async () => {
    const isEditMode = !(Object.keys(data).length === 0 || data.id === "")
    if (!isEditMode) {
      await createDocument(documentData)
    } else {
      await updateDocument(documentData)
    }
    setShowConfirmation(false)
    setShowUploadDocs(false)
  }

  return (
    <FormikProvider value={formik}>
      <>
        <Modal
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
          key="UplodDocsConfirmationModal"
          size="lg"
          style={{ zIndex: 4060 }}
          // backdrop="static"
          centered
          fullscreen="xl-down"
          backdropClassName="MainAdminConfirmationBackDrop"
        >
          <Modal.Header closeButton>
            <Modal.Title>Saving confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 style={{ color: "rgb(255 0 0 / 80%)" }}>
              Please review to ensure that the information provided is accurate.
            </h5>
            <div style={{ padding: "15px" }}>
              Content type:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.contenttypeName}
              </span>
              <br />
              Name:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.name}
              </span>
              <br />
              Pool:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.poolName}
              </span>
              <br />
              Owner:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.ownerName}
              </span>
              <br />
              Vessel:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.vesselName}
              </span>
              <br />
              Year:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.year}
              </span>
              <br />
              Month:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.monthLabel}{" "}
              </span>
              <br />
              Part of month:{" "}
              <span
                style={{
                  color: "rgb(255 0 0 / 75%)",
                  fontWeight: "600",
                  paddingLeft: "5px",
                }}
              >
                {documentData.partofmonthName}{" "}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span style={{ fontSize: "14px" }}>
              If everything appears to be correct, please click 'Save'
              Otherwise, click 'Review'.
            </span>
            <Button
              variant="primary"
              onClick={() => {
                handleSaving()
              }}
            >
              Save
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowConfirmation(false)
              }}
            >
              Review
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showUploadDocs}
          onHide={() => {
            setShowUploadDocs(false)
          }}
          key="uploadmodaladmin"
          size="lg"
          style={{ zIndex: 2060 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <div className="PoolPortal_AdminEntryBody d-flex flex-column">
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="contenttypeId" as="h6">
                    Content type:
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      contenttypes
                        ? contenttypes.map(
                          (contenttype: PoolPortalContenttype) => ({
                            label: contenttype.name,
                            value: contenttype.id,
                          })
                        )
                        : []
                    }
                    id="contenttypeId"
                    name="contenttypeId"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    key="contenttypeId"
                    defaultValue={
                      documentData
                        ? {
                          label: documentData.contenttypeName,
                          value: documentData.contenttypeId,
                        }
                        : contenttypes
                          ? {
                            label: contenttypes[0].name,
                            value: contenttypes[0].id,
                          }
                          : ""
                    }
                    value={
                      formik.touched.contenttypeId
                        ? formik.values.contenttypeId
                          ? {
                            label: formik.values.contenttypeName,
                            value: formik.values.contenttypeId,
                          }
                          : undefined
                        : {
                          label: documentData.contenttypeName,
                          value: documentData.contenttypeId,
                        }
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "contenttypeId",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldValue(
                        "contenttypeName",
                        selectedOption ? selectedOption.label : ""
                      )
                      const contenttype = contenttypes?.find(
                        (item) =>
                          item.id ===
                          (selectedOption ? selectedOption.value : "")
                      )
                      await formik.setFieldValue(
                        "contenttypeCode",
                        contenttype?.code
                      )
                      await formik.setFieldTouched("contenttypeId", true)

                      await formik.setFieldValue(
                        "poolId",
                        selectedOption && pools ? pools[0].id : ""
                      )
                      await formik.setFieldValue(
                        "poolName",
                        selectedOption && pools ? pools[0].name : ""
                      )
                      await formik.setFieldTouched("poolId", true)
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                  />
                  <Button variant="light" onClick={() => setShowSettings(true)}>
                    <FontAwesomeIcon icon={faAdd} className="fa-thin" />
                  </Button>

                  {formik.touched.contenttypeId &&
                    formik.errors.contenttypeId && (
                      <div className="PoolPortal_ErrorMessage">
                        {formik.errors.contenttypeId}
                      </div>
                    )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="poolId" as="h6">
                    Pool:
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      pools
                        ? pools.map((pool: PoolPortalPool) => ({
                          label: pool.name,
                          value: pool.id,
                        }))
                        : []
                    }
                    id="poolId"
                    name="poolId"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    defaultValue={
                      documentData
                        ? {
                          label: documentData.poolName,
                          value: documentData.poolId,
                        }
                        : pools
                          ? { label: pools[0].name, value: pools[0].id }
                          : null
                    }
                    value={
                      formik.touched.poolId
                        ? formik.values.poolId
                          ? {
                            label: formik.values.poolName,
                            value: formik.values.poolId,
                          }
                          : undefined
                        : {
                          label: documentData.poolName,
                          value: documentData.poolId,
                        }
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "poolId",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldValue(
                        "poolName",
                        selectedOption ? selectedOption.label : ""
                      )
                      await formik.setFieldTouched("poolId", true)
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                  />
                  <Button variant="light" onClick={() => setShowSettings(true)}>
                    <FontAwesomeIcon icon={faAdd} className="fa-thin" />
                  </Button>
                  {formik.touched.poolId && formik.errors.poolId && (
                    <div className="PoolPortal_ErrorMessage">
                      {formik.errors.poolId}
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="ownerId" as="h6">
                    Owner:
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      owners
                        ? owners
                          .filter((owner: PoolPortalOwner) => {
                            return (
                              !formik.values.poolId ||
                              owner.poolId === formik.values.poolId
                            )
                          })
                          .map((owner: PoolPortalOwner) => ({
                            label: owner.name,
                            value: owner.id,
                          }))
                        : []
                    }
                    id="ownerId"
                    name="ownerId"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    defaultValue={
                      documentData
                        ? {
                          label: documentData.ownerName,
                          value: documentData.ownerId,
                        }
                        : owners
                          ? { label: owners[0].name, value: owners[0].id }
                          : null
                    }
                    value={
                      formik.values.contenttypeCode === "NEWSLETTERS" ?
                        {
                          label: "",
                          value: ""
                        } : (
                          formik.touched.ownerId
                            ? formik.values.ownerId
                              ? {
                                label: formik.values.ownerName,
                                value: formik.values.ownerId,
                              }
                              : undefined
                            : {
                              label: documentData.ownerName,
                              value: documentData.ownerId,
                            })
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "ownerId",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldValue(
                        "ownerName",
                        selectedOption ? selectedOption.label : ""
                      )
                      await formik.setFieldTouched("ownerId", true)
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                    isDisabled={formik.values.contenttypeCode === "NEWSLETTERS"}
                  />
                  <Button variant="light" onClick={() => setShowSettings(true)}>
                    <FontAwesomeIcon icon={faAdd} className="fa-thin" />
                  </Button>
                  {formik.touched.ownerId && formik.errors.ownerId && (
                    <div className="PoolPortal_ErrorMessage">
                      {formik.errors.ownerId}
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="vesselId" as="h6">
                    Vessel:
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      vessels
                        ? vessels
                          .filter((vessel: PoolPortalVessel) => {
                            return (
                              !formik.values.ownerId ||
                              vessel.ownerId === formik.values.ownerId
                            )
                          })
                          .map((vessel: PoolPortalVessel) => ({
                            label: vessel.name,
                            value: vessel.id,
                          }))
                        : []
                    }
                    id="vesselId"
                    name="vesselId"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    defaultValue={
                      documentData
                        ? {
                          label: documentData.vesselName,
                          value: documentData.vesselId,
                        }
                        : vessels
                          ? { label: vessels[0].name, value: vessels[0].id }
                          : null
                    }
                    value={
                      formik.values.contenttypeCode === "NEWSLETTERS" ?
                        {
                          label: "",
                          value: ""
                        } : (
                          formik.touched.vesselId
                            ? formik.values.vesselId
                              ? {
                                label: formik.values.vesselName,
                                value: formik.values.vesselId,
                              }
                              : undefined
                            : {
                              label: documentData.vesselName,
                              value: documentData.vesselId,
                            }
                        )
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "vesselId",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldValue(
                        "vesselName",
                        selectedOption ? selectedOption.label : ""
                      )
                      await formik.setFieldTouched("vesselId", true)

                      if (!formik.values.ownerId) {
                        const vessel = vessels?.find(
                          (vessel) => vessel.id === selectedOption.value
                        )
                        const vesselOwnerName = vessel?.ownerName ?? ""
                        const vesselOwnerId = vessel?.ownerId ?? ""
                        setDocumentData({
                          ...documentData,
                          ownerName: vesselOwnerName,
                          ownerId: vesselOwnerId,
                        })

                        await formik.setFieldValue("ownerId", vesselOwnerId)
                        await formik.setFieldValue("ownerName", vesselOwnerName)
                        await formik.setFieldTouched("ownerId", true)
                      }
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                    isDisabled={formik.values.contenttypeCode === "NEWSLETTERS"}
                  />
                  <Button variant="light" onClick={() => setShowSettings(true)}>
                    <FontAwesomeIcon icon={faAdd} className="fa-thin" />
                  </Button>
                  {formik.touched.vesselId && formik.errors.vesselId && (
                    <div className="PoolPortal_ErrorMessage">
                      {formik.errors.vesselId}
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="year" as="h6">
                    Year
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      years
                        ? years.map((year: YearObject) => ({
                          label: year.name,
                          value: year.id,
                        }))
                        : []
                    }
                    id="year"
                    name="year"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    defaultValue={
                      documentData
                        ? {
                          label: documentData.year,
                          value: documentData.year,
                        }
                        : years
                          ? { label: years[0].name, value: years[0].id }
                          : null
                    }
                    value={
                      formik.touched.year
                        ? formik.values.year
                          ? {
                            label: formik.values.year,
                            value: formik.values.year,
                          }
                          : undefined
                        : {
                          label: documentData.year,
                          value: documentData.year,
                        }
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "year",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldTouched("year", true)
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                  />
                  {formik.touched.year && formik.errors.year && (
                    <div className="PoolPortal_ErrorMessage">
                      {formik.errors.year}
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="month" as="h6">
                    Month:
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      months
                        ? months.map((month: MonthObject) => ({
                          label: month.name,
                          value: month.id,
                        }))
                        : []
                    }
                    id="month"
                    name="month"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    defaultValue={
                      documentData
                        ? {
                          label: GetMonthLabel(documentData.month),
                          value: documentData.month,
                        }
                        : months
                          ? { label: months[0].name, value: months[0].id }
                          : null
                    }
                    value={
                      formik.touched.month
                        ? formik.values.month
                          ? {
                            label: formik.values.monthLabel,
                            value: formik.values.month,
                          }
                          : undefined
                        : {
                          label: GetMonthLabel(documentData.month),
                          value: documentData.month,
                        }
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "month",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldValue(
                        "monthLabel",
                        selectedOption ? selectedOption.label : ""
                      )
                      await formik.setFieldTouched("month", true)
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                  />
                  {formik.touched.month && formik.errors.month && (
                    <div className="PoolPortal_ErrorMessage">
                      {formik.errors.month}
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="partofmothId" as="h6">
                    Part of month:
                  </BootstrapForm.Label>
                  <Field
                    as={Select}
                    options={
                      partofmonths
                        ? partofmonths.map(
                          (partofmonth: PoolPortalPartofmonth) => ({
                            label: partofmonth.name,
                            value: partofmonth.id,
                          })
                        )
                        : []
                    }
                    id="partofmonthId"
                    name="partofmonthId"
                    className="form-control"
                    menuPlacement="auto"
                    isClearable
                    placeholder=""
                    defaultValue={
                      documentData
                        ? {
                          label: documentData.partofmonthName,
                          value: documentData.partofmonthId,
                        }
                        : partofmonths
                          ? {
                            label: partofmonths[0].name,
                            value: partofmonths[0].id,
                          }
                          : null
                    }
                    value={
                      formik.touched.partofmonthId
                        ? formik.values.partofmonthId
                          ? {
                            label: formik.values.partofmonthName,
                            value: formik.values.partofmonthId,
                          }
                          : undefined
                        : {
                          label: documentData.partofmonthName,
                          value: documentData.partofmonthId,
                        }
                    }
                    onChange={async (selectedOption: SelectOption) => {
                      await formik.setFieldValue(
                        "partofmonthId",
                        selectedOption ? selectedOption.value : ""
                      )
                      await formik.setFieldValue(
                        "partofmonthName",
                        selectedOption ? selectedOption.label : ""
                      )
                      await formik.setFieldTouched("partofmonthId", true)
                    }}
                    getOptionLabel={(option: { label: any }) => option.label}
                  />
                  <Button variant="light" onClick={() => setShowSettings(true)}>
                    <FontAwesomeIcon icon={faAdd} className="fa-thin" />
                  </Button>
                  {formik.touched.partofmonthId &&
                    formik.errors.partofmonthId && (
                      <div className="PoolPortal_ErrorMessage">
                        {formik.errors.partofmonthId}
                      </div>
                    )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="name" as="h6">
                    Name:
                  </BootstrapForm.Label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    default={documentData.name || ""}
                    value={formik.values.name || documentData.name || ""}
                    className="form-control"
                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                      await formik.setFieldValue("name", e.target.value)
                      await formik.setFieldTouched("name", true)
                    }}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="PoolPortal_ErrorMessage">
                      {formik.errors.name}
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <BootstrapForm.Label htmlFor="partofmothId" as="h6">
                    File upload:
                  </BootstrapForm.Label>
                  <FileDropZone
                    uploadedFile=""
                    fileName=""
                    ownerId={formik.values.ownerId ?? ""}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    setFileName={async (fileName: string) => {
                      await formik.setFieldValue("name", fileName)
                    }}
                  />
                  {formik.touched.partofmonthId &&
                    formik.errors.partofmonthId && (
                      <div className="PoolPortal_ErrorMessage">
                        {formik.errors.partofmonthId}
                      </div>
                    )}
                </div>
              </div>
              <hr />
              <div>
                <Button
                  type="submit"
                  disabled={formik.isSubmitting}
                  variant="primary"
                  onClick={async (e) => {

                    const validataionErrors = await formik.validateForm()
                    if (Object.keys(validataionErrors).length > 0) {
                      await formik.setFieldTouched("contenttypeId", true)
                      await formik.setFieldTouched("name", true)
                      await formik.setFieldTouched("poolId", true)
                      await formik.setFieldTouched("ownerId", true)
                      await formik.setFieldTouched("vesselId", true)
                      await formik.setFieldTouched("year", true)
                      await formik.setFieldTouched("month", true)
                      await formik.setFieldTouched("partofmonthId", true)
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  }}
                >
                  {formik.isSubmitting && (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />
                      &nbsp;&nbsp;
                    </>
                  )}
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  type="button"
                  onClick={() => {
                    handleCancel()
                  }}
                  variant="secondary"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    </FormikProvider>
  )
}
