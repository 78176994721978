import { ConstructPoolPortalPoolsHeaders } from "modules/PoolPortal/helpers/headers"
import {
  AdminSettingsEnum,
  PoolPortalPool,
  PoolPortalPools,
} from "modules/PoolPortal/helpers/types"
import { usePools } from "modules/PoolPortal/hooks/usePoolsAPI"
import { FormatEnumToString, GetEntryPageState } from "modules/PoolPortal/utils"
import { useEffect, useState } from "react"
import { Button, Modal, Offcanvas } from "react-bootstrap"
import { SettingsEntryHOC } from "../SettingsHOC"
import { SettingsTable } from "../SettingsTable"

export const PoolsAdminMain = () => {
  // UseHooks for the Pools
  const { pools, isLoading, deletePool } = usePools()

  const [poolsData, setPoolsData] = useState(pools ?? ([] as PoolPortalPools))
  const [showEntryBlade, setShowEntryBlade] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [currentPool, setCurrentPool] = useState({} as PoolPortalPool)

  useEffect(() => {
    if (pools) {
      setPoolsData([...pools])
    }
  }, [isLoading, pools])

  const handleEditBladeClose = () => {
    setShowEntryBlade(false)
  }
  const handleEditBladeShow = () => {
    setShowEntryBlade(true)
  }

  const newPoolsHandler = () => {
    setShowEntryBlade(true)
    setCurrentPool({} as PoolPortalPool)
  }

  const editPoolHandler = (id: string) => {
    const data = poolsData as PoolPortalPools
    if (data) {
      var foundIndex = data?.findIndex((x: PoolPortalPool) => x.id === id)
      setCurrentPool(data[foundIndex])
      handleEditBladeShow()
    }
  }

  const deletePoolHandler = (id: string) => {
    // show the pop here and set the currentPool for deletion
    const data = poolsData as PoolPortalPools
    if (data) {
      var foundIndex = data?.findIndex((x: PoolPortalPool) => x.id === id)
      setCurrentPool(data[foundIndex])
      setShowDelete(true)
    }
  }

  const modalDeleteHandler = async () => {
    //  actual deletion
    await deletePool(currentPool.id)
    setShowDelete(false)
  }

  return (
    <>
      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        style={{ zIndex: 5060 }}
        backdropClassName="SettingsDeleteConfirmationBackDrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Pool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the "{currentPool.name}" pool?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={modalDeleteHandler}>
            Delete
          </Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        className="PoolPortal_SettingsEditOffcanvas"
        show={showEntryBlade}
        onHide={handleEditBladeClose}
        placement="end"
        backdrop="static"
        scroll={false}
        style={{ zIndex: 4060 }}
        backdropClassName="SettingsEditOffCanvassBackDrop"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Settings - {GetEntryPageState(currentPool)}{" "}
            {FormatEnumToString(AdminSettingsEnum.POOLS)}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SettingsEntryHOC
            type={AdminSettingsEnum.POOLS}
            data={currentPool}
            setShowEntryBlade={setShowEntryBlade}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <SettingsTable
        body={poolsData!}
        headers={ConstructPoolPortalPoolsHeaders(
          editPoolHandler,
          deletePoolHandler
        )}
        newHandler={newPoolsHandler}
      />
    </>
  )
}
