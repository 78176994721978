import moment from "moment"
import { RightShipInspection } from "../hooks/usePrevetGetRightShipData"

function getBadgeColorAndText(rightShipInspection: RightShipInspection) {
  const { lastInspectionOutcome, lastInspectionValidity, required } =
    rightShipInspection
  const lastInspectionValidityDate = moment(lastInspectionValidity)
  const now = moment()
  const formattedValidity = lastInspectionValidityDate.format("LL")

  const remainingDays = lastInspectionValidityDate.diff(now, "d")

  if (now.isAfter(lastInspectionValidityDate)) {
    return {
      fill: "#e61e2f",
      text: "Requires a valid RightShip inspection.",
    }
  }

  if (remainingDays < 60) {
    return {
      fill: "#F7661F",
      text: `Valid for ${remainingDays} more days`,
    }
  }

  if (lastInspectionOutcome === "acceptable") {
    return {
      fill: "#008561",
      text: `Valid until ${formattedValidity}`,
    }
  }

  return {
    fill: "#e61e2f",
    text: "Requires a valid RightShip inspection.",
  }
}

export function PrevetRightShipBadgeIcon({
  rightShipInspection,
  platformLink,
}: {
  rightShipInspection: RightShipInspection
  platformLink: string
}) {
  const { fill, text } = getBadgeColorAndText(rightShipInspection)
  return (
    <div className="d-flex mt-1 mb-1">
      <svg
        className="ms-2 me-2"
        style={{ width: "18px" }}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 42"
      >
        <path d="M14,0C6.3,0,0,6.3,0,14c0,4.4,2,8.5,5.5,11.1L3.5,40C3.4,41,4,41.8,5,42c0.1,0,0.1,0,0.2,0l0,0c0.3,0,0.7-0.1,0.9-0.2L14,37l7.9,4.7c0.3,0.2,0.6,0.3,0.9,0.2l0,0c1,0,1.7-0.9,1.7-1.8c0-0.1,0-0.1,0-0.2l-2-14.9c6.1-4.7,7.2-13.5,2.5-19.6C22.3,2,18.3,0,14,0z M14.9,33.5c-0.6-0.3-1.2-0.3-1.8,0l-5.6,3.4L8.8,27c3.4,1.3,7.1,1.3,10.4,0l1.3,9.9L14.9,33.5z M19.8,22.7c-0.1,0-0.2,0.1-0.2,0.2C17.9,24,16,24.5,14,24.5C8.2,24.5,3.5,19.8,3.5,14C3.5,8.2,8.2,3.5,14,3.5S24.5,8.2,24.5,14C24.5,17.5,22.7,20.8,19.8,22.7z" />
      </svg>
      <div>
        <h3 className="mb-0 fs-6" style={{ color: fill }}>
          RIGHTSHIP INSPECTION
        </h3>
        <a href={platformLink} target="_blank" rel="noreferrer">
          {text}
        </a>
      </div>
    </div>
  )
}
