export function calcExtraCostPerDay({
  fastShipKtsPerHour,
  slowShipKtsPerHour,
  fuelConPerDay,
  hirePrice,
  fuelPrice,
}: {
  fastShipKtsPerHour: number
  slowShipKtsPerHour: number
  fuelConPerDay: number
  hirePrice: number
  fuelPrice: number
}) {
  const fastShipDistPerDay = fastShipKtsPerHour * 24;
  const slowShipDistPerDay = slowShipKtsPerHour * 24;
  const diffInDistInNM = fastShipDistPerDay - slowShipDistPerDay;
  const diffInTime = diffInDistInNM / slowShipKtsPerHour;

  const extraFuelConsumed = (diffInTime * fuelConPerDay) / 24;
  const extraFuelCost = extraFuelConsumed * fuelPrice;

  const extraHirePrice = (hirePrice / 24) * diffInTime;

  const extraCostTotal = extraFuelCost + extraHirePrice;

  return extraCostTotal;
}