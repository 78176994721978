import React, { ChangeEvent, Dispatch, MutableRefObject, useState } from "react"
import { Button, Card, Form } from "react-bootstrap"

import { PrevetSpeedGraphModal } from "./PrevetSpeedGraphModal"
import { MemoPrevetVesselSpeedChart } from "./PrevetVesselSpeedChart"
import {
  PrevetSpeedGraphZoneType,
  getZonesOfConcernWithSinglePointZoneIndex,
} from "../getSpeedChartOptionsHelpers"
import { LineSeriesOption } from "echarts"
import { useFetchVesselLegs } from "../hooks/useFetchVesselLegs"

interface PrevetVesselSpeedProps {
  vesselImo: number
  vesselName: string
  setIsTradingModalShown: React.Dispatch<React.SetStateAction<boolean>>
  setDateForCenterMarker: React.Dispatch<
    React.SetStateAction<undefined | string>
  >
  setLadenAvgSpeed: Dispatch<React.SetStateAction<undefined | number>>
  setBallastAvgSpeed: Dispatch<React.SetStateAction<undefined | number>>
  setLadenMaxSpeed: Dispatch<React.SetStateAction<undefined | number>>
  setBallastMaxSpeed: Dispatch<React.SetStateAction<undefined | number>>
  ladenChartInstance: MutableRefObject<any>
  ballastChartInstance: MutableRefObject<any>
  userLadenVesselSpeed: string | undefined
  userBallastVesselSpeed: string | undefined
}

export function PrevetVesselSpeedWrapper({
  vesselImo,
  vesselName,
  setIsTradingModalShown,
  setDateForCenterMarker,
  setLadenAvgSpeed,
  setBallastAvgSpeed,
  setLadenMaxSpeed,
  setBallastMaxSpeed,
  ladenChartInstance,
  ballastChartInstance,
  userLadenVesselSpeed,
  userBallastVesselSpeed,
}: PrevetVesselSpeedProps) {
  const { vesselLegsData } = useFetchVesselLegs({
    imo: vesselImo,
  })
  const [isSpeedGraphModalShown, setIsSpeedGraphModalShown] = useState(false)
  const [useForMinTreshold, setUseForMinTreshold] =
    useState<PrevetSpeedGraphZoneType>(PrevetSpeedGraphZoneType.Dips)

  // Imperatively set the ocean zones on chart instead of passing zone type
  // To the memoized chart component as that will cause chart to rerender
  // and remove the user inputted TC Desc.
  function handleChangeZoneType(event: ChangeEvent<HTMLInputElement>) {
    setUseForMinTreshold(event.target.id as PrevetSpeedGraphZoneType)
    if (!vesselLegsData || vesselLegsData.length < 1) {
      return
    }
    const ladenOpts = ladenChartInstance.current.getOption()
    const ballastOpts = ballastChartInstance.current.getOption()
    const {
      zonesOfConcern: ladenZones,
      indexOfSinglePointZones: ladenIndexOfSinglePointZones,
    } = getZonesOfConcernWithSinglePointZoneIndex({
      speedData: vesselLegsData.filter((data) => data.laden === true),
      isLaden: true,
      useForMinTreshold: event.target.id as PrevetSpeedGraphZoneType,
    })
    const {
      zonesOfConcern: ballastZones,
      indexOfSinglePointZones: ballastIndexOfSinglePointZones,
    } = getZonesOfConcernWithSinglePointZoneIndex({
      speedData: vesselLegsData.filter((data) => data.laden === false),
      isLaden: false,
      useForMinTreshold: event.target.id as PrevetSpeedGraphZoneType,
    })

    ladenChartInstance.current.setOption({
      ...ladenOpts,
      series: [
        // Order of ladenOpts items in series array has to match initial order
        {
          ...(ladenOpts.series as LineSeriesOption[])[0],
          markArea: {
            ...(ladenOpts.series as LineSeriesOption[])[0].markArea,
            data: ladenZones,
          },
        },
        {
          ...(ladenOpts.series as LineSeriesOption[])[1],
        },
        {
          type: "line",
          xAxisIndex: 1,
          markArea: {
            // Enables the hack to draw a mark area around a single point
            ...(ladenOpts.series as LineSeriesOption[])[2].markArea,
            data: ladenIndexOfSinglePointZones.map((idx) => {
              return [{ xAxis: idx * 2 }, { xAxis: idx * 2 + 1 }]
            }),
          },
        },
      ],
    })
    ballastChartInstance.current.setOption({
      ...ballastOpts,
      series: [
        // Order of ladenOpts items in series array has to match initial order
        {
          ...(ballastOpts.series as LineSeriesOption[])[0],
          markArea: {
            ...(ballastOpts.series as LineSeriesOption[])[0].markArea,
            data: ballastZones,
          },
        },
        {
          ...(ballastOpts.series as LineSeriesOption[])[1],
        },
        {
          type: "line",
          xAxisIndex: 1,
          markArea: {
            // Enables the hack to draw a mark area around a single point
            ...(ballastOpts.series as LineSeriesOption[])[2].markArea,
            data: ballastIndexOfSinglePointZones.map((idx) => {
              return [{ xAxis: idx * 2 }, { xAxis: idx * 2 + 1 }]
            }),
          },
        },
      ],
    })
  }

  return (
    <Card.Body className="PrevetTrio_SpeedGraph_Inner">
      {isSpeedGraphModalShown && (
        <PrevetSpeedGraphModal
          isSpeedGraphModalShown={isSpeedGraphModalShown}
          vesselImo={vesselImo}
          vesselName={vesselName}
          closeSpeedGraphModal={() => {
            setIsSpeedGraphModalShown(false)
          }}
          userLadenVesselSpeed={userLadenVesselSpeed}
          userBallastVesselSpeed={userBallastVesselSpeed}
          setIsTradingModalShown={setIsTradingModalShown}
          setDateForCenterMarker={setDateForCenterMarker}
        />
      )}
      <div className="mb-0">
        <div className="mb-0 ">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h4 className="card-title mb-0">Vessel Speed</h4>
            <div className="d-flex">
              <div className="d-flex align-items-center me-4">
                {[
                  PrevetSpeedGraphZoneType.Dips,
                  PrevetSpeedGraphZoneType.Outliers,
                  PrevetSpeedGraphZoneType.None,
                ].map((zoneType) => {
                  return (
                    <div key={zoneType}>
                      <Form.Check
                        className="d-flex align-items-center"
                        type="checkbox"
                        id={zoneType}
                      >
                        <Form.Check.Input
                          className="p-0 ms-1 mt-0"
                          style={{ float: "revert" }}
                          type="checkbox"
                          checked={zoneType === useForMinTreshold}
                          onChange={handleChangeZoneType}
                        />
                        <Form.Check.Label
                          style={{ width: "unset" }}
                          className="m-0 ms-1 mt-0"
                        >
                          {zoneType}
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  )
                })}
              </div>
              <Button
                className="PrevetTrio_SpeedGraph_OceanZoneButton"
                onClick={() => setIsSpeedGraphModalShown(true)}
              >
                All Ocean Zones
              </Button>
            </div>
          </div>
          <MemoPrevetVesselSpeedChart
            vesselImo={vesselImo}
            vesselName={vesselName}
            isLaden={true}
            setAvgSpeed={setLadenAvgSpeed}
            setMaxSpeed={setLadenMaxSpeed}
            setIsTradingModalShown={setIsTradingModalShown}
            setDateForCenterMarker={setDateForCenterMarker}
            chartInstance={ladenChartInstance}
            useForMinTreshold={PrevetSpeedGraphZoneType.Dips}
          />
          <MemoPrevetVesselSpeedChart
            vesselImo={vesselImo}
            vesselName={vesselName}
            isLaden={false}
            setAvgSpeed={setBallastAvgSpeed}
            setMaxSpeed={setBallastMaxSpeed}
            setIsTradingModalShown={setIsTradingModalShown}
            setDateForCenterMarker={setDateForCenterMarker}
            chartInstance={ballastChartInstance}
            useForMinTreshold={PrevetSpeedGraphZoneType.Dips}
          />
        </div>
      </div>
    </Card.Body>
  )
}
