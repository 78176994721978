import { useEffect, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"

interface PortPredictorDaysInPortInputProps {
  portTurnAroundTime: string | undefined
  setPortTurnAroundTime: (time: string | undefined) => void
}

export function PortPredictorDaysInPortInput({
  portTurnAroundTime,
  setPortTurnAroundTime,
}: PortPredictorDaysInPortInputProps) {
  const [localPortTurnAroundTime, setLocalPortTurnAroundTime] = useState<
    string | undefined
  >(portTurnAroundTime === undefined ? undefined : String(portTurnAroundTime))

  useEffect(() => {
    const time =
      portTurnAroundTime === undefined ? "" : String(portTurnAroundTime)
    setLocalPortTurnAroundTime(time)
  }, [portTurnAroundTime])

  useEffect(() => {
    if (
      localPortTurnAroundTime === undefined ||
      localPortTurnAroundTime === ""
    ) {
      return
    }
    setPortTurnAroundTime(localPortTurnAroundTime)
  }, [localPortTurnAroundTime])

  return (
    <>
      <Form.Label className="PortPredictor_FormLabel fw-bold">
        <>Est. Turn Around Time</>
      </Form.Label>
      <InputGroup className="PortPredictor_InputGroup">
        <Form.Control
          className="ms-0"
          placeholder="Est. Days in Port"
          aria-label="Est. Days in Port"
          aria-describedby="est-days-in-port"
          value={localPortTurnAroundTime}
          type="number"
          onChange={(event) => {
            if (event.target.value) {
              setLocalPortTurnAroundTime(event.target.value)
              return
            }
            setLocalPortTurnAroundTime("")
          }}
        />
        <InputGroup.Text id="turnAroundTime">Days</InputGroup.Text>
      </InputGroup>
    </>
  )
}
