import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { useFormikContext } from "formik"
import { PoolPortalDocument } from "modules/PoolPortal/helpers/types"
import { useCallback, useEffect, useState } from "react"
import Spinner from "react-bootstrap/Spinner"
import { useDropzone } from "react-dropzone"
import { toast } from "react-toastify"

interface FileDropZoneProps {
  uploadedFile: string
  fileName: string | undefined
  ownerId: string | undefined
  setFileName: (fileName: string) => void
  setFieldTouched: (
    field: keyof PoolPortalDocument,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void
  setFieldValue: (
    field: keyof PoolPortalDocument,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
}
interface UploadRequest {
  file: string
}

interface UploadResponse {
  filePath: string
}
export function FileDropZone({
  fileName,
  ownerId,
  setFileName,
  setFieldValue,
  setFieldTouched,
}: FileDropZoneProps) {
  const request = useAxiosRequest()
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)

  const { values }: { values: PoolPortalDocument } = useFormikContext()

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]): T[] => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onabort = () => console.log("file reading was aborted")
        reader.onerror = () => console.log("file reading has failed")
        reader.readAsDataURL(file)
        reader.onloadend = async () => {
          let base64data = reader.result
          if (!base64data || !(typeof base64data === "string")) {
            return
          }

          base64data = base64data.replace("data:application/pdf;base64,", "")
          setIsLoadingUploadFile(true)
          try {
            const responseBody = await request<UploadRequest, UploadResponse>({
              url: `${BASE_URL}/poolportal/document/upload?ownerid=${ownerId}`,
              method: "post",
              data: {
                file: base64data,
              },
            })

            if (!responseBody) {
              return
            }

            if (!responseBody.data || !responseBody.data.filePath) {
              console.error("Data is null, undefined or an empty string")
            }

            setFileName(file.name)
            setFieldValue("filePath", responseBody.data.filePath)
            // TODO: Check this one
            if (!values.name) {
              const reportNameFromFileName = file.name.split(".")[0]
              setFieldValue("name", reportNameFromFileName)
            }
          } catch (err) {
            setFieldTouched("filePath", true)
            let message = "Error uploading to server"
            if (axios.isAxiosError(err)) {
              const axiosErroMessage = err?.response?.data?.error?.message
              message = axiosErroMessage
                ? message + ": " + axiosErroMessage
                : message + "."
            } else if (err instanceof Error) {
              message = message + ": " + err.message
            } else {
              message = "."
            }
            toast.warn(message, {
              position: "bottom-right",
              autoClose: 8000,
            })
          } finally {
            setIsLoadingUploadFile(false)
          }
        }
      })

      return acceptedFiles
    },
    [setFieldValue, setFileName, setFieldTouched]
  )

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/pdf": [".pdf"],
    },
  })

  useEffect(() => {
    if (fileRejections.length === 0) {
      return
    }

    fileRejections.forEach((rejectedFile) => {
      const rejectedFileMessage = rejectedFile.errors[0].message
      toast.warn(rejectedFileMessage, {
        position: "bottom-right",
        autoClose: 4000,
      })
    })
  }, [fileRejections])

  return (
    <>
      <section>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {isLoadingUploadFile && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {values.filePath ? (
            <div className="AdminReportsDropZone_Content">
              <div className="AdminReportsDropZone_Inner">
                <div className="mb-3">
                  <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                </div>
                <h4>Drop files here or click to replace:</h4>
                <div className="mb-2" style={{ color: "#ffad00" }}>
                  {fileName ? fileName : values.filePath}
                </div>
                <em>(Only one *.pdf file will be accepted)</em>
              </div>
            </div>
          ) : (
            <div className="AdminReportsDropZone_Content">
              <div className="AdminReportsDropZone_Inner">
                <div className="mb-3">
                  <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                </div>
                <h4>Drop files here or click to upload.</h4>
                <em>(Only one *.pdf file will be accepted)</em>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
