import { ErrorMessage, Field, Form, Formik } from "formik"
import { PoolPortalOwner } from "modules/PoolPortal/helpers/types"
import { useContenttypes } from "modules/PoolPortal/hooks/useContenttypesAPI"
import { useOwners } from "modules/PoolPortal/hooks/useOwnersAPI"
import { usePools } from "modules/PoolPortal/hooks/usePoolsAPI"
import { DefaultValidationSchema } from "modules/PoolPortal/utils"
import { Form as BootstrapForm, Button, Spinner } from "react-bootstrap"
import Select from "react-select"
import * as Yup from "yup"

interface OwnersAdminEntryPageProps {
  data: PoolPortalOwner
  setShowEntryBlade: (show: boolean) => void
}

const validationSchema = Yup.object().shape({
  ...DefaultValidationSchema(),
  poolId: Yup.string().required("Pool is required"),
})

export const OwnersAdminEntryPage = ({
  data,
  setShowEntryBlade,
}: OwnersAdminEntryPageProps) => {
  const { createOwner, updateOwner } = useOwners()
  const { pools } = usePools()

  const isEditMode = !(Object.keys(data).length === 0 || data.id === "")

  const handleSubmit = async (values: PoolPortalOwner) => {
    // Handle form submission
    if (!isEditMode) {
      await createOwner(values as PoolPortalOwner)
    } else {
      await updateOwner({
        ...data,
        ...{
          code: values.code,
          name: values.name,
          description: values.description,
          poolId: values.poolId,
        },
      } as PoolPortalOwner)
    }
    setShowEntryBlade(false)
  }

  const handleCancel = () => {
    setShowEntryBlade(false)
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <>
          <Form>
            <div className="PoolPortal_AdminEntryBody d-flex flex-column">
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="code" as="h5">
                  Code:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="code"
                  name="code"
                  default={data.code}
                  disabled={isEditMode}
                  className="form-control ml-50"
                />
                <ErrorMessage
                  name="code"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>

              <div className="mb-3">
                <BootstrapForm.Label htmlFor="name" as="h5">
                  Name:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  default={data.name}
                  className="form-control"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>

              <div className="mb-3">
                <BootstrapForm.Label htmlFor="poolId" as="h5">
                  Pool:
                </BootstrapForm.Label>
                <Field
                  as={Select}
                  options={
                    pools
                      ? pools.map((pool) => ({
                          label: pool.name,
                          value: pool.id,
                        }))
                      : []
                  }
                  id="poolId"
                  name="poolId"
                  className="form-control"
                  menuPlacement="auto"
                  // defaultValue={pools ? { label: pools[0].name, value: pools[0].id } : {}}
                  value={
                    values.poolId
                      ? { label: values.poolName, value: values.poolId }
                      : undefined
                  }
                  // value={pools ? pools.find(pool => pool.id === values.poolId) : null} // Set the selected value
                  onChange={async (selectedOption: {
                    value: string
                    label: string
                  }) => {
                    await setFieldValue(
                      "poolId",
                      (selectedOption as { value: string }).value
                    )
                    await setFieldValue(
                      "poolName",
                      (selectedOption as { label: string }).label
                    )
                  }}
                  getOptionLabel={(option: { label: any }) => option.label} // Specify how to display the label
                />
                <ErrorMessage
                  name="poolId"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>

              <div className="mb-3">
                <BootstrapForm.Label htmlFor="description" as="h5">
                  Description:
                </BootstrapForm.Label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  default={data.description}
                  className="form-control"
                  style={{ height: "60px" }}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>
            </div>
            <hr />
            <div>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {isSubmitting && (
                  <>
                    <Spinner animation="border" variant="light" size="sm" />
                    &nbsp;&nbsp;
                  </>
                )}
                Save
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button type="button" onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}
