import { ConstructPoolPortalVesselsHeaders } from "modules/PoolPortal/helpers/headers"
import {
  AdminSettingsEnum,
  PoolPortalVessel,
  PoolPortalVessels,
} from "modules/PoolPortal/helpers/types"
import { useVessels } from "modules/PoolPortal/hooks/useVesselsAPI"
import { FormatEnumToString, GetEntryPageState } from "modules/PoolPortal/utils"
import { useEffect, useState } from "react"
import { Button, Modal, Offcanvas } from "react-bootstrap"
import { SettingsEntryHOC } from "../SettingsHOC"
import { SettingsTable } from "../SettingsTable"

export const VesselsAdminMain = () => {
  // UseHooks for the Vessels
  const { vessels, isLoading, deleteVessel } = useVessels()

  const [vesselsData, setVesselsData] = useState(
    vessels ?? ([] as PoolPortalVessels)
  )
  const [showEntryBlade, setShowEntryBlade] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [currentVessel, setCurrentVessel] = useState({} as PoolPortalVessel)

  useEffect(() => {
    if (vessels) {
      setVesselsData([...vessels])
    }
  }, [isLoading, vessels])

  const handleEditBladeClose = () => {
    setShowEntryBlade(false)
  }
  const handleEditBladeShow = () => {
    setShowEntryBlade(true)
  }

  const newVesselsHandler = () => {
    setShowEntryBlade(true)
    setCurrentVessel({} as PoolPortalVessel)
  }

  const editVesselHandler = (id: string) => {
    const data = vesselsData as PoolPortalVessels
    if (data) {
      var foundIndex = data?.findIndex((x: PoolPortalVessel) => x.id === id)
      setCurrentVessel(data[foundIndex])
      handleEditBladeShow()
    }
  }

  const deleteVesselHandler = (id: string) => {
    // show the pop here and set the currentVessel for deletion
    const data = vesselsData as PoolPortalVessels
    if (data) {
      var foundIndex = data?.findIndex((x: PoolPortalVessel) => x.id === id)
      setCurrentVessel(data[foundIndex])
      setShowDelete(true)
    }
  }

  const modalDeleteHandler = async () => {
    //  actual deletion
    await deleteVessel(currentVessel.id)
    setShowDelete(false)
  }

  return (
    <>
      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        style={{ zIndex: 5060 }}
        backdropClassName="SettingsDeleteConfirmationBackDrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vessel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the "{currentVessel.name}" vessel?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={modalDeleteHandler}>
            Delete
          </Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        className="PoolPortal_SettingsEditOffcanvas"
        show={showEntryBlade}
        onHide={handleEditBladeClose}
        placement="end"
        backdrop="static"
        scroll={false}
        style={{ zIndex: 4060 }}
        backdropClassName="SettingsEditOffCanvassBackDrop"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Settings - {GetEntryPageState(currentVessel)}{" "}
            {FormatEnumToString(AdminSettingsEnum.VESSELS)}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SettingsEntryHOC
            type={AdminSettingsEnum.VESSELS}
            data={currentVessel}
            setShowEntryBlade={setShowEntryBlade}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <SettingsTable
        body={vesselsData!}
        headers={ConstructPoolPortalVesselsHeaders(
          editVesselHandler,
          deleteVesselHandler
        )}
        newHandler={newVesselsHandler}
      />
    </>
  )
}
