import {
  faDownload,
  faEdit,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  GetLatestDateInUserTimezone,
  GetMonthLabel,
} from "modules/PoolPortal/utils"
import {
  Button,
  ButtonGroup,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap"
import { TableColumnType } from "react-bs-datatable"
import {
  PoolPortalContenttype,
  PoolPortalDocumentBase,
  PoolPortalOwner,
  PoolPortalOwnerstoragesetting,
  PoolPortalPartofmonth,
  PoolPortalPool,
  PoolPortalVessel,
} from "./types"

export const ConstructDocumentTableHeaders = (
  editCallback: (id: string) => void,
  // saveCallback: (id: string) => void,
  // undoCallback: (id: string) => void,
  deleteCallback: (id: string) => void,
  viewCallback: (id: string) => void,
  downloadCallback: (id: string, name: string) => void
) => {
  const result: TableColumnType<PoolPortalDocumentBase>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalDocumentBase,
      cell: (row) => (
        <>
          <ButtonGroup>
            <Button
              size="sm"
              variant="secondary"
              className="PoolPortal_ActionButtons"
              onClick={() => {
                // Document View
                viewCallback(row.id)
              }}
            >
              <FontAwesomeIcon icon={faEye} className="fa-thin" />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="PoolPortal_ActionButtons"
              onClick={() => {
                // Document download
                downloadCallback(row.id, row.name)
              }}
            >
              <FontAwesomeIcon icon={faDownload} className="fa-thin" />
            </Button>
            <Button
              size="sm"
              className="PoolPortal_ActionButtons"
              onClick={() => editCallback(row.id)}
            >
              <FontAwesomeIcon icon={faEdit} className="fa-thin" />
            </Button>
            <Button
              variant="danger"
              size="sm"
              className="PoolPortal_ActionButtons"
              onClick={() => deleteCallback(row.id)}
            >
              <FontAwesomeIcon icon={faTrash} className="fa-thin" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
    {
      title: "Content Type",
      prop: "contenttypeCode" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <OverlayTrigger
          placement="top"
          delay={{ show: 500, hide: 400 }}
          overlay={
            <Tooltip id="ppToolTip-top">{row.contenttypeDescription}</Tooltip>
          }
        >
          <div className="PoolPortal_DataCell">{row.contenttypeCode}</div>
        </OverlayTrigger>
      ),
    },
    {
      title: "Pool",
      prop: "poolCode" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        // <div className="PoolPortal_DataCell">{row.poolCode}</div>
        <OverlayTrigger
          placement="top"
          delay={{ show: 500, hide: 400 }}
          overlay={<Tooltip id="ppToolTip-top">{row.poolName}</Tooltip>}
        >
          <div className="PoolPortal_DataCell">{row.poolCode}</div>
        </OverlayTrigger>
      ),
    },
    {
      title: "Owner",
      prop: "ownerCode" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <div className="PoolPortal_DataCell">{row.ownerCode}</div>
      ),
    },
    {
      title: "Vessel",
      prop: "vesselCode" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <div className="PoolPortal_DataCell">{row.vesselCode}</div>
      ),
    },
    {
      title: "Name",
      prop: "name" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <div className="PoolPortal_DataCell" title={row.name}>
          {row.name}
        </div>
      ),
    },
    {
      title: "Year",
      prop: "year" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <div className="PoolPortal_DataCell">{row.year}</div>
      ),
    },
    {
      title: "Month",
      prop: "monthLabel" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <div className="PoolPortal_DataCell">{GetMonthLabel(row.month)}</div>
      ),
    },
    {
      title: "Part of Month",
      prop: "partofmonthName" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        <div className="PoolPortal_DataCell">{row.partofmonthName}</div>
      ),
    },
    {
      title: "Last Modified Date",
      prop: "updatedAt" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        // <div className="PoolPortal_DataCell">{`${row.createdByName}<${row.createdByEmail}>`}</div>
        <div className="PoolPortal_DataCell">{`${GetLatestDateInUserTimezone(
          row.createdAt,
          row.updatedAt
        )}`}</div>
      ),
    },
    {
      title: "Created by",
      prop: "createdByName" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        // <div className="PoolPortal_DataCell">{`${row.createdByName}<${row.createdByEmail}>`}</div>
        <div className="PoolPortal_DataCell">{`${row.createdByName}`}</div>
      ),
    },
    {
      title: "Modified by",
      prop: "updatedByName" as keyof PoolPortalDocumentBase,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalDocumentBase) => (
        // <div className="PoolPortal_DataCell">{`${row.updatedByName}<${row.updatedByEmail}>`}</div>
        <div className="PoolPortal_DataCell">{`${row.updatedByName}`}</div>
      ),
    },
  ]

  return result
}

export const ConstructPoolPortalVesselsHeaders = (
  editCallback: (id: string) => void,
  deleteCallback: (id: string) => void
) => {
  const result: TableColumnType<PoolPortalVessel>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalVessel,
      cell: (row) => {
        return (
          <ButtonGroup>
            {
              <Button
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => editCallback(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} className="fa-thin" />
              </Button>
            }
            {
              <Button
                variant="danger"
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => deleteCallback(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-thin" />
              </Button>
              // )
            }
          </ButtonGroup>
        )
      },
    },
    {
      title: "Code",
      prop: "code" as keyof PoolPortalVessel,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalVessel) => (
        <div className="PoolPortal_DataCell">
          {row.code}
          {/* {(row.vesselCode || " ") + row.isRowOnEditState} */}
        </div>
        // <input value={row.vesselCode} />
      ),
    },
    {
      title: "Name",
      prop: "name" as keyof PoolPortalVessel,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalVessel) => (
        <div className="PoolPortal_DataCell">{row.name}</div>
      ),
    },
    {
      title: "Description",
      prop: "description" as keyof PoolPortalVessel,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalVessel) => (
        <div className="PoolPortal_DataCell">{row.description}</div>
      ),
    },
    {
      title: "Owner",
      prop: "ownerCode" as keyof PoolPortalVessel,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalVessel) => (
        <OverlayTrigger
          placement="top"
          delay={{ show: 500, hide: 400 }}
          overlay={
            <Popover
              id="popover-basic"
              style={{ border: "1px solid blue", zIndex: "89999" }}
            >
              <Popover.Body>
                <>
                  {`Owner Name: ${row.ownerName}`}
                  <br />
                  {`Pool: [${row.poolCode}] ${row.poolName}`}
                </>
              </Popover.Body>
            </Popover>
          }
        >
          <div className="PoolPortal_DataCell">{row.ownerCode}</div>
        </OverlayTrigger>
      ),
    },
  ]

  return result
}

export const ConstructPoolPortalPoolsHeaders = (
  editCallback: (id: string) => void,
  deleteCallback: (id: string) => void
) => {
  const result: TableColumnType<PoolPortalPool>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalPool,
      cell: (row) => {
        if (row.isRowOnEditState) {
        }
        return (
          <ButtonGroup>
            {
              <Button
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => editCallback(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} className="fa-thin" />
              </Button>
            }
            {
              <Button
                className="PoolPortal_ActionButtons"
                variant="danger"
                size="sm"
                onClick={() => deleteCallback(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-thin" />
              </Button>
              // )
            }
          </ButtonGroup>
        )
      },
    },
    {
      title: "Code",
      prop: "code" as keyof PoolPortalPool,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalPool) => (
        <div className="PoolPortal_DataCell">
          {row.code}
          {/* {(row.vesselCode || " ") + row.isRowOnEditState} */}
        </div>
        // <input value={row.vesselCode} />
      ),
    },
    {
      title: "Name",
      prop: "name" as keyof PoolPortalPool,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalPool) => (
        <div className="PoolPortal_DataCell">{row.name}</div>
      ),
    },
    {
      title: "Description",
      prop: "description" as keyof PoolPortalPool,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalPool) => (
        <div className="PoolPortal_DataCell">{row.description}</div>
      ),
    },
  ]

  return result
}

export const ConstructPoolPortalOwnersHeaders = (
  editCallback: (id: string) => void,
  deleteCallback: (id: string) => void
) => {
  const result: TableColumnType<PoolPortalOwner>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalOwner,
      cell: (row) => {
        if (row.isRowOnEditState) {
        }
        return (
          <ButtonGroup>
            {
              <Button
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => editCallback(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} className="fa-thin" />
              </Button>
            }
            {
              <Button
                variant="danger"
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => deleteCallback(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-thin" />
              </Button>
              // )
            }
          </ButtonGroup>
        )
      },
    },
    {
      title: "Code",
      prop: "code" as keyof PoolPortalOwner,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwner) => (
        <div className="PoolPortal_DataCell">
          {row.code}
          {/* {(row.vesselCode || " ") + row.isRowOnEditState} */}
        </div>
        // <input value={row.vesselCode} />
      ),
    },
    {
      title: "Name",
      prop: "name" as keyof PoolPortalOwner,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwner) => (
        <div className="PoolPortal_DataCell">{row.name}</div>
      ),
    },
    {
      title: "Description",
      prop: "description" as keyof PoolPortalOwner,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwner) => (
        <div className="PoolPortal_DataCell">{row.description}</div>
      ),
    },
    {
      title: "Pool",
      prop: "poolCode" as keyof PoolPortalOwner,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwner) => (
        <OverlayTrigger
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>{`Pool Name: ${row.poolName}`}</Popover.Body>
            </Popover>
          }
          placement="bottom"
        >
          <div className="PoolPortal_DataCell">{row.poolCode}</div>
        </OverlayTrigger>
      ),
    },
  ]

  return result
}

export const ConstructPoolPortalContenttypesHeaders = (
  editCallback: (id: string) => void,
  deleteCallback: (id: string) => void
) => {
  const result: TableColumnType<PoolPortalContenttype>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalContenttype,
      cell: (row) => {
        return (
          <ButtonGroup>
            {
              <Button
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => editCallback(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} className="fa-thin" />
              </Button>
            }
            {
              <Button
                className="PoolPortal_ActionButtons"
                variant="danger"
                size="sm"
                onClick={() => deleteCallback(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-thin" />
              </Button>
              // )
            }
          </ButtonGroup>
        )
      },
    },
    {
      title: "Code",
      prop: "code" as keyof PoolPortalContenttype,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalContenttype) => (
        <div className="PoolPortal_DataCell">
          {row.code}
          {/* {(row.vesselCode || " ") + row.isRowOnEditState} */}
        </div>
        // <input value={row.vesselCode} />
      ),
    },
    {
      title: "Name",
      prop: "name" as keyof PoolPortalContenttype,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalContenttype) => (
        <div className="PoolPortal_DataCell">{row.name}</div>
      ),
    },
    {
      title: "Description",
      prop: "description" as keyof PoolPortalContenttype,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalContenttype) => (
        <div className="PoolPortal_DataCell">{row.description}</div>
      ),
    },
  ]

  return result
}

export const ConstructPoolPortalPartofmonthsHeaders = (
  editCallback: (id: string) => void,
  deleteCallback: (id: string) => void
) => {
  const result: TableColumnType<PoolPortalPartofmonth>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalPartofmonth,
      cell: (row) => {
        return (
          <ButtonGroup>
            {
              <Button
                size="sm"
                className="PoolPortal_ActionButtons"
                onClick={() => editCallback(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} className="fa-thin" />
              </Button>
            }
            {
              <Button
                className="PoolPortal_ActionButtons"
                variant="danger"
                size="sm"
                onClick={() => deleteCallback(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-thin" />
              </Button>
              // )
            }
          </ButtonGroup>
        )
      },
    },
    {
      title: "Name",
      prop: "name" as keyof PoolPortalPartofmonth,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalPartofmonth) => (
        <div className="PoolPortal_DataCell">{row.name}</div>
      ),
    },
    {
      title: "Description",
      prop: "description" as keyof PoolPortalPartofmonth,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalPartofmonth) => (
        <div className="PoolPortal_DataCell">{row.description}</div>
      ),
    },
  ]

  return result
}

export const ConstructPoolPortalOwnerstoragesettingHeaders = (
  editCallback: (id: string) => void,
  deleteCallback: (id: string) => void
) => {
  const result: TableColumnType<PoolPortalOwnerstoragesetting>[] = [
    {
      title: "",
      prop: "id" as keyof PoolPortalOwnerstoragesetting,
      cell: (row) => {
        return (
          <ButtonGroup>
            {
              <Button
                className="PoolPortal_ActionButtons"
                variant="danger"
                size="sm"
                onClick={() => deleteCallback(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-thin" />
              </Button>
            }
          </ButtonGroup>
        )
      },
    },
    {
      title: "Owner",
      prop: "ownerId" as keyof PoolPortalOwnerstoragesetting,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwnerstoragesetting) => (
        <div className="PoolPortal_DataCell">{row.ownerName}</div>
      ),
    },
    {
      title: "Storage Name",
      prop: "storageName" as keyof PoolPortalOwnerstoragesetting,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwnerstoragesetting) => (
        <div className="PoolPortal_DataCell">{row.storageName}</div>
      ),
    },
    // {
    //   title: "Container Name",
    //   prop: "containerName" as keyof PoolPortalOwnerstoragesetting,
    //   isFilterable: true,
    //   isSortable: true,
    //   cell: (row: PoolPortalOwnerstoragesetting) => (
    //     <div className="PoolPortal_DataCell">{row.containerName}</div>
    //   ),
    // },
    {
      title: "Container Endpoint",
      prop: "containerEndpoint" as keyof PoolPortalOwnerstoragesetting,
      isFilterable: true,
      isSortable: true,
      cell: (row: PoolPortalOwnerstoragesetting) => (
        <div className="PoolPortal_DataCell">{row.containerEndpoint}</div>
      ),
    },
  ]

  return result
}
