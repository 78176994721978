import { getLastValid, isValidArray } from './data';

/**
 * Returns last position from an array of positions.
 */
export const getLastPosition = (positions) => {
    if (isValidArray(positions)) {
        return getLastValid(positions, ['lon', 'lat']);
    }

    return null;
};
