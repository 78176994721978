import React from "react"
import { Modal, Button } from "react-bootstrap"
import { useDeleteMaintenance } from "modules/MaintenanceNotification/hooks/useDeleteMaintenance"
import Cookies from "js-cookie"
interface MaintenanceDeleteProps {
  showPreview: boolean
  handlePreviewClose: () => void
  id: string
  refreshList: () => void
  isCurrent: boolean
  startDate: string
  endDate: string
}



const MaintenanceDelete: React.FC<MaintenanceDeleteProps> = ({
  showPreview,
  handlePreviewClose,
  id,
  refreshList,
  isCurrent,
  startDate,
  endDate
}) => {
  const { deleteMaintenance, isLoading } = useDeleteMaintenance({
    handleDeleteMaintenance: () => {
      refreshList()
      handlePreviewClose()
    },
  })

  const handleDelete = () => {
    deleteMaintenance(id)
    if (isCurrent) {
      Cookies.remove("maintenance_popup")
    }
  }

  return (
    <Modal show={showPreview} onHide={handlePreviewClose}>
      <Modal.Header closeButton>
        <Modal.Title>Maintenance Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <p>Are you sure you want to delete this record?</p>
          <p>Start Date: {new Date(startDate).toLocaleString()}</p>
          <p>End Date: {new Date(endDate).toLocaleString()}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handlePreviewClose}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isLoading}>
          {isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Deleting
            </>
          ) : (
            <>Delete</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MaintenanceDelete
