import {
  PortPredictorPortsData,
  PortPredictorVesselStateDataWithMajorCargo,
  VesselActivity,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import { Form, Spinner } from "react-bootstrap"
import Select, { SingleValue } from "react-select"
import {
  PortPredictorPortSelectOption,
  PortPredictorPortTypeSelectOption,
} from "./PortPredictorArrivalInputs"
import { matchPortNameWithMasterDestPortToFind } from "modules/PortPredictor/hooks/useJourneyStart"

interface PortPredictorPortsSelectorProps {
  isPortsLoading: boolean
  isPortsError: boolean
  portsData: PortPredictorPortsData[] | undefined
  chosenPort: PortPredictorPortsData | undefined
  chosenCargo: string | undefined
  isPortTurnAroundTimeLoading: boolean
  setChosenPort: (port: PortPredictorPortsData | undefined) => void
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
  chosenPortType: PortPredictorPortTypeSelectOption | undefined
}

export function PortPredictorPortsSelector({
  isPortsLoading,
  isPortsError,
  portsData,
  chosenPort,
  setChosenPort,
  isPortTurnAroundTimeLoading,
  chosenCargo,
  chosenVesselStateData,
  chosenPortType,
}: PortPredictorPortsSelectorProps) {
  const isChosenPortAlsoMasterDest = getIsChosenPortAlsoMasterDest({
    masterDest: chosenVesselStateData?.last_master_destination_desc,
    chosenPort: chosenPort?.port,
  })
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center">
        <Form.Label className="PortPredictor_FormLabel fw-bold">
          <>Port</>
        </Form.Label>
        {isPortsError && (
          <div>
            There was an error loading available ports, please contact support.
          </div>
        )}
        <Select
          placeholder={
            isPortsLoading && portsData === undefined ? (
              <div>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  variant="light"
                />
                <span className="ms-1">Loading Ports...</span>
              </div>
            ) : (
              "Select Port"
            )
          }
          className="PortPredictor_InputGroup"
          options={portsData?.map((port) => {
            return { label: port.port, value: port.locode }
          })}
          value={
            chosenPort && {
              label:
                chosenPort.port +
                `${isChosenPortAlsoMasterDest ? " (AIS Master Dest.)" : ""}`,
              value: chosenPort.locode,
            }
          }
          onChange={(
            selectedPort: SingleValue<PortPredictorPortSelectOption>
          ) => {
            if (!selectedPort || !portsData) {
              return
            }
            const chosen = portsData.find(
              (port) => port.locode === selectedPort.value
            )
            setChosenPort(chosen)
          }}
        />
      </div>
      {chosenPort &&
        chosenCargo &&
        isPortTurnAroundTimeLoading &&
        chosenPortType?.activity !== VesselActivity.Other && (
          <div className="PortPredictor_InputWrapper mt-2">
            <Spinner as="span" animation="border" size="sm" variant="light" />
            <span className="ms-1">
              Loading Port Turn Around Time for Last 10 Vessels
            </span>
          </div>
        )}
    </div>
  )
}

function getIsChosenPortAlsoMasterDest({
  masterDest,
  chosenPort,
}: {
  masterDest: string | undefined
  chosenPort: string | undefined
}) {
  if (!masterDest || !chosenPort) {
    return false
  }
  return matchPortNameWithMasterDestPortToFind({
    portName: chosenPort,
    portToFind: masterDest,
  })
}
