import { useState } from "react"
import { useDispatch } from "react-redux"
import Select, { SingleValue } from "react-select"
import { setDefaultSegment } from "../../../user/userSlice"
import { VesselListSingle, VesselSegmentInputSelection } from "./PrevetMain"
import { VesselSegment } from "user/userService"
export interface ChosenVesselForSelect {
  imo: number
}

interface SelectOption {
  label: string
  value: string
}
interface PrevetVesselSearchProps {
  vesselsData: VesselListSingle[] | undefined
  defaultSegment: VesselSegmentInputSelection
  getVesselsLoading: boolean
  getVesselDetailsLoading: boolean
  chosenSegment: VesselSegmentInputSelection
  getChosenVessel: (args: ChosenVesselForSelect) => void
  setChosenSegment: (segment: VesselSegmentInputSelection) => void
  getVessels: ({ segment }: { segment: string }) => void
  vesselSegments: VesselSegmentInputSelection[]
  clearChosenVesselData: () => void
  getSegmentSensitivityData: (segment: string) => void
}

export function PrevetVesselSearch({
  vesselsData,
  getVesselsLoading,
  getVesselDetailsLoading,
  chosenSegment,
  getChosenVessel,
  defaultSegment,
  setChosenSegment,
  getVessels,
  vesselSegments,
  clearChosenVesselData,
  getSegmentSensitivityData,
}: PrevetVesselSearchProps) {
  const dispatch = useDispatch()
  const [chosenVessel, setChosenVessel] = useState<SelectOption | undefined>()

  return (
    <>
      <div className="VesselSearch_Label">
        <h5 className="mb-0">Search Vessel</h5>
      </div>
      <Select
        className="VesselSearch_Name"
        isDisabled={getVesselDetailsLoading || getVesselsLoading}
        options={vesselsData}
        value={chosenVessel ? chosenVessel : null}
        placeholder={
          getVesselsLoading
            ? `Loading ${chosenSegment.label} vessels...`
            : "Search by Vessel Name / IMO Number"
        }
        onChange={(vessel: SingleValue<SelectOption>) => {
          if (!vessel) {
            return
          }
          clearChosenVesselData()
          setChosenVessel(vessel)
          getSegmentSensitivityData(chosenSegment.value)
          getChosenVessel({
            imo: Number(vessel.value),
          })
        }}
      />
      <div className="VesselSearch_Segment">
        <Select
          defaultValue={defaultSegment}
          isDisabled={getVesselDetailsLoading || getVesselsLoading}
          options={vesselSegments}
          onChange={(segment: SingleValue<VesselSegmentInputSelection>) => {
            if (!segment) {
              return
            }
            setChosenVessel(undefined)
            clearChosenVesselData()
            setChosenSegment(segment)
            dispatch(setDefaultSegment(segment.value as VesselSegment))
            getVessels({ segment: segment.value })
          }}
        />
      </div>
    </>
  )
}
