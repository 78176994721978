import * as Yup from "yup"
import { AdminSettingsEnum, ContentType, MonthObject, PoolPortalSingleTypes, YearObject } from "./helpers/types"

export type PoolType = ContentType | AdminSettingsEnum


export const FormatEnumToString = <T extends PoolType>(enumValue: T): string => {
  const enumString = enumValue.toString()
  const formattedString = enumString
    .replace(/_/g, ' ') // Replace '_' with space
    .toLowerCase()      // Convert to lowercase
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' ')

  return formattedString
}

export const GenerateRandomString = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength)
    result += characters.charAt(randomIndex)
  }
  return result
}

export const GetEntryPageState = (data: PoolPortalSingleTypes) => {
  return data &&
    (Object.keys(data).length === 0 || data.id === "")
    ? "New"
    : "Edit"
}

export const ContructPermissionName = (contentCode: string = "*", poolCode: string = "*", ownerCode: string = "*", vesselCode: string = "*") => {
  let permissionName = `${contentCode}_${poolCode}_${ownerCode}_${vesselCode}`
  if (vesselCode === "*") {
    permissionName = `${contentCode}_${poolCode}_${ownerCode}`
  }
  if (vesselCode === "*" && ownerCode === "*") {
    permissionName = `${contentCode}_${poolCode}`
  }
  if (vesselCode === "*" && ownerCode === "*" && poolCode === "*") {
    permissionName = `${contentCode}`
  }
  if (vesselCode === "*" && ownerCode === "*" && poolCode === "*" && contentCode === "*") {
    permissionName = ""
  }
  return permissionName
}

export const DefaultValidationSchema = (exclude: string[] = []): any => {
  const schema: any = {
    code: Yup.string()
      .required("Code is required")
      .max(25, "Code must be at most 25 characters")
      .matches(/^[^_:]*$/, 'Code must not contain "_" or ":" character'),
    name: Yup.string()
      .required("Name is required")
      .max(40, "Name must be at most 40 characters"),
    description: Yup.string()
      // .required("Description is required")
      .max(50, "Description must be at most 50 characters"),
  }

  if (exclude.length > 0) {
    return Object.keys(schema)
      .filter(key => !exclude.includes(key))
      .reduce((acc: any, key) => {
        acc[key] = schema[key]
        return acc
      }, {})
  }

  return schema
}


export const RemoveDuplicates = (inputString: string): string => {
  // Split the input string into an array using ","
  const valuesArray = inputString.split(",")

  // Convert the array into a Set to remove duplicates
  const uniqueValues = Array.from(new Set(valuesArray))

  // Join the unique values back into a string using ","
  const uniqueString = uniqueValues.join(",")

  return uniqueString
}

export const GenerateYearArray = (): YearObject[] => {
  const currentYear = new Date().getFullYear()
  const years: YearObject[] = []

  for (let year = currentYear; year >= 2012; year--) {
    years.push({ id: year.toString(), name: year.toString() })
  }

  return years
}

export const GenerateMonthArray = (): MonthObject[] => {
  const months: MonthObject[] = []
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]

  for (let month = 0; month < 12; month++) {
    months.push({ id: (month + 1).toString(), name: monthNames[month] })
  }

  return months
}

export const GetMonthLabel = (monthNumber: number | null | undefined | string): string => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]

  const parsedMonth = parseInt(monthNumber as string)
  if (isNaN(parsedMonth) || parsedMonth < 1 || parsedMonth > 12) {
    return ""
  }

  return months[parsedMonth - 1]
}

export const GetLatestDateInUserTimezone = (createdAt: string, updatedAt: string): string => {
  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt);

  // Determine the latest date
  const latestDate = createdDate > updatedDate ? createdDate : updatedDate;

  return latestDate.toLocaleString();
}