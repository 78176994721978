import React, {useEffect} from "react"
import { Offcanvas } from "react-bootstrap"
import MaintenanceForm from "./MaintenanceForm"

interface MaintenanceOffcanvasProps {
  show: boolean
  handleClose: () => void
  handleShow?: () => void
  startDate: string
  endDate: string
  message: string
  setStartDate: (date: string) => void
  setEndDate: (date: string) => void
  setMessage: (message: string) => void
  handleEditorChange: ({ html, text }: { html: string; text: string }) => void
  handlePreviewShow: () => void
  refreshMaintenanceList: () => void
  mode: "create" | "edit"
  id?: string
}

const MaintenanceOffcanvas: React.FC<MaintenanceOffcanvasProps> = ({
  show,
  handleClose,
  startDate,
  endDate,
  message,
  setStartDate,
  setEndDate,
  setMessage,
  handleEditorChange,
  handlePreviewShow,
  refreshMaintenanceList,
  mode,
  id,
}) => {
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      className="custom-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{mode === "edit" ? "Edit Maintenance" : "Add Maintenance"}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="d-flex flex-row align-items-center justify-content-between mt-3 mx-1">
          <h5>{mode === "edit" ? "Edit Maintenance" : "Add Maintenance"}</h5>
          <a onClick={handleClose}>
            <i className="ti-close text-faded" style={{fontSize: '16px', fontWeight: 'bold'}}/>
          </a>
        </div>
        <MaintenanceForm
          startDate={startDate}
          endDate={endDate}
          message={message}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setMessage={setMessage}
          handleEditorChange={handleEditorChange}
          handleClose={handleClose}
          handlePreviewShow={handlePreviewShow}
          refreshMaintenanceList={refreshMaintenanceList}
          mode={mode}
          id={id}
        />
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default MaintenanceOffcanvas
