import { Menu, MenuItem, Sidebar } from "react-pro-sidebar"
import { Link, useLocation } from "react-router-dom"
import { useGetUserPermissionsQuery } from "user/userService"
import { renderProductsMenu } from "./ProductsMenu"
import { RootState } from "store"
import { useSelector } from "react-redux"
import MaintenanceSidebarItem from "modules/MaintenanceNotification/sidebar/MaintenanceSidebarItem"

export function AppSidebar() {
  const { data: permissions } = useGetUserPermissionsQuery()
  const appRoutes = useSelector((state: RootState) => state.user.appRoutes)

  const { pathname: currentPath } = useLocation()

  return (
    <>
      {permissions && (
        <Sidebar className="mm-sidebar">
          <div className="Sidebar_SectionTitle">PRODUCTS</div>
          <Menu closeOnClick={false}>
            {appRoutes &&
              renderProductsMenu({
                currentPath,
                permissions,
                menuItems: appRoutes,
                level: 1,
              })}
              <MaintenanceSidebarItem/>
            {permissions && permissions.includes("read:internal") && (
              <>
                <div className="Sidebar_SectionTitle">INTERNAL</div>
                <MenuItem
                  active={currentPath === "/version"}
                  icon={<i className="ti-target"></i>}
                  component={<Link to="/version" />}
                >
                  <div>Version</div>
                </MenuItem>
                <MenuItem
                  active={currentPath === "/playground"}
                  icon={<i className="ti-target"></i>}
                  component={<Link to="/playground" />}
                >
                  <div>Playground</div>
                </MenuItem>
              </>
            )}
          </Menu>
        </Sidebar>
      )}
    </>
  )
}
