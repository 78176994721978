import React, { useEffect, useRef } from "react"
import { Marker as LeafletMarker, useMapEvent } from "react-leaflet"

import L, { PointTuple } from "leaflet"

interface MarkerProps {
  lon: number
  lat: number
  iconType: "currPos" | "futurePort" | "priorPort"
  children: JSX.Element
  openPopup?: boolean
}

const iconPriorPort = {
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png",
}

const iconCurrPos = {
  iconUrl: "images/marker-circle.svg",
  iconSize: [25, 25] as PointTuple,
}

export function Marker({
  lon,
  lat,
  openPopup,
  iconType,
  children,
}: MarkerProps) {
  const markerRef = useRef<L.Marker>(null)

  const map = useMapEvent("zoomend", () => {})
  map.setView([lat, lon], map.getZoom())

  useEffect(() => {
    if (markerRef.current) {
      if (iconType === "currPos") {
        markerRef.current.setIcon(new L.Icon(iconCurrPos))
      }
      if (iconType === "priorPort") {
        markerRef.current.setIcon(new L.Icon(iconPriorPort))
      }
    }
  }, [iconType])

  useEffect(() => {
    if (markerRef.current && openPopup) {
      markerRef.current.openPopup()
    }
  }, [openPopup])

  return (
    <>
      <LeafletMarker ref={markerRef} position={[lat, lon]}>
        {children}
      </LeafletMarker>
    </>
  )
}
