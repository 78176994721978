import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { PoolPortalOwnerstoragesetting } from '../helpers/types'
import axios from 'axios'
import { toast } from 'react-toastify'
import OwnerstoragesettingService from './ownerstoragesettingsService'

export const useOwnerstoragesettings = () => {
  const queryClient = useQueryClient()
  const ownerstoragesettingService = OwnerstoragesettingService()

  // Fetch ownerstoragesettings
  const { data: ownerstoragesettings, isLoading } = useQuery(['ownerstoragesettings'], ownerstoragesettingService.getOwnerstoragesettings)


  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newOwnerstoragesetting: PoolPortalOwnerstoragesetting }) =>
      ownerstoragesettingService.createOwnerstoragesetting(variables.newOwnerstoragesetting),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ownerstoragesettings'])
      },
      onError: (error: Error) => {
        console.error("Error during createOwnerstoragesetting:", error.message)
      },
    })

  const handleCreate = async (newOwnerstoragesetting: PoolPortalOwnerstoragesetting): Promise<PoolPortalOwnerstoragesetting> => {
    try {
      const createdOwnerstoragesetting = await createMutation.mutateAsync({
        newOwnerstoragesetting,
      })
      return createdOwnerstoragesetting as PoolPortalOwnerstoragesetting // Explicitly cast to PoolPortalOwnerstoragesetting
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create ownerstoragesetting - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { id: string, updatedOwnerstoragesetting: PoolPortalOwnerstoragesetting }) => ownerstoragesettingService.updateOwnerstoragesetting(variables.id, variables.updatedOwnerstoragesetting), {
    onSuccess: () => {
      queryClient.invalidateQueries(['ownerstoragesettings'])
    },
  })

  const handleUpdate = async (id: string, updatedOwnerstoragesetting: PoolPortalOwnerstoragesetting): Promise<PoolPortalOwnerstoragesetting> => {
    try {
      const result = await updateMutation.mutateAsync({ id, updatedOwnerstoragesetting })
      return result as PoolPortalOwnerstoragesetting
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to update ownerstoragesetting - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(ownerstoragesettingService.deleteOwnerstoragesetting, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ownerstoragesettings'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete ownerstoragesetting - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    ownerstoragesettings,
    isLoading,
    createOwnerstoragesetting: handleCreate,
    updateOwnerstoragesetting: handleUpdate,
    deleteOwnerstoragesetting: handleDelete
  }
}
