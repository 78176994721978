import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

interface EditMaintenanceParams {
  id: string
  maintenanceStart: string
  maintenanceEnd: string
  maintenanceMesasge: string
}

interface UseEditMaintenanceProps {
  handleEditMaintenance: (data: any) => void
}

export function useEditMaintenance({
  handleEditMaintenance,
}: UseEditMaintenanceProps) {
  const request = useAxiosRequest()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const editMaintenance = async (params: EditMaintenanceParams) => {
    try {
      setIsLoading(true)
      const response = await request<any, unknown>({
        url: `/api/v1/maintenance/single/${params.id}`,
        method: "PUT",
        data: params,
      })

      if (handleEditMaintenance) {
        handleEditMaintenance(response?.data)
      }

      toast.success("Maintenance updated successfully", {
        position: "bottom-right",
        autoClose: 8000,
      })
    } catch (err: unknown) {
      let message = "Error updating maintenance"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        const statusCode = err?.response?.status
        if (statusCode == 409) {
          message = "Maintenance overlaps with existing event"
        }
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      if (isMounted.current) {
        toast.error(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false)
      }
    }
  }

  return { editMaintenance, isLoading }
}
