import BigNumber from "bignumber.js"
import ReactEcharts, { EChartsInstance } from "echarts-for-react"
import { Modal, Row } from "react-bootstrap"
import {
  average as findAverage,
  max as findMax,
  min as findMin,
} from "simple-statistics"
import { getSpeedChartOptions } from "../helpers/getSpeedChartOptionsNew"
import { useFetchVesselLegs } from "../hooks/useFetchVesselLegs"
import { SpeedChartClickEvent } from "./PrevetVesselSpeedChart"

interface PrevetSpeedGraphModalProps {
  vesselName: string
  isSpeedGraphModalShown: boolean
  closeSpeedGraphModal: () => void
  vesselImo: number
  userLadenVesselSpeed: string | undefined
  userBallastVesselSpeed: string | undefined
  setIsTradingModalShown: React.Dispatch<React.SetStateAction<boolean>>
  setDateForCenterMarker: React.Dispatch<
    React.SetStateAction<undefined | string>
  >
}

export function PrevetSpeedGraphModal({
  vesselName,
  vesselImo,
  isSpeedGraphModalShown,
  closeSpeedGraphModal,
  userLadenVesselSpeed,
  userBallastVesselSpeed,
  setIsTradingModalShown,
  setDateForCenterMarker,
}: PrevetSpeedGraphModalProps) {
  const monthsAgoFromToday = 6
  const { vesselLegsData, isVesselLegsLoading, isVesselLegsError } =
    useFetchVesselLegs({
      imo: vesselImo,
    })

  if (!vesselLegsData) {
    return <></>
  }

  const ladenLegs = vesselLegsData.filter((data) => data.laden === true)

  const ballastLegs = vesselLegsData.filter((data) => data.laden === false)

  const ladenData = getSpeedChartOptions({
    vesselName,
    speedDataRaw: ladenLegs,
    ladenStatus: "laden",
    months: monthsAgoFromToday,
    showAllZones: true,
    userVesselSpeed: userLadenVesselSpeed,
  })

  const ballastData = getSpeedChartOptions({
    vesselName,
    speedDataRaw: ballastLegs,
    ladenStatus: "ballast",
    months: monthsAgoFromToday,
    showAllZones: true,
    userVesselSpeed: userBallastVesselSpeed,
  })

  return (
    <Modal size="xl" show={isSpeedGraphModalShown}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="OverviewModalLabel">
          Speed Chart: {vesselName} ({vesselImo})
        </h5>
        <button
          onClick={() => {
            closeSpeedGraphModal()
          }}
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="PortPredictor_SpeedGraphModal">
        <Row>
          <ReactEcharts
            style={{
              height: "348px",
            }}
            className="PrevetVesselSpeedChart"
            option={ladenData}
            onEvents={{
              click: (event: SpeedChartClickEvent) => {
                setIsTradingModalShown(true)
                setDateForCenterMarker(event.data.pos_date)
              },
              dataZoom: (event: any, echart: EChartsInstance) => {
                const opts = echart.getOption()
                const startIndex = opts.dataZoom[0].startValue
                const endIndex = opts.dataZoom[0].endValue

                const min = findMin(
                  ladenLegs
                    .slice(startIndex, endIndex + 1)
                    .map((item) => item.sog)
                )
                const max = findMax(
                  ladenLegs
                    .slice(startIndex, endIndex + 1)
                    .map((item) => item.sog)
                )
                const avgCalc = findAverage(
                  ladenLegs
                    .slice(startIndex, endIndex + 1)
                    .map((item) => item.sog)
                )

                const avg = BigNumber(avgCalc)
                  .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                  .toNumber()

                echart.setOption({
                  ...opts,
                  title: {
                    ...opts.title,
                    text: `6 Months LADEN (Kt)  Min: ${min}  Max: ${max}  Avg: ${avg}`,
                  },
                })
              },
            }}
          />
        </Row>
        <Row>
          <ReactEcharts
            style={{
              height: "348px",
            }}
            className="PrevetVesselSpeedChart"
            option={ballastData}
            onEvents={{
              click: (event: SpeedChartClickEvent) => {
                setIsTradingModalShown(true)
                setDateForCenterMarker(event.data.pos_date)
              },
              dataZoom: (event: any, echart: EChartsInstance) => {
                const opts = echart.getOption()
                const startIndex = opts.dataZoom[0].startValue
                const endIndex = opts.dataZoom[0].endValue
                const ballastLegs = vesselLegsData.filter(
                  (data) => data.laden === false
                )

                const min = findMin(
                  ballastLegs
                    .slice(startIndex, endIndex + 1)
                    .map((item) => item.sog)
                )
                const max = findMax(
                  ballastLegs
                    .slice(startIndex, endIndex + 1)
                    .map((item) => item.sog)
                )
                const avgCalc = findAverage(
                  ballastLegs
                    .slice(startIndex, endIndex + 1)
                    .map((item) => item.sog)
                )

                const avg = BigNumber(avgCalc)
                  .decimalPlaces(1, BigNumber.ROUND_HALF_CEIL)
                  .toNumber()

                echart.setOption({
                  ...opts,
                  title: {
                    ...opts.title,
                    text: `6 Months BALLAST (Kt)  Min: ${min}  Max: ${max}  Avg: ${avg}`,
                  },
                })
              },
            }}
          />
        </Row>
      </div>
    </Modal>
  )
}
