import { ErrorBoundary } from "ErrorBoundary"
import { useRef, useState } from "react"
import { Button, Card, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { RootState } from "store"

import { usePrevetGetSensitivityDataForSegment } from "../hooks/usePrevetGetSensitivityDataForSegment"
import { usePrevetGetVessels } from "../hooks/usePrevetGetVessels"

import { VesselSegment } from "user/userService"
import { useFetchVesselDetails } from "../hooks/useFetchVesselDetails"
import {
  DDCategories,
  VesselStay,
  useFetchVesselStays,
} from "../hooks/useFetchVesselStays"
import { usePrevetGetRightShipData } from "../hooks/usePrevetGetRightShipData"
import { PrevetDryDockBadge } from "./PrevetDryDockBadge"
import { PrevetLastFiveCargoes } from "./PrevetLast5Cargoes"
import { PrevetPortStays } from "./PrevetPortStays"
import { PrevetPortStaysCautionMessage } from "./PrevetPortStaysCautionMessage"
import { PrevetRightShip } from "./PrevetRightShip"
import {
  PrevetSensitivityCalculator,
  getAvgSpeedFromPortStays,
} from "./PrevetSensitivityCalculator"
import { PrevetVesselHistory } from "./PrevetVesselHistory"
import { ChosenVesselForSelect, PrevetVesselSearch } from "./PrevetVesselSearch"
import { PrevetVesselSpeedWrapper } from "./PrevetVesselSpeedWrapper"
import { PrevetVesselTradingModal } from "./PrevetVesselTradingModal"

export type ImoNumber = string

export interface VesselListSingle {
  label: string
  value: ImoNumber
}

export interface VesselSegmentInputSelection {
  label: string
  value: VesselSegment
}

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div>
      <p>Sorry, we are having technical issues, please contact support.</p>
      <pre>{error.message}</pre>
    </div>
  )
}

export function PrevetMain() {
  const user = useSelector((state: RootState) => state.user)
  const vesselSegments = user.chosenOrganization?.segments

  const defaultSegment = user.defaultSegment

  const [chosenSegment, setChosenSegment] =
    useState<VesselSegmentInputSelection | null>(
      defaultSegment ? { value: defaultSegment, label: defaultSegment } : null
    )

  const { vesselsData, getVessels, getVesselsLoading } = usePrevetGetVessels({
    defaultSegment,
  })

  const {
    vesselDetailsData,
    clearVesselDetailsData,
    fetchVesselDetails,
    getVesselDetailsLoading,
    getVesselDetailsError,
  } = useFetchVesselDetails()

  /**
   * Port Stays Data
   */
  const { vesselStaysData, isVesselStaysLoading, isVesselStaysError } =
    useFetchVesselStays({
      imo: vesselDetailsData?.imo,
      built: vesselDetailsData?.built,
    })
  const portStaysLadenAvgSpeed = getAvgSpeedFromPortStays({
    vesselStaysData,
    isLaden: true,
  })

  const portStaysBallastAvgSpeed = getAvgSpeedFromPortStays({
    vesselStaysData,
    isLaden: false,
  })

  /**
   * Speed Data
   * VesselLegs data is fetched from PrevetVesselSpeedChart
   */
  const [ladenAvgSpeed, setLadenAvgSpeed] = useState<number | undefined>()
  const [ballastAvgSpeed, setBallastAvgSpeed] = useState<number | undefined>()
  const [ladenMaxSpeed, setLadenMaxSpeed] = useState<number | undefined>()
  const [ballastMaxSpeed, setBallastMaxSpeed] = useState<number | undefined>()
  const [userLadenVesselSpeed, setUserLadenVesselSpeed] = useState<
    string | undefined
  >()
  const [userBallastVesselSpeed, setUserBallastVesselSpeed] = useState<
    string | undefined
  >()

  /**
   * Sensitivity
   */
  const { getSegmentSensitivityData, segmentSensitivityData } =
    usePrevetGetSensitivityDataForSegment()

  const ladenChartInstance = useRef<any>()
  const ballastChartInstance = useRef<any>()

  /**
   * Right Ship
   */
  const { rightShipData, rightShipDataLoading } = usePrevetGetRightShipData({
    imo: Number(vesselDetailsData?.imo),
  })
  const rightShipVesselInfo = rightShipData?.items[0]

  /**
   * Trading Map
   */
  const [isTradingModalShown, setIsTradingModalShown] = useState(false)
  const [dateForCenterMarker, setDateForCenterMarker] = useState<
    string | undefined
  >()

  return (
    <div>
      {vesselStaysData &&
        vesselDetailsData &&
        isTradingModalShown &&
        chosenSegment && (
          <PrevetVesselTradingModal
            chosenSegment={chosenSegment}
            closeTradingModal={() => {
              setIsTradingModalShown(false)
              setDateForCenterMarker(undefined)
            }}
            isTradingModalShown={isTradingModalShown}
            vesselDetails={vesselDetailsData}
            vesselStays={vesselStaysData}
            dateForCenterMarker={dateForCenterMarker}
          />
        )}
      <Card className="PrevetMain_SearchBar">
        <Card.Body>
          <div className="VesselSearch_Row">
            {defaultSegment && chosenSegment && vesselSegments && (
              <PrevetVesselSearch
                vesselsData={vesselsData}
                defaultSegment={{
                  label: defaultSegment,
                  value: defaultSegment,
                }}
                getVesselsLoading={getVesselsLoading}
                getVesselDetailsLoading={getVesselDetailsLoading}
                chosenSegment={chosenSegment}
                getChosenVessel={({ imo }: ChosenVesselForSelect) => {
                  fetchVesselDetails(imo)
                  setUserLadenVesselSpeed(undefined)
                  setUserBallastVesselSpeed(undefined)
                }}
                setChosenSegment={(segment: VesselSegmentInputSelection) => {
                  setChosenSegment(segment)
                }}
                getVessels={getVessels}
                vesselSegments={vesselSegments.map((segment) => {
                  return { label: segment, value: segment }
                })}
                clearChosenVesselData={() => {
                  clearVesselDetailsData()
                  setBallastAvgSpeed(undefined)
                  setLadenAvgSpeed(undefined)
                  setBallastMaxSpeed(undefined)
                  setLadenMaxSpeed(undefined)
                }}
                getSegmentSensitivityData={(segment: string) => {
                  getSegmentSensitivityData(segment)
                }}
              />
            )}
            <div className="VesselSearch_ButtonContainer">
              <Button
                color="primary"
                className="btn btn-primary w-100 waves-effect waves-light"
                disabled={!vesselDetailsData || !vesselStaysData}
                onClick={() => {
                  setIsTradingModalShown(true)
                }}
              >
                {getVesselDetailsLoading || getVesselsLoading ? (
                  <Spinner
                    size="sm"
                    variant="light"
                    animation="border"
                    role="status"
                  />
                ) : (
                  <div>
                    <span className="me-2">
                      <i className="ti-eye" />
                    </span>
                    Info & Trading
                  </div>
                )}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {!vesselDetailsData && !getVesselDetailsError && (
          <div role="alert" className="fade bg-ifp mb-0 alert alert-info show">
            Please select a vessel
          </div>
        )}
        {getVesselDetailsError && (
          <div role="alert" className="fade bg-ifp mb-0 alert alert-info show">
            Unable to get vessel, please contact support.
          </div>
        )}
        {vesselDetailsData && chosenSegment && (
          <div>
            {/* This h3 is for the print view only */}
            <h3 className="PrevetTrio_ShipNameImo">
              {vesselDetailsData.vessel_name} | {vesselDetailsData.imo}
            </h3>
            <div className="PrevetTrio">
              <div className="PrevetTrio_PortStays d-flex flex-column">
                <PrevetPortStays
                  vesselDetailsData={vesselDetailsData}
                  vesselStaysData={vesselStaysData}
                  isVesselStaysLoading={isVesselStaysLoading}
                  isVesselStaysError={isVesselStaysError}
                />
                <div className="PrevetTrio_RightShip">
                  <div style={{ fontSize: "0.8rem" }}>
                    {vesselStaysData && (
                      <PrevetPortStaysCautionMessage
                        cautionStay={getCautionStay(vesselStaysData)}
                      />
                    )}
                    {vesselStaysData && (
                      <PrevetDryDockBadge
                        vesselDetails={vesselDetailsData}
                        vesselStays={vesselStaysData}
                      />
                    )}
                  </div>
                  {rightShipDataLoading && (
                    <Card className="pt-5 pb-5 mt-3 mb-0 d-flex align-items-center">
                      <Spinner animation="border" role="status" />
                      <div className="mt-4">Loading RightShip Data...</div>
                    </Card>
                  )}
                  {rightShipVesselInfo && (
                    <PrevetRightShip
                      rightShipVesselInfo={rightShipVesselInfo}
                    />
                  )}
                </div>
              </div>
              <Card className="PrevetTrio_SpeedGraph mb-0 ms-3 me-3">
                <PrevetVesselSpeedWrapper
                  vesselName={vesselDetailsData.vessel_name}
                  vesselImo={vesselDetailsData.imo}
                  setIsTradingModalShown={setIsTradingModalShown}
                  setDateForCenterMarker={setDateForCenterMarker}
                  setLadenAvgSpeed={setLadenAvgSpeed}
                  setBallastAvgSpeed={setBallastAvgSpeed}
                  setLadenMaxSpeed={setLadenMaxSpeed}
                  setBallastMaxSpeed={setBallastMaxSpeed}
                  ladenChartInstance={ladenChartInstance}
                  ballastChartInstance={ballastChartInstance}
                  userLadenVesselSpeed={userLadenVesselSpeed}
                  userBallastVesselSpeed={userBallastVesselSpeed}
                />
              </Card>
              <div className="PrevetTrio_Sensitivity d-flex flex-column">
                <Card className="mb-0 flex-grow-1">
                  <Card.Body>
                    <PrevetSensitivityCalculator
                      ladenChartInstance={ladenChartInstance}
                      ballastChartInstance={ballastChartInstance}
                      speedGraphLadenAvgSpeed={ladenAvgSpeed}
                      speedGraphBallastAvgSpeed={ballastAvgSpeed}
                      portStaysLadenAvgSpeed={portStaysLadenAvgSpeed}
                      portStaysBallastAvgSpeed={portStaysBallastAvgSpeed}
                      realLadenMaxSpeed={ladenMaxSpeed}
                      realBallastMaxSpeed={ballastMaxSpeed}
                      segmentSensitivityData={segmentSensitivityData}
                      vesselName={vesselDetailsData.vessel_name}
                      defaultFuelCon={getFuelConBySegment(chosenSegment)}
                      setUserLadenVesselSpeed={(speed: string | undefined) => {
                        setUserLadenVesselSpeed(speed)
                      }}
                      setUserBallastVesselSpeed={(
                        speed: string | undefined
                      ) => {
                        setUserBallastVesselSpeed(speed)
                      }}
                    />
                  </Card.Body>
                </Card>
                {vesselStaysData && (
                  <Card className="mb-0 mt-3">
                    <Card.Body className="pt-3 pb-3">
                      <PrevetLastFiveCargoes
                        vesselStaysData={vesselStaysData}
                      />
                    </Card.Body>
                  </Card>
                )}
              </div>
            </div>
            <div className="PrevetChosenVesselData_Row mt-3">
              <Card>
                <Card.Body>
                  {isVesselStaysLoading && (
                    <div className="d-flex flex-column align-items-center">
                      <Spinner
                        className="mb-3"
                        animation="border"
                        role="status"
                      />
                      <div>Loading historical vessel data...</div>
                    </div>
                  )}
                  {isVesselStaysError && (
                    <div className="d-flex justify-content-center">
                      Error loading historical vessel data.
                    </div>
                  )}
                  {vesselStaysData && (
                    <PrevetVesselHistory vesselStays={vesselStaysData} />
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </ErrorBoundary>
    </div>
  )
}

function getCautionStay(vesselStays: VesselStay[]) {
  const caution = vesselStays
    .filter((stay) => {
      if (stay.yard_activity === "R") {
        return true
      }
      if (stay.yard_activity && DDCategories.includes(stay.yard_activity)) {
        return false
      }
      if (stay.duration >= 10) {
        return true
      }
      return false
    })
    .sort((a, b) => {
      // The API serves up values sorted in descending order and this step should not be necessary
      const firstDate = new Date(a.date_to)
      const secondDate = new Date(b.date_to)
      return Number(secondDate) - Number(firstDate)
    })

  return caution.length > 0 ? caution[0] : null
}

function getFuelConBySegment(segment: VesselSegmentInputSelection) {
  if (segment.value === VesselSegment.Handysize) {
    return 18
  }
  if (segment.value === VesselSegment.Supramax) {
    return 23
  }
  if (segment.value === VesselSegment.Panamax) {
    return 25
  }
  // Otherwise chosen segment is capesize
  return 43
}
