import { useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"

export function useFetchBerths({ portName }: { portName: string | undefined }) {
  const { getBerths } = usePortPredictorAPI()

  const {
    isLoading: isBerthsLoading,
    isError: isBerthsError,
    isSuccess: isBerthsSuccess,
    data: berthsData,
  } = useQuery({
    queryKey: [`portPredictor/getBerths/${portName}`],
    queryFn: ({ queryKey }) => {
      const res = getBerths({ portName } as { portName: string })
      return res
    },
    staleTime: 60000, // 10 minutes
    enabled: !!portName
  })

  return {
    berthsData,
    isBerthsLoading,
    isBerthsError,
    isBerthsSuccess,
  }
}
