import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { useAxiosRequest } from "api/axiosRequest"
import { useCallback, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { VesselSegment } from "user/userService"

export interface VesselListSingle {
  label: string
  value: string
}

export function usePrevetGetVessels({
  defaultSegment,
}: {
  defaultSegment: VesselSegment | null
}) {
  const [vesselsData, setVesselsData] = useState<
    VesselListSingle[] | undefined
  >()
  const [getVesselsLoading, setVesselsLoading] = useState(false)
  let mounted = useRef(true)
  const request = useAxiosRequest()

  useEffect(() => {
    if (!defaultSegment) {
      return
    }
    const controller = new AbortController()

    getVessels({ segment: defaultSegment, controller })

    return () => {
      mounted.current = false
      controller.abort()
    }
  }, [defaultSegment])

  async function getVessels({
    segment,
    controller,
  }: {
    segment: string
    controller?: AbortController
  }) {
    if (mounted.current) {
      setVesselsLoading(true)
      setVesselsData(undefined)
    }
    try {
      const responseBody = await request<any, VesselListSingle[]>({
        signal: controller?.signal,
        url: `${BASE_URL}/prevetting/vessels`,
        params: { segment },
      })
      if (mounted.current && responseBody) {
        setVesselsData(responseBody.data)
      }
    } catch (err: unknown) {
      if (mounted.current) {
        if (axios.isAxiosError(err)) {
          toast.warn(`Unable to load list of vessels for segment ${segment}`, {
            position: "bottom-right",
            autoClose: 8000,
          })
        }
      }
    } finally {
      if (mounted.current) {
        setVesselsLoading(false)
      }
    }
  }

  return {
    vesselsData,
    getVessels: useCallback(getVessels, []),
    getVesselsLoading,
  }
}
