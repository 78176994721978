import { useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

interface UseDeleteMaintenanceArgs {
  handleDeleteMaintenance?: (id: string) => void
}

export function useDeleteMaintenance({
  handleDeleteMaintenance,
}: UseDeleteMaintenanceArgs) {
  const request = useAxiosRequest()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  async function deleteMaintenance(id: string) {
    try {
      setIsLoading(true)
      await request<any, unknown>({
        url: `api/v1/maintenance/single`,
        method: "DELETE",
        params: { id },
      })

      if (handleDeleteMaintenance) {
        handleDeleteMaintenance(id)
      }
      
        toast.success("Maintenance deleted successfully", {
          position: "bottom-right",
          autoClose: 8000,
        })
      
    } catch (err: unknown) {
      let message = "Error deleting maintenance"
      if (axios.isAxiosError(err)) {
        const axiosErrorMessage = err?.response?.data?.error?.message
        message = axiosErrorMessage
          ? message + ": " + axiosErrorMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      if (isMounted.current) {
        toast.error(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false)
      }
    }
  }

  return { deleteMaintenance, isLoading }
}
