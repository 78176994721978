import { useProSidebar } from "react-pro-sidebar"

interface BurgerToggleProps {
  setIsSidebarCollapsed: () => void
}

const BurgerToggle = (props: BurgerToggleProps) => {
  const { collapseSidebar } = useProSidebar()

  return (
    <button
      type="button"
      className="btn btn-sm px-3 font-size-24 header-item waves-effect"
      id="vertical-menu-btn"
      onClick={() => {
        props.setIsSidebarCollapsed()
        collapseSidebar()
      }}
      data-target="#topnav-menu-content"
    >
      <i className="mdi mdi-menu"></i>
    </button>
  )
}

export default BurgerToggle
