import { PortPredictorVesselSpeed } from "modules/PortPredictor/hooks/usePortPredictorAPI"
import moment from "moment"

export interface ChosenVesselSpeedAverages {
  averageLaden: number | undefined
  averageBallast: number | undefined
  defaultMonthsAgoFromToday?: number
}

// TODO: Test these function
export function getMonthsAgoLimit(monthsAgoFromToday: number) {
  return moment(new Date()).subtract(monthsAgoFromToday, "months")
}

// TODO: Test these function
export function filterForMonths({
  vesselSpeed,
  monthsAgoLimit,
}: {
  vesselSpeed: PortPredictorVesselSpeed[]
  monthsAgoLimit: moment.Moment
}) {
  return vesselSpeed.filter((speed) => {
    const date = moment(speed.pos_date)
    return date > monthsAgoLimit
  })
}

export enum SpeedDataAvgKey {
  SixMonths = "sixMonths",
  threeMonths = "threeMonths"
}

export interface VesselSpeedDataWithAverages {
  speedData: PortPredictorVesselSpeed[]
  ballast: {
    sixMonths: {
      data: PortPredictorVesselSpeed[]
      avg: number | undefined
    }
    threeMonths: {
      data: PortPredictorVesselSpeed[]
      avg: number | undefined
    }
    defaultMonthsKey: SpeedDataAvgKey
    defaultMonths: 3 | 6
  }
  laden: {
    sixMonths: {
      data: PortPredictorVesselSpeed[]
      avg: number | undefined
    }
    threeMonths: {
      data: PortPredictorVesselSpeed[]
      avg: number | undefined
    }
    defaultMonthsKey: SpeedDataAvgKey
    defaultMonths: 3 | 6
  }
}

export function getVesselSpeedDataWithAverages(
  speedData: PortPredictorVesselSpeed[] | undefined
) {
  if (!speedData) {
    return
  }
  let defaultMonthsBallast = 3
  let defaultMonthsBallastKey = SpeedDataAvgKey.threeMonths

  let defaultMonthsLaden = 3
  let defaultMonthsLadenKey = SpeedDataAvgKey.threeMonths

  const {
    ballastData: threeMonthsBallastData,
    ladenData: threeMonthsLadenData,
    ballastAvg: threeMonthsBallastAvg,
    ladenAvg: threeMonthsLadenAvg,
  } = getDataForNumMonths({ speedData, months: 3 })
  const {
    ballastData: sixMonthsBallastData,
    ladenData: sixMonthsLadenData,
    ballastAvg: sixMonthsBallastAvg,
    ladenAvg: sixMonthsLadenAvg,
  } = getDataForNumMonths({ speedData, months: 6 })

  if (!threeMonthsBallastAvg || threeMonthsBallastData.length < 1) {
    defaultMonthsBallast = 6
    defaultMonthsBallastKey = SpeedDataAvgKey.SixMonths
  }
  if (!threeMonthsLadenAvg || sixMonthsLadenData.length < 1) {
    defaultMonthsLaden = 6
    defaultMonthsLadenKey = SpeedDataAvgKey.SixMonths
  }

  return {
    speedData,
    ballast: {
      sixMonths: {
        data: sixMonthsBallastData,
        avg: sixMonthsBallastAvg,
      },
      threeMonths: {
        avg: threeMonthsBallastAvg,
        data: threeMonthsBallastData,
      },
      defaultMonthsKey: defaultMonthsBallastKey,
      defaultMonths: defaultMonthsBallast,
    },
    laden: {
      sixMonths: {
        avg: sixMonthsLadenAvg,
        data: sixMonthsLadenData,
      },
      threeMonths: {
        avg: threeMonthsLadenAvg,
        data: threeMonthsLadenData,
      },
      defaultMonths: defaultMonthsLaden,
      defaultMonthsKey: defaultMonthsLadenKey,
    },
  } as VesselSpeedDataWithAverages
}

function getDataForNumMonths({
  speedData,
  months,
}: {
  speedData: PortPredictorVesselSpeed[]
  months: number
}) {
  const startDate = moment(new Date()).subtract(months, "months")
  const speedDataFiltered = speedData.filter((speed) => {
    const date = moment(speed.pos_date)
    return date > startDate
  })

  const ballastData = speedDataFiltered?.filter((leg) => !leg.laden)
  const ladenData = speedDataFiltered?.filter((leg) => leg.laden)
  const ballastAvg =
    ballastData &&
    ballastData.reduce((a, b) => {
      return a + b.sog
    }, 0) / ballastData.length
  const ladenAvg =
    ladenData &&
    ladenData.reduce((a, b) => {
      return a + b.sog
    }, 0) / ladenData.length

  return {
    ballastData,
    ladenData,
    ballastAvg,
    ladenAvg,
  }
}
