import React, { useEffect } from "react"
import { Spinner } from "react-bootstrap"
import MaintenanceLists from "./MaintenanceLists"

interface MaintenanceListWrapperProps {
  refresh: boolean
}

function MaintenanceListWrapper({ refresh }: MaintenanceListWrapperProps) {
  useEffect(() => {
    
  }, [refresh])

  return (
    <div className="w-100 bg-white mt-4 p-3">
      <MaintenanceLists refresh = {refresh} />
    </div>
  )
}

export default MaintenanceListWrapper
