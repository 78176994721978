import { useState } from "react"


import { ImageMap, PositronMap } from "./Map"

function MapWithStyle({ overlay, ...props }) {
  if (overlay === "positron") {
    return <PositronMap {...props} />
  }
  return <ImageMap {...props} />
}

export const MapPanel = ({
  height,
  overlays,
  className,
  children,
  ...props
}) => {
  const [overlay, setOverlay] = useState(overlays[1])

  return (
    <div className={`card ${className}`}>
      <MapWithStyle
        overlay={overlay}
        width="100%"
        height={height + "vh"}
        scrollWheelZoom
        {...props}
      >
        {children}
      </MapWithStyle>

      {/* <div className="flex align-items-start justify-content-between">
        <div className="flex justify-content-end">
          {overlays.length > 1 && (
            <div className="ml-3">
              <Select
                value={overlay.value}
                onChange={(e) => setOverlay(e.value)}
                options={overlays.map((value) => ({
                  value: value,
                  label: value,
                }))}
                placeholder="Select map overlay"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    maxWidth: "20rem",
                  }),
                }}
              />
            </div>
          )}
        </div>
      </div> */}
    </div>
  )
}

MapPanel.defaultProps = {
  height: 50,
  overlays: ["positron", "image"],
  className: "",
}

export default MapPanel
