import {
  MarkerType,
  PortPredictorJourneyMarker,
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
} from "modules/PortPredictor/portPredictorReducer"

import {
  PortPredictorTurnAroundTimeData,
  PortPredictorVesselSpeed,
  PortPredictorVesselStateDataWithMajorCargo,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import { Dispatch, SetStateAction } from "react"
import { VesselSegment } from "user/userService"
import { PortPredictorArrivalInputs } from "../PortPredictorArrival/PortPredictorArrivalInputs"
import { PortPredictorDepartureInputs } from "../PortPredictorDeparture/PortPredictorDepartureInputs"
import L, { LatLngTuple, Map as LeafletMap } from "leaflet"
import { FuturePortLoc } from "modules/PortPredictor/helpers/mapHelpers"
import { MarkerLatLon } from "../PortPredictorMain"

interface PortPredictorMarkerInputsProps {
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
  currMarker: PortPredictorJourneyMarker
  segment: VesselSegment
  prevMarker: PortPredictorJourneyMarker | undefined
  isStartOfJourney: boolean
  turnAroundTimeData: PortPredictorTurnAroundTimeData[] | undefined
  onUpdateMarker: (updatedMarker: PortPredictorJourneyMarker) => void
  onDeleteMarker: (deletedMarker: PortPredictorJourneyMarker) => void
  showSpeedGraphModal: () => void
  setTurnAroundTimeData: Dispatch<
    SetStateAction<PortPredictorTurnAroundTimeData[] | undefined>
  >
  map: LeafletMap | null
  markers: MarkerLatLon[]
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
}

export function PortPredictorMarkerInputs({
  showSpeedGraphModal,
  chosenVesselSpeedData,
  chosenVesselStateData,
  turnAroundTimeData,
  currMarker,
  segment,
  prevMarker,
  isStartOfJourney,
  onUpdateMarker,
  onDeleteMarker,
  setTurnAroundTimeData,
  map,
  markers,
}: PortPredictorMarkerInputsProps) {
  if (currMarker.markerType === MarkerType.Arrival) {
    return (
      <PortPredictorArrivalInputs
        currMarker={currMarker as PortPredictorMarkerArrival}
        prevMarker={prevMarker as PortPredictorMarkerDeparture}
        isStartOfJourney={isStartOfJourney}
        segment={segment}
        onUpdateMarker={onUpdateMarker}
        onDeleteMarker={onDeleteMarker}
        turnAroundTimeData={turnAroundTimeData}
        setTurnAroundTimeData={setTurnAroundTimeData}
        chosenVesselStateData={chosenVesselStateData}
        map={map}
        markers={markers}
      />
    )
  }
  if (currMarker.markerType === MarkerType.Departure) {
    return (
      <PortPredictorDepartureInputs
        isStartOfJourney={isStartOfJourney}
        showSpeedGraphModal={showSpeedGraphModal}
        chosenVesselSpeedData={chosenVesselSpeedData}
        currMarker={currMarker as PortPredictorMarkerDeparture}
        prevMarker={prevMarker as PortPredictorMarkerArrival}
        onUpdateMarker={onUpdateMarker}
      />
    )
  }
  return <div>Unidentified currMarker type</div>
}
