import { getMapboxConfig } from "helpers/Mapbox/helper"
import * as L from "leaflet"
import { GeodesicLine } from "leaflet.geodesic"

import React, { useEffect, useRef, useState } from "react"
import {
  CircleMarker,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet"
import { getPortStaysData } from "../helpers/getPortStaysData"
import { VesselStay } from "../hooks/useFetchVesselStays"
import { VesselPositions } from "../hooks/useFetchVesselPositions"
import moment from "moment"

interface PrevetModalMapProps {
  vesselStays: VesselStay[]
  vesselPositions: VesselPositions[]
  showPortStayMarkers: boolean
  showTradingPatternMarkers: boolean
  dateForCenterMarker: string | undefined
}
function getMarkerColor(duration: any): string {
  if (duration >= 20) {
    return "#ec4561"
  }
  if (duration >= 10 && duration <= 19) {
    return "#ffdd15"
  }
  if (duration <= 9) {
    return "#02a499"
  }
  return "FFFFFF"
}

function getCenterMarker(
  vesselPositions: VesselPositions[],
  chosenDataPoint: VesselPositions | undefined
) {
  if (vesselPositions.length < 1) {
    return [51.505, -0.09]
  }
  if (chosenDataPoint) {
    return [chosenDataPoint.lat, chosenDataPoint.lon]
  }
  return [vesselPositions[0].lat, vesselPositions[0].lon]
}

function renderPopup(
  marker: VesselPositions,
  markerColor: string,
  ladenStatus: string
) {
  return (
    <>
      {moment(marker.pos_date).format("DD-MM-YYYY")} <br />
      Speed : {marker.sog} (Kn) <br />
      <div style={{ color: `${markerColor}`, fontWeight: "bold" }}>
        {ladenStatus.toUpperCase()}
      </div>
      Destination : {marker.master_destination} <br />
      Draft Ratio : {marker.draft_ratio} <br />
      ETA : {marker.master_eta}
    </>
  )
}

type MarkerLadenOrBallastStatus = "laden" | "ballast"

function getMarkerLadenOrBallastStatus(
  marker: VesselPositions
): MarkerLadenOrBallastStatus {
  if (
    typeof marker.model_laden === "undefined" ||
    typeof marker.draft_laden === "undefined"
  ) {
    return marker.draft_ratio > 0.7 ? "laden" : "ballast"
  }

  if (marker.model_laden === true) {
    return "laden"
  }

  if (marker.model_laden === false) {
    return "ballast"
  }

  if (marker.model_laden === null && marker.draft_laden === true) {
    return "laden"
  }

  if (marker.model_laden === null && marker.draft_laden === false) {
    return "ballast"
  }

  return marker.draft_ratio > 0.7 ? "laden" : "ballast"
}

function getMarkerActivityForPortStay(activity: string) {
  if (activity === "L") {
    return "Loading"
  }
  if (activity === "D") {
    return "Discharging"
  }
  if (activity === "F") {
    return "Bunkering"
  }
}

export function PrevetModalMap({
  vesselStays,
  vesselPositions,
  showPortStayMarkers,
  showTradingPatternMarkers,
  dateForCenterMarker,
}: PrevetModalMapProps) {
  const mapboxConfig = getMapboxConfig()
  const mapboxUrl =
    "https://api.mapbox.com/styles/v1/" +
    mapboxConfig.user +
    "/" +
    mapboxConfig.style +
    "/tiles/256/{z}/{x}/{y}@2x?access_token=" +
    mapboxConfig.access_token

  const finalDateForCenterMarker =
    dateForCenterMarker === undefined
      ? vesselPositions[0].pos_date
      : dateForCenterMarker

  const chosenDataPoint = vesselPositions.filter((aisDataPoint) => {
    return aisDataPoint.pos_date === finalDateForCenterMarker
  })[0] as VesselPositions | undefined

  let chosenDataPointPopupRef = useRef<null | L.Popup>(null)

  const [chosenDataPointPopupRefReady, setChosenDataPointPopupRefReady] =
    useState(false)

  const centerMarker = getCenterMarker(vesselPositions, chosenDataPoint)

  // Need to use vessel-stay data instead
  const portStayData = vesselStays
  const tradingPolyLine: any = []

  const portStayMarkers =
    showPortStayMarkers && portStayData.length > 0
      ? portStayData.map((marker: any) => {
          const markerColor = getMarkerColor(marker.duration)

          return (
            <CircleMarker
              key={
                "port-" +
                marker.port +
                "-" +
                marker.date_from +
                "-" +
                marker.duration
              }
              center={marker}
              radius={10}
              fillOpacity={0.9}
              stroke={false}
              fillColor={markerColor}
              eventHandlers={{
                mouseover: (event: any) => event.target.openPopup(),
                mouseout: (event: any) => event.target.closePopup(),
              }}
            >
              <Popup>
                <div>Arrival: {marker.date_from}</div>
                <div>Departure: {marker.date_to}</div>
                <div className="d-flex align-items-center">
                  <span>Duration :</span>
                  <span className="ms-1 me-1">
                    {getColorCode(marker.duration)}
                  </span>
                  <span>{marker.duration} Days</span>
                  <span className="ms-1">{"("}</span>
                  <span>{getPortStayDurationText(marker.duration)}</span>
                  <span>{")"}</span>
                </div>
                <div>Port : {marker.port}</div>
                <div>
                  Activity : {getMarkerActivityForPortStay(marker.activity)}
                </div>
              </Popup>
            </CircleMarker>
          )
        })
      : null

  const tradingPatternMarkers = showTradingPatternMarkers
    ? vesselPositions.map((marker: VesselPositions, index) => {
        tradingPolyLine.push([marker.lat, marker.lon])

        const ladenStatus = getMarkerLadenOrBallastStatus(marker)
        const markerColor = ladenStatus === "laden" ? "#3050A2" : "#FF5B29"

        const userHasSelectedDataPoint =
          chosenDataPoint?.pos_date === marker.pos_date

        if (userHasSelectedDataPoint) {
          return (
            <Marker
              key={"position-" + marker.pos_date}
              eventHandlers={{
                mouseover: (event: any) => event.target.openPopup(),
                // load: (event: any) => event.target.openPopup(),
              }}
              position={{ lat: marker.lat, lng: marker.lon }}
            >
              <Popup
                ref={(popup: L.Popup | null) => {
                  if (popup) {
                    chosenDataPointPopupRef.current = popup
                    setChosenDataPointPopupRefReady(true)
                  }
                }}
                // keepInView={true}
                closeButton={true}
              >
                {renderPopup(marker, markerColor, ladenStatus)}
              </Popup>
            </Marker>
          )
        }
        return (
          <CircleMarker
            key={"position-" + marker.pos_date}
            center={{ lat: marker.lat, lng: marker.lon }}
            radius={5}
            stroke={false}
            fillOpacity={0.6}
            fillColor={markerColor}
            eventHandlers={{
              mouseover: (event: any) => event.target.openPopup(),
              mouseout: (event: any) => event.target.closePopup(),
            }}
          >
            <Popup>{renderPopup(marker, markerColor, ladenStatus)}</Popup>
          </CircleMarker>
        )
      })
    : null

  const mapRef = useRef<L.Map | undefined>()

  const geodesicPoints = vesselPositions.map((point) => {
    return new L.LatLng(point.lat, point.lon)
  })

  const geodesicInstance = new GeodesicLine([geodesicPoints], {
    color: "#a49fa9",
    dashArray: "4 4 8",
    dashOffset: "12",
    weight: 2,
  })

  const geodesicRef = useRef(geodesicInstance)

  useEffect(() => {
    if (!mapRef.current) {
      return
    }
    if (!geodesicRef.current) {
      return
    }

    geodesicRef.current.setLatLngs(geodesicPoints)
  }, [vesselPositions, geodesicPoints])

  useEffect(() => {
    if (chosenDataPointPopupRefReady && mapRef.current) {
      chosenDataPointPopupRef.current?.openOn(mapRef.current)
    }
  }, [chosenDataPointPopupRefReady])

  return (
    <React.Fragment>
      <MapContainer
        center={centerMarker as [number, number]}
        zoom={5}
        minZoom={3}
        maxZoom={30}
        scrollWheelZoom={true}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance
          geodesicRef.current.addTo(mapInstance)
        }}
      >
        <TileLayer url={mapboxUrl} />

        {portStayMarkers}

        {tradingPatternMarkers}
      </MapContainer>
    </React.Fragment>
  )
}

function getPortStayDurationText(days: number) {
  if (days < 10) {
    return <span>Short port stay</span>
  }
  if (days >= 10) {
    return <span>Prolonged port stay</span>
  }
  return <></>
}

function getColorCode(days: number) {
  const okColor = "#00763b"
  const dangerColor = "#ec4561"
  const warningColor = "#ffdd15"

  if (days < 10) {
    return (
      <div
        style={{
          borderRadius: "100%",
          height: "10px",
          width: "10px",
          backgroundColor: okColor,
        }}
      ></div>
    )
  }
  if (days >= 20) {
    return (
      <div
        style={{
          borderRadius: "100%",
          height: "10px",
          width: "10px",
          backgroundColor: dangerColor,
        }}
      ></div>
    )
  }
  if (days >= 10) {
    return (
      <div
        style={{
          borderRadius: "100%",
          height: "10px",
          width: "10px",
          backgroundColor: warningColor,
        }}
      ></div>
    )
  }
  return <></>
}
