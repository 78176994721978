import { useMap } from 'react-leaflet';

import { isValidArray } from '../../utils/data';

export const fitMapBounds = (map, positions, maxZoom = 5, padding = [20, 20]) => {
    if (isValidArray(positions)) {
        map.fitBounds(
            positions.filter((p) => p.lat && p.lon).map((p) => [p.lat, p.lon]),
            { maxZoom: maxZoom, padding: padding }
        );
    } else {
        map.fitWorld({ maxZoom: maxZoom, padding: padding });
    }
};

export const FitBounds = ({ positions, maxZoom, padding }) => {
    const map = useMap();

    fitMapBounds(map, positions, maxZoom, padding);

    return null;
};

FitBounds.defaultProps = {
    positions: [],
    maxZoom: 10,
    padding: [20, 20]
};

export default FitBounds;
