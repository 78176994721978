import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import { Helmet } from "react-helmet-async"

//Import Components
import Breadcrumb from "../../../components/Common/Breadcrumbs"
import { UserProfileFormNew } from "../components/UserProfileFormNew"

export function UserProfile() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>User Profile | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <Breadcrumb title="Account Settings" breadcrumbItem="User Profile" />
          <Row>
            <Col lg="12">
              <UserProfileFormNew />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
