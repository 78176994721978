import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SettingsTableDatatableWrapperProps } from "modules/PoolPortal/helpers/types"
import { Button, Col, Row, Table } from "react-bootstrap"
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
  TableRowType,
} from "react-bs-datatable"

export const SettingsTable = function <TTableRowType extends TableRowType>({
  headers,
  body,
  newHandler,
}: SettingsTableDatatableWrapperProps<TTableRowType>) {
  return (
    <DatatableWrapper
      body={body}
      headers={headers}
      sortProps={{
        initialState: {
          prop: "id",
          order: "asc" as const,
        },
      }}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 5,
          options: [5, 10, 20, 50],
        },
      }}
    >
      <Row className="PoolPortal_AdminHeader mb-4 p-2">
        <Col xs={12} className="d-flex justify-content-between">
          <div className="PoolPortal_Search">
            <div className="PoolPortal_SearchControl">
              <Filter
                classes={{
                  inputGroup: "PoolPortal_FilterInput",
                  clearButton: "PoolPortal_ClearButton",
                }}
                placeholder="Search..."
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <Button
              style={{ alignItems: "center", width: "85px" }}
              // disabled={hasModifiedState}
              onClick={() => {
                // setNewState(true)
                newHandler()
              }}
            >
              <FontAwesomeIcon icon={faAdd} className="fa-thin" />
              &nbsp;&nbsp;&nbsp;New
            </Button>
          </div>
        </Col>
      </Row>
      <Table>
        <TableHeader />
        <TableBody></TableBody>
      </Table>

      <Row className="PrevetVesselHistory_Pagination d-flex flex-row justify-content-between">
        <PaginationOptions labels={{ beforeSelect: "Show results" }} />
        <Pagination />
      </Row>
    </DatatableWrapper>
  )
}
