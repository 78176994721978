import { Accordion, Offcanvas } from "react-bootstrap"

import { AdminSettingsEnum } from "modules/PoolPortal/helpers/types"

import { FormatEnumToString } from "modules/PoolPortal/utils"
import { SettingsAdminHOC } from "./SettingsHOC"

interface SettingsPageProps {
  show: boolean
  closeHandler: () => void
}
export const SettingsPage = ({ show, closeHandler }: SettingsPageProps) => {
  return (
    <>
      <Offcanvas
        className="PoolPortal_SettingsOffcanvas"
        show={show}
        onHide={closeHandler}
        placement="end"
        backdrop="static"
        scroll={false}
        style={{ zIndex: 3060 }}
        backdropClassName="AdminSettingOverlayBackDrop"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <>
            <Accordion defaultActiveKey="0" aria-disabled className="m-10">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {FormatEnumToString(AdminSettingsEnum.VESSELS)}
                </Accordion.Header>
                <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
                  <SettingsAdminHOC type={AdminSettingsEnum.VESSELS} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {FormatEnumToString(AdminSettingsEnum.OWNERS)}
                </Accordion.Header>
                <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
                  <SettingsAdminHOC type={AdminSettingsEnum.OWNERS} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {FormatEnumToString(AdminSettingsEnum.POOLS)}
                </Accordion.Header>
                <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
                  <SettingsAdminHOC type={AdminSettingsEnum.POOLS} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {FormatEnumToString(AdminSettingsEnum.CONTENTTYPE)}
                </Accordion.Header>
                <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
                  <SettingsAdminHOC type={AdminSettingsEnum.CONTENTTYPE} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {FormatEnumToString(AdminSettingsEnum.PARTOFMONTH)}
                </Accordion.Header>
                <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
                  <SettingsAdminHOC type={AdminSettingsEnum.PARTOFMONTH} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  {FormatEnumToString(AdminSettingsEnum.OWNERSTORAGESETTING)}
                </Accordion.Header>
                <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
                  <SettingsAdminHOC
                    type={AdminSettingsEnum.OWNERSTORAGESETTING}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
