import { User, withAuthenticationRequired } from "@auth0/auth0-react"

//Import Components
import { AuthLayoutProps } from "components/Layout/Content/AuthLayout"
import { ContentLoading } from "components/Layout/Content/ContentLoading"
import { useSelector } from "react-redux"
import { RootState } from "store"
import Loading from "../../components/Layout/NonAuth/Loading"
import {
  useGetUserOrganizationsQuery,
  useGetUserPermissionsQuery,
} from "../../user/userService"
import { Organization } from "user/userSlice"
import { SalesPage } from "modules/Marketing/SalesPage"

interface AuthMiddlewareProps {
  path: string
  component: () => JSX.Element
  isAuthProtected: boolean
  permissionForPage?: string
  layout: ({ children }: AuthLayoutProps) => JSX.Element
}

interface ProtectedComponentProps {
  layout: ({ children }: AuthLayoutProps) => JSX.Element
  component: () => JSX.Element
  permissionForPage?: string
}

function getLoadingMessage({
  getPermissionsLoading,
  permissions,
  user,
  getOrganizationsLoading,
  organizations,
}: {
  getPermissionsLoading: boolean
  permissions: string[] | undefined
  user: User | undefined
  getOrganizationsLoading: boolean
  organizations: Organization[] | undefined
}) {
  if (getPermissionsLoading || !permissions) {
    return "permissions data"
  }
  if (getOrganizationsLoading || !organizations) {
    return "organization data"
  }
  if (!user) {
    return "user data"
  }
}

function ProtectedComponentLayout({
  layout: Layout,
  component: Component,
  permissionForPage,
}: ProtectedComponentProps) {
  const {
    data: permissions,
    error: getPermissionsError,
    isLoading: getPermissionsLoading,
  } = useGetUserPermissionsQuery()

  const {
    data: organizations,
    error: getOrganizationsError,
    isLoading: getOrganizationsLoading,
  } = useGetUserOrganizationsQuery()

  const userState = useSelector((state: RootState) => state.user)

  if (
    getPermissionsLoading ||
    !permissions ||
    !userState.user ||
    !organizations ||
    getOrganizationsLoading
  ) {
    const msg = getLoadingMessage({
      getPermissionsLoading,
      permissions,
      user: userState.user,
      getOrganizationsLoading,
      organizations,
    })

    return (
      <Layout>
        <ContentLoading message={msg} />
      </Layout>
    )
  }
  if (
    getPermissionsError ||
    (permissionForPage && !permissions?.includes(permissionForPage))
  ) {
    return (
      <Layout>
        <SalesPage />
      </Layout>
    )
  }
  return (
    <Layout>
      <Component />
    </Layout>
  )
}

export function AuthMiddleware({
  component: Component,
  layout: Layout,
  isAuthProtected,
  permissionForPage,
}: AuthMiddlewareProps) {
  const ComponentLayout = () => {
    return (
      <Layout>
        <Component />
      </Layout>
    )
  }

  if (isAuthProtected) {
    const WrappedComponent = withAuthenticationRequired(
      ProtectedComponentLayout,
      {
        onRedirecting: () => <Loading />,
      }
    )

    return (
      <WrappedComponent
        layout={Layout}
        component={Component}
        permissionForPage={permissionForPage}
      />
    )
  }
  return <ComponentLayout />
}
