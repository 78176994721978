import { ErrorMessage, Field, Form, Formik } from "formik"
import { PoolPortalContenttype } from "modules/PoolPortal/helpers/types"
import { useContenttypes } from "modules/PoolPortal/hooks/useContenttypesAPI"
import { DefaultValidationSchema } from "modules/PoolPortal/utils"
import { Form as BootstrapForm, Button, Spinner } from "react-bootstrap"
import * as Yup from "yup"

interface ContenttypesAdminEntryPageProps {
  data: PoolPortalContenttype
  setShowEntryBlade: (show: boolean) => void
}

const validationSchema = Yup.object().shape({ ...DefaultValidationSchema() })

export const ContenttypesAdminEntryPage = ({
  data,
  setShowEntryBlade,
}: ContenttypesAdminEntryPageProps) => {
  const { createContenttype, updateContenttype } = useContenttypes()

  const isEditMode = !(Object.keys(data).length === 0 || data.id === "")

  const handleSubmit = async (values: PoolPortalContenttype) => {
    // Handle form submission
    if (!isEditMode) {
      await createContenttype(values as PoolPortalContenttype)
    } else {
      await updateContenttype(data.id, {
        ...data,
        ...{
          code: values.code,
          name: values.name,
          description: values.description,
        },
      } as PoolPortalContenttype)
    }

    setShowEntryBlade(false)
  }

  const handleCancel = () => {
    setShowEntryBlade(false)
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="PoolPortal_AdminEntryBody d-flex flex-column">
            <div className="mb-3">
              <BootstrapForm.Label htmlFor="code" as="h5">
                Code:
              </BootstrapForm.Label>
              <Field
                type="text"
                id="code"
                name="code"
                default={data.code}
                disabled={isEditMode}
                className="form-control ml-50"
              />
              <ErrorMessage
                name="code"
                component="div"
                className="PoolPortal_ErrorMessage"
              />
            </div>

            <div className="mb-3">
              <BootstrapForm.Label htmlFor="name" as="h5">
                Name:
              </BootstrapForm.Label>
              <Field
                type="text"
                id="name"
                name="name"
                default={data.name}
                className="form-control"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="PoolPortal_ErrorMessage"
              />
            </div>
            <div className="mb-3">
              <BootstrapForm.Label htmlFor="description" as="h5">
                Description:
              </BootstrapForm.Label>
              <Field
                as="textarea"
                id="description"
                name="description"
                default={data.description}
                className="form-control"
                style={{ height: "60px" }}
              />
              <ErrorMessage
                name="description"
                component="div"
                className="PoolPortal_ErrorMessage"
              />
            </div>
          </div>
          <hr />
          <div>
            <Button type="submit" disabled={isSubmitting} variant="primary">
              {isSubmitting && (
                <>
                  <Spinner animation="border" variant="light" size="sm" />
                  &nbsp;&nbsp;
                </>
              )}
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button type="button" onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
