import { BASE_URL } from 'api/url_helper';
import { useAxiosRequest } from "api/axiosRequest"
import { PoolPortalPool, PoolPortalPools } from '../helpers/types'
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const PoolService = () => {
  const request = useAxiosRequest()
  const getPools = async () => {
    try {
      const poolsUrl = `${POOLPORTAL_API_BASE_URL}/pools`
      const response = await request<any, PoolPortalPools>(
        {
          url: poolsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const createPool = async (newPool: PoolPortalPool) => {
    try {
      const response = await request<any, PoolPortalPool>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/pool`,
          method: "POST",
          data: newPool
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updatePool = async (updatedPool: PoolPortalPool) => {
    try {
      const response = await request<any, PoolPortalPool>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/pool`,
          method: "PUT",
          data: updatedPool
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deletePool = async (id: string) => {
    try {
      const response = await request<any, PoolPortalPool>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/pool?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getPools,
    createPool,
    updatePool,
    deletePool
  }
}

export default PoolService
