import React, { ErrorInfo, ReactNode } from "react"

interface Props {
  FallbackComponent: React.ElementType
  children?: ReactNode
}

interface State {
  error: boolean | null
}

// KCD recommends using react-error-boundary in place of this
// custom component.
export class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error:", error)
  }

  render() {
    if (this.state.error) {
      return <this.props.FallbackComponent error={this.state.error} />
    }

    return this.props.children
  }
}
