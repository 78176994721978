import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useGetUserOrganizationsQuery } from "user/userService"
import { setChosenOrganization } from "../../../user/userSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import DefaultAlert from "components/Common/Alert/DefaultSweetAlert"
import { useAuth0 } from "@auth0/auth0-react"

export function OrganizationMenu() {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const chosenOrganization = useSelector(
    (state: RootState) => state.user.chosenOrganization
  )
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const {
    data: organizations,
    error: getOrganizationsError,
    isLoading: getOrganizationsLoading,
  } = useGetUserOrganizationsQuery()

  const logOutAndRedirect = () => {
    localStorage.clear()
    logout({ returnTo: window.location.origin })
  }

  useEffect(() => {
    if (getOrganizationsError) {
      // Redirect user to the login page if fetch error occurs
      logOutAndRedirect()
    }
  }, [getOrganizationsError])

  return (
    <>
      {organizations && organizations.length > 0 && (
        <Dropdown
          show={isMenuOpen}
          onToggle={() => setIsMenuOpen(!isMenuOpen)}
          className="d-inline-block ms-2"
        >
          <Dropdown.Toggle
            className="btn header-item waves-effect"
            variant="default"
          >
            {chosenOrganization
              ? chosenOrganization.display_name
              : organizations[0].display_name}
            {organizations.length > 1 && (
              <span className="mdi mdi-chevron-down"></span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="language-switch dropdown-menu-end"
            style={{ margin: 0 }}
          >
            {organizations &&
              organizations.map((obj, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      dispatch(setChosenOrganization(obj))
                    }}
                  >
                    {obj.display_name}{" "}
                  </Dropdown.Item>
                )
              })}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!getOrganizationsLoading &&
        !(organizations && organizations.length > 0) && (
          <DefaultAlert
            success={false}
            title="Error loading the organization data."
            message="Sorry, we have encountered an error. Please contact support."
            onConfirm={logOutAndRedirect}
          />
        )}
    </>
  )
}
