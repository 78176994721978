import { useAxiosRequest } from "api/axiosRequest";
import axios from "axios";
import { setDefaultResultOrder } from "dns";
import { useState } from "react";
import { toast } from "react-toastify";

interface CreateMaintenanceParams {
    maintenanceStart: string;
    maintenanceEnd: string;
    maintenanceMesasge: string;
}

interface UseCreateMaintenanceArgs{
    handleCreateMaintenance?: (data: any)=> void
}

export function useCreateMaintenance({handleCreateMaintenance}: UseCreateMaintenanceArgs){
    const request = useAxiosRequest();
    const [isLoading, setIsLoading] = useState(false)

    async function createMaintenance(params: CreateMaintenanceParams){
        try {
            setIsLoading(true);
            const response = await request<any, unknown>({
                url: "api/v1/maintenance/single",
                method: "POST",
                data: params
            });

            if (handleCreateMaintenance){
                handleCreateMaintenance(response?.data);
            }

            toast.success("Maintenance created successfully", {
                position: "bottom-right",
                autoClose: 8000,
            });
        } catch(err: unknown){
            let message = "Error creating Maintenance";
            if (axios.isAxiosError(err)){
                const axiosErrorMessage = err?.response?.data?.error?.message;
                const statusCode = err?.response?.status;
                if(statusCode == 409){
                    message = "Maintenance overlaps with existing event"
                }
                else{
                    message = axiosErrorMessage
                    ? message + ": " + axiosErrorMessage
                    : message + ".";
                }
            } else if (err instanceof Error){
                message = message + ": " + err.message;
            } else {
                message = "."
            }
            toast.error(message, {
                position: "bottom-right",
                autoClose: 8000,
            });
        } finally {
            setIsLoading(false);
        }
    }

    return {createMaintenance, isLoading};
}