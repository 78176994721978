import { ReportsListSingle } from "../hooks/useGetReportsList"

export interface AdminReportsState {
  reportsList: ReportsListSingle[] | undefined
  isLoadingReportsList: boolean
  isErrorReportsList: string | undefined
}

export const initialReportsState: AdminReportsState = {
  reportsList: undefined,
  isLoadingReportsList: false,
  isErrorReportsList: undefined,
}

export const GET_REPORTSLIST_SUCCESS = "GET_REPORTSLIST_SUCCESS"
export const GET_REPORTSLIST_LOADING = "GET_REPORTSLIST_LOADING"
export const GET_REPORTSLIST_ERROR = "GET_REPORTSLIST_ERROR"
export const CREATE_NEW_REPORT = "CREATE_NEW_REPORT"
export const EDIT_REPORT = "EDIT_REPORT"
export const DELETE_REPORT = "DELETE_REPORT"

export type AdminReportsActions =
  | {
      type: "GET_REPORTSLIST_SUCCESS"
      payload: ReportsListSingle[]
    }
  | {
      type: "GET_REPORTSLIST_LOADING"
      payload: boolean
    }
  | {
      type: "GET_REPORTSLIST_ERROR"
      payload: string | undefined
    }
  | {
      type: "CREATE_NEW_REPORT"
      payload: ReportsListSingle
    }
  | {
      type: "EDIT_REPORT"
      payload: ReportsListSingle
    }
  | {
      type: "DELETE_REPORT"
      payload: string // uuid
    }

export function adminReportsReducer(
  state: AdminReportsState,
  action: AdminReportsActions
) {
  switch (action.type) {
    case GET_REPORTSLIST_SUCCESS:
      return {
        ...state,
        isLoadingReportsList: false,
        reportsList: action.payload,
      }
    case GET_REPORTSLIST_LOADING:
      return {
        ...state,
        isLoadingReportsList: action.payload,
      }
    case GET_REPORTSLIST_ERROR:
      return {
        ...state,
        isErrorReportsList: action.payload,
      }
    case CREATE_NEW_REPORT:
      return {
        ...state,
        reportsList: !state.reportsList
          ? undefined
          : [action.payload, ...state.reportsList],
      }
    case EDIT_REPORT:
      const filtered = state.reportsList?.filter(
        (report) => report.uuid !== action.payload.uuid
      )
      return {
        ...state,
        reportsList: !filtered ? undefined : [...filtered, action.payload],
      }
    case DELETE_REPORT:
      return {
        ...state,
        reportsList: state.reportsList?.filter(
          (report) => report.uuid !== action.payload
        ),
      }
    default:
      return state
  }
}
