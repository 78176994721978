import { useAxiosRequest } from "api/axiosRequest";
import { BASE_URL } from "api/url_helper"
import moment from "moment";

export enum VesselPortStayActivity {
  Load = "L",
  Discharge = "D",
  Fuel = "F",
  CanalTransit = "C",
  Yard = "Y"
}

export interface VesselPortStay {
  date_from: string,
  date_to: string,
  port: string,
  lon: number,
  lat: number,
  duration: number,
  yard_duration: number,
  cargo: string,
  activity: VesselPortStayActivity,
  leg: "laden" | "ballast" | null,
  avg_speed_mov: number,
  max_speed: number, // Do not use this prop, use avg_speed_mov
  // avg_speed: number,
  nobs: number
}

export interface VesselDetails {
  beam: number,
  built: number,
  cubic: number,
  design: string,
  designer: string,
  draft: number,
  dwt: number,
  imo: number,
  loa: number,
  shipyard: string,
  shipyard_country: string,
  tpc: number,
  vessel_name: string
}

export interface VesselLeg {
  pos_date: string
  sog: number
  laden: boolean
  zone_code?: string
  zone_name?: string
  zone_alias?: string
  zone_group?: string
  prev_port: string,
  next_port: string
}



export interface VesselPosition {
  pos_date: string
  updated_at: string
  lon: number
  lat: number
  sog: number
  draft_ratio: number
  draft_laden: boolean
  model_laden: boolean
  master_destination: string
  master_eta: string
}


export function usePrevetAPI() {
  const request = useAxiosRequest()

  async function getVesselStays(imo: number) {
    // Get last 5 years data
    return request<any, VesselPortStay[]>({
      url: `${BASE_URL}/prevetting/vessel-stays`,
      params: { imo }
    }).then((response) => response?.data)
  }

  async function getVesselDetails(imo: number) {
    return request<any, VesselDetails>({
      url: `${BASE_URL}/prevetting/vessel-details`,
      params: { imo }
    }).then((response) => response?.data)
  }

  async function getVesselLegs(imo: number) {
    // Get last 6 months data
    const end = moment(Date.now()).format("YYYY-MM-DD")
    const start = moment(new Date())
      .subtract("6", "months")
      .format("YYYY-MM-DD")

    return request<any, VesselLeg[]>({
      url: `${BASE_URL}/prevetting/vessel-legs`,
      params: { imo, start, end }
    }).then((response) => response?.data)
  }

  // TODO: Use this to replace useFetchVesselPositions
  // This has been tried before but it fucked with the centering of latest marker
  async function getVesselPositions({ imo, months }: { imo: number | string, months: number }) {
    return request<any, VesselPosition[]>({
      url: `${BASE_URL}/prevetting/vessel-positions`,
      params: { imo, months }
    }).then((response) => response?.data)
  }

  return {
    getVesselDetails,
    getVesselLegs,
    getVesselPositions,
    getVesselStays,
  }
}