
import { AdminReportsWrapper } from "./AdminReports/AdminReportsWrapper"
import { ReportsOverviewWrapper } from "./UserReports/ReportsOverviewWrapper"
const settings = {
  routes: [
    {
      path: "/admin/reports",
      permissionForPage: "read:reports-admin",
      component: AdminReportsWrapper,
    },
    {
      path: "/reports-overview",
      permissionForPage: "read:reports",
      component: ReportsOverviewWrapper,
    },
  ],
}
export default settings
