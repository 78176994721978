import { ErrorMessage, Field, Form, Formik } from "formik"
import {
  PoolPortalOwner,
  PoolPortalOwnerstoragesetting,
  PoolPortalPool,
} from "modules/PoolPortal/helpers/types"
import { useOwners } from "modules/PoolPortal/hooks/useOwnersAPI"
import { useOwnerstoragesettings } from "modules/PoolPortal/hooks/useOwnerstoragesettingsAPI"
import { usePools } from "modules/PoolPortal/hooks/usePoolsAPI"
import { Form as BootstrapForm, Button, Spinner } from "react-bootstrap"
import Select from "react-select"
import * as Yup from "yup"

interface OwnerstoragesettingsAdminEntryPageProps {
  data: PoolPortalOwnerstoragesetting
  setShowEntryBlade: (show: boolean) => void
}

const validationSchema = Yup.object().shape({
  ownerId: Yup.string().required("Owner is required"),
  storageName: Yup.string().required("Storage Name is required"),
  // containerName: Yup.string().required("Container Name is required"),
  containerEndpoint: Yup.string().required("Container Endpoint is required"),
  containerKey: Yup.string().required("Container Key is required"),
})

export const OwnerstoragesettingsAdminEntryPage = ({
  data,
  setShowEntryBlade,
}: OwnerstoragesettingsAdminEntryPageProps) => {
  const { createOwnerstoragesetting, updateOwnerstoragesetting } =
    useOwnerstoragesettings()

  const { owners } = useOwners()
  const { pools } = usePools()

  const isEditMode = !(Object.keys(data).length === 0 || data.id === "")

  const handleSubmit = async (values: PoolPortalOwnerstoragesetting) => {
    // Handle form submission
    if (!isEditMode) {
      await createOwnerstoragesetting(values as PoolPortalOwnerstoragesetting)
    } else {
      await updateOwnerstoragesetting(data.id, {
        ...data,
        ...{
          ownerId: values.ownerId,
          storageName: values.storageName,
          containerName: values.containerName,
          containerEndpoint: values.containerEndpoint,
          containerKey: values.containerKey,
        },
      } as PoolPortalOwnerstoragesetting)
    }

    setShowEntryBlade(false)
  }

  const handleCancel = () => {
    setShowEntryBlade(false)
  }

  const displayPoolMessage = (ownerId: string) => {
    const foundOwner = owners?.find(
      (owner: PoolPortalOwner) => owner.id === ownerId
    )
    if (foundOwner) {
      const foundPool = pools?.find(
        (pool: PoolPortalPool) => pool.id === foundOwner.poolId
      )
      return foundPool ? `This owner belongs to "${foundPool.name}" pool.` : ""
    }
    return ""
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <>
          <Form>
            <div className="PoolPortal_AdminEntryBody d-flex flex-column">
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="ownerId" as="h5">
                  Owner:
                </BootstrapForm.Label>
                <Field
                  as={Select}
                  options={
                    owners
                      ? owners.map((owner: PoolPortalOwner) => {
                          let ownerLabel = owner.name

                          return { label: ownerLabel, value: owner.id }
                        })
                      : []
                  }
                  id="ownerId"
                  name="ownerId"
                  className="form-control"
                  menuPlacement="auto"
                  // defaultValue={pools ? { label: pools[0].name, value: pools[0].id } : {}}
                  value={
                    values.ownerId
                      ? { label: values.ownerName, value: values.ownerId }
                      : undefined
                  }
                  onChange={async (selectedOption: {
                    value: string
                    label: string
                  }) => {
                    await setFieldValue(
                      "ownerId",
                      (selectedOption as { value: string }).value
                    )
                    await setFieldValue(
                      "ownerName",
                      (selectedOption as { label: string }).label
                    )
                  }}
                  getOptionLabel={(option: { label: any }) => option.label} // Specify how to display the label
                />
                <ErrorMessage
                  name="ownerId"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
                <div>{displayPoolMessage(values.ownerId)}</div>
              </div>
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="storageName" as="h5">
                  Storage Name:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="storageName"
                  name="storageName"
                  default={data.storageName}
                  // disabled={isEditMode}
                  className="form-control ml-50"
                />
                <ErrorMessage
                  name="storageName"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="containerEndpoint" as="h5">
                  Container Endpoint:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="containerEndpoint"
                  name="containerEndpoint"
                  default={data.containerEndpoint}
                  className="form-control"
                />
                <ErrorMessage
                  name="containerEndpoint"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="containerKey" as="h5">
                  Container Key:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="containerKey"
                  name="containerKey"
                  default={data.containerKey}
                  className="form-control"
                />
                <ErrorMessage
                  name="containerKey"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>
            </div>
            <hr />
            <div>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {isSubmitting && (
                  <>
                    <Spinner animation="border" variant="light" size="sm" />
                    &nbsp;&nbsp;
                  </>
                )}
                Save
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button type="button" onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}
