import AlertError from "components/Common/Alert/AlertError"
import Breadcrumbs from "components/Common/Breadcrumbs"
import { ContentLoading } from "components/Layout/Content/ContentLoading"
import { getPermissionsForSelectedOrganisation } from "helpers/Authorization/getPermissionsForSelectedOrganisation"
import {
  Organisation,
  UserState,
} from "modules/Authentication/store/user/interfaces"
import React from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { ReportsOverviewMain } from "./ReportsOverviewMain"
import { useGetUserOrganizationsQuery } from "user/userService"

function renderReportsContent({
  userOrganisation,
  userState,
  hasPermissionsToReadReports,
}: {
  userOrganisation: Organisation
  userState: UserState
  hasPermissionsToReadReports: boolean | null
}) {
  if (userState.loading) {
    return <ContentLoading />
  }
  if (!hasPermissionsToReadReports) {
    return (
      <AlertError
        title="Sorry! "
        text="You do not have the relevant permissions to access to this page."
      />
    )
  }
  return
}

export function ReportsOverviewWrapper() {
  const {
    data: organizations,
    error: getOrganizationsError,
    isLoading: getOrganizationsLoading,
  } = useGetUserOrganizationsQuery()

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Reports Overview | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div className="main-content_inner">
            <Breadcrumbs
              title="Reports Overview"
              breadcrumbItem="List of Reports"
            />
            {organizations && organizations.length > 0 && (
              <ReportsOverviewMain
                userOrganisation={{
                  label: organizations[0].name, // This will take the user's first organization and send it in all requests
                  value: organizations[0].id,
                }}
              />
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
