import { useQuery } from "@tanstack/react-query"
import { addYardActivity } from "modules/Prevetting/helpers/addYardActivity"
import moment from "moment"
import { usePortPredictorAPI } from "./usePortPredictorAPI"


export const DDAlertCategories = ["DD-S", "DD-R", "DD-Late", "R"]

// Includes DD-R which could be done year before, but never DD-Late as the current time will be in a DD year for the vessel
export const DDCategoriesForDDYear = ["DD-S", "DD-M", "DD-L", "DD-R"]
export const DDCategories = ["DD-L", "DD-M", "DD-S", "DD-R", "DD-Late"]
export const DDYardStayCategories = [
  "DD-L",
  "DD-M",
  "DD-S",
  "DD-Late",
  "DD-R",
  "R",
]

export type DDAbbr = "DD-S" | "DD-M" | "DD-L" | "DD-R" | "R" | "DD-Late"
export interface VesselStay {
  date_from: string
  date_to: string
  port: string
  lon: number
  lat: number
  duration: number
  yard_duration: number
  cargo: string | null
  activity: any
  leg: "laden" | "ballast" | null
  avg_speed_mov: number
  max_speed: number
  nobs: number
  yard_activity?: DDAbbr
}

// TODO: Add test for this
export function useFetchVesselStays({ imo, built }: { imo: number | undefined, built?: number }) {
  const { getVesselStays } = usePortPredictorAPI()

  const {
    isLoading: isVesselStaysLoading,
    isError: isVesselStaysError,
    isSuccess: isVesselStaysSuccess,
    data: vesselStaysData,
  } = useQuery({
    // Query keys are unique, only when they change, the query is re-run
    // https://tanstack.com/query/v4/docs/react/guides/query-keys
    queryKey: ["port-predictor/getVesselStays", imo],
    // How to use query functions: https://tanstack.com/query/v4/docs/react/guides/query-functions
    queryFn: () => getVesselStays(imo as number).then((vesselStays) => {
      if (!vesselStays || vesselStays.length < 1) {
        return undefined
      }


      return addYardActivity({ initialStays: vesselStays, built: built as number })
    }).catch((err: unknown) => {
      return Promise.reject(new Error(`Unable to fecth vessel-stays for IMO ${imo}`))
    }),
    enabled: !!(imo && built),
    staleTime: 1000,
  })

  return {
    vesselStaysData,
    isVesselStaysLoading,
    isVesselStaysError,
    isVesselStaysSuccess,
  }
}