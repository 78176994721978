import { AxiosRequestConfigWithData, useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useEffect, useState, useRef } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { isMobile } from "react-device-detect"
import * as pdfjsLib from "pdfjs-dist"

const pdfjsWorker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`

interface PdfModalProps {
  idToCheck: string | undefined
  nameFieldName?: string | undefined
  isModalShown: boolean
  handleClose: () => void
  requestParameters: AxiosRequestConfigWithData<any>
}

const PDFViewer = ({ pdfBlobUrl }: { pdfBlobUrl: string }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const renderPDF = async () => {
      if (pdfBlobUrl && containerRef.current) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
        const loadingTask = pdfjsLib.getDocument(pdfBlobUrl)
        const pdf = await loadingTask.promise
        try {

          containerRef.current.innerHTML = ''
          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber)
            const scale = 1.5
            const viewport = page.getViewport({ scale })
            const canvas = document.createElement('canvas')
            const context = canvas.getContext("2d")
            if (context) {
              // Set the canvas dimensions based on the viewport dimensions
              const originalWidth = viewport.width
              const originalHeight = viewport.height

              // Set the canvas width to 80% of the container's width
              const containerWidth = containerRef.current.clientWidth
              const scaleFactor = containerWidth / originalWidth

              canvas.style.width = `${containerWidth}px`
              canvas.style.height = `${originalHeight * scaleFactor}px`

              canvas.width = originalWidth
              canvas.height = originalHeight

              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              }

              await page.render(renderContext).promise
              containerRef.current.appendChild(canvas)
            } else {
              console.error("Failed to get canvas context.")
            }
          }
        } catch (err) {
          console.error("Failed to render pdf", err)
        }
      }
    }

    renderPDF()
  }, [pdfBlobUrl])

  return <div ref={containerRef} id="pdf-container"></div>
}

export function PdfModal({
  idToCheck,
  nameFieldName = "reportName",
  isModalShown,
  handleClose,
  requestParameters,
}: PdfModalProps): JSX.Element {
  const request = useAxiosRequest()
  const [reportName, setReportName] = useState<string | undefined>()
  const [isLoadingBase64, setIsLoadingBase64] = useState(false)
  const [pdfBlobUrl, setPdfBlobUrl] = useState("")


  useEffect(() => {
    if (!idToCheck || !isModalShown) {
      return
    }
    async function getData() {
      setReportName(undefined)
      setIsLoadingBase64(true)

      try {
        URL.revokeObjectURL(pdfBlobUrl)
        const responseBody = await request<any, any>({ ...requestParameters })
        if (!responseBody) {
          return
        }
        const base64 = responseBody.data.file
        setReportName(responseBody.data[`${nameFieldName}`])

        const binaryString = window.atob(base64) // Decode base64
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        const blob = new Blob([bytes], { type: "application/pdf" })
        const blobUrl = URL.createObjectURL(blob)

        setPdfBlobUrl(blobUrl)
        setTimeout(() => URL.revokeObjectURL(blobUrl), 1000)
      } catch (err: unknown) {
        let message = "Error downloading PDF"
        if (axios.isAxiosError(err)) {
          const axiosErroMessage = err?.response?.data?.error?.message
          message = axiosErroMessage
            ? message + ": " + axiosErroMessage
            : message + "."
        } else if (err instanceof Error) {
          message = message + ": " + err.message
        } else {
          message = "."
        }
        toast.warn(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      } finally {
        setIsLoadingBase64(false)
      }
    }
    getData()
  }, [requestParameters, isModalShown])

  return (
    <Modal
      show={isModalShown}
      onHide={handleClose}
      size="lg"
      dialogClassName="viewPdfModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <>
            {isLoadingBase64 && (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  style={{ marginRight: "10px" }}
                />
                Loading...
              </>
            )}{" "}
            <div style={{ maxWidth: "80vw", overflow: "auto" }}>{reportName}</div>
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(!isLoadingBase64 && pdfBlobUrl) && (
          isMobile ? (
            <PDFViewer pdfBlobUrl={pdfBlobUrl} />
          ) : (
            <iframe
              src={pdfBlobUrl}
              width="100%"
              height="700"
              style={{ border: 'none' }}
            />
          )
        )}
      </Modal.Body>
    </Modal>
  )
}

