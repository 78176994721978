import moment from "moment"
import { DDAbbr, DDCategoriesForDDYear, VesselStay } from "../hooks/useFetchVesselStays"

const dryDockInterval = 5

export function getYearsToDryDock(builtYear: number) {
  const now = moment()
  const builtDate = moment(builtYear, "YYYY")
  const vesselAge = moment.duration(now.diff(builtDate)).years()

  if (vesselAge % 5 === 0) {
    return 0
  }
  return dryDockInterval - (vesselAge % 5)
}

export function getIsPresentYearADryDockYear(built: number) {
  const currDate = moment().year() // TODO: Better pass this as param
  const timeElapsed = built - currDate
  if (timeElapsed % 5 === 0) {
    return true
  }
  return false
}

export function getDDinDDYear(stays: VesselStay[], now: moment.Moment) {
  const starOfYear = now.startOf("year")
  const startOfPrevYear = starOfYear.subtract(1, "year")
  return stays.filter((stay) => {
    return (
      moment(stay.date_from).isAfter(startOfPrevYear) &&
      stay.yard_activity &&
      DDCategoriesForDDYear.includes(stay.yard_activity)
    )
  })
}

export type VesselStayWithYardActivity = VesselStay & { yard_activity: DDAbbr }
export function getLatestTwoDryDock(
  stays: VesselStay[]
): VesselStayWithYardActivity[] {
  const DDstays = []
  const ddAbbrArr: DDAbbr[] = ["DD-S", "DD-M", "DD-L", "DD-R", "DD-Late"]

  for (let i = 0; i < stays.length; i++) {
    const stay = stays[i]
    const activity = stay.yard_activity
    if (DDstays.length > 2) {
      break
    }
    if (activity && ddAbbrArr.includes(activity)) {
      if (
        activity === "DD-Late" ||
        activity === "DD-L" ||
        activity === "DD-M" ||
        activity === "DD-R"
      ) {
        DDstays.push(stay)
      }
      if (activity === "DD-S") {
        DDstays.push(stay)
      }
    }
  }

  return DDstays as VesselStayWithYardActivity[]
}
