import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { getUserOrganisationId } from "helpers/Authorization/getUserOrganisationId"
import { useEffect } from "react"
import { useAxiosRequest } from "../../../api/axiosRequest"
import { Organisation } from "./useGetOrganisations"

export interface ReportsListSingle {
  createdAt: string
  filePath: string
  id: string
  isDeleted: boolean
  orgId: string
  reportName: string
  reportSummary: string
  updatedAt: string
  uuid: string
}

interface UseGetReportsListProps {
  chosenOrganisation: Organisation | null
  handleGetReportsListSuccess: (payload: ReportsListSingle[]) => void
  handleGetReportsListLoading: (payload: boolean) => void
  handleGetReportsListError: (payload: string | undefined) => void
}

export function useGetReportsList({
  chosenOrganisation,
  handleGetReportsListSuccess,
  handleGetReportsListLoading,
  handleGetReportsListError,
}: UseGetReportsListProps): null {
  const request = useAxiosRequest()
  const cachedOrganisation = localStorage.getItem("organisation")
  const userCurrentSelectedOrgId = getUserOrganisationId(cachedOrganisation)
  const orgIdToGetReportsWith = chosenOrganisation
    ? chosenOrganisation.value
    : userCurrentSelectedOrgId

  useEffect(() => {
    const controller = new AbortController()
    let unmounted = false

    if (!unmounted) {
      handleGetReportsListLoading(true)
      handleGetReportsListError(undefined)
    }
    async function fetchReportsList() {
      try {
        if (!orgIdToGetReportsWith) {
          handleGetReportsListError("You do not have a valid organisation id.")
          return
        }
        const responseBody = await request<any, ReportsListSingle[]>({
          signal: controller.signal,
          url: `${BASE_URL}/reports`,
        })
        if (!unmounted && responseBody) {
          handleGetReportsListSuccess(responseBody.data)
        }
      } catch (err: unknown) {
        let message: string
        const genericMessage =
          "Your request was unsuccessful. Try again, and if issue persists, please contact support."
        if (axios.isAxiosError(err)) {
          message = err?.response?.data?.error?.message
        } else if (err instanceof Error) {
          message = err?.message
        } else {
          message = genericMessage
        }
        if (!unmounted) {
          handleGetReportsListError(message)
        }
      } finally {
        if (!unmounted) {
          handleGetReportsListLoading(false)
        }
      }
    }
    fetchReportsList()
    return () => {
      unmounted = true
      controller.abort()
    }
  }, [
    handleGetReportsListError,
    handleGetReportsListLoading,
    handleGetReportsListSuccess,
    userCurrentSelectedOrgId,
    orgIdToGetReportsWith,
  ])

  return null
}
