import { useState } from 'react';

export const useHighlight = () => {
    const [highlight, setHighlight] = useState(null);

    const set = (data) => {
        if (data) {
            const { lon, lat } = data;

            setHighlight(lon && lat ? data : null);
        } else {
            setHighlight(null);
        }
    };

    return [highlight, set];
};

export default useHighlight;
