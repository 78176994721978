import React from "react"
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import { format } from "date-fns"

interface MaintenanceItem {
  id: string
  maintenanceStart: string
  maintenanceEnd: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}

interface MaintenanceListTableProps {
  maintenanceItems: MaintenanceItem[]
  onPreview: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  isPrevious?: boolean
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return format(date, "MMMM d, yyyy hh:mm a XXX")
}

const MaintenanceListTable: React.FC<MaintenanceListTableProps> = ({
  maintenanceItems,
  onPreview,
  onEdit,
  onDelete,
  isPrevious,
}) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Maintenance Start</th>
          <th>Maintenance End</th>
          <th>Created by</th>
          <th>Created at</th>
          <th>Updated by</th>
          <th>Updated at</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {maintenanceItems.map((item) => (
          <tr key={item.id}>
            <td>{formatDate(item.maintenanceStart)}</td>
            <td>{formatDate(item.maintenanceEnd)}</td>
            <td>{item.createdBy}</td>
            <td>{formatDate(item.createdAt)}</td>
            <td>{item.updatedBy}</td>
            <td>{formatDate(item.updatedAt)}</td>
            <td>
              <div className="d-flex flex-row gap-1">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-preview">Preview</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    onClick={() => onPreview(item.id)}
                  >
                    <i className="ti-eye" />
                  </Button>
                </OverlayTrigger>
                {isPrevious ? (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-clone">Clone</Tooltip>}
                    >
                      <Button variant="primary" onClick={() => onEdit(item.id)}>
                        <i className="ti-files" />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-edit">Edit</Tooltip>}
                    >
                      <Button variant="primary" onClick={() => onEdit(item.id)}>
                        <i className="ti-pencil" />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
               {/*  <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-edit">Edit</Tooltip>}
                >
                  <Button variant="primary" onClick={() => onEdit(item.id)}>
                    <i className="ti-pencil" />
                  </Button>
                </OverlayTrigger> */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-delete">Delete</Tooltip>}
                >
                  <Button variant="danger" onClick={() => onDelete(item.id)}>
                    <i className="ti-trash" />
                  </Button>
                </OverlayTrigger>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MaintenanceListTable
