import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { VesselSegment, userApi } from "./user/userService"
import userReducer from "./user/userSlice"
import { listenerMiddleware } from "user/userMiddleware"

const defaultSegmentState: VesselSegment | null = JSON.parse(
  localStorage.getItem("defaultSegment") || "null"
)

export const store = configureStore({
  preloadedState: {
    user: { defaultSegment: defaultSegmentState },
  },
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware(), listenerMiddleware.middleware]
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
