import AlertError from "components/Common/Alert/AlertError"
import Breadcrumbs from "components/Common/Breadcrumbs"
import { ContentLoading } from "components/Layout/Content/ContentLoading"
import { UserState } from "modules/Authentication/store/user/interfaces"
import React from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { pdfjs } from "react-pdf"
import { useSelector } from "react-redux"
import { getPermissionsForSelectedOrganisation } from "../../../helpers/Authorization/getPermissionsForSelectedOrganisation"
import { Organisation, useGetOrganisations } from "../hooks/useGetOrganisations"
import { AdminReportsMain } from "./AdminReportsMain"
import { useGetUserOrganizationsQuery } from "user/userService"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
const userDataSelector = (store: any) => store.User

function renderReportsContent({
  organizations,
  getOrganizationsLoading,
  getOrganizationsError,
}: {
  organizations: Organisation[] | undefined
  getOrganizationsLoading: boolean
  getOrganizationsError: string | undefined
}): JSX.Element {
  if (getOrganizationsLoading) {
    return <ContentLoading />
  }

  if (!organizations) {
    return (
      <AlertError
        title="Sorry! "
        text={`Error: ${getOrganizationsError} fetching client organisations, please try again.`}
      />
    )
  }
  return (
    <div>
      <AdminReportsMain
        organisations={organizations} // Note spelling
      />
    </div>
  )
}

export function AdminReportsWrapper() {
  const {
    data: organizations,
    error: getOrganizationsError,
    isLoading: getOrganizationsLoading,
  } = useGetUserOrganizationsQuery()

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Report Management | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div className="main-content_inner">
            <Breadcrumbs
              title="Report Management"
              breadcrumbItem="Report Management"
            />
            {organizations &&
              renderReportsContent({
                organizations: organizations.map((o) => {
                  return { label: o.name, value: o.id } // TODO: Temp mapping from old org to new org interface
                }),
                getOrganizationsLoading,
                getOrganizationsError: JSON.stringify(getOrganizationsError), // TODO: Better error handling
              })}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
