import { formatCargoLabel } from "modules/PortPredictor/components/portPredictorFormatCargoLabelHelper"
import { VesselStay } from "../hooks/useFetchVesselStays"
import moment from "moment"
import { OverlayTrigger, Popover } from "react-bootstrap"

const renderTooltip = () => {
  return (
    <Popover id="popover-basic">
      <Popover.Body>Cargo is est.</Popover.Body>
    </Popover>
  )
}

export function PrevetLastFiveCargoes({
  vesselStaysData,
}: {
  vesselStaysData: VesselStay[]
}) {
  const lastFiveStaysWithCargoes = vesselStaysData
    .filter((stay) => stay.cargo !== null)
    .slice(0, 5)

  return (
    <div>
      <div className="d-flex border-bottom">
        <h4 className="card-title ">Last 5 Cargoes</h4>

        <OverlayTrigger placement="top" overlay={renderTooltip()}>
          <i
            className="ti-info-alt ms-1"
            style={{ position: "relative", fontSize: "0.65rem" }}
          />
        </OverlayTrigger>
        {lastFiveStaysWithCargoes.length < 5 && (
          <div className="ms-2">
            (Only {lastFiveStaysWithCargoes.length} Shipments)
          </div>
        )}
      </div>
      <div>
        {lastFiveStaysWithCargoes.map((stay, i) => {
          return (
            <div
              key={`${stay.cargo}_${stay.date_from}`}
              className="d-flex justify-content-between"
            >
              <div>
                <span>{i + 1}. </span>
                {formatCargoLabel(stay.cargo as string)}
              </div>
              <div>{moment(stay.date_from).format("MMM [']YY")}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
