import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { usePortPredictorAPI } from "./usePortPredictorAPI"
import { MarkerPoint } from "../components/PortPredictorMain"

interface FetchDistanceParams { source: string | undefined, target: string | undefined }

export function useFetchDistance({ source, target }: FetchDistanceParams) {
    const { getDistance } = usePortPredictorAPI()

    const {
        isLoading: isGetDistanceLoading,
        isError: isGetDistanceError,
        isSuccess: isGetDistanceSuccess,
        data: distanceData,
    } = useQuery({
        queryKey: [
            "portPredictor/getDistance",
            {
                source, target
            },
        ],
        queryFn: ({ queryKey }: QueryFunctionContext<(string | FetchDistanceParams)[]>) => {
            return getDistance(queryKey[1] as { source: string, target: string })
        },
        select: (data) => {
            const points = getLatLngPointsFromPath(data?.path) as MarkerPoint[]
            return {
                distance: data?.distance,
                path: data?.path,
                points
            }
        },
        enabled: !!(source && target), // Ensure all props are there
        staleTime: 600000, // 10 minutes
    })

    return {
        distanceInNm: distanceData?.distance,
        distanceData: distanceData,
        isGetDistanceLoading,
        isGetDistanceError,
        isGetDistanceSuccess,
    }
}

export function getLatLngPointsFromPath(path: string | undefined) {
    if (!path) {
        return
    }
    const values = path.split("(")[1].split(")")[0]
    return values.split(",").map((pair) => {
        const latLng = pair.trim().split(" ")
        return {
            lat: Number(latLng[1]),
            lon: Number(latLng[0])
        }
    })
}