import React, { useEffect, useState } from "react"
import { useGetMaintenance } from "../hooks/useGetMaintenance"
import { MenuItem } from "react-pro-sidebar"
import MaintenancePreview from "../components/AdminMaintenance/MaintenancePreview"
import Cookies from "js-cookie"

function MaintenanceSidebarItem() {
  const { getSingleMaintenance, maintenanceList, isLoading } = useGetMaintenance()
  const [showModal, setShowModal] = useState<boolean>(false)

  //check if there is current maintenance
  useEffect(() => {
    getSingleMaintenance()
    const interval = setInterval(() => {
      getSingleMaintenance()
    }, 900000) //check for maintenance every 15 minutes

    return () => clearInterval(interval)
  }, [])

  //Check if user is seeing the cookie for the first time
  useEffect(() => {
    if (
      maintenanceList.Current.length > 0 &&
      !Cookies.get("maintenance_popup")
    ) {
      const tom = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      const end = new Date(maintenanceList.Current[0].maintenanceEnd)
      if (tom < end) {
        Cookies.set("maintenance_popup", "seen", { expires: tom })
      } else {
        Cookies.set("maintenance_popup", "seen", { expires: end })
      }
      setShowModal(true)
    }
  }, [maintenanceList])

  return (
    <>
      {maintenanceList.Current.length > 0 ? (
        <>
          <MenuItem
            style={{ color: "red" }}
            icon={<i className="ti-alert" />}
            className="text-red"
            onClick={() => setShowModal(true)}
          >
            Maintenance Notice
          </MenuItem>
          <MaintenancePreview
            showPreview={showModal}
            handlePreviewClose={() => setShowModal(false)}
            message={maintenanceList.Current[0]?.maintenanceMesasge}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MaintenanceSidebarItem
