import React, { useContext, useEffect, useRef } from 'react';

import { LineChart } from 'echarts/charts';
import {
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    ToolboxComponent,
    TooltipComponent
} from 'echarts/components';
import { getInstanceByDom, init, use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

import { getLastValid, isValidArray } from '../../utils/data';
import { isNull } from '../../utils/format';

use([
    LineChart,
    GridComponent,
    DataZoomComponent,
    LegendComponent,
    ToolboxComponent,
    TooltipComponent,
    MarkLineComponent,
    CanvasRenderer
]);

const colors = {
    red: '#ff6459',
    blue: '#4baaf5',
    yellow: '#d5a326',
    purple: '#a389d4',
    teal: '#30aa9f',
    gray: '#9caeb7',
    orange: '#f79530',
    cyan: '#30c9dc',
    background: '#ffffff',
    grid: '#dfe7ef',
    text: '#9ca3af',
    tooltip: {
        text: '#5d646d'
    },
    axisPointer: {
        background: '#9caeb7',
        color: '#1f2d40'
    }
};

const getColor = (index) => {
    const choices = ['red', 'blue', 'yellow', 'purple', 'teal', 'cyan', 'orange'];

    return colors[choices[index % choices.length]];
};

const getTooltipPosition = (point, params, dom, rect, size) => {
    const obj = { top: '10' };

    if (point[0] < size.viewSize[0] / 2) {
        obj.right = '2%';
    } else {
        obj.left = '5%';
    }

    return obj;
};

const options = {
    grid: {
        left: 0,
        right: 40,
        top: 30,
        bottom: 0,
        containLabel: true
    },
    toolbox: {
        show: true,
        right: 50,
        top: 0,
        feature: {
            dataZoom: {
                yAxisIndex: 'none'
            },
            dataView: {
                readOnly: true,
                backgroundColor: colors.background,
                textColor: colors.text,
                textareaColor: colors.background,
                textareaBorderColor: colors.text
            }
        }
    },
    tooltip: {
        trigger: 'axis',
        textStyle: {
            color: colors.tooltip.text,
            fontSize: 12
        },
        position: getTooltipPosition,
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: colors.axisPointer.background,
                color: colors.axisPointer.color,
                precision: '1'
            }
        }
    },
    tooltipResponsive: {
        triggerOn: 'none',
        textStyle: {
            color: colors.tooltip.text,
            fontSize: 12
        },
        position: getTooltipPosition,
        alwaysShowContent: false
    },
    legend: {
        type: 'scroll',
        icon: 'circle',
        orient: 'horizontal',
        top: 0,
        left: 50,
        textStyle: {
            color: colors.text
        }
    },
    dataZoom: [
        {
            type: 'slider',
            show: true,
            yAxisIndex: 0,
            start: 0,
            end: 100,
            width: 12,
            right: 10,
            backgroundColor: colors.text,
            moveHandleSize: 0,
            showDataShadow: false,
            fillerColor: colors.grid
        }
    ],
    xAxis: {
        type: 'category',
        splitLine: {
            show: true,
            lineStyle: {
                color: colors.grid
            }
        },
        axisLine: {
            lineStyle: {
                color: colors.grid
            }
        },
        axisLabel: {
            color: colors.text
        },
        boundaryGap: false,
        data: []
    },
    xAxisResponsive: {
        type: 'category',
        splitLine: {
            show: true,
            lineStyle: {
                color: colors.grid
            }
        },
        axisLine: {
            lineStyle: {
                color: colors.grid
            }
        },
        axisLabel: {
            color: colors.text
        },
        axisPointer: {
            snap: true,
            type: 'line',
            label: {
                show: false
            },
            handle: {
                show: true,
                color: colors.axisPointer.background,
                size: 25,
                margin: 5,
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 0
            }
        },
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value',
        splitLine: {
            show: true,
            lineStyle: {
                color: colors.grid
            }
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: colors.grid
            }
        },
        axisLabel: {
            color: colors.text
        }
    },
    series: {
        type: 'line',
        smooth: false,
        showSymbol: true,
        showAllSymbol: true,
        symbol: 'circle',
        symbolSize: 5,
        triggerLineEvent: true,
        lineStyle: {
            width: 1.25
        },
        emphasis: {
            focus: 'series'
        },
        blur: {
            lineStyle: {
                opacity: 1,
                width: 0.5
            }
        }
    }
};

export const EChart = ({
    datasets,
    tooltipFormatter,
    xAxisFormatter,
    highlight,
    legend,
    responsive,
    yMin,
    yMax,
    width,
    height
}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        let chart = null;

        if (chartRef.current) {
            chart = init(chartRef.current);
            initOption(chart);
        }

        const resizeChart = () => {
            chart?.resize();
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            if (chart) {
                if (highlight) {
                    chart.off('highlight');
                }

                chart.dispose();
            }

            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    useEffect(() => {
        if (isValidArray(datasets)) {
            if (tooltipFormatter) {
                updateOption({
                    tooltip: {
                        formatter: (params) =>
                            tooltipFormatter(params.map((p) => getTooltipData(p)))
                    }
                });
            }

            if (xAxisFormatter) {
                updateOption({
                    xAxis: {
                        axisLabel: {
                            formatter: (value, index) => xAxisFormatter(value)
                        }
                    }
                });
            }

            updateOption({ xAxis: { data: getLabels() } });

            updateOption(
                { series: datasets.map((dt, i) => createSeries(dt, i)) },
                { replaceMerge: ['series'] }
            );

            if (responsive) {
                updateOption({
                    xAxis: { axisPointer: { value: getLastValidLabel() } }
                });
            }

            if (!isNull(yMin) && !isNull(yMax)) {
                updateOption({ yAxis: { min: yMin, max: yMax } });
            }

            if (highlight) {
                subscribe('highlight', (params) => {
                    if (params?.batch) {
                        highlight(params.batch.map((p) => getHighlightData(p)));
                    }
                });
            }
        }
    }, [datasets]);

    const initOption = (chart) => {
        const option = {
            toolbox: options.toolbox,
            grid: options.grid,
            xAxis: responsive ? options.xAxisResponsive : options.xAxis,
            yAxis: options.yAxis,
            dataZoom: options.dataZoom,
            tooltip: responsive ? options.tooltipResponsive : options.tooltip,
            legend: options.legend,
            series: []
        };

        chart.setOption(option);
    };

    const updateOption = (option, opts) => {
        if (chartRef.current) {
            const chart = getInstanceByDom(chartRef.current);

            if (opts) {
                chart?.setOption(option, opts);
            } else {
                chart?.setOption(option);
            }
        }
    };

    const subscribe = (e, callback) => {
        if (chartRef.current) {
            const chart = getInstanceByDom(chartRef.current);

            chart.on(e, (params) => callback(params));
        }
    };

    const createSeries = (dataset, index) => {
        return {
            ...options.series,
            name: legend ? dataset.name : null,
            color: dataset?.color ? colors[dataset.color] : getColor(index),
            data: dataset.data.map((item) => item[dataset.value]),
            showSymbol: !!dataset?.symbolSize,
            symbolSize: dataset?.symbolSize,
            lineStyle: dataset?.lineStyle ?? options.series.lineStyle,
            markLine: dataset?.markLine
        };
    };

    const getTooltipData = (param) => {
        return {
            ...datasets[param.seriesIndex].data[param.dataIndex],
            color: param.color
        };
    };

    const getHighlightData = (param) => {
        return {
            ...datasets[param.seriesIndex].data[param.dataIndex]
        };
    };

    const getLabels = () => {
        return datasets[0].data.map((item) => item[datasets[0].label]);
    };

    const getLastValidLabel = () => {
        const { data, label, value } = datasets[0];

        if (isValidArray(data)) {
            const item = getLastValid(data, value);

            if (item) {
                return item[label];
            }
        }
    };

    return <div ref={chartRef} style={{ width: width, height: height }} />;
};

EChart.defaultProps = {
    datasets: [],
    tooltipFormatter: null,
    xAxisFormatter: null,
    highlight: null,
    legend: false,
    responsive: false,
    yMin: null,
    yMax: null,
    width: '100%',
    height: '100%'
};

export default EChart;
