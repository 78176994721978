import { AxiosRequestConfigWithData, useAxiosRequest } from "api/axiosRequest"
import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

export interface GetPdfBase64Args {
  id: string
  reportName: string
  requestParameters: AxiosRequestConfigWithData<any>
}

export function useGetPdfBase64(download: boolean = true): {
  getPdfBase64: (args: GetPdfBase64Args) => void
  pdfBase64: string | undefined
  currentlyLoadingId: string | undefined
} {
  const request = useAxiosRequest()
  const [pdfBase64, setPdfBase64] = useState<string | undefined>()
  const [currentlyLoadingId, setCurrentlyLoadingId] = useState<
    undefined | string
  >()
  const [chosenReportName, setChosenReportName] = useState<string | undefined>()

  useEffect(() => {
    if (!pdfBase64 || !chosenReportName) {
      return
    }
    const linkSource = `data:application/pdf;base64,${pdfBase64}`

    if (download) {
      const downloadLink = document.createElement("a")
      const fileName = `${chosenReportName}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } else {
      // Convert base64 to binary array
      const binaryString = window.atob(pdfBase64)
      const len = binaryString.length
      const binaryArray = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        binaryArray[i] = binaryString.charCodeAt(i)
      }

      // Create a Blob from the binary data
      const pdfBlob = new Blob([binaryArray], { type: "application/pdf" })

      // Generate a Blob URL
      const url = URL.createObjectURL(pdfBlob)

      // Open the Blob URL in a new tab
      window.open(url, "_blank")

      // Cleanup the blob URL after the PDF has been loaded
      setTimeout(() => URL.revokeObjectURL(url), 1000)
    }

    setChosenReportName(undefined)
  }, [pdfBase64, chosenReportName])

  async function getPdfBase64({
    id,
    reportName,
    requestParameters,
  }: GetPdfBase64Args) {
    try {
      setChosenReportName(reportName)
      setCurrentlyLoadingId(id)
      const responseBody = await request<any, { file: string }>({
        ...requestParameters,
      })
      if (!responseBody) {
        return
      }
      const base64 = responseBody.data.file

      setPdfBase64(base64)
    } catch (err: unknown) {
      let message = "Error downloading PDF"
      if (axios.isAxiosError(err)) {
        const axiosErroMessage = err?.response?.data?.error?.message
        message = axiosErroMessage
          ? message + ": " + axiosErroMessage
          : message + "."
      } else if (err instanceof Error) {
        message = message + ": " + err.message
      } else {
        message = "."
      }
      toast.warn(message, {
        position: "bottom-right",
        autoClose: 8000,
      })
    } finally {
      setCurrentlyLoadingId(undefined)
      setPdfBase64(undefined)
      setChosenReportName(undefined)
    }
  }

  return { getPdfBase64, pdfBase64, currentlyLoadingId }
}
