import React from "react"
import { Modal, Button } from "react-bootstrap"
import MarkdownPreview from "@uiw/react-markdown-preview"

interface MaintenancePreviewProps {
  showPreview: boolean
  handlePreviewClose: () => void
  message: string
}

const MaintenancePreview: React.FC<MaintenancePreviewProps> = ({
  showPreview,
  handlePreviewClose,
  message,
}) => {
  return (
    <Modal show={showPreview} onHide={handlePreviewClose}>
      <Modal.Header closeButton>
        <Modal.Title>Maintenance Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MarkdownPreview source={message} style={{ fontSize: "14px" }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handlePreviewClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MaintenancePreview
