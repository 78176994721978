import moment from "moment"
import { PortPredictorVesselSpeed } from "../hooks/usePortPredictorAPI"

import {
  filterForMonths,
  getMonthsAgoLimit,
} from "./PortPredictorDeparture/helpers"

import {
  average as findAverage,
  max as findMax,
  min as findMin,
} from "simple-statistics"
import BigNumber from "bignumber.js"


interface GetPortPredictorSpeedChartOptionsArgs {
  monthsAgoFromToday: number
  speedData: PortPredictorVesselSpeed[]
  isLaden: boolean
}


function getVesselSpeedForMonthsAndLadenOrBallastStatus({
  speedData,
  isLaden,
  monthsAgoFromToday,
}: {
  speedData: PortPredictorVesselSpeed[]
  isLaden: boolean
  monthsAgoFromToday: number
}) {
  const monthsAgoLimit = getMonthsAgoLimit(monthsAgoFromToday)

  const filteredForMonths = filterForMonths({
    vesselSpeed: speedData,
    monthsAgoLimit,
  })

  return isLaden
    ? filteredForMonths.filter((leg) => leg.laden)
    : filteredForMonths.filter((leg) => !leg.laden)
}

export function getPortPredictorSpeedChartOptions({
  monthsAgoFromToday,
  speedData,
  isLaden,
}: GetPortPredictorSpeedChartOptionsArgs) {
  const speedDataFiltered = getVesselSpeedForMonthsAndLadenOrBallastStatus({
    speedData,
    isLaden,
    monthsAgoFromToday
  })

  const minSpeed = speedDataFiltered.length > 0 ? findMin(speedDataFiltered.map((data) => data.sog)) : undefined
  const maxSpeed = speedDataFiltered.length > 0 ? findMax(speedDataFiltered.map((data) => data.sog)) : undefined
  const avgSpeed = speedDataFiltered.length > 0 ? findAverage(speedDataFiltered.map((data) => data.sog)) : undefined

  const titleText = `${monthsAgoFromToday} Months ${isLaden ? "LADEN" : "BALLAST"
    } (Kt) Min: ${minSpeed} Max: ${maxSpeed} Avg: ${avgSpeed && BigNumber(avgSpeed).decimalPlaces(2, BigNumber.ROUND_HALF_CEIL)}`

  let prevMonth = ""

  return {
    title: {
      show: true,
      left: "center",
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontColor: "#5b626b",
        fontWeight: "normal",
        fontFamily: "Source Sans Pro",
      },
    },
    dataset: [
      {
        source: speedDataFiltered,
      },
    ],
    series: [
      {
        name: isLaden ? "LADEN" : "BALLAST",
        type: "line",
        encode: {
          x: "date",
          y: "sog",
          tooltip: ["sog"],
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: isLaden ? "#001446" : "#FF5B29",
          opacity: 0.35,
        },
        symbolSize: 6,
        connectNulls: true,
      },
      {
        name: `${isLaden ? "LADEN" : "BALLAST"} AVG`,
        type: "line",
        showSymbol: false,
        color: "transparent",
        tooltip: {
          show: false,
        },

        markLine: {
          show: true,
          data: [
            {
              name: "average line",
              type: "average",
            },
          ],
          lineStyle: {
            color: isLaden ? "#001446" : "#FF5B29",
          },
        },
        encode: {
          x: "date",
          y: "average",
        },
      },
    ],
    grid: {
      zlevel: 0,
      x: 45,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
      height: 190,
      right: 35,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#849CDB",
        },
      },
    },
    dataZoom: [
      {
        backgroundColor: isLaden ? "#b2b6c3" : "#ffc9bb",
        fillerColor: isLaden ? "#001446" : "#FF5B29",
        bottom: 28,
        textStyle: {
          width: 55,
          color: "#333333",
          fontSize: 10,
        },
        handleSize: "200%",
        moveHandleStyle: {
          opacity: 0,
        },
        type: "slider",
        show: true,
        xAxisIndex: [0],
        start: 0,
        height: 10,
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(1,1,1,0)",
          },
          areaStyle: {
            color: "rgba(1,1,1,0)",
          },
        },
        dataBackground: {
          lineStyle: {
            color: "rgba(1,1,1,0)",
          },
          areaStyle: {
            color: "rgba(1,1,1,0)",
          },
        },
      },
    ],
    color: [isLaden ? "#001446" : "#FF5B29"],
    xAxis: [
      {
        type: "category", // This divides the x-axis by the dates available
        // type: "time", // This will divide the x-axis by ? months (supposedly 6 months, but unsure why)
        splitNumber: 3,
        axisLabel: {
          formatter: (params: any, index: number) => {
            const currMonth = moment(params).format("MMM")
            if (index === speedData.length - 1) {
              // Check if we are on the last iteration of the dataset
              // If so, we always want to return the current month to end the x-axis
              // However the chart will not always choose to render this depending on the space available
              // As such, this is more of a nice to have.
              return currMonth
            }
            if (currMonth === prevMonth) {
              return ""
            }
            prevMonth = currMonth
            return currMonth
          },
          margin: 6,
          hideOverlap: true,
        },
        axisPointer: {
          type: "shadow",
          label: {
            formatter: function (params: any) {
              return moment(new Date(params.value)).format("YYYY-MM-DD")
            },
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        nameRotate: 90,
        nameLocation: "middle",
        nameGap: 0,
        nameTextStyle: {
          fontWeight: "normal",
          fontSize: 12,
        },
        min: minSpeed && Math.floor(minSpeed),
        max: maxSpeed && Math.ceil(maxSpeed),
        axisLabel: {
          formatter: "{value} Kt",
          margin: 6,
          hideOverlap: true,
          showMinLabel: true,
        },
        splitLine: {
          show: false,
        },
      },
    ]
  }
}
