import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BASE_URL } from "api/url_helper"
import {
  ContentType,
  PoolPortalDocument,
  PoolPortalDocuments,
  SelectOption,
  YearObject,
} from "modules/PoolPortal/helpers/types"
import { useUserDocuments } from "modules/PoolPortal/hooks/useUserDocumentsAPI"
import { GenerateYearArray } from "modules/PoolPortal/utils"
import { PdfModal } from "modules/Shared/components/PdfModal"
import { useGetPdfBase64 } from "modules/Shared/hooks/useGetPdfBase64"
import { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Row, Spinner } from "react-bootstrap"
import Select from "react-select"

export function Newletters() {
  const { getPdfBase64, currentlyLoadingId } = useGetPdfBase64()
  const years = GenerateYearArray()

  const [yearfilter, setYearfilter] = useState([
    { label: years[0].name, value: years[0].id },
  ] as SelectOption[])

  const { userDocuments, refetchUserDocuments, isLoadingUserDocuments } =
    useUserDocuments(
      ContentType.NEWSLETTERS,
      "",
      yearfilter ? yearfilter.map((item) => item.value).join(",") : ""
    )

  const [showMore, setShowMore] = useState(false)
  const [userDataDocuments, setUserDataDocuments] = useState(
    [] as PoolPortalDocuments
  )
  const [currentDocument, setCurrentDocument] = useState(
    {} as PoolPortalDocument
  )
  const [showDocument, setShowDocument] = useState(false)

  useEffect(() => {
    setUserDataDocuments(userDocuments)
  }, [userDocuments])

  return (
    <>
      <PdfModal
        idToCheck={currentDocument.id}
        nameFieldName="name"
        isModalShown={showDocument}
        handleClose={() => {
          setShowDocument(false)
        }}
        requestParameters={{
          url: `${BASE_URL}/poolportal/document`,
          params: {
            id: currentDocument.id,
            includefile: true,
          },
        }}
      />
      <div className="PoolPortal_UserContainer">
        <div className="PoolPortal_UserFilter">
          <div className="PoolPortal_UserFilterRow">
            <div className="PoolPortal_UserFilterLabel">
              <h5>Year</h5>
            </div>
            <div style={{ width: "100%" }}>
              <Select
                aria-labelledby="aria-label"
                isMulti
                inputId="aria-example-input"
                name="aria-live-color"
                defaultValue={{ label: years[0].name, value: years[0].id }}
                options={
                  years
                    ? years.map((year: YearObject) => ({
                      label: year.name,
                      value: year.id,
                    }))
                    : []
                }
                onChange={(selectedOptions: any) => {
                  setYearfilter(selectedOptions)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      {isLoadingUserDocuments ? (
        <div className="PoolPortal_LoadingContainer">
          <Spinner as="span" animation="border" size="sm" variant="primary" />
          <span >
            Loading...
          </span>
        </div>
      ) : (
        <div className="PoolPortal_SortedGroupContainer">
          {userDataDocuments &&
            (!showMore ? userDataDocuments.slice(0, 1) : userDataDocuments).map(
              (doc: PoolPortalDocument, index: number) => {
                return (
                  <Row key={doc.id}>
                    <Col className="PoolPortal_DocGroupCol" >
                      <div className="PoolPortal_DocGroupLabel" >
                        <ButtonGroup>
                          <Button
                            size="sm"
                            className="PoolPortal_ActionButtons"
                            onClick={() => {
                              setCurrentDocument({ ...doc })
                              setShowDocument(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} className="fa-thin" />
                          </Button>{" "}
                          <Button
                            size="sm"
                            className="PoolPortal_ActionButtons"
                            onClick={() => {
                              getPdfBase64({
                                id: doc.id,
                                reportName: doc.name,
                                requestParameters: {
                                  url: `${BASE_URL}/poolportal/document`,
                                  params: {
                                    id: doc.id,
                                    includefile: true,
                                  },
                                },
                              })
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="fa-thin"
                            />
                          </Button>{" "}
                        </ButtonGroup>
                        <h5 style={{ paddingLeft: "20px", paddingTop: "5px" }}>{doc.name}</h5>
                      </div>
                      <div>
                        {index === 0 && userDataDocuments.length > 1 && (
                          <Button
                            style={{ width: "80px" }}
                            variant={showMore ? "warning" : "light"}
                            size="sm"
                            className="PoolPortal_ActionButtons"
                            onClick={() => {
                              setShowMore(!showMore)
                            }}
                          >
                            {!showMore ? " More" : " Less"} &nbsp;
                            <FontAwesomeIcon
                              icon={
                                !showMore ? faAngleDoubleDown : faAngleDoubleUp
                              }
                              className="fa-thin"
                            />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                )
              }
            )}
        </div>
      )}
    </>
  )
}
