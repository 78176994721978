import React, { useRef } from "react"
import { useAxiosRequest } from "api/axiosRequest"
import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { toast } from "react-toastify"
import { dataTool } from "echarts"
import { any } from "prop-types"

export const ui_version = (process.env.REACT_APP_UI_VERSION as string).trim()

export function VersionWrapper() {
  const request = useAxiosRequest()
  let mounted = useRef(true)

  const [versionData, setVersionData] = useState<any>()

  useEffect(() => {
    const controller = new AbortController()
    async function getVersionData() {
      try {
        const responseBody = await request<any, any>({
          signal: controller?.signal,
          url: `${BASE_URL}/version`,
        })

        if (mounted.current && responseBody) {
          responseBody.data.ui = {GIT_TAG: ui_version}
          setVersionData(responseBody.data)
        }
      } catch (err: unknown) {
        if (mounted.current) {
          if (axios.isAxiosError(err)) {
            toast.warn("Error getting version data", {
              position: "bottom-right",
              autoClose: 8000,
            })
          }
        }
      }
    }

    getVersionData()

    return () => {
      mounted.current = false
      controller.abort()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Pre-Vetting | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div className="p-4">
            <pre>{JSON.stringify(versionData, null, 2)}</pre>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
