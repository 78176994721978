import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown } from "react-bootstrap"

//i18n
import { withTranslation } from "react-i18next"

// Redux
import { Link } from "react-router-dom"

//Auth0
import { useAuth0 } from "@auth0/auth0-react"

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const { logout } = useAuth0()

  return (
    <React.Fragment>
      <Dropdown
        show={menu}
        onToggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <Dropdown.Toggle
          className="btn header-item noti-icon waves-effect"
          id="page-header-user-dropdown"
          variant="default"
          tag="button"
        >
          <i className="mdi mdi-cog-outline"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Link to="/user-profile" className="dropdown-item">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </Link>
          <div className="dropdown-divider" />
          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              localStorage.clear()
              logout({ returnTo: window.location.origin })
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(ProfileMenu)
