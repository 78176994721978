import Breadcrumbs from "components/Common/Breadcrumbs"
import { ContentType } from "modules/PoolPortal/helpers/types"
import { FormatEnumToString } from "modules/PoolPortal/utils"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { Agreements } from "./Agreements"
import { Hirestatements } from "./HireStatements"
import { Newletters } from "./Newsletters"

export function UsersPoolPortalWrapper({ docType }: { docType: ContentType }) {
  const doc_name = FormatEnumToString(docType)
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>{`Pool Portal - ${doc_name}  | Market Manager Platform`}</title>
        </Helmet>
        <Container fluid>
          {/* <div className="PoolPortal_BreadcrumbBar">
            <Breadcrumbs title="Pool Portal" breadcrumbItem={doc_name} />
          </div> */}

          <div className="main-content_inner">
            <div className="PortPredictor_BreadcrumbBar">
              <Breadcrumbs title="Pool Portal" breadcrumbItem={doc_name} />
            </div>
            {docType === ContentType.AGREEMENTS && <Agreements />}
            {docType === ContentType.HIRE_STATEMENTS && <Hirestatements />}
            {docType === ContentType.NEWSLETTERS && <Newletters />}
          </div>
        </Container>
      </div>
    </>
  )
}
