import axios from "axios"
import moment from "moment"
import { useAxiosRequest } from "api/axiosRequest"

const baseUrl = "http://localhost:1337/api/v1/prevetting/vessel-positions"
// const baseUrl = 'https://bulkview-api.private.klaveness.com/vessel-ops/positions';

export const useFetchPositions = (imo, dateFrom, dateTo, resolution) => {
    const request = useAxiosRequest()
    const params = new URLSearchParams({
        imo: imo,
        date_from: dateFrom,
        date_to: dateTo,
    })

    // const url = `${baseUrl}/${resolution}?${params.toString()}`;

    async function fetchPositions(imo, dateFrom, dateTo, resolution) {

        try {
            const response = await request({ url: baseUrl, params: { segment: "panamax", months: 6, imo } })
            const format = resolution === "daily" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"

            return response.data.map((pos, index, array) => ({
                date: moment(pos.target_date).format(format),
                timestamp: moment(pos.target_date),
                id: pos.pos_id,
                navStaAlias: pos.nav_sta_alias,
                movStatus: pos.mov_status,
                speed: pos.speed,
                lon: pos.lon,
                lat: pos.lat,
                heading: pos.course,
                eventType: pos.event_type,
                eventSubtype: pos.event_subtype,
                cargo: pos.cargo,
                draught: pos.draught,
                eta: pos.eta,
                destination: pos.destination,
            }))
        } catch (error) {
            throw new Error(error)
        }
    }

    return {
        fetchPositions,
    }
}
