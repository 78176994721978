import { PortPredictorWrapper } from "./components/PortPredictorWrapper"

const routes = [
  {
    path: "/port-predictor",
    permissionForPage: "read:port-predictor",
    component: PortPredictorWrapper,
  },
]

const settings = {
  routes,
}

export default settings
