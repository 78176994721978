import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { Formik } from "formik"
import { useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import { Organisation } from "../hooks/useGetOrganisations"
import { ReportsListSingle } from "../hooks/useGetReportsList"
import { AdminReportsUploadFormFormik } from "./AdminReportsUploadFormFormik"

export type UploadEditFields = "reportName" | "reportSummary" | "filePath"

const UploadReportSchema = Yup.object().shape({
  reportName: Yup.string()
    .min(2, "Report name has to be more than 2 characters")
    .max(255, "Report name has to be less than 255 characters")
    .required("Report name is required"),
  reportSummary: Yup.string()
    .max(500, "Report summary has to be less than 500 characters")
    .required("A summary of the report is required"),
  filePath: Yup.string().required("No file uploaded"),
})

export interface ReportFormikValues {
  reportName: string
  reportSummary: string
  filePath: string
}

export type ReportFormikValuesWithUuid = ReportFormikValues & { uuid: string }

interface AdminReportsUploadFormProps {
  handleClose: () => void
  chosenOrganisation: Organisation
  handleCreateNewReport: (payload: ReportsListSingle) => void
  handleEditReport: (payload: ReportsListSingle) => void
  editReportInitialValues: ReportFormikValuesWithUuid | undefined
  isModalInEditMode: boolean
}

interface AdminReportsCreateReportRequest {
  reportName: string
  reportSummary: string
  filePath: string
}

export type AdminReportsEditReportRequest = AdminReportsCreateReportRequest & {
  uuid: string
}

function getRequestOptions(
  isEditMode: boolean,
  options: ReportFormikValues & { uuid?: string }
): AdminReportsCreateReportRequest | AdminReportsEditReportRequest {
  const { uuid, reportName, reportSummary, filePath } = options
  return isEditMode
    ? { reportName, reportSummary, filePath, uuid }
    : {
        reportName,
        reportSummary,
        filePath,
      }
}

export function AdminReportsUploadForm({
  handleClose,
  chosenOrganisation,
  handleCreateNewReport,
  handleEditReport,
  editReportInitialValues,
  isModalInEditMode,
}: AdminReportsUploadFormProps) {
  const request = useAxiosRequest()
  const [fileName, setFileName] = useState<string | undefined>()
  const initialValues = editReportInitialValues
    ? {
        reportName: editReportInitialValues.reportName,
        reportSummary: editReportInitialValues.reportSummary,
        filePath: editReportInitialValues.filePath,
      }
    : {
        reportName: "",
        reportSummary: "",
        filePath: "",
      }

  return (
    <div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={UploadReportSchema}
        onSubmit={async ({ reportName, reportSummary, filePath }) => {
          try {
            const responseBody = await request<
              AdminReportsCreateReportRequest,
              ReportsListSingle
            >({
              url: `${BASE_URL}/reports/single`,
              method: isModalInEditMode ? "PUT" : "POST",
              timeout: 300000,  // max 5mins wait
              data: getRequestOptions(isModalInEditMode, {
                reportName,
                reportSummary: reportSummary,
                filePath: filePath,
                uuid: editReportInitialValues
                  ? editReportInitialValues.uuid
                  : undefined,
              }),
            })
            if (!responseBody) {
              return
            }
            if (isModalInEditMode) {
              handleEditReport(responseBody.data)
              handleClose()
              toast.success(`Report ${reportName} edited!`, {
                position: "bottom-right",
                autoClose: 8000,
              })
              return
            }
            handleCreateNewReport(responseBody.data)
            handleClose()
            toast.success(`Report ${reportName} created!`, {
              position: "bottom-right",
              autoClose: 8000,
            })
          } catch (err: unknown) {
            let message = "Error creating report"
            if (axios.isAxiosError(err)) {
              if (err.response?.status && err.response?.status < 500) {
                const axiosErroMessage = err?.response?.data
                message = axiosErroMessage
                  ? message + ": " + axiosErroMessage
                  : message + "."
              }
            } else if (err instanceof Error) {
              message = message + ": " + err.message
            } else {
              message = "."
            }
            toast.warn(message, {
              position: "bottom-right",
              autoClose: 4000,
            })
          }
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          setFieldTouched,
          setFieldValue,
          isValid,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <div>
              <AdminReportsUploadFormFormik
                chosenOrganisation={chosenOrganisation}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                values={values}
                setFieldTouched={(
                  field: UploadEditFields,
                  isTouched: boolean | undefined
                ) => {
                  setFieldTouched(field, isTouched)
                }}
                setFieldValue={(field: UploadEditFields, value: string) => {
                  setFieldValue(field, value)
                }}
                fileName={fileName}
                setFileName={(fileName: string) => setFileName(fileName)}
                isValid={isValid}
              />
              <div className="d-flex justify-content-end mt-3 mb-1">
                <Button
                  variant="secondary"
                  className="btn-lg"
                  style={{ marginRight: "0.75rem" }}
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                  variant="primary"
                  className="btn-lg"
                  style={{ minWidth: "87.27px" }}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}
