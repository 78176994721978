import { ErrorMessage, Field, Form, Formik } from "formik"
import {
  PoolPortalUser,
  PoolPortalUserpermission,
} from "modules/PoolPortal/helpers/types"
import { useDocuments } from "modules/PoolPortal/hooks/useDocumentsAPI"
import { useOwners } from "modules/PoolPortal/hooks/useOwnersAPI"
import { useUserpermissions } from "modules/PoolPortal/hooks/useUserPermissionsAPI"
import { RemoveDuplicates } from "modules/PoolPortal/utils"
import { useEffect, useState } from "react"
import {
  Form as BootstrapForm,
  Button,
  Offcanvas,
  Spinner,
} from "react-bootstrap"
import Select from "react-select"
import * as Yup from "yup"

interface UserPermissionPageProps {
  show: boolean
  closeHandler: () => void
}

export const UserPermissionPage = ({
  show,
  closeHandler,
}: UserPermissionPageProps) => {
  const { poolUsers, isLoadingPoolUsers } = useDocuments()
  const { owners } = useOwners()
  
  const {
    createUserpermission,
    userpermissions,
    getUserpermissions,
    refetchUserPermissions,
    isLoadingUserPermssions,
  } = useUserpermissions()

  const [permissions, setPermissions] = useState([""])
  const [data, setData] = useState({
    userId: "",
    permissions: "",
  } as PoolPortalUserpermission)

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required("User is required"),
  })

  useEffect(() => {
    if (owners) {
      setPermissions(owners.map((owner) => owner.code))
    }
  }, [owners])

  useEffect(() => {
    const getPermission = async () => {
      getUserpermissions(data.userId)
      refetchUserPermissions()
    }
    getPermission()
  }, [data.userId])

  useEffect(() => {
    if (userpermissions) {
      setData({ ...data, ...userpermissions })
    } else {
      if (data.userId !== "") {
        const getPermission = async () => {
          getUserpermissions(data.userId)
          refetchUserPermissions()
        }
        getPermission()
      } else {
        setData({} as PoolPortalUserpermission)
      }
    }
  }, [userpermissions])

  const handleClose = () => {
    setData({} as PoolPortalUserpermission)
    closeHandler()
  }
  const handleSubmit = async (values: PoolPortalUserpermission) => {
    values.permissions = values.permissions ?? data.permissions
    const newUserPermission = await createUserpermission(values)

    getUserpermissions(newUserPermission.userId)
    refetchUserPermissions()

    handleClose()
  }

  const contructDisplayName = (name: string, email: string): string => {
    return `${name}<${email}>`
  }

  const getPermissionValue = (values: any, data: any) => {
    return values.permissions
      ? values.permissions
          .split(",")
          .map((item: any) => ({ label: item, value: item }))
      : data.permissions
      ? data.permissions
          .split(",")
          .map((item: any) => ({ label: item, value: item }))
      : []
  }

  const getValue = (values: any, data: any) => {
    return permissions
      ? permissions
          .filter((permission) =>
            data.permissions
              ? data.permissions.split(",").includes(permission)
              : false
          )
          .map((permission: string) => ({
            label: permission,
            value: permission,
          }))
      : []
  }
  return (
    <>
      <Offcanvas
        className="PoolPortal_SettingsOffcanvas"
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
        scroll={false}
        style={{ zIndex: 3060 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>User Permissions</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <>
            <Formik
              initialValues={data}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                setFieldValue,
                values,
                touched,
                errors,
                setFieldTouched,
              }) => (
                <>
                  <Form>
                    <div className="PoolPortal_AdminEntryBody d-flex flex-column">
                      <div className="mb-3">
                        <BootstrapForm.Label htmlFor="userId" as="h5">
                          Users:
                        </BootstrapForm.Label>
                        <Field
                          as={Select}
                          disabled={isLoadingPoolUsers}
                          isLoading={isLoadingPoolUsers}
                          onBlur={() => {
                            setFieldTouched("userId", true)
                          }}
                          options={
                            poolUsers
                              ? poolUsers.map((user: PoolPortalUser) => {
                                  return {
                                    label: contructDisplayName(
                                      user.name,
                                      user.email
                                    ),
                                    value: user.user_id,
                                  }
                                })
                              : []
                          }
                          id="userId"
                          name="userId"
                          className="form-control"
                          menuPlacement="auto"
                          value={
                            values.userId
                              ? {
                                  label: values.userLabel,
                                  value: values.userId,
                                }
                              : null
                          }
                          onChange={async (selectedOption: {
                            value: string
                            label: string
                          }) => {
                            setFieldValue(
                              "userLabel",
                              (selectedOption as { label: string }).label
                            )
                            setFieldValue(
                              "userId",
                              (selectedOption as { value: string }).value
                            )
                            await setData({
                              ...data,
                              userId: (selectedOption as { value: string })
                                .value,
                            })

                            getUserpermissions(selectedOption.value)
                            refetchUserPermissions()
                          }}
                          getOptionLabel={(option: { label: any }) =>
                            option.label
                          }
                        />
                        {isLoadingUserPermssions && (
                          <Spinner
                            animation="border"
                            className="ms-2 button-spinner"
                            variant="info"
                          />
                        )}
                        <ErrorMessage
                          name="userId"
                          component="div"
                          className="PoolPortal_ErrorMessage"
                        />
                      </div>

                      <div className="mb-1">
                        <BootstrapForm.Label htmlFor="permissions" as="h5">
                          Permissions:
                        </BootstrapForm.Label>
                        <Field
                          as={Select}
                          onBlur={() => {
                            setFieldTouched("permissions", true)
                          }}
                          options={
                            permissions
                              ? permissions.map((permission: string) => ({
                                  label: permission,
                                  value: permission,
                                }))
                              : []
                          }
                          id="permissions"
                          name="permissions"
                          className="form-control"
                          menuPlacement="auto"
                          isMulti
                          defaultValue={getPermissionValue(values, data)}
                          value={getValue(values, data)}
                          onChange={(
                            selectedOptions:
                              | { label: string; value: string }[]
                              | undefined
                          ) => {
                            const selectedValues = selectedOptions
                              ? (selectedOptions as { value: string }[])
                                  .map((option) => option.value)
                                  .join(",")
                              : ""

                            const permissions = data.permissions
                              ? `${data.permissions},`
                              : ""
                            setFieldValue(
                              "permissions",
                              RemoveDuplicates(`${selectedValues}`)
                            )
                            setData({
                              ...data,
                              permissions: RemoveDuplicates(
                                `${selectedValues}`
                              ),
                            })
                          }}
                          getOptionLabel={(option: { label: any }) =>
                            option.label
                          }
                        />
                        <ErrorMessage
                          name="permissions"
                          component="div"
                          className="PoolPortal_ErrorMessage"
                        />
                      </div>
                    </div>
                    <hr />
                    <div>
                      <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting && (
                          <>
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            &nbsp;&nbsp;
                          </>
                        )}
                        Save
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Button type="button" onClick={handleClose}>
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
