import React, { useState } from "react"
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap"
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableColumnType,
  TableHeader,
  TableRow,
} from "react-bs-datatable"

import MarkdownPreview from "@uiw/react-markdown-preview"
import { BASE_URL } from "api/url_helper"
import moment from "moment-timezone"
import { useGetPdfBase64 } from "../../Shared/hooks/useGetPdfBase64"
import { ReportFormikValuesWithUuid } from "../AdminReports/AdminReportsUploadForm"
import { useDeleteReport } from "../hooks/useDeleteReport"
import { ReportsListSingle } from "../hooks/useGetReportsList"

interface ReportsListTableProps {
  reportsList: ReportsListSingle[]
  openEditModal?: (fields: ReportFormikValuesWithUuid) => void
  openPdfModal: (args: { orgId: string; uuid: string }) => void
  handleDeleteReport?: (uuid: string) => void
}

type ArrayElementType = ReportsListSingle & { actions: any }
export function ReportsListTable({
  reportsList,
  openEditModal,
  openPdfModal,
  handleDeleteReport,
}: ReportsListTableProps): JSX.Element {
  const reportsListWithActions = reportsList.map((props, index) => {
    return { ...props, actions: undefined }
  })
  const { getPdfBase64, currentlyLoadingId } = useGetPdfBase64()
  const { deleteReport, currentlyDeletingUuid } = useDeleteReport({
    handleDeleteReport,
  })

  // State to manage the visibility of the delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteReportName, setDeleteReportName] = useState("")
  // State to store the UUID of the report to be deleted
  const [reportToDeleteUuid, setReportToDeleteUuid] = useState<string | null>(
    null
  )

  const showDeleteConfirmationModal = (uuid: string) => {
    setReportToDeleteUuid(uuid)
    setShowDeleteModal(true)
  }

  const hideDeleteConfirmationModal = () => {
    setShowDeleteModal(false)
    setReportToDeleteUuid(null)
  }

  const TABLE_HEADERS: TableColumnType<ArrayElementType>[] = [
    {
      prop: "reportName",
      title: "Report Name",
      cell: (row) => (
        <span style={{ position: "relative", top: "6px" }}>
          {row.reportName}
        </span>
      ),
    },
    {
      prop: "createdAt",
      title: "Date Created",
      isSortable: true,
      cell: (row) => (
        <span style={{ position: "relative", top: "6px" }}>
          {moment(new Date(row.createdAt)).utc().format("HH:mm DD-MM-YYYY z")}
          {/* {moment(new Date(row.createdAt))
            .tz("Europe/Berlin")
            .format("HH:mm DD-MM-YYYY z")} */}
        </span>
      ),
    },
    {
      prop: "actions",
      title: "Actions",
      cell: (row) => (
        <ButtonGroup>
          <Button
            title="View"
            color="primary"
            className="waves-effect waves-light me-2"
            onClick={() => {
              openPdfModal({
                orgId: row.orgId,
                uuid: row.uuid,
              })
            }}
          >
            <i className="ti-eye"></i>
          </Button>
          <Button
            // If user is currently downloading a pdf, disable buttons to
            // prevent user from downloading multiple pdfs in one go.
            disabled={Boolean(currentlyLoadingId)}
            title="Download"
            color="primary"
            className="waves-effect waves-light me-2"
            onClick={() => {
              getPdfBase64({
                // orgId: row.orgId,
                id: row.uuid,
                reportName: row.reportName,
                requestParameters: {
                  url: `${BASE_URL}/reports/single`,
                  params: {
                    orgId: row.orgId,
                    uuid: row.uuid,
                  },
                },
              })
            }}
          >
            {currentlyLoadingId === row.uuid ? (
              <Spinner
                size="sm"
                variant="light"
                animation="border"
                role="status"
              />
            ) : (
              <i className="ti-download"></i>
            )}
          </Button>
          {openEditModal && (
            <Button
              onClick={() => {
                openEditModal({
                  reportName: row.reportName,
                  reportSummary: row.reportSummary,
                  filePath: row.filePath,
                  uuid: row.uuid,
                })
              }}
              title="Edit"
              color="primary"
              className="waves-effect waves-light me-2"
            >
              <i className="ti-pencil-alt"></i>
            </Button>
          )}
          {handleDeleteReport && (
            <Button
              title="Delete"
              color="primary"
              className="waves-effect waves-light"
              onClick={() => {
                setDeleteReportName(row.reportName)
                showDeleteConfirmationModal(row.uuid)
              }}
            >
              <i className="ti-close"></i>
            </Button>
          )}
        </ButtonGroup>
      ),
    },
  ]
  return (
    <Card className="reports">
      <Card.Header>
        <h5>List of Reports</h5>
      </Card.Header>
      <Card.Body>
        <DatatableWrapper
          body={reportsListWithActions}
          headers={TABLE_HEADERS}
          sortProps={{
            initialState: {
              order: "desc",
              prop: "createdAt",
            },
            sortValueObj: {
              createdAt: (date: unknown) => {
                return new Date(date as string).getTime()
              },
            },
          }}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 20, 50, 100],
            },
          }}
        >
          <Table>
            <TableHeader />
            <TableBody>
              {(rows) => {
                return rows.map((row) => {
                  return (
                    <React.Fragment key={row.uuid}>
                      <TableRow rowData={row} rowIdx={row.uuid} />
                      <tr
                        style={{ borderBottom: "2px solid rgba(0,0,0,0.15)" }}
                        className="tbody-tr"
                      >
                        <td
                          colSpan={3}
                          className="tbody-td"
                          style={{ padding: "0 0.75rem" }}
                        >
                          <Accordion flush>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span style={{ color: "#3050a2" }}>
                                  Report Summary: {row.reportName}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <MarkdownPreview
                                  className="ReportsListTable_Summary"
                                  source={row.reportSummary}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
              }}
            </TableBody>
          </Table>
          <Col className="d-flex flex-col justify-content-end align-items-end">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Card.Body>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={hideDeleteConfirmationModal}
        size={deleteReportName.length > 50 ? "lg" : "sm"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the "{deleteReportName}" report?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideDeleteConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (reportToDeleteUuid) {
                deleteReport(reportToDeleteUuid)
              }
              hideDeleteConfirmationModal()
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  )
}
