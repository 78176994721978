import { useAxiosRequest } from "api/axiosRequest";
import { BASE_URL } from 'api/url_helper';
import { PoolPortalUserpermission } from '../helpers/types';
const POOLPORTAL_API_BASE_URL = `${BASE_URL}/poolportal`;

const UserpermissionService = () => {
  const request = useAxiosRequest()
  const getUserpermissions = async (user_id: string) => {
    try {
      const userpermissionsUrl = `${POOLPORTAL_API_BASE_URL}/userpermissions?user_id=${user_id}`
      const response = await request<any, PoolPortalUserpermission>(
        {
          url: userpermissionsUrl,
          method: "GET"
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const createUserpermission = async (newUserpermission: PoolPortalUserpermission) => {
    try {
      const response = await request<any, PoolPortalUserpermission>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/userpermission`,
          method: "POST",
          data: newUserpermission
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateUserpermission = async (id: string, updatedUserpermission: PoolPortalUserpermission) => {
    try {
      const response = await request<any, PoolPortalUserpermission>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/userpermission`,
          method: "PUT",
          data: updatedUserpermission
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteUserpermission = async (id: string) => {
    try {
      const response = await request<any, PoolPortalUserpermission>(
        {
          url: `${POOLPORTAL_API_BASE_URL}/userpermission?id=${id}`,
          method: "DELETE",
        }
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getUserpermissions,
    createUserpermission,
    updateUserpermission,
    deleteUserpermission
  }
}

export default UserpermissionService
