import moment from "moment"
import { getVesselAge } from "../helpers/addYardActivity"
import {
  VesselStayWithYardActivity,
  getDDinDDYear,
  getLatestTwoDryDock,
  getYearsToDryDock,
} from "../helpers/getLastestDryDock"
import { VesselStay } from "../hooks/useFetchVesselStays"
import { VesselDetails } from "../hooks/usePrevetAPI"

function getDryDockCss({
  yearsToDryDock,
  hasDDinDDYear,
}: {
  yearsToDryDock: number
  hasDDinDDYear: boolean
}) {
  if (yearsToDryDock === 0 && !hasDDinDDYear) {
    return "danger"
  }
  if (yearsToDryDock === 0 && hasDDinDDYear) {
    return "ok"
  }
  if (yearsToDryDock <= 3) {
    return "warning"
  }
  if (yearsToDryDock > 3 && yearsToDryDock < 5) {
    return "ok"
  }
  return "default"
}

function getDDCategory(stay: VesselStayWithYardActivity) {
  if (stay.yard_activity === "DD-S") {
    return "Short"
  }
  if (stay.yard_activity === "DD-M") {
    return "Medium"
  }
  if (stay.yard_activity === "DD-L") {
    return "Long"
  }
  if (stay.yard_activity === "DD-R") {
    return "DD/Repair/IWS"
  }
  if (stay.yard_activity === "DD-Late") {
    if (stay.yard_duration > 25) {
      return "Long & Late"
    }
    if (stay.yard_duration > 14) {
      return "Medium & Late"
    }
    return "Short & Late"
  }
}

function renderLatestDryDock(DDstays: VesselStayWithYardActivity[]) {
  const cautionDD = ["DD-S", "DD-Late", "DD-R"]
  const stays = [...DDstays].reverse() // Stays from oldest to newest
  return stays.map((stay) => {
    const date = moment(stay.date_from)
    const DDCategory = getDDCategory(stay)
    return (
      <span className="me-1" key={stay.date_from}>
        DD: {date.format("MMM")} '{date.format("YY")} {"("}
        {stay.yard_duration} days - {DDCategory}
        {cautionDD.includes(stay.yard_activity) && (
          <i className="ti-alert ms-1" />
        )}
        {")"}
      </span>
    )
  })
}

function getRecentRepair(stays: VesselStay[]) {
  return stays.find((stays) => stays.yard_activity === "R")
}

function getRepairsCount(stays: VesselStay[]) {
  return stays.filter((stay) => stay.yard_activity === "R").length
}

export function PrevetDryDockBadge({
  vesselDetails,
  vesselStays,
}: {
  vesselDetails: VesselDetails
  vesselStays: VesselStay[]
}) {
  const now = moment()
  const currDate = now.format("YYYY-MM-DD")
  const vesselAge = getVesselAge({
    built: Number(vesselDetails.built),
    date: currDate,
  })

  const yearsToDryDock = getYearsToDryDock(Number(vesselDetails.built))
  const lastestTwoDryDock = getLatestTwoDryDock(vesselStays)
  const DDinDDYear = getDDinDDYear(vesselStays, now)
  const oapApply = vesselAge >= 20 ? true : false
  const recentRepair = getRecentRepair(vesselStays)
  const repairCount = getRepairsCount(vesselStays)

  return (
    <div>
      <div
        className={`PrevetDryDockBadge PrevetDryDockBadge--${getDryDockCss({
          yearsToDryDock,
          hasDDinDDYear: DDinDDYear.length > 0,
        })}`}
      >
        <span>
          {yearsToDryDock <= 3 && <i className="ti-alert me-2" />}
          {/* Ships are dry docked every 5th year, so on the fifth year, the ship is performing its best and we reset the years */}
          Est. {yearsToDryDock === 1 ? "yr" : "yrs"} to DD :{" "}
          {yearsToDryDock === 5 ? "0" : yearsToDryDock}{" "}
        </span>
        {lastestTwoDryDock.length > 0 && (
          <span>
            <span className="ms-1 me-1">|</span>
            {renderLatestDryDock(lastestTwoDryDock)}
          </span>
        )}
      </div>
      {(oapApply || repairCount > 0) && (
        <div
          className={`PrevetDryDockBadge PrevetDryDockBadge--${getDryDockCss({
            yearsToDryDock,
            hasDDinDDYear: DDinDDYear.length > 0,
          })} mt-1`}
        >
          <i className="ti-alert me-2" />
          {recentRepair && (
            <>
              <span>
                Yard/Repair/IWS:{" "}
                {moment(recentRepair.date_from).format("MMM 'YY")}
              </span>
              <span className="ms-1 me-1">|</span>
              <span>Total Repairs/IWS: {getRepairsCount(vesselStays)}</span>
            </>
          )}
          {oapApply && (
            <>
              {recentRepair && <span className="ms-1 me-1">|</span>}
              <span>
                OAP may apply (20+ years) | Built: {vesselDetails.built}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  )
}
