import { ErrorMessage, Field, Form, Formik } from "formik"
import {
  PoolPortalOwner,
  PoolPortalPool,
  PoolPortalVessel,
} from "modules/PoolPortal/helpers/types"
import { useOwners } from "modules/PoolPortal/hooks/useOwnersAPI"
import { usePools } from "modules/PoolPortal/hooks/usePoolsAPI"
import { useVessels } from "modules/PoolPortal/hooks/useVesselsAPI"
import { DefaultValidationSchema } from "modules/PoolPortal/utils"
import { Form as BootstrapForm, Button, Spinner } from "react-bootstrap"
import Select from "react-select"
import * as Yup from "yup"

interface VesselsAdminEntryPageProps {
  data: PoolPortalVessel
  setShowEntryBlade: (show: boolean) => void
}

const validationSchema = Yup.object().shape({
  ...DefaultValidationSchema(),
  ownerId: Yup.string().required("Owner is required"),
})

export const VesselsAdminEntryPage = ({
  data,
  setShowEntryBlade,
}: VesselsAdminEntryPageProps) => {
  const { createVessel, updateVessel } = useVessels()

  const { owners } = useOwners()
  const { pools } = usePools()

  const isEditMode = !(Object.keys(data).length === 0 || data.id === "")

  const handleSubmit = async (values: PoolPortalVessel) => {
    // Handle form submission
    if (!isEditMode) {
      await createVessel(values as PoolPortalVessel)
    } else {
      await updateVessel(data.id, {
        ...data,
        ...{
          code: values.code,
          name: values.name,
          description: values.description,
          ownerId: values.ownerId,
        },
      } as PoolPortalVessel)
    }

    setShowEntryBlade(false)
  }

  const handleCancel = () => {
    setShowEntryBlade(false)
  }

  const displayPoolMessage = (ownerId: string) => {
    const foundOwner = owners?.find(
      (owner: PoolPortalOwner) => owner.id === ownerId
    )
    if (foundOwner) {
      const foundPool = pools?.find(
        (pool: PoolPortalPool) => pool.id === foundOwner.poolId
      )
      return foundPool ? `This owner belongs to "${foundPool.name}" pool.` : ""
    }
    return ""
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <>
          <Form>
            <div className="PoolPortal_AdminEntryBody d-flex flex-column">
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="code" as="h5">
                  Code:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="code"
                  name="code"
                  default={data.code}
                  disabled={isEditMode}
                  className="form-control ml-50"
                />
                <ErrorMessage
                  name="code"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>

              <div className="mb-3">
                <BootstrapForm.Label htmlFor="name" as="h5">
                  Name:
                </BootstrapForm.Label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  default={data.name}
                  className="form-control"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>

              <div className="mb-3">
                <BootstrapForm.Label htmlFor="ownerId" as="h5">
                  Owner:
                </BootstrapForm.Label>
                <Field
                  as={Select}
                  options={
                    owners
                      ? owners.map((owner: PoolPortalOwner) => {
                          let ownerLabel = owner.name

                          return { label: ownerLabel, value: owner.id }
                        })
                      : []
                  }
                  id="ownerId"
                  name="ownerId"
                  className="form-control"
                  menuPlacement="auto"
                  // defaultValue={pools ? { label: pools[0].name, value: pools[0].id } : {}}
                  value={
                    values.ownerId
                      ? { label: values.ownerName, value: values.ownerId }
                      : undefined
                  }
                  onChange={async (selectedOption: {
                    value: string
                    label: string
                  }) => {
                    await setFieldValue(
                      "ownerId",
                      (selectedOption as { value: string }).value
                    )
                    await setFieldValue(
                      "ownerName",
                      (selectedOption as { label: string }).label
                    )
                  }}
                  getOptionLabel={(option: { label: any }) => option.label} // Specify how to display the label
                />
                <ErrorMessage
                  name="ownerId"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
                <div>{displayPoolMessage(values.ownerId)}</div>
              </div>
              <div className="mb-3">
                <BootstrapForm.Label htmlFor="description" as="h5">
                  Description:
                </BootstrapForm.Label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  default={data.description}
                  className="form-control"
                  style={{ height: "60px" }}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="PoolPortal_ErrorMessage"
                />
              </div>
            </div>
            <hr />
            <div>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {isSubmitting && (
                  <>
                    <Spinner animation="border" variant="light" size="sm" />
                    &nbsp;&nbsp;
                  </>
                )}
                Save
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button type="button" onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}
