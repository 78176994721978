import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"

export function HomePage() {
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>Home | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div>Choose an option from the sidebar</div>
          {/* <ContentLoading /> */}
        </Container>
      </div>
    </>
  )
}
