
/**
 * Returns last position from an array of positions.
 */
export const getLastPosition = (positions) => {
  if (isValidArray(positions)) {
    return getLastValid(positions, ['lon', 'lat']);
  }

  return null;
};

/**
 * Returns true if line given by two points crosses the -180th antimeridian.
 */
export const crossesAntimeridianLeft = (prev, next) => {
  return prev.lon - next.lon < -180;
};

/**
 * Returns true if line given by two points crosses the 180th antimeridian.
 */
export const crossesAntimeridianRight = (prev, next) => {
  return prev.lon - next.lon > 180;
};

/**
 * Makes a correction of positions to fix antimeridian crossings.
 */
export const fixAntimeridian = (positions) => {
  const posClone = [...positions]
  // const posClone = positions
  for (let i = 0; i < posClone.length - 1; i++) {
    if (isNull(posClone[i].lon)) continue;

    for (let j = i + 1; j < posClone.length; j++) {
      if (!isNull(posClone[j].lon)) {
        if (crossesAntimeridianLeft(posClone[i], positions[j])) {
          for (let k = j; k < posClone.length; k++) {
            if (!isNull(posClone[k].lon)) {
              posClone[k].lon -= 360;
            }
          }
        }

        if (crossesAntimeridianRight(posClone[i], positions[j])) {
          for (let k = j; k < posClone.length; k++) {
            if (!isNull(posClone[k].lon)) {
              posClone[k].lon += 360;
            }
          }
        }

        break;
      }
    }
  }
  return [...positions];
};

/**
 * Returns true if the array is not empty.
 *
 * @param array
 */
export const isValidArray = (array) => {
  return array && Array.isArray(array) && array.length > 0;
};

const isNull = (value) => {
  return value === null || value === undefined;
};

/*
 * Returns last item with not null keys from an array of objects.
 * @param array - array of objects
 * @param keys - array of keys to check
 */
export const getLastValid = (array, keys) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (Array.isArray(keys)) {
      if (keys.every((key) => !isNull(array[i][key]))) {
        return array[i];
      }
    } else {
      if (!isNull(array[i][keys])) {
        return array[i];
      }
    }
  }

  return null;
};