import { useLocation } from "react-router-dom"
import classes from "./sales-page.module.scss"
import { Button } from "react-bootstrap"
// import background from "../../assets/sales-prevetting-desktop.jpeg"

/**
 * To import multiple modules:
 * import foo from "./foo.module.scss"
 * import bar from "./bar.module.scss"
 *
 * const classes = {...foo, ...bar}
 */

const salesPageInfo = {
  "/prevetting": {
    title: "Unlock these insights",
    subtitle: "Pre-vetting by Market Manager",
    question: "Tired of poorly performing vessels?",
    bullets: [
      "An under-performance by 1 knot can reduce your PnL by $100k on a standard voyage",
      "Understand the value of competing vessels",
      "Includes commercial Rightship data, and much more",
    ],
  },
  "/reports-overview": {
    title: "Elevate your decision-making",
    subtitle: "Research as a service by Market Manager",
    question: "Looking for a way to gain a competitive advantage?",
    bullets: [
      "Enhance your commercial decisions with actionable analytics on port, commodity and fleet statistics",
      "Gain strategic foresight through unparalleled insights​",
      "Use our expert advisors to help make informed decisions​",
    ],
  },
  "/freight-optimizer": {
    title: "Optimize your freight savings",
    subtitle: "Freight optimizer by Market Manager",
    question: "Looking to save more on your next freight requirement?",
    bullets: [
      "Executing a laycan at the right time can save you ~$0.50/MT on a 30-day voyage​",
      "Get freight predictions on your selected routes with daily level of granularity​",
      "Stay informed of when best to execute the trade​",
    ],
  },
  "/chartering-insights": {
    title: "Navigate the market with confidence",
    subtitle: "Chartering insights by Market Manager",
    question: "Want to stay ahead of your competition?",
    bullets: [
      "Expertly managed AIS database to support your decision on when to fix​​",
      "Tailored research for each individual in the chartering team​",
      "Real time intelligence on supply and demand dynamics to help you plan ahead​​",
    ],
  },
  "/port-predictor": {
    title: "Sailing towards certainty",
    subtitle: "Port Predictor by Market Manager",
    question: "Tired of vessels missing laycans?",
    bullets: [
      "See port turnaround combined with vessel performance​",
      "Ensure your nominated vessel meets your laycan​​",
      "Have better control over your fleet itinerary to seize the contango market​​​",
    ],
  },
}

function getBackgroundImageClass(path: string) {
  if (path === "/prevetting") {
    return classes.Background_Prevet
  }
  if (path === "/port-predictor") {
    return classes.Background_PortPredictor
  }
  if (path === "/freight-optimizer") {
    return classes.Background_FreightOptimizer
  }
  if (path === "/chartering-insights") {
    return classes.Background_CharteringInsights
  }
  if (path === "/reports-overview") {
    return classes.Background_ReportsOverview
  }
}

export function SalesPage() {
  const { pathname: currentPath } = useLocation()

  const content = salesPageInfo[currentPath as keyof typeof salesPageInfo]

  const backgroundClass = getBackgroundImageClass(currentPath)
  return (
    <div className={`${classes.Container} ${backgroundClass}`}>
      <div className={classes.Header}>
        <div className={classes.Header_Inner}>
          <div className={classes.Title}>{content.title}</div>
          <div className={classes.Subtitle}>{content.subtitle}</div>
        </div>
      </div>
      <div className={classes.Body}>
        <div>
          <div className={`${classes.Title} mb-3`}>{content.question}</div>
          <ul>
            {content.bullets.map((bullet) => {
              return (
                <li className={classes.Bullet} key={bullet}>
                  {bullet}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className={classes.ButtonContainer}>
        <Button
          className={classes.Button}
          href="mailto:support@marketmanager.com"
        >
          <div>CONTACT US</div>
        </Button>
      </div>
    </div>
  )
}
