import { useMap } from 'react-leaflet';

import { CircleMarker } from './CircleMarker';
import { fitMapBounds } from './FitBounds';

export const CircleMarkerGroup = ({
    points,
    validate,
    fitBounds,
    usePointColor,
    tooltip: Tooltip,
    ...props
}) => {
    const map = useMap();
    const positions = validate ? points.filter((p) => p.lat && p.lon) : points;

    if (fitBounds && positions.length > 0) {
        fitMapBounds(map, positions);
    }

    const createMarker = (point, color = null) => {
        return (
            <CircleMarker
                lat={point.lat}
                lon={point.lon}
                minRadius={props.minRadius}
                maxRadius={props.maxRadius}
                weight={props.weight}
                color={color ? color : point.color}
                opacity={props.opacity}
                setView={false}
                key={point.id}
            >
                {Tooltip && <Tooltip data={point} />}
            </CircleMarker>
        );
    };

    return (
        <>
            {usePointColor && positions.map((p) => createMarker(p))}
            {!usePointColor && positions.map((p) => createMarker(p, props.color))}
        </>
    );
};

CircleMarkerGroup.defaultProps = {
    validate: true,
    fitBounds: false,
    usePointColor: false,
    weight: 1,
    minRadius: 3,
    maxRadius: 15,
    color: 'primary',
    opacity: 1
};

export default CircleMarkerGroup;
