import axios from "axios"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { VesselDetails, usePrevetAPI } from "./usePrevetAPI"


export function useFetchVesselDetails() {
  const { getVesselDetails } = usePrevetAPI()

  const [vesselDetailsData, setVesselDetailsData] = useState<
    VesselDetails | undefined
  >()
  const [getVesselDetailsLoading, setVesselDetailsLoading] = useState(false)
  const [getVesselDetailsError, setVesselDetailsError] = useState<
    string | undefined
  >()

  async function fetchVesselDetails(imo: number) {
    setVesselDetailsLoading(true)
    setVesselDetailsData(undefined)
    setVesselDetailsError(undefined)
    try {
      const response = await getVesselDetails(imo)
      if (response) {
        setVesselDetailsData({
          ...response,
        })
      }
    } catch (err: unknown) {
      const message = `Unable to load details of vessel with imo ${imo}.`
      setVesselDetailsError(message)
      if (axios.isAxiosError(err)) {
        toast.warn(message, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
    } finally {
      setVesselDetailsLoading(false)
    }
  }

  return {
    vesselDetailsData,
    clearVesselDetailsData: () => {
      setVesselDetailsData(undefined)
    },
    fetchVesselDetails: useCallback(fetchVesselDetails, []),
    getVesselDetailsLoading,
    getVesselDetailsError,
  }
}
