import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

export interface RightShipInspection {
  required: boolean
  lastInspectionOutcome: "acceptable" | "unacceptable" | "expired"
  lastInspectionValidity: string // date string
  additionalData: null | unknown
}
export interface RightShipVesselInfo {
  ghg: {
    rating: string
    ratingDate: string // utc date
    additionalData: {
      key: "evdi" | "verified" | "plus"
      value: boolean | number
    }[]
  }
  rightshipInspection: RightShipInspection
  rightshipInternalVesselId: number
  safetyScore: {
    safetyScore: number
    date: string
    additionalData: {
      key: "indicativeScore" | "docSafetyScore"
      value: any
    }[]
  }
  platformLink: string
}

export interface RightShipData {
  items: RightShipVesselInfo[]
}

interface GetRightShipDataRequest {
  Imos: string[]
}
export function usePrevetGetRightShipData({ imo }: { imo: number | undefined }) {
  const request = useAxiosRequest()
  const [rightShipData, setRightShipData] = useState<
    RightShipData | undefined
  >()
  const [rightShipDataLoading, setRightShipDataLoading] = useState(false)
  const [rightShipDataError, setRightShipDataError] = useState<
    string | undefined
  >()
  let mounted = useRef(true)

  useEffect(() => {
    mounted.current = true
    const controller = new AbortController()
    if (!imo) {
      return
    }
    getRightShipVesselData({ imo, controller })
    return () => {
      mounted.current = false
      controller.abort()
    }
  }, [imo])

  async function getRightShipVesselData({
    imo,
    controller,
  }: {
    imo: number
    controller: AbortController
  }) {
    setRightShipDataLoading(true)
    setRightShipData(undefined)
    try {
      const responseBody = await request<
        GetRightShipDataRequest,
        RightShipData
      >({
        signal: controller?.signal,
        url: `${BASE_URL}/prevetting/rightship`,
        params: { imo: String(imo) },
      })
      if (mounted.current && responseBody) {
        setRightShipData(responseBody.data)
      }
    } catch (err: unknown) {
      if (mounted.current) {
        const message = `Unable to load RightShip data for vessel with imo ${imo}`
        if (axios.isAxiosError(err)) {
          toast.warn(message, {
            position: "bottom-right",
            autoClose: 8000,
          })
          setRightShipDataError(message)
        }
      }
    } finally {
      if (mounted.current) {
        setRightShipDataLoading(false)
      }
    }
  }

  return {
    rightShipData,
    rightShipDataLoading,
    rightShipDataError,
  }
}
