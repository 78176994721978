import { Form, Spinner } from "react-bootstrap"
import Select, { SingleValue } from "react-select"
import {
  PortPredictorCargoGroupTypes,
  PortPredictorCargoesData,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import { groupCargoes } from "./helpers"
import { PortPredictorChosenCargo } from "./PortPredictorArrivalInputs"
import {
  formatCargoLabelsForDropDown,
  formatCargoLabel,
} from "../portPredictorFormatCargoLabelHelper"

interface PortPredictorCargoInputsProps {
  cargoesData: PortPredictorCargoesData[] | undefined
  isCargoesLoading: boolean
  isCargoesError: boolean
  chosenCargo: PortPredictorChosenCargo
  setChosenCargo: (cargo: PortPredictorChosenCargo) => void
  isDisabled: boolean
}

export function PortPredictorCargoInputs({
  cargoesData,
  isCargoesLoading,
  isCargoesError,
  chosenCargo,
  setChosenCargo,
  isDisabled,
}: PortPredictorCargoInputsProps) {
  return (
    <div className="d-flex flex-column align-items-end">
      <div className="d-flex align-items-center">
        <Form.Label className="PortPredictor_FormLabel fw-bold">
          <>Most Likely Cargo</>
        </Form.Label>
        {cargoesData && (
          <Select
            isDisabled={isDisabled}
            placeholder={
              isCargoesLoading && cargoesData === undefined ? (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                  <span className="ms-1">Loading Cargoes...</span>
                </div>
              ) : (
                "Select Cargo"
              )
            }
            className="PortPredictor_InputGroup"
            options={[
              {
                label: "Major Group",
                options: formatCargoLabelsForDropDown(
                  groupCargoes(cargoesData).major_group
                ),
              },
              {
                label: "Minor Group",
                options: formatCargoLabelsForDropDown(
                  groupCargoes(cargoesData).minor_group
                ),
              },
              {
                label: "Commodities",
                options: formatCargoLabelsForDropDown(
                  groupCargoes(cargoesData).commodities
                ),
              },
            ]}
            value={
              chosenCargo.cargo && chosenCargo.cargoGroup
                ? {
                    label: formatCargoLabel(chosenCargo.cargo),
                    value: chosenCargo.cargo,
                    cargoGroup: chosenCargo.cargoGroup,
                  }
                : undefined
            }
            onChange={(
              cargo: SingleValue<{
                label: string
                value: string
                cargoGroup: PortPredictorCargoGroupTypes
              }>
            ) => {
              if (!cargo) {
                return
              }
              setChosenCargo({
                cargo: cargo.value,
                cargoGroup: cargo.cargoGroup,
              })
            }}
          />
        )}
      </div>
      {isCargoesError && <div>Unable to find cargoes for selected port</div>}
    </div>
  )
}
