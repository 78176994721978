import React from "react"
import { Col } from "react-bootstrap"

//Import Images
import logoDark from "../../../assets/images/logo-dark.png"

export function ContentLoading({ message }: { message?: string }) {
  return (
    <React.Fragment>
      <div className="d-flex align-items-center pb-0 min-vh-50">
        <div className="content-center w-100">
          <div className="container">
            <div className="row justify-content-center">
              <Col xl={6}>
                <div className="row align-items-center">
                  <div className="col-9 col-sm-9 col-md-6 col-lg-6 mx-auto">
                    <div className="row align-items-center">
                      <img
                        src={logoDark}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-1 col-sm-1 col-md-2 col-lg-2 ms-auto">
                        <div
                          role="status"
                          className="content-spinner text-content-loading"
                        >
                          <span className="sr-only">
                            {message ? `Loading ${message}...` : "Loading..."}
                          </span>
                        </div>
                      </div>
                      <div className="col-2 col-sm-2 col-md-2 col-lg-3 ms-1 me-auto">
                        <h5 className="text-content-loading">
                          {message ? `Loading ${message}...` : "Loading..."}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
