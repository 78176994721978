import { Button, Modal, Row } from "react-bootstrap"
import ReactEcharts, { EChartsInstance } from "echarts-for-react"
import { PortPredictorVesselSpeed } from "../hooks/usePortPredictorAPI"

import { getPortPredictorSpeedChartOptions } from "./getPortPredictorSpeedChartOptions"
import { useState } from "react"

interface PortPredictorSpeedGraphModalProps {
  chosenVesselName: string
  isSpeedGraphModalShown: boolean
  closeSpeedGraphModal: () => void
  chosenVesselSpeedData: PortPredictorVesselSpeed[]
}

export function PortPredictorSpeedGraphModal({
  chosenVesselName,
  chosenVesselSpeedData,
  isSpeedGraphModalShown,
  closeSpeedGraphModal,
}: PortPredictorSpeedGraphModalProps) {
  const [monthsAgoFromToday, setMonthsAgoFromToday] = useState(3)
  const ladenData = getPortPredictorSpeedChartOptions({
    speedData: chosenVesselSpeedData,
    isLaden: true,
    monthsAgoFromToday,
  })
  const ballastData = getPortPredictorSpeedChartOptions({
    speedData: chosenVesselSpeedData,
    isLaden: false,
    monthsAgoFromToday,
  })
  return (
    <Modal size="lg" show={isSpeedGraphModalShown}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="OverviewModalLabel">
          Speed Chart: {chosenVesselName}
        </h5>
        <button
          onClick={() => {
            closeSpeedGraphModal()
          }}
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="PortPredictor_SpeedGraphModal">
        <div>
          <Button className="me-2" onClick={() => setMonthsAgoFromToday(3)}>
            3 Months
          </Button>
          <Button onClick={() => setMonthsAgoFromToday(6)}>6 Months</Button>
        </div>
        <Row>
          <ReactEcharts
            style={{
              height: "328px",
            }}
            className="PrevetVesselSpeedChart"
            onEvents={{
              datazoom: (event: any, echarts: EChartsInstance) => {
                const option = echarts.getOption()
              },
            }}
            option={ladenData}
          />
        </Row>
        <Row>
          <ReactEcharts
            style={{
              height: "328px",
            }}
            className="PrevetVesselSpeedChart"
            onEvents={{
              datazoom: (event: any, echarts: EChartsInstance) => {
                const option = echarts.getOption()
              },
            }}
            option={ballastData}
          />
        </Row>
      </div>
    </Modal>
  )
}
