import { useQuery } from "@tanstack/react-query"
import { VesselLeg, usePrevetAPI } from "./usePrevetAPI"

export function useFetchVesselLegs({ imo }: { imo: number | undefined }) {
  const { getVesselLegs } = usePrevetAPI()

  const {
    isLoading: isVesselLegsLoading,
    isError: isVesselLegsError,
    isSuccess: isVesselLegsSuccess,
    data: vesselLegsData,
  } = useQuery({
    // Query keys are unique, only when they change, the query is re-run
    // https://tanstack.com/query/v4/docs/react/guides/query-keys
    queryKey: ["prevetting/getVesselLegs", imo],
    // How to use query functions: https://tanstack.com/query/v4/docs/react/guides/query-functions
    queryFn: () =>
      getVesselLegs(imo as number)
        .then((vesselLegs) => {
          if (!vesselLegs) {
            return undefined
          }

          const vesselLegsWithZoneGroup = vesselLegs.map((leg) => {
            return {
              ...leg,
              zone_group: getZoneGroup(leg.zone_alias)
            }
          })

          return vesselLegsWithZoneGroup
        })
        .catch((err: unknown) => {
          return Promise.reject(
            new Error(`Unable to fecth vessel-legs for IMO ${imo}`)
          )
        }),
    enabled: !!imo,
    staleTime: 1000 * 60 * 10, // 10 minutes
  })

  return {
    vesselLegsData,
    isVesselLegsLoading,
    isVesselLegsError,
    isVesselLegsSuccess,
  }
}

function getZoneGroup(zone_alias: string | undefined) {
  if (!zone_alias) {
    return undefined
  }
  const zoneGroupMap = {
    "S.ATL": "S.ATL",
    "C.PAC": "C.PAC",
    "N.PAC": "N.PAC",
    "S.PAC": "S.PAC",
    "CN/JP": "F.EAST",
    "S.CN.Sea": "S.CN.Sea",
    "GoThlnd": "F.EAST",
    "PPN.Sea": "F.EAST",
    "S.PPN": "F.EAST",
    "E.KALI": "F.EAST",
    "FE.INDO": "N.AUS",
    "SW.KALI": "F.EAST",
    "TORRES": "N.AUS",
    "N.AUS": "N.AUS",
    "S.AUS": "S.AUS",
    "E.AUS": "E.AUS",
    "S.IND.Ocn": "IND.Ocn",
    "C.IND.Ocn": "IND.Ocn",
    "SUEZ.Cnl": "Canal",
    "MED": "MED",
    "ARBN.Sea": "IND.Ocn",
    "B.Bengal": "IND.Ocn",
    "RED.Sea": "RED.Sea",
    "AGN.Sea": "MED",
    "N.Sea": "N.Sea",
    "B.Biscay": "N.ATL",
    "KIEL.Cnl": "Canal",
    "Mgllan.S": "S.ATL",
    "Cp.Horn": "S.ATL",
    "EN.Chnl": "Straits",
    "BAL.Sea": "Baltic",
    "Mar.Sea": "Straits",
    "COGH": "COGH",
    "Bosp.S": "Straits",
    "D'danelle.S": "Straits",
    "BLK.Sea": "BLK.Sea",
    "SG.Strait": "Straits",
    "Hainan.S": "S.CN.Sea",
    "GOA": "RED.Sea",
    "PG": "PG",
    "Malacca.S": "Straits",
    "Sunda.S": "Straits",
    "USG": "USG",
    "N.ATL": "N.ATL"
  } as {
    [key: string]: string | undefined
  }

  return zoneGroupMap[zone_alias]
}










