import React from "react"
import { Card } from "react-bootstrap"
import { Helmet } from "react-helmet-async"

//Import Components
import Header from "./Header"
import Footer from "./Footer"

//Import Assets
import errorImg from "../../../assets/images/404.png"

const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>404 Not Found | Market Manager Platform</title>
      </Helmet>
      <div className="loading-bg d-flex align-items-center pb-0 content-landing">
        <div className="content-center w-100 mb-2">
          <div className="container">
            <Header />
            <div className="row justify-content-center">
              <div className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8 mx-auto">
                <Card>
                  <Card.Body>
                    <div className="row align-items-center">
                      <div className="col-lg-4 col-sm-12 ms-auto">
                        <div className="ex-page-content">
                          <h1 className="text-dark display-1 mt-4">404!</h1>
                          <h4 className="mb-4">Oops, page not found</h4>
                          <p className="mb-5">
                            We had trouble find the page. Would you like to go
                            back and try again?
                          </p>
                          <a
                            className="btn btn-warning mb-5 waves-effect waves-light"
                            href="/"
                          >
                            Back to Dashboard
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-5 col-sm-12 mx-auto">
                        <img
                          src={errorImg}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NotFound
