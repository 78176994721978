import { BASE_URL } from "api/url_helper"
import { ContentLoading } from "components/Layout/Content/ContentLoading"
import { PdfModal } from "modules/Shared/components/PdfModal"
import { useCallback, useReducer, useState } from "react"
import { ReportsListTable } from "../Shared/ReportsListTable"
import { Organisation } from "../hooks/useGetOrganisations"
import {
  ReportsListSingle,
  useGetReportsList,
} from "../hooks/useGetReportsList"
import { AdminReportsUploadBar } from "./AdminReportsUploadBar"
import { AdminReportsUploadEditModal } from "./AdminReportsUploadEditModal"
import { ReportFormikValuesWithUuid } from "./AdminReportsUploadForm"
import {
  AdminReportsState,
  CREATE_NEW_REPORT,
  DELETE_REPORT,
  EDIT_REPORT,
  GET_REPORTSLIST_ERROR,
  GET_REPORTSLIST_LOADING,
  GET_REPORTSLIST_SUCCESS,
  adminReportsReducer,
  initialReportsState,
} from "./adminReportsReducer"

interface RenderAdminReportsListProps {
  adminReportsState: AdminReportsState
  openEditModal: (fields: ReportFormikValuesWithUuid) => void
  openPdfModal: (args: { orgId: string; uuid: string }) => void
  handleDeleteReport: (uuid: string) => void
}

function renderAdminReportsList({
  adminReportsState,
  openEditModal,
  openPdfModal,
  handleDeleteReport,
}: RenderAdminReportsListProps): JSX.Element {
  if (adminReportsState.isLoadingReportsList) {
    return <ContentLoading />
  }
  if (adminReportsState.isErrorReportsList) {
    return (
      <div>We're having problems loading the list of reports at this time.</div>
    )
  }
  return adminReportsState.reportsList ? (
    <ReportsListTable
      openPdfModal={openPdfModal}
      openEditModal={openEditModal}
      handleDeleteReport={handleDeleteReport}
      reportsList={adminReportsState.reportsList}
    />
  ) : (
    <div>
      Unable to load reports although no errors thrown from fetch to /reports
    </div>
  )
}

export function AdminReportsMain({
  organisations,
}: {
  organisations: Organisation[]
}): JSX.Element {
  const [adminReportsState, adminReportsDispatch] = useReducer(
    adminReportsReducer,
    initialReportsState
  )

  const [isModalShown, setIsModalShown] = useState(false)
  const [isPdfModalShown, setIsPdfModalShown] = useState(false)
  const [viewPdfOrgId, setViewPdfOrgId] = useState<string | undefined>()
  const [viewPdfUuid, setViewPdfUuid] = useState<string | undefined>()
  const [isModalInEditMode, setIsModalInEditMode] = useState(false)
  const [editReportInitialValues, setEditReportInitialValues] = useState<
    ReportFormikValuesWithUuid | undefined
  >()

  const defaultOrganisation =
    organisations && organisations.length > 0 ? organisations[0] : null
  const [chosenOrganisation, setChosenOrganisation] =
    useState<Organisation | null>(defaultOrganisation)

  const handleCreateNewReport = useCallback((payload: ReportsListSingle) => {
    adminReportsDispatch({
      type: CREATE_NEW_REPORT,
      payload,
    })
  }, [])

  const handleEditReport = useCallback((payload: ReportsListSingle) => {
    adminReportsDispatch({
      type: EDIT_REPORT,
      payload,
    })
  }, [])

  const handleDeleteReport = useCallback((payload: string) => {
    adminReportsDispatch({
      type: DELETE_REPORT,
      payload,
    })
  }, [])

  const handleGetReportsListSuccess = useCallback(
    (payload: ReportsListSingle[]) => {
      adminReportsDispatch({
        type: GET_REPORTSLIST_SUCCESS,
        payload,
      })
    },
    []
  )

  const handleGetReportsListLoading = useCallback((payload: boolean) => {
    adminReportsDispatch({
      type: GET_REPORTSLIST_LOADING,
      payload,
    })
  }, [])

  const handleGetReportsListError = useCallback(
    (payload: string | undefined) => {
      adminReportsDispatch({
        type: GET_REPORTSLIST_ERROR,
        payload,
      })
    },
    []
  )

  useGetReportsList({
    chosenOrganisation,
    handleGetReportsListSuccess,
    handleGetReportsListLoading,
    handleGetReportsListError,
  })

  return (
    <div>
      <PdfModal
        idToCheck={viewPdfUuid}
        isModalShown={isPdfModalShown}
        handleClose={() => {
          setIsPdfModalShown(false)
        }}
        requestParameters={{
          url: `${BASE_URL}/reports/single`,
          params: {
            uuid: viewPdfUuid,
          },
        }}
      />
      <AdminReportsUploadEditModal
        editReportInitialValues={editReportInitialValues}
        isModalShown={isModalShown}
        isModalInEditMode={isModalInEditMode}
        handleClose={() => {
          setIsModalShown(false)
          // Always set to false on close, even from the default upload mode
          setIsModalInEditMode(false)
          setEditReportInitialValues(undefined)
        }}
        chosenOrganisation={chosenOrganisation}
        handleCreateNewReport={handleCreateNewReport}
        handleEditReport={handleEditReport}
      />
      <AdminReportsUploadBar
        setIsModalShown={(isShown: boolean) => {
          setIsModalShown(isShown)
        }}
        chosenOrganisation={chosenOrganisation}
        setChosenOrganisation={(org) => setChosenOrganisation(org)}
        organisations={organisations}
      />
      <div>
        {renderAdminReportsList({
          adminReportsState,
          handleDeleteReport: (uuid: string) => handleDeleteReport(uuid),
          openPdfModal: ({ orgId, uuid }: { orgId: string; uuid: string }) => {
            setViewPdfOrgId(orgId)
            setViewPdfUuid(uuid)
            setIsPdfModalShown(true)
          },
          openEditModal: (fields: ReportFormikValuesWithUuid) => {
            setIsModalInEditMode(true)
            setIsModalShown(true)
            setEditReportInitialValues(fields)
          },
        })}
      </div>
    </div>
  )
}
