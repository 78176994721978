import { TableColumnType, TableRowType } from "react-bs-datatable"

// UI
export interface SettingsTableDatatableWrapperProps<TTableRowType extends TableRowType> {
  // hasModifiedState: boolean
  headers: TableColumnType<TTableRowType>[]
  body: TTableRowType[]
  newHandler: () => void
  // editHandler: () => void
  // saveHandler: () => void
  // undoHandler: () => void
  // deleteHandler: () => void
}

export enum ContentType {
  HIRE_STATEMENTS = "hire statements",
  AGREEMENTS = "agreements",
  NEWSLETTERS = "newsletters",
}

export enum AdminSettingsEnum {
  DOCUMENTS = "documents",
  VESSELS = "vessel",
  OWNERS = "owners",
  POOLS = "pools",
  CONTENTTYPE = "content_types",
  PARTOFMONTH = "part_of_month",
  OWNERSTORAGESETTING = "owner_storage_setting"
}

export enum UIStateEnum {
  NEW_STATE,
  EDIT_STATE,
}

export type SelectOption = {
  value: string
  label: string
}

export interface YearObject {
  id: string
  name: string
}

export interface MonthObject {
  id: string
  name: string
}


// data
export type PoolPortalListTypes = PoolPortalDocumentsBase | PoolPortalDocuments | PoolPortalVesselsBase | PoolPortalVessels | PoolPortalOwnersBase | PoolPortalOwners | PoolPortalPoolsBase | PoolPortalPools | PoolPortalContenttypesBase | PoolPortalContenttypes | PoolPortalPartofmonthsBase | PoolPortalPartofmonths | PoolPortalPermissionsBase | PoolPortalPermissions | PoolPortalUserpermissionsBase | PoolPortalOwnerstoragesettings | PoolPortalUserpermissionsBase | PoolPortalOwnerstoragesettings
export type PoolPortalSingleTypes = PoolPortalDocumentBase | PoolPortalDocument | PoolPortalVesselBase | PoolPortalVessel | PoolPortalOwnerBase | PoolPortalOwner | PoolPortalPoolBase | PoolPortalPool | PoolPortalContenttypeBase | PoolPortalContenttype | PoolPortalPartofmonthBase | PoolPortalPartofmonth | PoolPortalPermissionBase | PoolPortalPermission | PoolPortalUserpermissionBase | PoolPortalUserpermission | PoolPortalOwnerstoragesettingBase | PoolPortalOwnerstoragesetting


// Documents
export interface PoolPortalDocumentBase {
  id: string
  contenttypeId: string
  contenttypeCode: string
  contenttypeName: string
  contenttypeDescription: string
  poolId: string
  poolCode: string
  poolName: string
  poolDescription: string
  ownerId: string
  ownerCode: string
  ownerName: string
  ownerDescription: string
  vesselId: string
  vesselCode: string
  vesselName: string
  vesselDescription: string
  name: string
  year: string
  month: string
  partofmonthId: string
  partofmonthName: string
  partofmonthDescription: string
  filePath: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string,
  createdByName: string
  updatedByName: string
  createdByEmail: string
  updatedByEmail: string
}
export interface PoolPortalDocument extends PoolPortalDocumentBase {
  // Added for UI
  // isModified?: boolean
  // isOpened?: boolean
  // isNew?: boolean
  // openSeqId?: number
  monthLabel: string
  hasPoolOwnerVesselIds: boolean
}
export type PoolPortalDocuments = PoolPortalDocument[] | undefined
export type PoolPortalDocumentsBase = PoolPortalDocumentBase[] | undefined


// Vessels
export interface PoolPortalVesselBase {
  id: string
  code: string
  name: string
  description: string
  poolId: string
  poolCode: string
  poolName: string
  poolDescription: string
  ownerId: string
  ownerCode: string
  ownerName: string
  ownerDescription: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}
export interface PoolPortalVessel extends PoolPortalVesselBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalVesselsBase = PoolPortalVesselBase[] | undefined
export type PoolPortalVessels = PoolPortalVessel[] | undefined


// Owners
export interface PoolPortalOwnerBase {
  id: string
  code: string
  name: string
  description: string
  poolId: string
  poolCode: string
  poolName: string
  poolDescription: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}
export interface PoolPortalOwner extends PoolPortalOwnerBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalOwnersBase = PoolPortalOwnerBase[] | undefined
export type PoolPortalOwners = PoolPortalOwner[] | undefined


// Pool
export interface PoolPortalPoolBase {
  id: string
  code: string
  name: string
  description: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}
export interface PoolPortalPool extends PoolPortalPoolBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalPoolsBase = PoolPortalPoolBase[] | undefined
export type PoolPortalPools = PoolPortalPool[] | undefined

// Contenttype
export interface PoolPortalContenttypeBase {
  id: string
  code: string
  name: string
  description: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}
export interface PoolPortalContenttype extends PoolPortalContenttypeBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalContenttypesBase = PoolPortalContenttypeBase[] | undefined
export type PoolPortalContenttypes = PoolPortalContenttype[] | undefined

// Partofmonth
export interface PoolPortalPartofmonthBase {
  id: string
  name: string
  description: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}
export interface PoolPortalPartofmonth extends PoolPortalPartofmonthBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalPartofmonthsBase = PoolPortalPartofmonthBase[] | undefined
export type PoolPortalPartofmonths = PoolPortalPartofmonth[] | undefined

// Partofmonth
export interface PoolPortalPermissionBase {
  id: string
  resource_server_identifier: string
  resource_server_name: string
  permission_name: string
  description: string
}
export interface PoolPortalPermission extends PoolPortalPermissionBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalPermissionsBase = PoolPortalPermissionBase[] | undefined
export type PoolPortalPermissions = PoolPortalPermission[] | undefined


// // Documentpermissions
// export interface PoolPortalDocumentpermissionBase {
//   id: string
//   documentId: string
//   permission: string
//   createdBy: string
//   createdAt: string
//   updatedBy: string
//   updatedAt: string
//   isDeleted: boolean
// }
// export interface PoolPortalDocumentpermission extends PoolPortalDocumentpermissionBase {
//   // Added for UI
//   isRowOnEditState?: boolean
// }
// export type PoolPortalDocumentpermissionsBase = PoolPortalDocumentpermissionBase[] | undefined
// export type PoolPortalDocumentpermissions = PoolPortalDocumentpermission[] | undefined

// Pool Users
export interface PoolPortalUserBase {
  user_id: string
  email: string
  name: string
  nickname: string
}

export interface PoolPortalUser extends PoolPortalUserBase {
  // Added for UI
  isRowOnEditState?: boolean
}

export type PoolPortalUsersBase = PoolPortalUserBase[] | undefined
export type PoolPortalUsers = PoolPortalUser[] | undefined

// Partofmonth
export interface PoolPortalUserpermissionBase {
  id: string
  userId: string
  permissions: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
}
export interface PoolPortalUserpermission extends PoolPortalUserpermissionBase {
  // Added for UI
  isRowOnEditState?: boolean
  userLabel: string
}
export type PoolPortalUserpermissionsBase = PoolPortalUserpermissionBase[] | undefined
export type PoolPortalUserpermissions = PoolPortalUserpermission[] | undefined

// Partofmonth
export interface PoolPortalOwnerstoragesettingBase {
  id: string
  ownerId: string
  ownerCode: string
  ownerName: string
  storageName: string
  containerName: string
  containerEndpoint: string
  containerKey: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  isDeleted: boolean
}
export interface PoolPortalOwnerstoragesetting extends PoolPortalOwnerstoragesettingBase {
  // Added for UI
  isRowOnEditState?: boolean
}
export type PoolPortalOwnerstoragesettingsBase = PoolPortalOwnerstoragesettingBase[] | undefined
export type PoolPortalOwnerstoragesettings = PoolPortalOwnerstoragesetting[] | undefined
