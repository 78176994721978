import {
  faFilter,
  faGears,
  faUpload,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BASE_URL } from "api/url_helper"
import { ConstructDocumentTableHeaders } from "modules/PoolPortal/helpers/headers"
import {
  MonthObject,
  PoolPortalContenttype,
  PoolPortalDocument,
  PoolPortalDocuments,
  PoolPortalOwner,
  PoolPortalPool,
  PoolPortalVessel,
  YearObject,
} from "modules/PoolPortal/helpers/types"
import { useContenttypes } from "modules/PoolPortal/hooks/useContenttypesAPI"
import { useDocuments } from "modules/PoolPortal/hooks/useDocumentsAPI"
import { useOwners } from "modules/PoolPortal/hooks/useOwnersAPI"
import { usePools } from "modules/PoolPortal/hooks/usePoolsAPI"
import { useVessels } from "modules/PoolPortal/hooks/useVesselsAPI"
import {
  GenerateMonthArray,
  GenerateYearArray,
  GetMonthLabel,
} from "modules/PoolPortal/utils"
import { PdfModal } from "modules/Shared/components/PdfModal"
import { useGetPdfBase64 } from "modules/Shared/hooks/useGetPdfBase64"
import { useEffect, useState } from "react"
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap"
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable"
import Select from "react-select"
import { MainAdminEntryPage } from "./MainAdminEntryPage"
import { SettingsPage } from "./SettingsPage"
import { UserPermissionPage } from "./UserPermissionPage"

const MainAdminPoolPortal = () => {
  const { documents, isLoading, deleteDocument } = useDocuments()
  const { contenttypes } = useContenttypes()
  const { owners } = useOwners()
  const { pools } = usePools()
  const { vessels } = useVessels()
  const years = GenerateYearArray()
  const months = GenerateMonthArray()
  const { getPdfBase64 } = useGetPdfBase64()

  const [documentsData, setDocumentsData] = useState(
    documents ?? ([] as PoolPortalDocuments)
  )

  type selectOption = { label: string; value: string }[]
  const [contenttypefilter, setContenttypefilter] = useState([] as selectOption)
  const [poolfilter, setPoolfilter] = useState([] as selectOption)
  const [ownerfilter, setOwnerfilter] = useState([] as selectOption)
  const [vesselfilter, setVesselfilter] = useState([] as selectOption)
  const [yearfilter, setYearfilter] = useState([] as selectOption)
  const [monthfilter, setMonthfilter] = useState([] as selectOption)

  const [showSettings, setShowSettings] = useState(false)
  const [showUserPermission, setShowUserPermission] = useState(false)
  const [showUploadDocs, setShowUploadDocs] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showDocument, setShowDocument] = useState(false)

  const [currentDocument, setCurrentDocument] = useState(
    {} as PoolPortalDocument
  )

  useEffect(() => {
    if (documents) {
      const updatedDocs = documents.map((item) => {
        return { ...item, monthLabel: GetMonthLabel(item.month) }
      })
      setDocumentsData([...updatedDocs])
    }
  }, [isLoading, documents])

  const deleteDocumentHandler = (id: string) => {
    // show the pop here and set the currentContenttype for deletion
    const data = documentsData as PoolPortalDocuments
    if (data) {
      const foundIndex = data?.findIndex((x: PoolPortalDocument) => x.id === id)
      setCurrentDocument(data[foundIndex])
      setShowDelete(true)
    }
  }

  const modalDeleteHandler = async () => {
    //  actual deletion
    await deleteDocument(currentDocument.id)
    setShowDelete(false)
  }

  useEffect(() => {
    const contenttypeIds = contenttypefilter.map(
      (contentype) => contentype.value
    )
    const poolIds = poolfilter.map((pool) => pool.value)
    const ownerIds = ownerfilter.map((owner) => owner.value)
    const vesselIds = vesselfilter.map((vessel) => vessel.value)
    const yearIds = yearfilter.map((year) => year.value)
    const monthIds = monthfilter.map((month) => month.value)

    if (documents) {
      const result = documents
        .filter(
          (document) =>
            !contenttypeIds.length ||
            contenttypeIds.includes(document.contenttypeId)
        )
        .filter(
          (document) => !poolIds.length || poolIds.includes(document.poolId)
        )
        .filter(
          (document) => !ownerIds.length || ownerIds.includes(document.ownerId)
        )
        .filter(
          (document) =>
            !vesselIds.length || vesselIds.includes(document.vesselId)
        )
        .filter(
          (document) => !yearIds.length || yearIds.includes(document.year)
        )
        .filter(
          (document) => !monthIds.length || monthIds.includes(document.month)
        )

      const updatedDocs = result.map((item) => {
        return { ...item, monthLabel: GetMonthLabel(item.month) }
      })

      setDocumentsData([...updatedDocs])
    }
  }, [
    contenttypefilter,
    poolfilter,
    ownerfilter,
    vesselfilter,
    yearfilter,
    monthfilter,
    documents,
  ])

  return (
    <>
      <SettingsPage
        show={showSettings}
        closeHandler={() => setShowSettings(false)}
      />
      <UserPermissionPage
        show={showUserPermission}
        closeHandler={() => setShowUserPermission(false)}
      />
      <MainAdminEntryPage
        data={currentDocument}
        showUploadDocs={showUploadDocs}
        setShowUploadDocs={setShowUploadDocs}
        setShowSettings={setShowSettings}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px",
        }}
      >
        <Button
          variant="primary"
          style={{
            alignItems: "center",
            width: "200px",
            minWidth: "200px",
          }}
          onClick={() => {
            setCurrentDocument({} as PoolPortalDocument)
            setShowUploadDocs(true)
          }}
        >
          <FontAwesomeIcon icon={faUpload} className="fa-thin" />
          <span style={{ paddingLeft: "5px" }}>Upload documents</span>
        </Button>
        <div className="PoolPortal_SettingsControl">
          <ButtonGroup>
            <Button
              variant="secondary"
              className="PoolPortal_ActionButtons"
              onClick={() => {
                setShowSettings(true)
              }}
              title="Settings"
            >
              <FontAwesomeIcon icon={faGears} className="fa-thin" />
              <span style={{ paddingLeft: "5px" }}>Settings</span>
            </Button>
            <Button
              variant="secondary"
              className="PoolPortal_ActionButtons"
              onClick={() => {
                setShowUserPermission(true)
              }}
              title="User Permission"
            >
              <FontAwesomeIcon icon={faUser} className="fa-thin" />
              <span style={{ paddingLeft: "5px" }}>User permission</span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Accordion defaultActiveKey="0" aria-disabled className="m-10">
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ padding: "0 10px", fontSize: "100px" }}>
            <FontAwesomeIcon
              icon={faFilter}
              className="fa-regular"
              style={{ padding: "0 10px" }}
            />
            Filter
          </Accordion.Header>
          <Accordion.Body bsPrefix="PoolPortal_AccordionBody">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    Content type:
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      aria-labelledby="aria-label"
                      isMulti
                      inputId="aria-example-input"
                      name="aria-live-color"
                      value={contenttypefilter ?? []}
                      options={
                        contenttypes
                          ? contenttypes.map(
                              (contenttype: PoolPortalContenttype) => ({
                                label: contenttype.name,
                                value: contenttype.id,
                              })
                            )
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        setContenttypefilter(selectedOptions)
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    Pool:
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      aria-labelledby="aria-label"
                      isMulti
                      inputId="aria-example-input"
                      name="aria-live-color"
                      value={poolfilter ?? []}
                      options={
                        pools
                          ? pools.map((pool: PoolPortalPool) => ({
                              label: pool.name,
                              value: pool.id,
                            }))
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        setPoolfilter(selectedOptions)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    Owner:
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      aria-labelledby="aria-label"
                      isMulti
                      inputId="aria-example-input"
                      name="aria-live-color"
                      value={ownerfilter ?? []}
                      options={
                        owners
                          ? owners.map((owner: PoolPortalOwner) => ({
                              label: owner.name,
                              value: owner.id,
                            }))
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        setOwnerfilter(selectedOptions)
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    Vessel:
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      aria-labelledby="aria-label"
                      isMulti
                      inputId="aria-example-input"
                      name="aria-live-color"
                      value={vesselfilter ?? []}
                      options={
                        vessels
                          ? vessels.map((vessel: PoolPortalVessel) => ({
                              label: vessel.name,
                              value: vessel.id,
                            }))
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        setVesselfilter(selectedOptions)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    Year:
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      aria-labelledby="aria-label"
                      isMulti
                      inputId="aria-example-input"
                      name="aria-live-color"
                      value={yearfilter ?? []}
                      options={
                        years
                          ? years.map((year: YearObject) => ({
                              label: year.name,
                              value: year.id,
                            }))
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        setYearfilter(selectedOptions)
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    Month:
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      aria-labelledby="aria-label"
                      isMulti
                      inputId="aria-example-input"
                      name="aria-live-color"
                      value={monthfilter ?? []}
                      options={
                        months
                          ? months.map((month: MonthObject) => ({
                              label: month.name,
                              value: month.id,
                            }))
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        setMonthfilter(selectedOptions)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "end",
                paddingBottom: "10px",
              }}
            >
              <Button
                style={{ width: "150px" }}
                onClick={() => {
                  setContenttypefilter([] as selectOption)
                  setPoolfilter([] as selectOption)
                  setOwnerfilter([] as selectOption)
                  setVesselfilter([] as selectOption)
                  setYearfilter([] as selectOption)
                  setMonthfilter([] as selectOption)
                }}
              >
                Clear all
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />

      <PdfModal
        idToCheck={currentDocument.id}
        nameFieldName="name"
        isModalShown={showDocument}
        handleClose={() => {
          setShowDocument(false)
        }}
        requestParameters={{
          url: `${BASE_URL}/poolportal/document`,
          params: {
            id: currentDocument.id,
            includefile: true,
          },
        }}
      />
      {isLoading ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Spinner as="span" animation="border" size="sm" variant="light" />
          <h5 style={{ paddingLeft: "10px" }}>Loading...</h5>
        </div>
      ) : (
        <div className="PoolPortal_AdminTableContainer">
          <DatatableWrapper
            body={documentsData!}
            headers={ConstructDocumentTableHeaders(
              (id: string) => {
                const doc = documents?.find((doc) => doc.id === id)
                if (doc) {
                  setCurrentDocument({ ...doc })
                  setShowUploadDocs(true)
                }
              },
              deleteDocumentHandler,
              (id: string) => {
                // View Document
                const doc = documents?.find((doc) => doc.id === id)
                if (doc) {
                  setCurrentDocument({ ...doc })
                  setShowDocument(true)
                }
              },
              (id: string, name: string) => {
                // Download Document
                getPdfBase64({
                  id: id,
                  reportName: name,
                  requestParameters: {
                    url: `${BASE_URL}/poolportal/document`,
                    params: {
                      id: id,
                      includefile: true,
                    },
                  },
                })
              }
            )}
            sortProps={{
              initialState: {
                prop: "createdAt",
                order: "desc" as const,
              },
            }}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 50, 100],
              },
            }}
          >
            <Row className="PoolPortal_AdminHeader ">
              <Col xs={12} className="d-flex justify-content-between">
                <div className="PoolPortal_Search">
                  <div className="PoolPortal_SearchControl">
                    <Filter
                      classes={{
                        inputGroup: "PoolPortal_FilterInput",
                        clearButton: "PoolPortal_ClearButton",
                      }}
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Table>
              <TableHeader />
              <TableBody></TableBody>
            </Table>

            <Row className="PrevetVesselHistory_Pagination d-flex flex-row justify-content-between">
              <PaginationOptions labels={{ beforeSelect: "Show results" }} />
              <Pagination />
            </Row>
          </DatatableWrapper>
        </div>
      )}

      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        style={{ zIndex: 5060 }}
        backdropClassName="SettingsDeleteConfirmationBackDrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Contenttype</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the "{currentDocument.name}" document?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={modalDeleteHandler}>
            Delete
          </Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MainAdminPoolPortal
