import Breadcrumbs from "components/Common/Breadcrumbs"
import React from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import { PortPredictor } from "./PortPredictorMain"

export function PortPredictorWrapper() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Port Predictor | Market Manager Platform</title>
        </Helmet>
        <Container fluid>
          <div className="main-content_inner">
            <div className="PortPredictor_BreadcrumbBar">
              <Breadcrumbs
                title="Port Predictor"
                breadcrumbItem="Port Predictor"
              />
            </div>
            <PortPredictor />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
