// import {
//   POOL_PORTAL_ADMIN,
//   POOL_PORTAL_AGREEMENTS,
//   POOL_PORTAL_HIRE_STATEMENTS,
//   POOL_PORTAL_NEWSLETTERS,
//   POOL_PORTAL_POOLOWNERS_FLEETAGE,
//   POOL_PORTAL_POOLOWNERS_FLEETGROWTH,
//   POOL_PORTAL_POOLOWNERS_GLOBAL_CONGESTION,
//   POOL_PORTAL_POOLOWNERS_ORDERBOOK,
//   POOL_PORTAL_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH,
//   POOL_PORTAL_POOLOWNERS_SUPPLY_OVERVIEW,
//   REPORTS_OVERVIEW,
//   appRoutes,
// } from "routes/app-routes"

// import { Hirestatements } from "./components/Users/Hirestatements"
// import { Agreements } from "./components/Users/Agreements"
// import { Newsletters } from "./components/Users/Newsletters"
import { UsersPoolPortalWrapper } from "./components/Users/UsersPoolPortalWrapper"

import { PowerBiWrapper } from "modules/PowerBi/PowerBiWrapper"
import { ContentType } from "./helpers/types"
import { AdminContainer } from "./components/Admin/Container"

// const hire_statements = () => <UsersPoolPortalWrapper doc_type="internal_hire_statements" />
// const newsletters = () => <UsersPoolPortalWrapper doc_type="internal_newsletters" />
// const agreements = () => <UsersPoolPortalWrapper doc_type="internal_agreements" />

// Pool Owners
export const POOL_PORTAL_TITLE_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH =
  "Effective Fleet Growth" // Dup of the one under management
export const POOL_PORTAL_TITLE_POOLOWNERS_SUPPLY_OVERVIEW = "Supply Overview"
export const POOL_PORTAL_TITLE_POOLOWNERS_GLOBAL_CONGESTION =
  "Global Congestion"
export const POOL_PORTAL_TITLE_POOLOWNERS_FLEETGROWTH = "Fleet Growth"
export const POOL_PORTAL_TITLE_POOLOWNERS_ORDERBOOK = "Order Book"
export const POOL_PORTAL_TITLE_POOLOWNERS_FLEETAGE = "Fleet Age"

export const POOL_PORTAL_TITLE = "Pool Portal"

const settings = {
  routes: [
    {
      path: "/admin/pool-portal",
      permissionForPage: "all:ppdocs",
      component: AdminContainer,
    },
    {
      path: "/pool-portal/hire-statements",
      permissionForPage: "read:ppdocs-hirestatements",
      component: () => (
        <UsersPoolPortalWrapper docType={ContentType.HIRE_STATEMENTS} />
      ),
    },
    {
      path: "/pool-portal/agreements",
      permissionForPage: "read:ppdocs-agreements",
      component: () => (
        <UsersPoolPortalWrapper docType={ContentType.AGREEMENTS} />
      ),
    },
    {
      path: "/pool-portal/newsletters",
      permissionForPage: "read:ppdocs-newsletters",
      component: () => (
        <UsersPoolPortalWrapper docType={ContentType.NEWSLETTERS} />
      ),
    },
    // {
    //   path: "/pool-portal/insights/supply-effectivefleetgrowth",
    //   permissionForPage: "read:ci-poolowners-supply-effectivefleetgrowth",
    //   component: () => {
    //     return (
    //       <PowerBiWrapper
    //         reportId={
    //           appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH]
    //             .reportId
    //         }
    //         groupId={
    //           appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH]
    //             .groupId
    //         }
    //         permission={
    //           appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH]
    //             .permission
    //         }
    //         pageName="ReportSection8ed6f049c1ebe0225e22"
    //         title={POOL_PORTAL_TITLE_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH}
    //         sectionTitle={POOL_PORTAL_TITLE}
    //         breadcrumbItem={
    //           POOL_PORTAL_TITLE_POOLOWNERS_SUPPLY_EFFECTIVE_FLEETGROWTH
    //         }
    //       />
    //     )
    //   },
    // },
    // {
    //   path: appRoutes[POOL_PORTAL_POOLOWNERS_FLEETAGE].path,
    //   permissionForPage: appRoutes[POOL_PORTAL_POOLOWNERS_FLEETAGE].permission,
    //   component: () => (
    //     <PowerBiWrapper
    //       reportId={appRoutes[POOL_PORTAL_POOLOWNERS_FLEETAGE].reportId}
    //       groupId={appRoutes[POOL_PORTAL_POOLOWNERS_FLEETAGE].groupId}
    //       permission={appRoutes[POOL_PORTAL_POOLOWNERS_FLEETAGE].permission}
    //       pageName="ReportSection084f876b440a4c067655"
    //       title={POOL_PORTAL_TITLE_POOLOWNERS_FLEETAGE}
    //       sectionTitle={POOL_PORTAL_TITLE}
    //       breadcrumbItem={POOL_PORTAL_TITLE_POOLOWNERS_FLEETAGE}
    //     />
    //   ),
    // },
    // {
    //   path: appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_OVERVIEW].path,
    //   permissionForPage:
    //     appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_OVERVIEW].permission,
    //   component: () => (
    //     <PowerBiWrapper
    //       reportId={appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_OVERVIEW].reportId}
    //       groupId={appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_OVERVIEW].groupId}
    //       permission={
    //         appRoutes[POOL_PORTAL_POOLOWNERS_SUPPLY_OVERVIEW].permission
    //       }
    //       pageName="ReportSection"
    //       title={POOL_PORTAL_TITLE_POOLOWNERS_SUPPLY_OVERVIEW}
    //       sectionTitle={POOL_PORTAL_TITLE}
    //       breadcrumbItem={POOL_PORTAL_TITLE_POOLOWNERS_SUPPLY_OVERVIEW}
    //     />
    //   ),
    // },
    // {
    //   path: appRoutes[POOL_PORTAL_POOLOWNERS_GLOBAL_CONGESTION].path,
    //   permissionForPage:
    //     appRoutes[POOL_PORTAL_POOLOWNERS_GLOBAL_CONGESTION].permission,
    //   component: () => (
    //     <PowerBiWrapper
    //       reportId={
    //         appRoutes[POOL_PORTAL_POOLOWNERS_GLOBAL_CONGESTION].reportId
    //       }
    //       groupId={appRoutes[POOL_PORTAL_POOLOWNERS_GLOBAL_CONGESTION].groupId}
    //       permission={
    //         appRoutes[POOL_PORTAL_POOLOWNERS_GLOBAL_CONGESTION].permission
    //       }
    //       pageName="ReportSection0a53953e4e610601b2ca"
    //       title={POOL_PORTAL_TITLE_POOLOWNERS_GLOBAL_CONGESTION}
    //       sectionTitle={POOL_PORTAL_TITLE}
    //       breadcrumbItem={POOL_PORTAL_TITLE_POOLOWNERS_GLOBAL_CONGESTION}
    //     />
    //   ),
    // },
    // {
    //   path: appRoutes[POOL_PORTAL_POOLOWNERS_FLEETGROWTH].path,
    //   permissionForPage:
    //     appRoutes[POOL_PORTAL_POOLOWNERS_FLEETGROWTH].permission,
    //   component: () => (
    //     <PowerBiWrapper
    //       reportId={appRoutes[POOL_PORTAL_POOLOWNERS_FLEETGROWTH].reportId}
    //       groupId={appRoutes[POOL_PORTAL_POOLOWNERS_FLEETGROWTH].groupId}
    //       permission={appRoutes[POOL_PORTAL_POOLOWNERS_FLEETGROWTH].permission}
    //       pageName="ReportSectiona57686b80c4dda936633"
    //       title={POOL_PORTAL_TITLE_POOLOWNERS_FLEETGROWTH}
    //       sectionTitle={POOL_PORTAL_TITLE}
    //       breadcrumbItem={POOL_PORTAL_TITLE_POOLOWNERS_FLEETGROWTH}
    //     />
    //   ),
    // },
    // {
    //   path: appRoutes[POOL_PORTAL_POOLOWNERS_ORDERBOOK].path,
    //   permissionForPage: appRoutes[POOL_PORTAL_POOLOWNERS_ORDERBOOK].permission,
    //   component: () => (
    //     <PowerBiWrapper
    //       reportId={appRoutes[POOL_PORTAL_POOLOWNERS_ORDERBOOK].reportId}
    //       groupId={appRoutes[POOL_PORTAL_POOLOWNERS_ORDERBOOK].groupId}
    //       permission={appRoutes[POOL_PORTAL_POOLOWNERS_ORDERBOOK].permission}
    //       pageName="ReportSectionb1e86c0e5fc8552fcfb6"
    //       title={POOL_PORTAL_TITLE_POOLOWNERS_ORDERBOOK}
    //       sectionTitle={POOL_PORTAL_TITLE}
    //       breadcrumbItem={POOL_PORTAL_TITLE_POOLOWNERS_ORDERBOOK}
    //     />
    //   ),
    // },
  ],
}
export default settings
