import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { PoolPortalUserpermission } from '../helpers/types'
import UserpermissionService from './userpermissionsService'

export const useUserpermissions = () => {
  const queryClient = useQueryClient()
  const userpermissionService = UserpermissionService()
  const [currentUserID, setCurrentUserID] = useState("")

  const { data: userpermissions, isLoading: isLoadingUserPermssions, refetch: refetchUserPermissions } = useQuery(
    ['userpermissions', currentUserID], // Include uuid in the query key
    ({ queryKey }) => {
      const [_, queryKeyUserID] = queryKey

      try {
        return userpermissionService.getUserpermissions(queryKeyUserID)
      } catch {
        return Promise.resolve({} as PoolPortalUserpermission)
      }
    },
    { enabled: false, refetchOnWindowFocus: false }
  )

  const handleGetUserpermissions = async (userID?: string) => {
    setCurrentUserID(userID || "")
    // Invalidate the queries with the updated query key
    await queryClient.invalidateQueries(['userpermissions', currentUserID])
    await refetchUserPermissions()
  }

  // Mutations for create, update, and delete
  // Create
  const createMutation = useMutation(
    (variables: { newUserpermission: PoolPortalUserpermission }) =>
      userpermissionService.createUserpermission(variables.newUserpermission),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userpermissions'])
      },
      onError: (error: Error) => {
        console.error("Error during createUserpermission:", error.message)
      },
    })

  const handleCreate = async (newUserpermission: PoolPortalUserpermission): Promise<PoolPortalUserpermission> => {
    try {
      const createdUserpermission = await createMutation.mutateAsync({
        newUserpermission,
      })
      return createdUserpermission as PoolPortalUserpermission // Explicitly cast to PoolPortalUserpermission
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to create userpermission - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }
      throw error
    }
  }

  // Update
  const updateMutation = useMutation(
    (variables: { id: string, updatedUserpermission: PoolPortalUserpermission }) => userpermissionService.updateUserpermission(variables.id, variables.updatedUserpermission), {
    onSuccess: () => {
      queryClient.invalidateQueries(['userpermissions'])
    },
  })

  const handleUpdate = async (id: string, updatedUserpermission: PoolPortalUserpermission): Promise<PoolPortalUserpermission> => {
    try {
      const result = await updateMutation.mutateAsync({ id, updatedUserpermission })
      return result as PoolPortalUserpermission
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to update userpermission - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  // Delete
  const deleteMutation = useMutation(userpermissionService.deleteUserpermission, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userpermissions'])
    },
  })

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        const errorMessage = responseData || 'Unknown error occurred';
        toast.warn(`Unable to delete userpermission - ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 8000,
        })
      }

      throw error
    }
  }

  return {
    userpermissions,
    getUserpermissions: handleGetUserpermissions,
    isLoadingUserPermssions,
    refetchUserPermissions,
    createUserpermission: handleCreate,
    updateUserpermission: handleUpdate,
    deleteUserpermission: handleDelete
  }
}
