import React, { useEffect, useState } from "react"
import MaintenanceListWrapper from "./MaintenanceListWrapper"
import { useGetMaintenance } from "modules/MaintenanceNotification/hooks/useGetMaintenance"
import { Spinner } from "react-bootstrap"
import MaintenanceOffcanvas from "../AdminMaintenance/MaintenanceOffcanvas"
import MaintenancePreview from "../AdminMaintenance/MaintenancePreview"
import MaintenanceDelete from "../AdminMaintenance/MaintenanceDelete"
import MaintenanceListTable from "modules/MaintenanceNotification/Shared/MaintenanceListTable"

const MaintenanceLists: React.FC<{ refresh: boolean }> = ({
  refresh,
}) => {
  const { getMaintenance, maintenanceList, isLoading } = useGetMaintenance()
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [isCurrent, setIsCurrent] = useState<boolean>(false)
  const [mode, setMode] = useState<"edit" | "create">("edit")

  useEffect(() => {
    getMaintenance()
  }, [refresh])

  const handlePreview = (id: string) => {
    const allItems = [
      ...(maintenanceList?.Current ?? []),
      ...(maintenanceList?.Previous ?? []),
      ...(maintenanceList?.UpComing ?? []),
    ]

    const maintenanceItem = allItems.find((item) => item.id == id)
    if (maintenanceItem) {
      setMessage(maintenanceItem.maintenanceMesasge)
      setShowPreview(true)
    }
  }

  const handleEdit = (id: string) => {
    setMode("edit")
    const allItems = [
      ...(maintenanceList?.Current ?? []),
      ...(maintenanceList?.Previous ?? []),
      ...(maintenanceList?.UpComing ?? []),
    ]

    const maintenanceItem = allItems.find((item) => item.id == id)
    if (maintenanceItem) {
      setStartDate(maintenanceItem.maintenanceStart)
      setEndDate(maintenanceItem.maintenanceEnd)
      setMessage(maintenanceItem.maintenanceMesasge)
      setSelectedId(id)
      setShowEdit(true)
    }
  }

  const handleCloseEdit = () => {
    setShowEdit(false)
    setSelectedId(null)
  }

  const handleDelete = (id: string) => {
    if(maintenanceList.Current.some(item => item.id === id)){
      setIsCurrent(true)
    }
    const allItems = [
      ...(maintenanceList?.Current ?? []),
      ...(maintenanceList?.Previous ?? []),
      ...(maintenanceList?.UpComing ?? []),
    ]
    const maintenanceItem = allItems.find((item) => item.id == id)
    setStartDate(maintenanceItem!.maintenanceStart)
    setEndDate(maintenanceItem!.maintenanceEnd)
    setSelectedId(id)
    setShowDelete(true)
  }

  const handleDeleteClose = () => {
    setShowDelete(false)
    setSelectedId(null)
    setIsCurrent(false)
  }

  const handleClone = (id: string ) => {
    /* Format date time */

    const formatDateTimeLocal = (dateString: string) => {
      const date = new Date(dateString)
      const offset = date.getTimezoneOffset()
      const localDate = new Date(date.getTime() - offset * 60 * 1000)
      return localDate.toISOString().slice(0, 16)
    }

    const now = new Date()
    const utc8Now = new Date(now.getTime() + 8 * 60 * 60 * 1000)
    const defaultDate = utc8Now.toISOString().slice(0, 16)


    const maintenanceItem = maintenanceList?.Previous.find((item)=> item.id=== id)
    if (maintenanceItem){
      setMode("create")
      setSelectedId(id)
      setMessage(maintenanceItem.maintenanceMesasge)
      setStartDate(formatDateTimeLocal(defaultDate))
      setEndDate(formatDateTimeLocal(defaultDate))
      setShowEdit(true)
    }
  }

  return (
    <div>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading ...</span>
        </Spinner>
      ) : (
        <div>
          <h5 className="mb-3">Ongoing Maintenance</h5>
          {maintenanceList?.Current?.length ? (
            <MaintenanceListTable
              maintenanceItems={maintenanceList.Current}
              onPreview={handlePreview}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ) : (
            <p>No Ongoing Maintenance</p>
          )}
            <h5 className="mb-3">Upcoming Maintenance</h5>
          {maintenanceList?.UpComing?.length ? (
            <MaintenanceListTable
              maintenanceItems={maintenanceList.UpComing}
              onPreview={handlePreview}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ) : (
            <p>No Upcoming Maintenance</p>
          )}
            <h5 className="mb-3">Previous Maintenance</h5>
          {maintenanceList?.Previous?.length ? (
            <MaintenanceListTable
              maintenanceItems={maintenanceList.Previous}
              onPreview={handlePreview}
              onEdit={handleClone}
              onDelete={handleDelete}
              isPrevious = {true}
            />
          ) : (
            <p>No Previous Maintenance</p>
          )}
        </div>
      )}
       <MaintenancePreview
        showPreview={showPreview}
        handlePreviewClose={() => setShowPreview(false)}
        message={message}
      />
      {selectedId !== null && (
        <MaintenanceOffcanvas
          show={showEdit}
          handleClose={handleCloseEdit}
          startDate={startDate}
          endDate={endDate}
          message={message}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setMessage={setMessage}
          handleEditorChange={({ html, text }) => setMessage(text)}
          handlePreviewShow={() => setShowPreview(true)}
          refreshMaintenanceList={getMaintenance}
          mode = {mode}
          id={selectedId}
        />
      )}

      {
        selectedId !== null && (
          <MaintenanceDelete
          showPreview = {showDelete}
          handlePreviewClose={handleDeleteClose}
          id = {selectedId}
          refreshList={getMaintenance}
          isCurrent = {isCurrent}
          startDate={startDate}
          endDate={endDate}/>
        )
      }
    </div>
  )
}

export default MaintenanceLists
