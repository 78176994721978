import { OffSymbol, OnSymbol } from "components/Common/SwitchSymbols"
import { Col, Row, Table } from "react-bootstrap"
import { DatatableWrapper, TableBody } from "react-bs-datatable"
import Select, { SingleValue } from "react-select"
import Switch from "react-switch"
import { VesselDetails } from "../hooks/usePrevetAPI"

interface PrevetModalInfoProps {
  vesselDetails: VesselDetails
  timeWindow: { label: string; value: number }
  setTimeWindow: (option: { label: string; value: number }) => void
  showTradingPatternMarkers: boolean
  setShowTradingPatternMarkers: (isShown: boolean) => void
  showPortStayMarkers: boolean
  setShowPortStayMarkers: (isShown: boolean) => void
}

export interface TimeWindow {
  label: string
  value: number
}

function formatPropertyFromVesselInfo(property: string) {
  if (property === "vessel_name") {
    return "Name"
  }
  return property.length <= 3
    ? property.toUpperCase()
    : property.charAt(0).toUpperCase() +
        property.slice(1).toLowerCase().replace("_", " ")
}

// TODO: Name is old prop from researchAPI, this is done for
// backward compatibility between staging and prod as the
// researchAPI is more updated on staging (13 Apr 2023)

export function PrevetModalInfo({
  vesselDetails,
  timeWindow,
  setTimeWindow,
  showTradingPatternMarkers,
  setShowTradingPatternMarkers,
  showPortStayMarkers,
  setShowPortStayMarkers,
}: PrevetModalInfoProps) {
  const requiredProps = [
    "imo",
    "vessel_name",
    "dwt",
    "built",
    "design",
    "designer",
    "loa",
    "beam",
    "draft",
    "cubic",
    "tpc",
  ]

  const vesselParticularsBody = requiredProps.map((prop) => {
    return {
      details: formatPropertyFromVesselInfo(prop),
      values: vesselDetails[prop as keyof VesselDetails],
    }
  })

  return (
    <>
      <Row className="mt-1">
        <Col md={6} sm={6} xs={6}>
          <h5 className="font-size-14 mt-2 mb-3">Trading Markers</h5>
          <Switch
            uncheckedIcon={<OffSymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#3050A2"
            onChange={(trading_pattern) => {
              setShowTradingPatternMarkers(trading_pattern)
            }}
            checked={showTradingPatternMarkers}
          />
        </Col>
        <Col md={6} sm={6} xs={6}>
          <h5 className="font-size-14 mt-2 mb-3">Port Stay Markers</h5>
          <Switch
            uncheckedIcon={<OffSymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#3050A2"
            onChange={(port_stay) => {
              setShowPortStayMarkers(port_stay)
            }}
            checked={showPortStayMarkers}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h5 className="font-size-14 mt-3 mb-3">
            Trading Pattern Time Window
          </h5>
          <Select
            value={timeWindow}
            onChange={(timeWindow: SingleValue<TimeWindow>) => {
              if (!timeWindow) {
                return
              }
              setTimeWindow(timeWindow)
            }}
            options={[
              {
                options: [
                  { label: "1 Month", value: 1 },
                  { label: "3 Months", value: 3 },
                  { label: "6 Months", value: 6 },
                  { label: "1 year", value: 12 },
                  { label: "2 years", value: 24 },
                ],
              },
            ]}
            classNamePrefix="select2-selection"
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <h5 className="font-size-14 mb-3">Vessel Particulars</h5>
          <DatatableWrapper
            headers={[
              {
                title: "Details",
                prop: "details",
              },
              {
                title: "Values",
                prop: "values",
              },
            ]}
            body={vesselParticularsBody}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 20, 50, 100],
              },
            }}
          >
            <div className="dataTables_wrapper">
              <Table responsive striped className="dataTable">
                <TableBody />
              </Table>
            </div>
          </DatatableWrapper>
        </Col>
      </Row>
    </>
  )
}
